import React, { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { CenterSelector } from "../../components/center-selector/center-selector";
import { ContentPad, ContentViewport, SinglePanel, Utility } from "../../app/app-layout";
import { useCenters } from "../../hooks/use-center/use-centers";
import { useAccessControl } from "../../app/access-control-context";
import { ListHeader } from "../../components/list-header/list-header";
import { Confirm, ConfirmHandle } from "../../components/confirm/confirm";

export const Centers: React.FC = () => {
  const { centers } = useCenters();
  const { activeCenterId, onChangeActiveCenter } = useAccessControl();

  const [filter, setFilter] = useState("");

  const confirmModal = useRef<ConfirmHandle>(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const onPrintRequested = useReactToPrint({
    content: () => tableRef.current
  });

  const onSelectCenter = (id: string) => {
    confirmModal.current &&
      confirmModal.current.confirm(() => {
        onChangeActiveCenter && onChangeActiveCenter(id);
      });
  };

  return (
    <ContentViewport>
      <SinglePanel>
        <Utility>
          <ListHeader filter={filter} onFilterChange={setFilter} onPrintRequested={onPrintRequested} />
        </Utility>
        <ContentPad>
          <CenterSelector
            ref={tableRef}
            filter={filter}
            centers={centers ?? []}
            activeCenterId={activeCenterId}
            onSelectCenter={onSelectCenter}
          />
          <Confirm ref={confirmModal} title="Confirm" message={`Do you want to change your currently active center?`} />
        </ContentPad>
      </SinglePanel>
    </ContentViewport>
  );
};

export const centersLoader = () => {
  return {
    activeTab: "Centers",
    tabQualifier: undefined
  };
};
