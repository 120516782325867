import { useEffect, useMemo, useRef, useState } from "react";
import invariant from "tiny-invariant";
import Modal from "react-bootstrap/Modal";

import { useAccessControl } from "../../app/access-control-context";
import {
  DefaultEditableStaffMemberContactDetail,
  useAvailableCenters,
  useUser,
  type StaffMember,
  type StaffMemberContactDetails
} from "../../hooks/use-user";
import { Confirm, ConfirmHandle } from "../confirm/confirm";
import { LoadingButton } from "../loading-button/loading-button";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import { EditAccountForm, useEditAccountForm } from "./edit-account-form";
import { EditContactForm, useEditContactForm } from "./edit-contact-form";
import { SearchExistingUsers } from "./search-existing-users";
import { useFindUser } from "../../hooks/use-find-user";
import { FormHelpers } from "../forms";
import { ModalToastHeader } from "../../toasts/modal-toast-header";
import { useDebounce } from "../../hooks/use-debounce";

const onboardingSteps = [
  {
    name: "search",
    buttonLabels: { back: "Cancel", next: "Skip" },
    existingStaffButtonLabels: { back: "Cancel", next: "Next" }
  },
  {
    name: "contact",
    buttonLabels: { back: "Back", next: "Next" },
    existingStaffButtonLabels: { back: "Back", next: "Next" }
  },
  {
    name: "account",
    buttonLabels: { back: "Back", next: "Save" },
    existingStaffButtonLabels: { back: "Back", next: "Save" }
  }
] as const;

export const StaffAdministrationOnboardingWizard: React.FC<{
  show: boolean;
  onClose: () => void;
  onSuccessfulSave?: (staffMember: StaffMember) => void;
}> = ({ show, onClose, onSuccessfulSave }) => {
  const { activeCenterId } = useAccessControl();

  const [currentStep, setCurrentStep] = useState(() => {
    const step = onboardingSteps.find(({ name }) => name === "search");
    invariant(step, `No search step found.`);
    return step;
  });
  const currentStepIndex = onboardingSteps.indexOf(currentStep);

  const confirmCloseWithoutSavingModal = useRef<ConfirmHandle>(null);
  const [staffMemberData, setStaffMemberData] = useState<StaffMemberContactDetails | undefined>();

  const { newUserMutator } = useUser(activeCenterId, staffMemberData?.id);
  const availableCentersQuery = useAvailableCenters(staffMemberData?.id);

  const goBack = () => {
    setCurrentStep(prevCurrentStep => onboardingSteps[onboardingSteps.indexOf(prevCurrentStep) - 1]);
  };

  const goNext = () => {
    setCurrentStep(prevCurrentStep => onboardingSteps[onboardingSteps.indexOf(prevCurrentStep) + 1]);
  };

  const editContactForm = useEditContactForm(staffMemberData);
  const onSubmitContactForm = editContactForm.handleSubmit(goNext);

  const editAccountForm = useEditAccountForm(staffMemberData?.hasMailbox ?? false);
  const onSubmitAccountForm = editAccountForm.handleSubmit(editAccountValues => {
    newUserMutator.mutate(
      {
        ...DefaultEditableStaffMemberContactDetail,
        ...staffMemberData,
        ...editContactForm.getValues(),
        ...editAccountValues,
        jobType: "FT"
      },
      {
        onSuccess: newUser => {
          onClose();
          onSuccessfulSave?.(newUser);
        }
      }
    );
  });

  const handleBack = () => {
    if (currentStepIndex === 0) {
      onClose();
    } else {
      goBack();
    }
  };

  const handleNext = () => {
    if (currentStep.name === "search" && availableCentersQuery.data?.length === 0 && staffMemberData?.id) {
      window.toasts.addModalToast({
        content: `${staffMemberData.displayName} already exists in all eligible centers`,
        type: "warning"
      });
      return;
    }

    if (currentStep.name === "search") {
      goNext();
    } else if (currentStep.name === "contact") {
      onSubmitContactForm();
    } else if (currentStep.name === "account") {
      onSubmitAccountForm();
    }
  };

  const localClose = () => {
    if (currentStep.name === "search") {
      onClose();
    } else {
      confirmCloseWithoutSavingModal.current?.confirm(() => {
        onClose();
      });
    }
  };

  useEffect(() => {
    if (show) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setCurrentStep(onboardingSteps.find(({ name }) => name === "search")!);
      setSearchTerm("");
      setSelectedStaffId("");
      editAccountForm.reset();
      editContactForm.reset();
    }
  }, [show]);

  const modalTitle = useMemo(() => {
    if (currentStep.name === "contact") {
      return staffMemberData?.id ? "Edit Contact Information" : "Add Contact Information";
    } else if (currentStep.name === "account") {
      return "Add account information";
    } else {
      return "Search for an Existing User";
    }
  }, [currentStep.name, staffMemberData]);

  // hoisting state for re-render handling
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStaffId, setSelectedStaffId] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 350);

  const { findResults } = useFindUser(debouncedSearchTerm);

  useEffect(() => {
    if (selectedStaffId && findResults) {
      const existing = findResults.find(sm => sm.id === selectedStaffId);
      if (existing) {
        setStaffMemberData({
          ...existing,
          phone: FormHelpers.formatPhoneNumber(existing.phone),
          emergencyContactPhone: FormHelpers.formatPhoneNumber(existing.emergencyContactPhone)
        });
        return;
      }
    }

    // handles either empty id or not found
    setStaffMemberData({ ...DefaultEditableStaffMemberContactDetail });
  }, [selectedStaffId]);

  return (
    <>
      <Modal
        show={show}
        className="onboarding-wizard-modal"
        backdrop="static"
        size="lg"
        fullscreen={"lg-down"}
        centered
        scrollable={true}
      >
        <Modal.Header closeButton onHide={localClose}>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="card">
          <LoadingOverlay loading={newUserMutator.isLoading || availableCentersQuery.isLoading}>
            <div className="onboarding-wizard-modal-body card-body">
              <ModalToastHeader />
              <SearchExistingUsers
                isCurrentStep={currentStep.name === "search"}
                searchTerm={searchTerm}
                onChangeSearchTerm={setSearchTerm}
                selectedStaffId={selectedStaffId}
                onChangeSelectedStaffId={setSelectedStaffId}
                findResults={findResults}
              />
              <EditContactForm editContactForm={editContactForm} isCurrentStep={currentStep.name === "contact"} />
              <EditAccountForm
                isCurrentStep={currentStep.name === "account"}
                availableCenters={availableCentersQuery.data ?? []}
                hasMailbox={staffMemberData?.hasMailbox ?? false}
                editAccountForm={editAccountForm}
              />
            </div>

            <Confirm
              ref={confirmCloseWithoutSavingModal}
              title="Confirm"
              message="Would you like to close the wizard and lose any entered data?"
            />
          </LoadingOverlay>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="w-25">
            <button className="btn btn-secondary w-100" onClick={handleBack}>
              {currentStep.buttonLabels.back}
            </button>
          </div>
          <div>
            Step {currentStepIndex + 1} of {onboardingSteps.length}
          </div>
          <div className="w-25">
            <LoadingButton
              isLoading={newUserMutator.isLoading}
              loadingMessage="Saving..."
              className="btn btn-primary w-100"
              onClick={handleNext}
            >
              {staffMemberData?.id ? currentStep.existingStaffButtonLabels.next : currentStep.buttonLabels.next}
            </LoadingButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
