import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAccessControl } from "../../app/use-access-control";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import type { PhoneActivityPayload } from "./types";
import { timelineQueryKey } from "./use-timeline";

export const useUpsertPhoneActivity = (options: { inquiryId: string; phoneActivityEventId?: string }) => {
  const { activeCenterId } = useAccessControl();
  const { getToken } = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: PhoneActivityPayload) =>
      options.phoneActivityEventId
        ? updatePhoneActivity(
            await getToken(),
            activeCenterId,
            options.inquiryId,
            options.phoneActivityEventId,
            payload
          )
        : createPhoneActivity(await getToken(), activeCenterId, options.inquiryId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(timelineQueryKey(activeCenterId, options.inquiryId));
    }
  });
};

const updatePhoneActivity = (
  accessToken: string,
  centerId: string,
  inquiryId: string,
  phoneActivityEventId: string,
  payload: PhoneActivityPayload
) =>
  callLcosService<PhoneActivityPayload>(
    accessToken,
    `/api/inquiries/${centerId}/${inquiryId}/phone_activity/${phoneActivityEventId}`,
    "PUT",
    payload
  );

export const createPhoneActivity = (
  accessToken: string,
  centerId: string,
  inquiryId: string,
  payload: PhoneActivityPayload
) =>
  callLcosService<PhoneActivityPayload>(
    accessToken,
    `/api/inquiries/${centerId}/${inquiryId}/phone_activity`,
    "POST",
    payload
  );
