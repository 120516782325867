import React from "react";

export interface PencilButtonProps {
  onEdit?: () => void;
  "data-testid"?: string;
}

export const PencilButton: React.FC<PencilButtonProps> = ({ onEdit, "data-testid": testId = "pencil-button" }) => {
  if (!onEdit) {
    return null;
  }

  return (
    <button className="btn btn-danger btn-sm" onClick={onEdit} data-testid={testId}>
      <i className="fas fa-pencil"></i>
      <span className="sr-only">Edit</span>
    </button>
  );
};
