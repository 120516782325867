import { useQuery } from "@tanstack/react-query";

import { useAccessToken } from "./use-access-token";
import { callLcosService } from "../data/lcosServices";
import { StaffMemberContactDetails } from "./use-user";

export interface FindUserResults {
  users: StaffMemberContactDetails[];
}

export const useFindUser = (term: string) => {
  const { getToken } = useAccessToken();

  const getFindUserData = async () => {
    return getFindUserResults(await getToken(), term);
  };

  const findQuery = useQuery({
    queryKey: ["findUserData", term],
    queryFn: getFindUserData,
    enabled: Boolean(term) && term.length > 1
  });

  return {
    findResults: (findQuery.data?.users ?? [])
      .slice()
      .sort((left, right) => left.displayName.localeCompare(right.displayName)),
    isLoading: findQuery.isLoading
  };
};

export const getFindUserResults = (accessToken: string, term: string) => {
  return callLcosService<FindUserResults>(accessToken, `/api/users?search=${term}`);
};
