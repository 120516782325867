import { FC } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { DashboardContact } from "../../../../hooks/use-inquiries";
import { SimpleTable, type SimpleTableHeader } from "../../../table";
import { FormHelpers } from "../../../forms";
import { useAccessControl } from "../../../../app/use-access-control";
import { INQUIRY_EDIT, STUDENT_FAMILY_CREATE_EDIT, STUDENT_FAMILY_VIEW } from "../../../../data/permissions";

interface FamilyContactsTableProps {
  contacts?: DashboardContact[];
  onSelectContact?: (id: number) => void;
}
export const FamilyContactsTable: FC<FamilyContactsTableProps> = ({ contacts, onSelectContact }) => {
  const { hasPermission } = useAccessControl();
  const headers: SimpleTableHeader<DashboardContact>[] = [
    {
      renderKey: "name",
      name: "Name",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: contact => <a href="#">{contact.displayName}</a>,
      tableDataCellProps: {
        isPrimary: true
      }
    },
    {
      renderKey: "email",
      name: "Email",
      render: item => {
        return (
          <OverlayTrigger placement={"top"} delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{item.email}</Tooltip>}>
            <span className="truncate">{item.email}</span>
          </OverlayTrigger>
        );
      }
    },
    {
      renderKey: "preferredPhone",
      name: "Preferred Phone",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: ({ preferredPhone }) => FormHelpers.formatPhoneNumber(preferredPhone)
    },
    {
      key: "source",
      name: "Source"
    },
    {
      key: "contactType",
      name: "Contact Type"
    }
  ];

  return (
    <SimpleTable
      tableCollapseScreenSize="sm"
      tableHover
      tableHasIcons
      data={contacts}
      headers={headers}
      onRowClick={
        hasPermission?.([STUDENT_FAMILY_CREATE_EDIT, STUDENT_FAMILY_VIEW, INQUIRY_EDIT])
          ? row => onSelectContact?.(row.id as number)
          : undefined
      }
      uniqueIdKey="id"
    />
  );
};
