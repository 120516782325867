import { useState } from "react";

import { FormRow, Field, PositionSelector, FormHelpers } from "../forms";
import { useFormInput } from "../../hooks/use-form-input";
import { EditPositionPayload, useModifyPosition, useEditPositionValidator } from "../../hooks/use-modify-position";
import { useAccessControl } from "../../app/use-access-control";
import { StaffMember } from "../../hooks/use-user";
import { EmployeeHistory } from "../../hooks/use-employment-history";
import { useModal } from "../modal/modal";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";

export interface UseEditPositionHistoryModalProps {
  staffMember: StaffMember | undefined;
}

export const useEditPositionHistoryModal = ({ staffMember }: UseEditPositionHistoryModalProps) => {
  const { activeCenterId } = useAccessControl();
  const [initialEndDate, setInitialEndDate] = useState<string>();
  const { show, hide: hideEditPositionHistoryModal, renderModal } = useModal();

  const { buffer, onChangeValue, setBuffer } = useFormInput<EditPositionPayload>({
    id: 0,
    effectiveDate: "",
    end: "",
    position: "",
    jobType: "FT"
  });

  const isValidPositionChange = useEditPositionValidator(buffer);
  const { editPositionMutator } = useModifyPosition(
    activeCenterId,
    staffMember?.id ?? "",
    hideEditPositionHistoryModal
  );

  const onSave = () => {
    editPositionMutator.mutate(buffer);
  };

  const showEditPositionHistoryModal = (employeeHistory: EmployeeHistory) => {
    setBuffer({
      id: employeeHistory?.id ?? 0,
      effectiveDate: employeeHistory?.start ?? "",
      end: employeeHistory?.end,
      position: employeeHistory?.position ?? "",
      jobType: "FT"
    });
    setInitialEndDate(employeeHistory?.end);
    show();
  };

  const renderEditPositionHistoryModal = () =>
    renderModal({
      title: `Position History - ${staffMember?.displayName}`,
      body: (
        <LoadingOverlay loading={editPositionMutator.isLoading}>
          <FormRow>
            <PositionSelector
              label="Position"
              mode="edit"
              required
              value={buffer.position}
              onChangeValue={onChangeValue("position")}
              onValidate={FormHelpers.isValidRequiredString}
            />
          </FormRow>

          <FormRow>
            <Field
              type="date"
              label="Start Date"
              required
              mode="edit"
              value={buffer.effectiveDate}
              onChangeValue={onChangeValue("effectiveDate")}
              onValidate={FormHelpers.isValidRequiredDateString}
            />
          </FormRow>

          <FormRow>
            <Field
              type="date"
              label="End Date"
              required
              mode={initialEndDate ? "edit" : "view"}
              value={buffer.end ?? ""}
              onChangeValue={onChangeValue("end")}
              onValidate={FormHelpers.isValidDateString}
            />
          </FormRow>
        </LoadingOverlay>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideEditPositionHistoryModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={onSave} disabled={!isValidPositionChange}>
            Save
          </button>
        </>
      )
    });

  return {
    hideEditPositionHistoryModal,
    showEditPositionHistoryModal,
    renderEditPositionHistoryModal
  };
};
