import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAccessToken } from "./use-access-token";
import { callLcosService } from "../data/lcosServices";
import { useMemo } from "react";

export interface EmployeeHistory {
  id: number;
  position: string;
  jobType: string;
  start: string;
  end: string;
  separation_reason?: string;
}

export interface EmploymentHistoryResponse {
  employeeHistory: EmployeeHistory[];
}

export const useEmploymentHistory = (centerId: string | undefined, staffMemberId: string | undefined) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const queryKey = ["employmentHistoryData", centerId, staffMemberId];

  const getEmploymentHistoryData = async () => {
    return getEmploymentHistory(await getToken(), centerId, staffMemberId);
  };

  const employmentHistoryQuery = useQuery({
    queryKey,
    queryFn: getEmploymentHistoryData,
    enabled: !!centerId && !!staffMemberId
  });

  const updateMutator = useMutation({
    mutationFn: async (updatedItem: EmployeeHistory) =>
      updateEmploymentHistory(await getToken(), centerId, staffMemberId, updatedItem),

    onSuccess: updatedData => {
      queryClient.invalidateQueries({ queryKey: ["centerStaffData", centerId] });
      return queryClient.setQueryData(queryKey, () => ({ employeeHistory: [...updatedData.employeeHistory] }));
    }
  });

  const createMutator = useMutation({
    mutationFn: async (newItem: EmployeeHistory) =>
      createEmploymentHistory(await getToken(), centerId, staffMemberId, newItem),

    onSuccess: updatedData => {
      queryClient.invalidateQueries({ queryKey: ["centerStaffData", centerId] });
      return queryClient.setQueryData(queryKey, () => ({ employeeHistory: [...updatedData.employeeHistory] }));
    }
  });

  const deleteMutator = useMutation({
    mutationFn: async (employmentHistoryId: number) =>
      deleteEmploymentHistory(await getToken(), centerId, staffMemberId, employmentHistoryId),

    onSuccess: updatedData => {
      queryClient.invalidateQueries({ queryKey: ["centerStaffData", centerId] });
      return queryClient.setQueryData(queryKey, () => ({ employeeHistory: [...updatedData.employeeHistory] }));
    }
  });

  const latest = useMemo(() => {
    if (employmentHistoryQuery.data?.employeeHistory?.length) {
      return employmentHistoryQuery.data.employeeHistory[employmentHistoryQuery.data.employeeHistory.length - 1];
    } else {
      return undefined;
    }
  }, [employmentHistoryQuery.data]);

  return {
    employmentHistory: employmentHistoryQuery.data?.employeeHistory ?? [],
    isLoading: employmentHistoryQuery.isLoading,
    createMutator,
    updateMutator,
    deleteMutator,
    latest
  };
};

export const getEmploymentHistory = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined
) => {
  return callLcosService<EmploymentHistoryResponse>(accessToken, `/api/position/${centerId}/${staffMemberId}`);
};

export const updateEmploymentHistory = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined,
  payload: EmployeeHistory
) => {
  const { id: employmentHistoryId } = payload;
  return callLcosService<EmploymentHistoryResponse>(
    accessToken,
    `/api/staff/${centerId}/${staffMemberId}/employment_history/${employmentHistoryId}`,
    "PUT",
    payload
  );
};

export const createEmploymentHistory = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined,
  payload: EmployeeHistory
) => {
  return callLcosService<EmploymentHistoryResponse>(
    accessToken,
    `/api/staff/${centerId}/${staffMemberId}/employment_history`,
    "POST",
    payload
  );
};

export const deleteEmploymentHistory = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined,
  employmentHistoryId: number
) => {
  return callLcosService<EmploymentHistoryResponse>(
    accessToken,
    `/api/staff/${centerId}/${staffMemberId}/employment_history/${employmentHistoryId}`,
    "DELETE"
  );
};
