import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import { callLcosService } from "../data/lcosServices";
import { useAccessToken } from "./use-access-token";

interface AssignedStaffId {
  centerId: string;
  staffId: string;
  employeeNumber: number | null;
}

export const useUserStaffIds = (userId?: string) => {
  const { getToken } = useAccessToken();
  const queryClient = useQueryClient();

  const assignedStaffIdsQuery = useQuery({
    queryKey: ["getStaffAssignedIds", userId],
    queryFn: async () => {
      invariant(userId, "userId must be defined");
      return getAssignedStaffIds(await getToken(), userId);
    },
    enabled: !!userId,
    select: ({ staffIds }) => Object.fromEntries(staffIds.map(staffId => [staffId.centerId, staffId]))
  });

  const updateStaffIdsMutator = useMutation({
    mutationFn: async (payload: AssignStaffIdPayload) => assignStaffIds(await getToken(), userId || "", payload),
    onSuccess: () => {
      queryClient.invalidateQueries(["getStaffAssignedIds", userId]);
      queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === "getStaffIdList" || query.queryKey[0] === "centerStaffData"
      });
      queryClient.invalidateQueries({
        predicate: query => query.queryKey[0] === "staffMemberData" && query.queryKey[2] === userId
      });
    }
  });

  return {
    assignedStaffIdsLookup: assignedStaffIdsQuery.data,
    isLoading: assignedStaffIdsQuery.isLoading,
    updateStaffIdsMutator
  };
};

const getAssignedStaffIds = (accessToken: string, userId: string) => {
  return callLcosService<{ staffIds: AssignedStaffId[] }>(accessToken, `/api/users/${userId}/staff-ids`, "GET");
};

export interface AssignStaffIdPayload {
  staffIds: {
    centerId: string;
    existsInLegacy: boolean;
    staffId?: string;
    employeeNumber: number | null;
  }[];
}

const assignStaffIds = (accessToken: string, userId: string, payload: AssignStaffIdPayload) => {
  return callLcosService<{ staffIds: AssignedStaffId[] }>(
    accessToken,
    `/api/users/${userId}/staff-ids`,
    "POST",
    payload
  );
};
