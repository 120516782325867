import React from "react";

import { FormRow, Field, FormDividerRow, FormHelpers, CenterMultiSelector } from "../forms";
import { useFormInput } from "../../hooks/use-form-input";
import { SeparationPayload, useModifyPosition, useSeparationValidator } from "../../hooks/use-modify-position";
import { SeparationReasonSelector } from "../forms/legacy/separation-reason-selector";
import { useAccessControl } from "../../app/use-access-control";
import { useStaffMemberCenters } from "../../hooks/use-staff";

import { RadioGroup } from "../forms/radio-group";
import { Checkbox } from "../forms/legacy/checkbox";
import { CenterDetail } from "../../hooks/use-center";
import { formatDateString } from "../forms/form-helpers";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import { useModal } from "../modal/modal";
import { StaffMember } from "../../hooks/use-user";

const accessTerminationOptions = [
  {
    id: "disable_now",
    name: "Deactivate System(s) Access Immediately"
  },
  {
    id: "disable_later",
    name: "Deactivate System(s) Access at 11:59pm on Deactivation Date"
  }
];

export interface UseSeparationModalProps {
  staffMember: StaffMember;
}

export const useSeparationModal = ({ staffMember }: UseSeparationModalProps) => {
  const { buffer, onChangeValue, resetBuffer } = useFormInput<SeparationPayload>({
    separationDate: "",
    separationReason: "",
    disableNow: false,
    eligibleForRehire: staffMember?.isRehireable || false,
    additionalCenters: []
  });

  const { show, hide: hideSeparationModal, renderModal } = useModal();

  const { activeCenterId, hasFeatureAccess } = useAccessControl();
  const isValidSeparation = useSeparationValidator(buffer);
  const { separationMutator } = useModifyPosition(activeCenterId, staffMember?.id, hideSeparationModal);
  const hasRehireAccess = !!hasFeatureAccess?.("eligible_for_rehire_edit");
  const onSave = () => {
    separationMutator.mutate(buffer);
  };

  const { currentCenters, isLoading: isStaffMemberCentersLoading } = useStaffMemberCenters(
    activeCenterId,
    staffMember?.id
  );

  const handleRadioGroupValueChange = (value: string) => {
    const isDisableNow = value === "disable_now";

    onChangeValue("disableNow")(isDisableNow);
    if (isDisableNow) {
      const currentDate = formatDateString(new Date().toDateString(), "yyyy-MM-DD");
      onChangeValue("separationDate")(currentDate);
    }
  };

  const showSeparationModal = () => {
    resetBuffer();
    show();
  };

  const renderSeparationModal = () =>
    renderModal({
      title: `Deactivation - ${staffMember?.displayName}`,
      fullscreen: "md-down",
      body: (
        <LoadingOverlay loading={isStaffMemberCentersLoading}>
          <FormRow>
            <RadioGroup
              value={buffer.disableNow ? "disable_now" : "disable_later"}
              label="Access Deactivation Options"
              options={accessTerminationOptions}
              onChangeValue={handleRadioGroupValueChange}
            />
          </FormRow>
          <FormDividerRow />
          {hasRehireAccess && (
            <FormRow>
              <Checkbox
                value={buffer.eligibleForRehire}
                onChangeValue={onChangeValue("eligibleForRehire")}
                label="Eligible for Reactivation"
              />
            </FormRow>
          )}
          <FormRow>
            <Field
              type="date"
              label="Deactivation Date"
              required
              mode={buffer.disableNow ? "view" : "edit"}
              value={buffer.separationDate}
              onChangeValue={onChangeValue("separationDate")}
              onValidate={FormHelpers.isValidRequiredDateString}
            />
          </FormRow>
          <FormRow>
            <SeparationReasonSelector
              onChangeValue={onChangeValue("separationReason")}
              label="Deactivation Reason"
              required
              mode="edit"
              value={buffer.separationReason}
              onValidate={FormHelpers.isValidRequiredString}
            />
          </FormRow>

          {currentCenters && currentCenters.length && (
            <>
              <FormDividerRow />
              <p>
                Would you like to terminate access for this user for <br />
                more than one Center?
              </p>
              <CenterMultiSelector
                checkedCenters={buffer.additionalCenters}
                availableCenters={currentCenters.filter(item => item.id !== activeCenterId) as CenterDetail[]}
                onChangeValue={onChangeValue("additionalCenters")}
              />
            </>
          )}
        </LoadingOverlay>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideSeparationModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={onSave} disabled={!isValidSeparation}>
            Save
          </button>
        </>
      )
    });

  return {
    hideSeparationModal,
    showSeparationModal,
    renderSeparationModal
  };
};
