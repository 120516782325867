import React, { useRef, useCallback } from "react";

import { AccessControlGate } from "../../app/access-control-gate";
import { Confirm, ConfirmHandle } from "../confirm/confirm";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import * as Permissions from "../../data/permissions";
import { CenterHoliday, useCenterHolidays, CenterDetail } from "../../hooks/use-center";
import { formatDateString } from "../forms/form-helpers";
import { useEditCenterHolidayModal } from "./edit-center-holiday";
import { SimpleTable, SimpleTableHeader } from "../table";
import { IconButton } from "../buttons/icon-button";
import { Card, CardBody, CardHeader } from "react-bootstrap";
import { useAccessControl } from "../../app/use-access-control";

export interface CenterHolidaysProps {
  center?: CenterDetail | undefined;
}

export const requiredPermissionForEditing = Permissions.CENTER_MANAGE_OPERATIONAL_SCHEDULE;

export const CenterHolidays: React.FC<CenterHolidaysProps> = ({ center }) => {
  const { hasPermission } = useAccessControl();
  const { holidays, isLoading, deleteHolidayMutator } = useCenterHolidays(center?.id);

  const confirmDeleteHolidayModal = useRef<ConfirmHandle>(null);

  const { renderEditCenterHolidayModal, showEditCenterHolidayModal } = useEditCenterHolidayModal({
    centerId: center?.id
  });

  const onDeleteHoliday = useCallback((id: string) => {
    confirmDeleteHolidayModal.current &&
      confirmDeleteHolidayModal.current.confirm(() => {
        deleteHolidayMutator.mutate(id);
      });
  }, []);

  const onAddHoliday = useCallback(() => {
    showEditCenterHolidayModal({ id: "", name: "", date: "" });
  }, []);

  const onEditHoliday = useCallback((item: { id: string; name: string; date: string }) => {
    showEditCenterHolidayModal({ id: item.id, name: item.name, date: item.date });
  }, []);

  if (!center || !holidays) {
    return null; // delay render until we have these
  }

  const holidaysClosuresSpecs: SimpleTableHeader<CenterHoliday>[] = [
    {
      renderKey: "date",
      name: "Date",
      render: item => `${formatDateString(item.date, "M/D/yyyy (dddd)")}`,
      tableDataCellProps: {
        isPrimary: true
      }
    },
    {
      key: "name",
      name: "Description"
    }
  ];
  if (hasPermission && hasPermission(requiredPermissionForEditing)) {
    holidaysClosuresSpecs.push({
      renderKey: "action",
      name: "",
      render: item => (
        <>
          <IconButton
            title="Edit"
            icon="edit"
            enableTooltip
            className="btn btn-table"
            onClick={() => onEditHoliday(item)}
          />
          <IconButton
            title="Delete"
            icon="trash"
            enableTooltip
            className="btn btn-table btn-danger"
            onClick={() => onDeleteHoliday(item.id)}
          />
        </>
      ),
      tableDataCellProps: {
        isAction: true,
        textAlign: "right"
      }
    });
  }

  return (
    <Card className="h-100">
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between">
          <h2>Holiday / Emergency Closures</h2>
          <AccessControlGate required={requiredPermissionForEditing}>
            <button className="btn btn-primary" onClick={onAddHoliday}>
              Add Closure
            </button>
            {renderEditCenterHolidayModal()}
            <Confirm ref={confirmDeleteHolidayModal} title="Confirm" message="Would you like to delete this holiday?" />
          </AccessControlGate>
        </div>
      </CardHeader>
      <CardBody>
        <LoadingOverlay loading={isLoading || deleteHolidayMutator.isLoading}>
          <SimpleTable
            tableCollapseScreenSize="lg"
            tableHasIcons
            data={holidays}
            headers={holidaysClosuresSpecs}
            uniqueIdKey="id"
          />
        </LoadingOverlay>
      </CardBody>
    </Card>
  );
};
