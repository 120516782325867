import { useEffect } from "react";
import { Navigate, createBrowserRouter, RouterProvider } from "react-router-dom";

import { StaffAdministration, staffAdministrationLoader } from "../screens/staff-administration/staff-administration";
import { Centers, centersLoader } from "../screens/centers/centers";
import { useAccessControl } from "./access-control-context";
import { LoadingOverlay } from "../components/loading-overlay/loading-overlay";
import * as Permissions from "../data/permissions";
import { CenterContactScreen, centerContactLoader } from "../screens/centers/center-contact-screen";
import { CenterHoursScreen, centerHoursLoader } from "../screens/centers/center-hours-screen";
import { Dashboard, dashboardLoader } from "../screens/dashboard/dashboard";
import { CenterNotesScreen, centerNotesLoader } from "../screens/centers/center-notes-screen";
import { InquiryManagement, inquiryManagementLoader } from "../screens/inquiry/inquiry-management";
import { NoAssignedCenterError } from "./no-assigned-center-error";
import { InquiryFormScreen, inquiryFormLoader } from "../screens/inquiry/inquiry-form-screen";
import { useStaffMemberCenters } from "../hooks/use-staff";

import { RootLayout } from "./root-layout";
import { centerWebsiteLoader, CenterWebsiteScreen } from "../screens/centers/center-website-screen";
import { appointmentCalendarLoader, AppointmentCalendarScreen } from "../screens/dashboard/appointment-calendar";

export const AppRouter = () => {
  const { hasPermission, loading, centerCount, hasFeatureAccess, activeCenter, profile } = useAccessControl();

  const { addTrackStaffCenterMutator } = useStaffMemberCenters(activeCenter.id, profile?.id);

  // track center login
  useEffect(() => {
    addTrackStaffCenterMutator.mutate();
  }, [activeCenter, profile]);

  if (!hasPermission || loading || !hasFeatureAccess) {
    // delays rendering until required data is loaded
    return <LoadingOverlay loading={true} />;
  }

  if (centerCount === 0) {
    throw new NoAssignedCenterError("User not authorized");
  }

  const Fallback = () => {
    if (hasFeatureAccess("dashboard")) {
      return <Navigate to="/dashboard" replace />;
    } else if (hasPermission(Permissions.STAFF_VIEW)) {
      return <Navigate to="/staff-administration" replace />;
    } else {
      return <Navigate to="/centers" replace />;
    }
  };

  const router = createBrowserRouter([
    {
      path: "/",
      Component: RootLayout,
      children: [
        { index: true, element: <Fallback /> },
        ...(hasFeatureAccess("dashboard")
          ? [
              {
                path: "dashboard/:status?",
                element: <Dashboard />,
                loader: dashboardLoader
              }
            ]
          : []),

        ...(hasFeatureAccess("appointment_calendar")
          ? [
              {
                path: "dashboard/calendar",
                element: <AppointmentCalendarScreen />,
                loader: appointmentCalendarLoader
              }
            ]
          : []),
        { path: "centers", element: <Centers />, loader: centersLoader },
        { path: "centers/contact", element: <CenterContactScreen />, loader: centerContactLoader },
        ...(hasPermission([
          Permissions.CENTER_MANAGE_OPERATIONAL_SCHEDULE,
          Permissions.CENTER_VIEW_OPERATIONAL_SCHEDULE
        ])
          ? [
              {
                path: "centers/hours",
                element: <CenterHoursScreen />,
                loader: centerHoursLoader
              }
            ]
          : []),
        { path: "centers/notes", element: <CenterNotesScreen />, loader: centerNotesLoader },
        ...(hasFeatureAccess("consumer_website")
          ? [
              {
                path: "centers/website",
                element: <CenterWebsiteScreen />,
                loader: centerWebsiteLoader
              }
            ]
          : []),
        ...(hasPermission(Permissions.STAFF_VIEW)
          ? [
              {
                path: "staff-administration/:status?",
                element: <StaffAdministration />,
                loader: staffAdministrationLoader
              }
            ]
          : []),

        // ...(hasFeatureAccess("students") && hasPermission(Permissions.STUDENT_MANAGEMENT_VIEW)
        //   ? [
        //       {
        //         path: "student-administration/:status?",
        //         element: <StudentAdministration />,
        //         loader: studentAdministrationLoader
        //       }
        //     ]
        //   : []),
        ...(hasFeatureAccess("inquiries") && hasPermission(Permissions.STUDENT_MANAGEMENT_VIEW)
          ? [
              {
                path: "inquiry/:inquiryId?",
                element: <InquiryManagement />,
                loader: inquiryManagementLoader
              },
              {
                path: "inquiry/form/:id?",
                element: <InquiryFormScreen />,
                loader: inquiryFormLoader
              }
            ]
          : []),

        {
          path: "*",
          element: <Fallback />
        }
      ]
    }
  ]);

  return <RouterProvider router={router} />;
};
