import { FC, useEffect, useMemo, useState } from "react";
import { SearchComboboxItem, SearchCombobox } from "./search-combobox";
import { CenterStaff, useLegacyStaffIdList } from "../../hooks/use-legacy-staff-id-list";

interface LegacyUserSearchProps {
  onSelect?: (item?: CenterStaff) => void;
  selected: string;
  centerId: string;
}

export const LegacyUserSearch: FC<LegacyUserSearchProps> = ({ selected, onSelect, centerId }) => {
  const { staffIds, isLoading } = useLegacyStaffIdList(centerId);

  const items = useMemo(() => {
    if (!staffIds.length) {
      return [{ value: "", label: "No Results Found", searchable: "", disabled: true }];
    }
    const results = staffIds.map(user => ({
      value: user.staffId,
      label: `${user.lastName}, ${user.firstName} (${user.staffId})`,
      searchable: `${user.lastName}, ${user.firstName} (${user.staffId})`
    }));

    return results;
  }, [staffIds]);

  const [selectedItem, setSelectedItem] = useState<SearchComboboxItem | undefined>();

  const handleSelectChange = (item?: SearchComboboxItem | null) => {
    setSelectedItem(item ?? { value: "", label: "", searchable: "" });
  };

  useEffect(() => {
    if (selectedItem) {
      const staffMember = staffIds.find(st => st.staffId === selectedItem.value);
      staffMember && onSelect && onSelect(staffMember);
      return;
    }
  }, [selectedItem, onSelect]);

  const initialInputValue = useMemo(() => {
    const item = items.find(i => i.value === selected);
    return item?.label ?? "";
  }, [selected, items]);

  return (
    <>
      <SearchCombobox
        items={items}
        onSelectChange={handleSelectChange}
        label={"Search for Legacy User"}
        isLoading={isLoading}
        initialInputValue={initialInputValue}
      />
    </>
  );
};
