import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAccessToken } from "../use-access-token";
import { callLcosService } from "../../data/lcosServices";
import { CenterDetail, CentersList } from "./types";

export const useCenters = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const getCentersData = async () => {
    return getCentersList(await getToken());
  };

  const centersQuery = useQuery({ queryKey: ["centersData"], queryFn: getCentersData });

  const centerMutator = useMutation({
    mutationFn: async (updatedData: CenterDetail) => updateCenter(await getToken(), updatedData),
    onSuccess: updated => {
      queryClient.setQueryData<CentersList>(["centersData"], (previous: CentersList | undefined) => {
        // one of those probably impossible in practice but need to account for since react-query
        // theoretically allows it
        // istanbul ignore if
        if (!previous || !previous.centers) {
          return {
            centers: [{ ...updated }]
          };
        } else {
          return {
            centers: [...previous.centers.filter(c => c.id !== updated.id), { ...updated }]
          };
        }
      });
    }
  });

  return {
    centers: centersQuery.data?.centers,
    isLoading: centersQuery.isLoading,
    centerMutator
  };
};

export const getCentersList = (accessToken: string) => {
  return callLcosService<CentersList>(accessToken, "/api/centers");
};

export const updateCenter = (accessToken: string, payload: CenterDetail) => {
  const { id: centerId } = payload;
  return callLcosService<CenterDetail>(accessToken, `/api/centers/${centerId}`, "PUT", payload);
};
