import { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { OperatingHours, OperatingHoursType, useCenterOperatingHours } from "../../hooks/use-center";
import { useAccessControl } from "../../app/use-access-control";
import { Checkbox } from "../forms/legacy/checkbox";
import { useModal } from "../modal/modal";
import { SimpleTable, SimpleTableHeader } from "../table";
import { useReferenceData } from "../../hooks/use-reference-data";
import { InputField } from "../forms/input-field";
import { LoadingButton } from "../loading-button/loading-button";

const centerHoursForWeek = z.object({
  days: z.array(
    z
      .object({
        weekday: z.number(),
        isClosed: z.boolean().default(false),
        openTime: z.string(),
        closeTime: z.string(),
        startApptTime: z.string(),
        endApptTime: z.string(),
        seasonStart: z.string(),
        seasonEnd: z.string()
      })
      .refine(val => !(!val.isClosed && !val.openTime), {
        message: "error",
        path: ["openTime"]
      })
      .refine(val => !(!val.isClosed && !val.closeTime), {
        message: "error",
        path: ["closeTime"]
      })
  )
});

type CenterHoursForWeekSchema = z.infer<typeof centerHoursForWeek>;

export const transformForSchema = (input: OperatingHours[]): CenterHoursForWeekSchema => {
  const days = input.map(row => {
    return {
      ...row,
      weekday: +row.weekday,
      isClosed: !row.openTime && !row.closeTime
    };
  });
  return { days };
};

export const transformForApi = (input: CenterHoursForWeekSchema): OperatingHours[] => {
  return input.days.map(row => {
    return {
      ...row,
      id: 0,
      weekday: row.weekday
    };
  });
};

export interface UseEditCenterHoursModalProps {
  subset: OperatingHoursType;
}

const dayArray = [
  { weekday: 1 },
  { weekday: 2 },
  { weekday: 3 },
  { weekday: 4 },
  { weekday: 5 },
  { weekday: 6 },
  { weekday: 7 }
];

const defaultHours: OperatingHours = {
  id: 0,
  weekday: 0,
  openTime: "",
  closeTime: "",
  startApptTime: "",
  endApptTime: "",
  seasonStart: "",
  seasonEnd: ""
};

export const useEditCenterHoursWeekModal = ({ subset }: UseEditCenterHoursModalProps) => {
  const { weekdayLookup } = useReferenceData();
  const { show, hide: hideEditCenterHoursWeekModal, renderModal } = useModal();
  const { activeCenterId } = useAccessControl();
  const { operatingHoursWeeklyEditMutator, brandStandardHours } = useCenterOperatingHours(activeCenterId);

  const {
    register,
    reset,
    watch,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<CenterHoursForWeekSchema>({
    mode: "onTouched",
    resolver: zodResolver(centerHoursForWeek)
  });

  // this intentionally does not have a dep array so that it runs every render
  useEffect(() => {
    const firstInvalid = document
      .querySelector(".center-hour-week-edit-table")
      ?.querySelector<HTMLInputElement>(".is-invalid:first-of-type");
    firstInvalid && firstInvalid.focus();
  });

  const [allowOutsideBrandStandardHours, setAllowOutsideBrandStandardHours] = useState<boolean>(false);
  const [isOutsideBrandStandardHours, setIsOutsideBrandStandardHours] = useState<boolean>(false);
  const localSave = handleSubmit(data => {
    if (!allowOutsideBrandStandardHours && subset === "default") {
      const days = getValues("days");
      let outsideBrandHours = false;
      clearErrors(`days`);

      brandStandardHours.forEach(standard => {
        const index = days.findIndex(d => +d.weekday === +standard.weekday);

        // Brand Standard hours are not defined for closed days
        if (days[index]?.isClosed) {
          setError(`days.${index}.isClosed`, { type: "custom" });
        }

        const isOpenAfterStandard = moment(days[index]?.openTime, "HH:mm:ss").isAfter(
          moment(standard.openTime, "HH:mm:ss")
        );
        const isCloseBeforeStandard = moment(days[index]?.closeTime, "HH:mm:ss").isBefore(
          moment(standard.closeTime, "HH:mm:ss")
        );

        if (isOpenAfterStandard) {
          setError(`days.${index}.openTime`, { type: "custom" });
        }
        if (isCloseBeforeStandard) {
          setError(`days.${index}.closeTime`, { type: "custom" });
        }

        outsideBrandHours = outsideBrandHours || days[index]?.isClosed || isOpenAfterStandard || isCloseBeforeStandard;
      });

      if (outsideBrandHours) {
        setIsOutsideBrandStandardHours(true);
        return;
      }
    }

    operatingHoursWeeklyEditMutator.mutate(
      { operatingHours: transformForApi(data), seasonalHours: false },
      {
        onSuccess: () => {
          hideEditCenterHoursWeekModal();
        }
      }
    );
  });

  const showEditCenterHoursWeekModal = (brandHours: OperatingHours[]) => {
    setAllowOutsideBrandStandardHours(subset !== "default");
    setIsOutsideBrandStandardHours(false);
    reset(transformForSchema(brandHours));
    show();
  };

  const tableHeaders: SimpleTableHeader<{ weekday: number }>[] = [
    {
      renderKey: "weekday",
      name: "Day",
      render: item => <h3>{weekdayLookup[item.weekday]}</h3>
    },
    {
      renderKey: "openTime",
      name: "Open",
      render: (item, index) => {
        const isClosed = watch(`days.${index}.isClosed` as const);
        if (!isClosed) {
          return (
            <InputField
              disableColClass
              type="time"
              step="900"
              label=""
              validationState={errors.days?.[index]?.openTime === undefined ? undefined : "error"}
              data-testid={`week-hours-edit-open-time-${item.weekday}`}
              key={`week-hours-edit-open-time-${item.weekday}`}
              {...register(`days.${index}.openTime` as const)}
            />
          );
        }
        return (
          <em className={errors.days?.[index]?.isClosed ? "outside-standard-hours no-border" : undefined}>Closed</em>
        );
      }
    },
    {
      renderKey: "closeTime",
      name: "Close",
      render: (item, index) => {
        const isClosed = watch(`days.${index}.isClosed` as const);
        if (!isClosed) {
          return (
            <InputField
              disableColClass
              type="time"
              step="900"
              label=""
              validationState={errors.days?.[index]?.closeTime === undefined ? undefined : "error"}
              data-testid={`week-hours-edit-close-time-${item.weekday}`}
              key={`week-hours-edit-close-time-${item.weekday}`}
              {...register(`days.${index}.closeTime` as const)}
            />
          );
        }
        return <em></em>;
      }
    },
    {
      renderKey: "close",
      name: "Close",
      render: (item, index) => {
        const isClosed = watch(`days.${index}.isClosed` as const);

        const onChangeValue = (newvalue: boolean) => {
          setValue(`days.${index}.isClosed`, newvalue);
          if (newvalue) {
            setValue(`days.${index}.openTime`, "");
            setValue(`days.${index}.closeTime`, "");
          } else {
            const dayOfWeek = getValues(`days.${index}.weekday`);
            const defaultDay = brandStandardHours.find(day => +day.weekday === +dayOfWeek) ?? {
              ...defaultHours,
              weekday: dayOfWeek
            };

            setValue(`days.${index}.openTime`, defaultDay.openTime);
            setValue(`days.${index}.closeTime`, defaultDay.closeTime);
          }
        };

        return (
          <Checkbox
            value={isClosed}
            large
            onChangeValue={onChangeValue}
            label=""
            data-testid={`week-hours-edit-isclosed-${item.weekday}`}
            key={`week-hours-edit-isclosed-${item.weekday}`}
          />
        );
      }
    }
  ];

  const renderEditCenterHoursWeekModal = () =>
    renderModal({
      size: "lg",
      title: `Edit ${subset === "default" ? "Standard Operating" : "Seasonal"} Hours`,
      body: (
        <>
          <SimpleTable
            className="center-hour-week-edit-table"
            tableCollapseScreenSize="lg"
            tableHasIcons
            headers={tableHeaders}
            data={dayArray}
            uniqueIdKey="weekday"
          />
        </>
      ),
      footer: (
        <>
          <div
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "15px",
              display: isOutsideBrandStandardHours ? "block" : "none"
            }}
            className="outside-standard-hours"
          >
            <Checkbox
              label="The selected hours are outside of the Brand Standards. Check the box to continue."
              value={allowOutsideBrandStandardHours}
              large
              onChangeValue={setAllowOutsideBrandStandardHours}
              data-testid="allow-outside-brand-standard-checkbox"
            />
          </div>
          <button className="btn btn-secondary" onClick={hideEditCenterHoursWeekModal}>
            Cancel
          </button>
          <LoadingButton
            className="btn btn-primary"
            onClick={localSave}
            isLoading={operatingHoursWeeklyEditMutator.isLoading}
            loadingMessage="Save"
          >
            Save
          </LoadingButton>
        </>
      )
    });

  return {
    renderEditCenterHoursWeekModal,
    showEditCenterHoursWeekModal,
    hideEditCenterHoursWeekModal
  };
};
