import React from "react";

import { useAccessControl } from "../../../app/access-control-context";
import { useStaffMember } from "../../../hooks/use-user";
import { LoadingOverlay } from "../../loading-overlay/loading-overlay";
import { CreateMailbox, Field, FormRow } from "../../forms";
import { StateSelector } from "../../forms/legacy/state-selector";

export interface ContactProps {
  staffMemberId: string;
  onEdit?: () => void;
}

export const Contact: React.FC<ContactProps> = ({ staffMemberId, onEdit }) => {
  const { activeCenterId, hasFeatureAccess } = useAccessControl();
  const { data, isLoading } = useStaffMember({ centerId: activeCenterId, staffMemberId });

  return (
    <>
      <h3 className="card-subheader">
        Contact
        {!!onEdit && (
          <button className="btn btn-primary btn-sm ms-2" onClick={onEdit}>
            <span className="fas fa-pencil" aria-hidden="true"></span>
            <span className="sr-only">Edit Contact Information</span>
          </button>
        )}
      </h3>
      <LoadingOverlay loading={isLoading}>
        <FormRow>
          <Field value={data?.firstName ?? ""} mode={"view"} label="First Name" />
          <Field value={data?.lastName ?? ""} mode={"view"} label="Last Name" />
        </FormRow>

        <FormRow>
          <Field value={data?.phone ?? ""} mode={"view"} label="Phone Number" />
          <Field value={data?.businessEmail ?? ""} mode={"view"} label="HLC Account" />
        </FormRow>
        <FormRow>
          <div className="col">
            <CreateMailbox value={!!data?.hasMailbox || !!data?.createMailbox} disabled={true} />
          </div>
        </FormRow>
        <FormRow>
          <Field value={data?.personalEmail ?? ""} mode={"view"} label="Personal Email" />
        </FormRow>

        <FormRow>
          <Field value={data?.line1 ?? ""} mode={"view"} label="Address 1" />
          <Field value={data?.line2 ?? ""} mode={"view"} label="Address 2" />
        </FormRow>
        <FormRow>
          <Field value={data?.zipcode ?? ""} mode={"view"} label="ZIP" />
          <Field value={data?.city ?? ""} mode={"view"} label="City" />
          <StateSelector value={data?.state ?? ""} mode={"view"} label="State" />
        </FormRow>

        <h3 className="card-subheader">Emergency Contact</h3>

        <FormRow>
          <Field value={data?.emergencyContactName ?? ""} mode={"view"} label="Name" />
          <Field value={data?.emergencyContactPhone ?? ""} mode={"view"} label="Phone" />
        </FormRow>

        {!!hasFeatureAccess?.("employee_id") && (
          <>
            <h3 className="card-subheader">Employee Number</h3>
            <FormRow>
              <Field value={data?.employeeNumber?.toString() ?? ""} mode={"view"} label="HR ID" />
            </FormRow>
          </>
        )}
      </LoadingOverlay>
    </>
  );
};
