import { useMutation, useQueryClient } from "@tanstack/react-query";

import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { inquiryQueryKeys } from "../use-inquiries/query-keys";
import { familyQueryKeys } from "./query-keys";
import type { FamilyContactDetailWithId, FamilyInfo, FamilyRelationship } from "./types";
import { useFamilyConstants } from "./use-family-constants";
import { addFamilyRole } from "./use-family-roles";

export const useFamilyRelationships = (familyId?: string) => {
  const { getToken } = useAccessToken();
  const queryClient = useQueryClient();

  const addFamilyRelationshipMutator = useMutation({
    mutationFn: async (payload: FamilyRelationship) =>
      familyId ? addFamilyRelationship(await getToken(), familyId, payload) : undefined,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: familyQueryKeys.base });
    }
  });

  const removeFamilyRelationshipMutator = useMutation({
    mutationFn: async (relationshipId: number) =>
      familyId ? removeFamilyRelationship(await getToken(), familyId, relationshipId) : undefined,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: familyQueryKeys.base });
    }
  });

  return {
    addFamilyRelationshipMutator,
    removeFamilyRelationshipMutator
  };
};

export const useReplaceContact = () => {
  const { getToken } = useAccessToken();
  const { EMERGENCY_CONTACT_VALUE } = useFamilyConstants();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      familyId,
      relationshipId,
      studentId,
      oldContact,
      newContact
    }: {
      familyId: string;
      relationshipId: string;
      studentId: number;
      oldContact: FamilyInfo;
      newContact: FamilyContactDetailWithId;
    }) =>
      replaceFamilyRelationship(
        await getToken(),
        familyId,
        relationshipId,
        studentId,
        EMERGENCY_CONTACT_VALUE,
        oldContact,
        newContact
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: familyQueryKeys.base });
      queryClient.invalidateQueries({ queryKey: inquiryQueryKeys.baseDashboard() });
    }
  });
};

const replaceFamilyRelationship = async (
  accessToken: string,
  familyId: string,
  relationshipId: string,
  studentId: number,
  emergencyContactValue: string,
  oldContact: FamilyInfo,
  newContact: FamilyContactDetailWithId
) => {
  await addFamilyRelationship(accessToken, familyId, {
    subjectContactId: newContact.id,
    objectContactId: studentId,
    relationshipId: relationshipId
  });

  if (oldContact.isPrimaryContact) {
    await addFamilyRole(accessToken, familyId, {
      id: newContact.id,
      role: "primary_contact"
    });
  }

  const emergencyContactRelationship = oldContact.relationships.find(
    ({ relationship: { value } }) => value === emergencyContactValue
  );
  if (emergencyContactRelationship) {
    await addFamilyRelationship(accessToken, familyId, {
      subjectContactId: newContact.id,
      objectContactId: studentId,
      relationshipId: emergencyContactValue
    });
  }
};

export const addFamilyRelationship = (accessToken: string, familyId: string, payload: FamilyRelationship) => {
  return callLcosService<FamilyRelationship>(accessToken, `/api/families/${familyId}/relationships`, "POST", payload);
};

export const removeFamilyRelationship = (accessToken: string, familyId: string, relationshipId: number) => {
  return callLcosService<{}>(accessToken, `/api/families/${familyId}/relationships/${relationshipId}`, "DELETE");
};
