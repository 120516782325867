import React, { ReactNode } from "react";
import { StaffMemberPermissions } from "../../hooks/use-staff";
import { StaffPermission } from "../../hooks/use-reference-data";
import { BaseAccordion } from "./base-accordion";
import { AccessPermissionRow } from "./access-permission-row";

export interface StaffPermissionAccordionProps {
  staffMemberPermissions?: StaffMemberPermissions;
  referenceData: StaffPermission[];
  onClickAllow: (action: string, isAllowed: boolean) => void;
  readOnly: boolean;
  defaultPermissions: string[];
}
export const StaffPermissionAccordion: React.FC<StaffPermissionAccordionProps> = ({
  referenceData,
  staffMemberPermissions,
  onClickAllow,
  readOnly,
  defaultPermissions
}) => {
  const getAccordion = (referenceData: StaffPermission[]) => {
    const itemData: { header: ReactNode; body: ReactNode }[] = [];
    let pending_count: number;

    referenceData.forEach(item => {
      const accordionData = {} as { header: ReactNode; body: ReactNode };
      pending_count = 0;
      accordionData.header = item.displayName;

      if (item.permissions && item.permissions.length > 0) {
        accordionData.body = (
          <table className="table table-hover table-cursor-normal table-collapse-sm table-has-icons">
            <thead>
              <tr>
                <th scope="col">Permission</th>
                <th scope="col" className="th-icon">
                  Allowed
                </th>
                <th scope="col" className="th-icon">
                  Default Permissions for Role
                </th>
              </tr>
            </thead>
            {item.permissions
              .filter(permission => permission.isActive)
              .map((permission, index) => {
                const staffPermission = staffMemberPermissions?.permissions.find(
                  smp => smp.action === permission.value
                );

                pending_count += staffPermission?.externalInProgress ? 1 : 0;

                return (
                  <AccessPermissionRow
                    permission={permission}
                    key={index}
                    index={index}
                    staffPermission={staffPermission}
                    onClickAllow={() => onClickAllow(permission.value as string, !staffPermission?.isAllowed)}
                    readOnly={readOnly}
                    isDefault={defaultPermissions?.includes(permission.value as string) ?? false}
                  />
                );
              })}
            <tfoot>
              <tr>
                <td colSpan={2}></td>
              </tr>
            </tfoot>
          </table>
        );
      }

      if (item.groups && item.groups.length > 0) {
        const a = getAccordion(item.groups);
        accordionData.body = <BaseAccordion items={a} darkHeaders={true} />;
      }

      if (pending_count > 0) {
        accordionData.header = (
          <>
            <div className="accordion-header-pending">{accordionData.header}</div>
            <div className="permission-status">
              <strong>Pending {pending_count}</strong>
              Recent changes not yet active.
            </div>
          </>
        );
      }
      itemData.push(accordionData);
    });

    return itemData;
  };

  return <BaseAccordion items={getAccordion(referenceData)} />;
};
