import { CenterStaffFilters, CenterDetail } from "./types";

export const DefaultCenterStaffFilters: CenterStaffFilters = {
  isRehireable: undefined,
  position: undefined,
  isActive: undefined
};

export const DefaultEditableCenterDetail: CenterDetail = {
  id: "",
  name: "",
  state: "",
  zipcode: "",
  city: "",
  line1: "",
  line2: "",
  phoneDirect: "",
  phoneTollfree: "",
  phoneAdvertised: "",
  email: "",
  url: ""
};
