import React, { useMemo, useRef, useState, useCallback, useEffect } from "react";

import { useAccessControl } from "../../../app/access-control-context";
import { useStaffMemberPermissions, useStaffEditabilityCheck } from "../../../hooks/use-staff";
import { useReferenceData, StaffPermission, ReferenceRecord } from "../../../hooks/use-reference-data";
import { Confirm, ConfirmHandle } from "../../confirm/confirm";
import { LoadingOverlay } from "../../loading-overlay/loading-overlay";
import * as Permissions from "../../../data/permissions";
import { Field, RoleSelector } from "../../forms";
import { StaffPermissionAccordion } from "../../accordion/permissions";

export interface AccessProps {
  staffMemberId?: string;
}

export const requiredPermissionForEditingPermissions = Permissions.STAFF_EDIT_PERMISSIONS;

export const Access: React.FC<AccessProps> = ({ staffMemberId }) => {
  const { activeCenterId, hasPermission, profile, hasFeatureAccess } = useAccessControl();
  const {
    staffMemberPermissions,
    isLoading: isLoadingStaffMemberPermissions,
    changeRoleAndResetPermissionsMutator,
    permissionMutator
  } = useStaffMemberPermissions(activeCenterId, staffMemberId);
  const { referenceData, isLoading: isReferenceDataLoading, defaultPermissionsLookup } = useReferenceData();
  const { isEditingSelf, permissionsDisabled, isRoleImmutable } = useStaffEditabilityCheck(staffMemberId);
  const [selectedRole, setSelectedRole] = useState(staffMemberPermissions?.role);

  useEffect(() => {
    setSelectedRole(staffMemberPermissions?.role);
  }, [staffMemberPermissions?.role]);
  const confirmPermissionResetModal = useRef<ConfirmHandle>(null);

  const showPermissionsEditor = useMemo(
    () => !!hasPermission?.([requiredPermissionForEditingPermissions, Permissions.STAFF_VIEW]) || isEditingSelf,
    [hasPermission, isEditingSelf]
  );

  const onClickResetPermissions = useCallback(() => {
    confirmPermissionResetModal.current &&
      confirmPermissionResetModal.current.confirm(() => {
        changeRoleAndResetPermissionsMutator.mutate({ role: selectedRole });
      });
  }, [selectedRole]);

  const hasEditPermission = useMemo(() => hasPermission?.(requiredPermissionForEditingPermissions), [hasPermission]);

  const [IsInitialPasswordVisible, setIsInitialPasswordVisible] = useState(false);
  const onToggleInitialPassword = useCallback(() => {
    setIsInitialPasswordVisible(previous => !previous);
  }, []);

  if (!referenceData || !staffMemberPermissions || !profile) {
    return null; // delay render until we have these
  }

  const filterTinRefDataPermissions = (permission: ReferenceRecord) => {
    if (!(permission.value as string).startsWith("tin")) {
      return true;
    }
    return !!hasFeatureAccess?.("test_innovators");
  };

  const filteredRefDataPermissions = (permission: StaffPermission) => {
    const filtered = {
      ...permission
    };
    if (filtered.groups?.length) {
      filtered.groups = filtered.groups.map(group => filteredRefDataPermissions(group));
    }

    filtered.permissions = filtered.permissions.filter(filterTinRefDataPermissions);
    return filtered;
  };

  const handleAllowClick = (action: string, isAllowed: boolean) => {
    if (hasEditPermission && !permissionsDisabled) {
      permissionMutator.mutate({ action, isAllowed });
    }
  };

  return (
    <LoadingOverlay
      loading={
        isLoadingStaffMemberPermissions ||
        isReferenceDataLoading ||
        changeRoleAndResetPermissionsMutator.isLoading ||
        permissionMutator.isLoading
      }
    >
      {showPermissionsEditor && (
        <>
          <h3 className="card-subheader">Access</h3>

          <div className="table-header-options">
            <RoleSelector
              mode={!hasEditPermission || (!isEditingSelf && isRoleImmutable) ? "view" : "edit"}
              isEditingSelf={isEditingSelf}
              value={selectedRole ?? ""}
              label="System Access Level"
              onChangeValue={(newvalue: string) => {
                setSelectedRole(newvalue);
              }}
              includeImmutable={true}
              removeEmptyOption
            />
            <button
              className="btn btn-primary"
              onClick={onClickResetPermissions}
              disabled={!hasEditPermission || permissionsDisabled}
            >
              Reset Permissions
            </button>
            <Confirm
              ref={confirmPermissionResetModal}
              title="Confirm Reset To Default Permissions"
              message="Confirming will change the role of the current user and reset all permissions to default role permissions.  Are you sure you wish to continue?"
            />
          </div>

          <StaffPermissionAccordion
            staffMemberPermissions={staffMemberPermissions}
            referenceData={referenceData.permissions
              .map(filteredRefDataPermissions)
              .filter(group => group.permissions?.length || group.groups?.length)}
            defaultPermissions={defaultPermissionsLookup[selectedRole ?? ""]}
            onClickAllow={handleAllowClick}
            readOnly={!hasEditPermission || permissionsDisabled}
          />

          <br />

          <Field
            type={IsInitialPasswordVisible ? "text" : "password"}
            value={staffMemberPermissions.initialPw ?? ""}
            mode={"view"}
            label="Initial password"
            addOnClassName={IsInitialPasswordVisible ? "fas icon fa-eye" : "fas icon fa-eye-slash"}
            onAddOnClick={onToggleInitialPassword}
          />
        </>
      )}
    </LoadingOverlay>
  );
};

export const extractGroupLabel = (input: string) => {
  if (!input || !input.includes(".")) {
    return "";
  } else {
    const value = input.split(".")[0];

    if (value.startsWith("mcp") || value.startsWith("lcn") || value.startsWith("tin")) {
      return value.toUpperCase();
    }

    return value.charAt(0).toUpperCase() + value.slice(1);
  }
};
