import { useQuery } from "@tanstack/react-query";
import { callLcosService } from "../data/lcosServices";
import { useAccessToken } from "./use-access-token";

export const features = [
  "center_operating_hours",
  "center_seasonal_hours",
  "consumer_website",
  "dashboard",
  "eligible_for_rehire_edit",
  "employee_id",
  "inquiries",
  "inquiry_timelines",
  "staff_availability",
  "staff_id",
  "staff_schedules",
  "staff_subjects",
  "students",
  "test_innovators",
  "appointment_calendar",
  "appointments_any_staff"
] as const;
export type Feature = (typeof features)[number];

export const useFeatureFlags = () => {
  const queryKey = ["getFeatureFlags"];
  const { getToken } = useAccessToken();
  const featureFlagData = useQuery({
    queryKey,
    queryFn: async () => getFeatureFlags(await getToken())
  });

  return { featureFlagSettings: featureFlagData.data };
};

export const getFeatureFlags = (accessToken: string) => {
  return callLcosService<Record<Feature, boolean>>(accessToken, "/api/data/features", "GET");
};
