import { useState, type FC } from "react";
import { useNavigate } from "react-router-dom";

import { useAccessControl } from "../../../../app/access-control-context";
import { INQUIRY_EDIT } from "../../../../data/permissions";
import { useAppointmentFormModal } from "../../../../forms/appointment/appointment-form";
import type { Inquiry, InquiryAppointment } from "../../../../hooks/use-inquiries";
import { useInquiryDashboard, useInquiryAppointments } from "../../../../hooks/use-inquiries";
import { LoadingOverlay } from "../../../loading-overlay/loading-overlay";
import { useFamilyContactDetailFormModal } from "../edit-family-contact-form";
import { useStudentDetailFormModal } from "../edit-student-contact-form";
import { StudentDetailsTable } from "./student-detail-table";
import { UpcomingEventsTable } from "./upcoming-events-table";
import { FamilyContactsTable } from "./family-contacts-table";
import { AppointmentHistoryAccordion } from "./appointment-history-accordion";
import { useAppointmentSummaryModal } from "./appointment-summary";

export const InquiryDashboardInfo: FC<{ inquiry: Inquiry }> = ({ inquiry }) => {
  const navigate = useNavigate();
  const [activeAppointment, setActiveAppointment] = useState<InquiryAppointment>();
  const [selectedUpcomingAppointment, setSelectedUpcomingAppointment] = useState<InquiryAppointment>();
  const { activeCenterId, hasPermission } = useAccessControl();
  const dashboardQuery = useInquiryDashboard({
    centerId: activeCenterId,
    inquiryId: inquiry.id
  });
  const { createEventMutator, updateEventMutator } = useInquiryAppointments({
    centerId: activeCenterId,
    inquiryId: inquiry.id
  });

  const { showFamilyContactFormModal, renderFamilyContactFormModal } = useFamilyContactDetailFormModal();

  const handleSelectContact = (contactId: number) => {
    showFamilyContactFormModal({ contactId, inquiryId: inquiry.id });
  };

  const handleUpdateAppointmentStatus = (appointment: InquiryAppointment) => {
    updateEventMutator.mutate(
      {
        ...appointment,
        staffMembers: appointment.staffMembers.map(({ id }) => id).filter(id => id),
        attendees: appointment.attendees.map(({ id }) => id)
      },
      {
        onSuccess: () => {
          navigate(`/inquiry/${inquiry.id}`);
        }
      }
    );
  };

  const handleRescheduleAppointment = (appointment: InquiryAppointment) => {
    setActiveAppointment(appointment);
    showAppointmentFormModal();
  };

  const handleCreateNewAppointment = () => {
    setActiveAppointment(undefined);
    showAppointmentFormModal();
  };

  const handleClickUpcomingAppointment = (appointment: InquiryAppointment) => {
    setSelectedUpcomingAppointment(appointment);
    showAppointmentSummaryModal();
  };

  const { showAppointmentFormModal, renderAppointmentFormModal } = useAppointmentFormModal({
    inquiryId: inquiry.id,
    appointment: activeAppointment,
    centerId: activeCenterId,
    attendees: dashboardQuery.data?.attendees ?? []
  });

  const { showStudentDetailFormModal, renderStudentDetailFormModal, renderDuplicateContactModal } =
    useStudentDetailFormModal(inquiry);

  const { showAppointmentSummaryModal, renderAppointmentSummaryModal } = useAppointmentSummaryModal({
    student: dashboardQuery.data?.student,
    familyInfo: dashboardQuery.data?.familyInfo,
    contacts: dashboardQuery.data?.attendees,
    appointment: selectedUpcomingAppointment,
    onUpdateAppointmentStatus: handleUpdateAppointmentStatus,
    onRescheduleAppointment: handleRescheduleAppointment
  });

  return (
    <LoadingOverlay loading={dashboardQuery.isLoading || createEventMutator.isLoading || updateEventMutator.isLoading}>
      <div className="card-body">
        <StudentDetailsTable
          inquiryDashboardInfo={dashboardQuery.data?.inquiry || []}
          onClickStudent={showStudentDetailFormModal}
        />
        {renderStudentDetailFormModal()}
        {renderDuplicateContactModal()}

        <div className="d-flex mt-4 mb-4 align-items-center justify-content-between">
          <h3 className="card-subheader mb-0">Primary/Emergency Contacts</h3>
        </div>

        <FamilyContactsTable contacts={dashboardQuery.data?.contacts} onSelectContact={handleSelectContact} />

        <div className="d-flex mt-4 mb-4 align-items-center justify-content-between">
          <h3 className="card-subheader mb-0">Upcoming Appointments</h3>
          {hasPermission && hasPermission(INQUIRY_EDIT) && (
            <button className="btn btn-primary btn-sm ms-2" onClick={handleCreateNewAppointment}>
              Create Appointment
            </button>
          )}
        </div>

        <UpcomingEventsTable
          upcomingEvents={dashboardQuery.data?.upcomingEvents}
          onUpdateAppointmentStatus={handleUpdateAppointmentStatus}
          onRescheduleAppointment={handleRescheduleAppointment}
          onClickUpcomingEvent={handleClickUpcomingAppointment}
        />

        {renderAppointmentSummaryModal()}

        {renderAppointmentFormModal()}
        {renderFamilyContactFormModal()}
      </div>
      <AppointmentHistoryAccordion className="mt-4" appointmentHistory={dashboardQuery.data?.pastEvents} />
    </LoadingOverlay>
  );
};
