import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm, type UseFormReturn } from "react-hook-form";
import { z } from "zod";

import { useAccessControl } from "../../app/use-access-control";
import type { CenterDetail } from "../../hooks/use-center";
import { useReferenceData } from "../../hooks/use-reference-data";
import { CenterMultiSelector } from "../forms/center-multi-selector";
import { FormDividerRow, FormRow } from "../forms/form-row";
import { InputField } from "../forms/input-field";
import { CreateMailbox } from "../forms/legacy/create-mailbox";
import { SelectField } from "../forms/select-field";

export interface EditAccountInformationProps {
  editAccountForm: UseFormReturn<EditAccountFormValues>;
  availableCenters: CenterDetail[];
  hasMailbox: boolean;
  isCurrentStep: boolean;
}

export const EditAccountForm: React.FC<EditAccountInformationProps> = ({
  editAccountForm: {
    register,
    control,
    formState: { errors }
  },
  availableCenters,
  hasMailbox,
  isCurrentStep
}) => {
  const {
    mutableRoles,
    positions: [_centerOwner, ...positions]
  } = useReferenceData();

  if (!isCurrentStep) {
    return null;
  }

  return (
    <div className="form step-body">
      <FormRow>
        <SelectField
          isRequired
          label="System Access Level"
          validationState={errors.role ? "error" : undefined}
          {...register("role")}
        >
          <option value="">Select a System Access Level</option>
          {mutableRoles.map(role => (
            <option key={role.value} value={role.value}>
              {role.name}
            </option>
          ))}
        </SelectField>
      </FormRow>

      <FormRow>
        <SelectField
          isRequired
          label="Position"
          validationState={errors.position ? "error" : undefined}
          {...register("position")}
        >
          <option value="">Select a Position</option>
          {positions.map(({ value, name }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </SelectField>
        <InputField
          isRequired
          label="Start Date"
          type="date"
          validationState={errors.startDate ? "error" : undefined}
          {...register("startDate")}
        />
      </FormRow>

      <FormRow>
        <Controller
          name="createMailbox"
          control={control}
          render={({ field: { value, onChange } }) => (
            <CreateMailbox value={value} disabled={hasMailbox} onChangeValue={onChange} />
          )}
        />
      </FormRow>

      <FormDividerRow />

      <p>This user will be added to the checked centers</p>

      <Controller
        name="centers"
        control={control}
        render={({ field: { value, onChange } }) => (
          <CenterMultiSelector checkedCenters={value} availableCenters={availableCenters} onChangeValue={onChange} />
        )}
      />
    </div>
  );
};

const editAccountFormSchema = z.object({
  createMailbox: z.boolean(),
  role: z.string().min(1),
  position: z.string().min(1),
  startDate: z.string().min(1),
  centers: z.array(z.string())
});

type EditAccountFormValues = z.infer<typeof editAccountFormSchema>;

export const useEditAccountForm = (hasMailbox: boolean) => {
  const { activeCenterId } = useAccessControl();

  return useForm<EditAccountFormValues>({
    defaultValues: {
      createMailbox: hasMailbox,
      role: "",
      position: "",
      startDate: "",
      centers: [activeCenterId]
    },
    resolver: zodResolver(editAccountFormSchema),
    mode: "onTouched"
  });
};
