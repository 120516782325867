import React, { useId } from "react";
import classnames from "classnames";

import { ReferenceRecord } from "../../hooks/use-reference-data";
import { StaffMemberPermission } from "../../hooks/use-staff";

export interface AccessPermissionRowProps {
  permission?: ReferenceRecord;
  index: number;
  staffPermission?: StaffMemberPermission;
  onClickAllow: () => void;
  readOnly: boolean;
  isDefault: boolean;
}

export const AccessPermissionRow: React.FC<AccessPermissionRowProps> = ({
  permission,
  staffPermission,
  onClickAllow,
  readOnly,
  isDefault
}) => {
  const sanitizeHTML = (htmlString: string) => {
    return htmlString.replace(/<.*?>/g, ""); // Remove all HTML tags
  };

  const wordsToWrap = ["edit", "view", "manage", "create"];
  const wordRegexList = wordsToWrap.map(word => new RegExp(`\\b(${word})\\b`, "gi"));
  let wrappedText = permission?.name || "";
  wordRegexList.forEach(regex => {
    wrappedText = wrappedText.replace(regex, (match: string, capturedWord: string) => `~~${capturedWord}~~`);
  });

  const sanitizedContent = sanitizeHTML(wrappedText);
  const parts = sanitizedContent.split(/(~~.*?~~)/);

  const renderedContent = parts.map((part: string, index: number) => {
    if (part.startsWith("~~")) {
      const capturedWord = part.slice(2, -2); // Remove the ~~
      return (
        <span key={index} className="hlc-green-text text-uppercase">
          {capturedWord}
        </span>
      );
    }
    return <React.Fragment key={index}>{part}</React.Fragment>;
  });
  const id = useId();

  return (
    <tbody>
      <tr>
        <td>
          <strong>{renderedContent}</strong>
          {!!staffPermission?.externalInProgress && (
            <div className="permission-status">
              <strong>Pending</strong>
              Recent changes not yet active.
            </div>
          )}
        </td>
        <td className="td-icon td-highlight">
          <span className="th-label">Allowed: </span>
          <div className={classnames("styled-check", { disabled: readOnly })}>
            <label htmlFor={`togglePerm${id}`}>
              <span className="sr-only">{permission?.name}</span>
              <input
                className="form-check-input"
                type="checkbox"
                name={`togglePerm${id}`}
                id={`togglePerm${id}`}
                checked={!!staffPermission?.isAllowed}
                onClick={onClickAllow}
                disabled={readOnly}
                readOnly
              />
              <span className="icon"></span>
            </label>
          </div>
        </td>
        <td className="td-icon td-highlight">
          <span className="th-label">Default Permission for Role: </span>
          <div className="styled-check disabled">
            <label htmlFor={`forRole${id}`}>
              <span className="sr-only">Default Permission for Role</span>
              <input
                className="form-check-input"
                id={`forRole${id}`}
                type="checkbox"
                checked={isDefault}
                disabled
                readOnly
              />
              <span className="icon"></span>
            </label>
          </div>
        </td>
      </tr>
    </tbody>
  );
};
