import { useMemo } from "react";
import { useWindowSize } from "usehooks-ts";

const MIN_PAGINATION_LIMIT = 5;

export interface UseDynamicPageSizeParameters {
  utilityHeight?: number;
  tableRowHeight?: number;
  containerPadding?: number;
  tablePadding?: number;
  tableHeaderHeight?: number;
  tableFooterHeight?: number;
}

export const DEFAULT_UTILITY_HEIGHT = 90;
export const DEFAULT_CONTAINER_PADDING = 20;
export const DEFAULT_TABLE_ROW_HEIGHT = 45;
export const DEFAULT_TABLE_PADDING = 15;
export const DEFAULT_TABLE_HEADER_HEIGHT = 25;
export const DEFAULT_TABLE_FOOTER_HEIGHT = 110;

const calculateLimitFromHeight = (height: number, parameters: UseDynamicPageSizeParameters) => {
  const {
    utilityHeight = DEFAULT_UTILITY_HEIGHT,
    tableRowHeight = DEFAULT_TABLE_ROW_HEIGHT,
    containerPadding = DEFAULT_CONTAINER_PADDING,
    tablePadding = DEFAULT_TABLE_PADDING,
    tableHeaderHeight = DEFAULT_TABLE_HEADER_HEIGHT,
    tableFooterHeight = DEFAULT_TABLE_FOOTER_HEIGHT
  } = {
    ...parameters
  };
  const headerHeight = document.querySelector(".app-header")?.clientHeight ?? 65;
  const remainingHeight =
    height -
    headerHeight -
    utilityHeight -
    2 * containerPadding -
    2 * tablePadding -
    tableHeaderHeight -
    tableFooterHeight;
  return Math.floor(remainingHeight / tableRowHeight);
};

export const useDynamicPageSize = (parameters: UseDynamicPageSizeParameters = {}) => {
  const { height } = useWindowSize({ debounceDelay: 300 });

  const results = useMemo(() => {
    const newLimit = calculateLimitFromHeight(height, parameters);
    const adjustedLimit = newLimit < MIN_PAGINATION_LIMIT ? MIN_PAGINATION_LIMIT : newLimit;

    return {
      limit: adjustedLimit,
      paginationOptions: {
        itemsPerPage: adjustedLimit
      }
    };
  }, [height, parameters]);

  return results;
};
