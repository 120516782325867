import { useQuery } from "@tanstack/react-query";

import { staffQueryKeys } from "./query-keys";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { type UseStaffAvailabilityProps } from "./types";
import { useCallback } from "react";

interface StaffAvailability {
  startDateTime: string;
  endDateTime: string;
}

export const useStaffAvailability = (centerId: string, props: UseStaffAvailabilityProps) => {
  const { getToken } = useAccessToken();
  const { appointmentTypeId, startDatetime, endDatetime } = props;

  return useQuery({
    queryKey: staffQueryKeys.availability(centerId, props),
    queryFn: async () => getStaffAvailability(await getToken(), centerId, props),
    enabled: !!(centerId && appointmentTypeId && startDatetime && endDatetime),
    select: useCallback(
      (data: StaffAvailability[]) =>
        data
          .map(dateRange => ({
            startDateTime: new Date(dateRange.startDateTime),
            endDateTime: new Date(dateRange.endDateTime)
          }))
          .filter(({ startDateTime }) => startDateTime > new Date()),
      []
    )
  });
};

const getStaffAvailability = (accessToken: string, centerId: string, queryParams: UseStaffAvailabilityProps) => {
  //TODO: URLSearchParams is expecting a string but can handle an array of strings
  //@ts-ignore
  const searchParams = new URLSearchParams(queryParams);
  return callLcosService<StaffAvailability[]>(
    accessToken,
    `/api/staff/${centerId}/availability?${searchParams}`,
    "GET"
  );
};
