import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import invariant from "tiny-invariant";

import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { DefaultCenterStaffFilters } from "./constants";
import type { CenterStaffList } from "./types";

export const centerStaffQueryKey = (centerId?: string) => ["centerStaffData", centerId] as const;

export const useCenterStaff = (centerId: string | undefined, enabled = true, filters = DefaultCenterStaffFilters) => {
  const { getToken } = useAccessToken();

  const staffQuery = useQuery({
    queryKey: centerStaffQueryKey(centerId),
    queryFn: async () => {
      invariant(centerId, "centerId is required");
      return getCenterStaffList(await getToken(), centerId);
    },
    enabled: !!centerId && enabled,
    select: useCallback(
      ({ staff }: CenterStaffList) =>
        staff
          .filter(
            ({ isActive, isRehireable, position }) =>
              (filters.isActive === undefined || isActive === filters.isActive) &&
              (filters.isRehireable === undefined || isRehireable === filters.isRehireable) &&
              (filters.position === undefined || position === filters.position)
          )
          .sort((left, right) => left.displayName.localeCompare(right.displayName)),
      [filters]
    )
  });

  return {
    staff: staffQuery.data,
    isLoading: staffQuery.isLoading
  };
};

const getCenterStaffList = (accessToken: string, centerId: string) =>
  callLcosService<CenterStaffList>(accessToken, `/api/staff/${centerId}`);
