import { FC, MouseEventHandler, ReactNode, useId } from "react";
import slugify from "slugify";
import classnames from "classnames";

import { BaseAccordion } from "./base-accordion";
import { Subject, SubjectGroup } from "../../hooks/use-subjects";

export interface SubjectGroupsAccordionHeaderProps {
  isSubGroup?: boolean;
  groupName: string;
  allSelected?: boolean;
  onToggleSelectAll: () => void;
  readonly: boolean;
}

export const SubjectGroupsAccordionHeader: FC<SubjectGroupsAccordionHeaderProps> = ({
  groupName,
  allSelected = false,
  onToggleSelectAll,
  readonly
}) => {
  const handleToggleSelectAll: MouseEventHandler<HTMLSpanElement> = e => {
    onToggleSelectAll();
    e.stopPropagation();
  };
  return (
    <>
      <div className="accordion-header-container">
        {groupName}
        {!readonly && (
          <span
            className="select-all-toggle"
            data-testid={"group-" + slugify(groupName)}
            onClick={handleToggleSelectAll}
          >
            {allSelected ? "Deselect All" : "Select All"}
          </span>
        )}
      </div>
    </>
  );
};

const TableWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <table className="table table-hover table-cursor-normal table-collapse-xs table-has-icons">
    <thead>
      <tr>
        <th>Subject</th>
        <th>Active</th>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
);

interface SubjectsAccordionProps {
  subjectGroups: SubjectGroup[];
  onSelectSubject: (subject: Subject, isActive: boolean) => void;
  onSelectSubjectsGroup: (subjects: Subject[], isActive: boolean) => void;
  readOnly: boolean;
}

export const SubjectsAccordion: FC<SubjectsAccordionProps> = ({
  subjectGroups,
  readOnly,
  onSelectSubjectsGroup,
  onSelectSubject
}) => {
  const getAccordionItems = (
    subjectGroups: SubjectGroup[]
  ): {
    accordionItems: { header: ReactNode; body: ReactNode }[];
    allSubjectsSelected: boolean;
  } => {
    const mappedGroups = subjectGroups.map(subjectGroup => {
      const allCurrentSubjectsSelected =
        !subjectGroup.subjects.length || !subjectGroup.subjects.some(subject => !subject.isActive);
      const { accordionItems = undefined, allSubjectsSelected: allGroupSubjectsSelected } = subjectGroup.group?.length
        ? getAccordionItems(subjectGroup.group)
        : { allSubjectsSelected: true };

      const handleToggleSelectAll = () => {
        const isActive = !(allCurrentSubjectsSelected && allGroupSubjectsSelected);
        const getFlattenedSubjectsList = (subjectGroup?: SubjectGroup[] | null): Subject[] => {
          if (!subjectGroup?.length) {
            return [];
          }
          const subjects = subjectGroup.flatMap(group => group.subjects);
          return subjectGroup.reduce(
            (acc, current) => {
              return [...acc, ...getFlattenedSubjectsList(current.group)];
            },
            [...subjects]
          );
        };
        const subjectsToUpdate = [...(subjectGroup.subjects ?? []), ...getFlattenedSubjectsList(subjectGroup.group)];
        onSelectSubjectsGroup(subjectsToUpdate, isActive);
      };

      return {
        accordionItem: {
          header: (
            <SubjectGroupsAccordionHeader
              groupName={subjectGroup.displayName}
              allSelected={allCurrentSubjectsSelected && allGroupSubjectsSelected}
              onToggleSelectAll={handleToggleSelectAll}
              readonly={readOnly}
            />
          ),
          body: (
            <>
              {!!subjectGroup.subjects?.length && (
                <TableWrapper>
                  {subjectGroup.subjects.map((subject, index) => (
                    <SubjectRow
                      subject={subject}
                      key={index}
                      readOnly={readOnly}
                      onClickActive={() => onSelectSubject(subject, !subject.isActive)}
                    />
                  ))}
                </TableWrapper>
              )}
              {!!accordionItems && <BaseAccordion items={accordionItems} darkHeaders={true} />}
            </>
          )
        },
        allSubjectsSelected: allCurrentSubjectsSelected && allGroupSubjectsSelected
      };
    });

    return {
      accordionItems: mappedGroups.map(group => group.accordionItem),
      allSubjectsSelected: !mappedGroups.some(group => group.allSubjectsSelected === false)
    };
  };
  const { accordionItems } = getAccordionItems(subjectGroups);

  return <BaseAccordion items={accordionItems} />;
};

interface SubjectRowProps {
  subject: Subject;
  readOnly: boolean;

  onClickActive: () => void;
}

const SubjectRow: FC<SubjectRowProps> = ({ subject, readOnly, onClickActive }) => {
  const id = useId();
  return (
    <tr>
      <td className="td-primary">{subject.subjectName}</td>

      <td className="td-icon td-highlight">
        <div className={classnames("styled-check", { disabled: readOnly })}>
          <label htmlFor={`toggleSubject${id}`}>
            <span className="sr-only">{subject.subjectName}</span>
            <input
              className="form-check-input"
              type="checkbox"
              name={`toggleSubject${id}`}
              id={`toggleSubject${id}`}
              checked={subject.isActive}
              onClick={onClickActive}
              data-testid={"item-" + slugify(subject.subjectName)}
              disabled={readOnly}
              readOnly
            />
            <span className="icon"></span>
          </label>
        </div>
      </td>
    </tr>
  );
};
