import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { FormRow } from "../forms/form-row";
import { useMyProfile } from "../../hooks/use-my-profile";
import { useFormModal } from "../modal/modal";
import { LoadingButton } from "../loading-button/loading-button";
import { InputField } from "../forms/input-field";

export const changePasswordSchema = z.object({
  currentPassword: z.string().trim().min(1, { message: "Current Password is Required" }),
  newPassword1: z.string().trim().min(1, { message: "New Password is Required" }),
  newPassword2: z.string().trim().min(1, { message: "Confirm New Password is Required" })
});

type ChangePasswordSchema = z.infer<typeof changePasswordSchema>;

export const useChangePasswordModal = () => {
  const { changeMyPasswordMutator, profile } = useMyProfile();

  const handleSavePassword = (formdata: ChangePasswordSchema) => {
    changeMyPasswordMutator.mutate(formdata, {
      onSuccess: () => {
        window.toasts.add({
          content: "Your password has been updated",
          type: "success"
        });
        hideChangePasswordModal();
      }
    });
  };

  const {
    show,
    hide: hideChangePasswordModal,
    renderModal,
    formId,
    methods: { register, reset, formState }
  } = useFormModal({
    name: "change-password-form",
    useFormProps: {
      values: { currentPassword: "", newPassword1: "", newPassword2: "" },
      resolver: zodResolver(changePasswordSchema),
      mode: "onTouched"
    },
    onSubmit: formValues => handleSavePassword(formValues)
  });

  const showChangePasswordModal = () => {
    reset({
      currentPassword: "",
      newPassword1: "",
      newPassword2: ""
    });
    show();
  };

  const renderChangePasswordModal = () =>
    renderModal({
      title: "Update your password",
      body: (
        <>
          <FormRow>
            <InputField
              type="password"
              label="Current Password"
              autoComplete="current-password"
              isRequired
              {...register("currentPassword")}
              validationState={formState.errors.currentPassword ? "error" : undefined}
            />
          </FormRow>

          <FormRow>
            <InputField
              type="password"
              label="New Password"
              autoComplete="new-password"
              isRequired
              {...register("newPassword1")}
              validationState={formState.errors.newPassword1 ? "error" : undefined}
            />
          </FormRow>

          <FormRow>
            <InputField
              type="password"
              label="Confirm New Password"
              autoComplete="new-password"
              isRequired
              {...register("newPassword2")}
              validationState={formState.errors.newPassword2 ? "error" : undefined}
            />
          </FormRow>

          {/* this hidden field aids accessibility and password managers and has no real bearing on the actual functionality */}
          <input id="username" type="hidden" value={profile?.mail ?? ""} />
        </>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideChangePasswordModal}>
            Cancel
          </button>

          <LoadingButton
            className="btn btn-primary"
            type="submit"
            form={formId}
            isLoading={changeMyPasswordMutator.isLoading}
            loadingMessage="Updating..."
          >
            Save
          </LoadingButton>
        </>
      )
    });

  return {
    showChangePasswordModal,
    hideChangePasswordModal,
    renderChangePasswordModal
  };
};
