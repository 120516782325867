import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { useState, type FC } from "react";
import { UseFieldArrayReturn, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import { useReferenceData } from "../../../hooks/use-reference-data";
import { FormDividerRow, FormRow } from "../../forms";
import { format24HourTimeStringForApi, type InProgressFormValues } from "../../forms/form-helpers";
import { SelectField } from "../../forms/select-field";
import { InputField } from "../../forms/input-field";

import { LoadingButton } from "../../loading-button/loading-button";
import { useFormModal } from "../../modal/modal";
import { useAccessControl } from "../../../app/use-access-control";
import { useStaffMemberSchedules } from "../../../hooks/use-staff/use-staff-member-schedules";
import { StaffMemberSchedule } from "../../../hooks/use-staff";
import { BasicFormProps } from "../../../forms/form-context-wrapper/form-context-wrapper";
import { IconButton } from "../../buttons/icon-button";

interface EditScheduleFormProps extends BasicFormProps<EditScheduleFormValues> {
  fieldArray: UseFieldArrayReturn<EditScheduleFormValues>;
}

const EditScheduleForm: FC<EditScheduleFormProps> = ({ methods, fieldArray }) => {
  const { fields, remove } = fieldArray;
  const { sessionRatios, deliveryMethods, referenceData } = useReferenceData();

  if (!methods) {
    return null;
  }

  const {
    register,
    formState: { errors }
  } = methods;

  return (
    <div className="form edit-staff-schedule-form" data-testid="edit-staff-schedule-form">
      <FormRow>
        <InputField
          type="date"
          label="Start"
          validationState={errors.startDate ? "error" : undefined}
          {...register("startDate", { deps: ["endDate", "days"] })}
        />
        <InputField
          type="date"
          label="End"
          validationState={errors.endDate ? "error" : undefined}
          {...register("endDate", { deps: ["days"] })}
        />
      </FormRow>
      <FormDividerRow />

      <table className="table table-collapse-md table-hover table-modal">
        <thead>
          <tr>
            <th className="w-20" scope="col">
              Day of Week
            </th>
            <th className="w-20" scope="col">
              Start
            </th>
            <th className="w-20" scope="col">
              End
            </th>
            <th className="w-20" scope="col">
              Location
            </th>
            <th className="w-auto" scope="col">
              Ratio
            </th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {fields.map(({ id }, i) => (
            <tr key={id}>
              <td>
                <SelectField
                  label="Day"
                  className="day-selector"
                  isRequired
                  role="combobox"
                  validationState={errors.days?.at?.(i)?.dayOfWeek ? "error" : undefined}
                  {...register(`days.${i}.dayOfWeek`, { valueAsNumber: true })}
                >
                  <option value="0">Select a Day</option>
                  {referenceData?.weekdays.map(({ name, value }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </td>
              <td>
                <InputField
                  label="Start"
                  type="time"
                  className="time-field"
                  isRequired
                  validationState={errors.days?.at?.(i)?.startTime ? "error" : undefined}
                  {...register(`days.${i}.startTime`, { deps: [`days.${i}.endTime`] })}
                />
              </td>
              <td>
                <InputField
                  label="End"
                  type="time"
                  className="time-field"
                  isRequired
                  validationState={errors.days?.at?.(i)?.endTime ? "error" : undefined}
                  {...register(`days.${i}.endTime`)}
                />
              </td>
              <td>
                <SelectField
                  label="Location"
                  className="delivery-selector"
                  isRequired
                  validationState={errors.days?.at?.(i)?.deliveryMethod ? "error" : undefined}
                  {...register(`days.${i}.deliveryMethod`)}
                >
                  <option value="">Select a Location</option>
                  {deliveryMethods.map(({ name, value }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </td>
              <td>
                <SelectField
                  label="Ratio"
                  className="ratio-selector"
                  isRequired
                  validationState={errors.days?.at?.(i)?.sessionRatio ? "error" : undefined}
                  {...register(`days.${i}.sessionRatio`)}
                >
                  <option value="">Select a Ratio</option>
                  {sessionRatios.map(({ name, value }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </td>
              <td>
                <div className="col form-floating">
                  {fields.length > 1 && (
                    <IconButton
                      title="Delete"
                      icon="trash"
                      data-testid={`trash-button-day-${i}`}
                      className="btn btn-table btn-danger"
                      onClick={() => remove(i)}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const editScheduleFormSchema = z
  .object({
    startDate: z.string(),
    endDate: z.string(),
    days: z
      .array(
        z
          .object({
            dayOfWeek: z
              .number({
                invalid_type_error: "Day of week is required",
                required_error: "Day of week is required"
              })
              .min(1, { message: "Day of week is required" }),
            startTime: z.string().min(1, { message: "Start time is required" }),
            endTime: z.string().min(1, { message: "End time is required" }),
            deliveryMethod: z.enum(["center", "virtual", "remote"]),
            sessionRatio: z.enum(["one_to_one", "four_to_one"])
          })
          .refine(({ startTime, endTime }) => moment(startTime, "HH:mm").isBefore(moment(endTime, "HH:mm")), {
            message: "Start time must be before end time",
            path: ["endTime"]
          })
          .transform(({ startTime, endTime, ...rest }) => ({
            ...rest,
            startTime: format24HourTimeStringForApi(startTime),
            endTime: format24HourTimeStringForApi(endTime)
          }))
      )
      .min(1)
  })
  .refine(({ startDate, endDate }) => !startDate || !endDate || new Date(startDate) <= new Date(endDate), {
    message: "End date cannot be before start date",
    path: ["endDate"]
  })
  .superRefine(({ startDate, endDate, days }, ctx) => {
    if (!startDate || !endDate) {
      return;
    }

    const start = moment(startDate);
    const end = moment(endDate);
    if (end.diff(start, "days") >= 7) {
      return;
    }

    const weekdaysInRange = new Set<number>();
    while (start <= end) {
      // make sunday 7 instead of 0 to match our API values
      weekdaysInRange.add(start.day() || 7);
      start.set("day", start.day() + 1);
    }

    days.forEach(({ dayOfWeek }, index) => {
      if (!weekdaysInRange.has(dayOfWeek)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["days", index, "dayOfWeek"],
          message: "Day of week must be in the date range"
        });
      }
    });
  });

export type EditScheduleFormValues = z.infer<typeof editScheduleFormSchema>;

type InProgressEditScheduleFormValues = InProgressFormValues<EditScheduleFormValues>;

export const useEditScheduleForm = (values?: InProgressEditScheduleFormValues) =>
  useForm<InProgressEditScheduleFormValues>({
    values,
    resolver: zodResolver(editScheduleFormSchema)
  });

interface UseEditScheduleFormModalProps {
  staffMemberId: string;
}

export const useEditScheduleFormModal = ({ staffMemberId }: UseEditScheduleFormModalProps) => {
  const { activeCenterId } = useAccessControl();
  const { createStaffScheduleMutator, updateStaffScheduleMutator } = useStaffMemberSchedules(
    activeCenterId,
    staffMemberId
  );

  const [editingSchedule, setEditingSchedule] = useState<InProgressFormValues<StaffMemberSchedule & { id: number }>>({
    id: 0,
    startDate: "",
    endDate: "",
    days: [{ id: 1, startTime: "", endTime: "", deliveryMethod: "center", sessionRatio: "one_to_one", dayOfWeek: 0 }]
  } as StaffMemberSchedule);

  const {
    renderModal,
    hide: hideEditScheduleFormModal,
    show,
    formId,
    methods
  } = useFormModal<EditScheduleFormValues>({
    name: "edit-staff-schedule-form",
    useFormProps: {
      //@ts-ignore
      values: {
        ...editingSchedule
      },
      resolver: zodResolver(editScheduleFormSchema),
      mode: "onTouched"
    },
    onSubmit: formValues => {
      if (editingSchedule.id === 0) {
        createStaffScheduleMutator.mutate(
          { ...formValues },
          {
            onSuccess: hideEditScheduleFormModal
          }
        );
      } else {
        updateStaffScheduleMutator.mutate(
          { id: +editingSchedule.id, ...formValues },
          {
            onSuccess: hideEditScheduleFormModal
          }
        );
      }
    }
  });

  const fieldArray = useFieldArray({ name: "days", control: methods.control });

  const showEditScheduleFormModal = (schedule: StaffMemberSchedule) => {
    setEditingSchedule(schedule);
    methods.reset(schedule);

    show();
  };

  const renderEditScheduleFormModal = () =>
    renderModal({
      className: "edit-staff-contact-form-modal",
      title: "Edit Staff Schedule",
      size: "xl",
      fullscreen: "xl-down",
      body: <EditScheduleForm fieldArray={fieldArray} />,
      footer: (
        <>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              fieldArray.append(
                {
                  dayOfWeek: 0,
                  startTime: "",
                  endTime: "",
                  deliveryMethod: "center",
                  sessionRatio: "one_to_one"
                },
                { shouldFocus: false }
              );
            }}
          >
            Add Day
          </button>
          <button type="button" className="btn btn-secondary" onClick={hideEditScheduleFormModal}>
            Cancel
          </button>

          <LoadingButton
            className="btn btn-primary"
            type="submit"
            form={formId}
            isLoading={updateStaffScheduleMutator.isLoading || createStaffScheduleMutator.isLoading}
            loadingMessage="Saving..."
          >
            Save
          </LoadingButton>
        </>
      )
    });

  return {
    hideEditScheduleFormModal,
    showEditScheduleFormModal,
    renderEditScheduleFormModal
  };
};
