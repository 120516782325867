import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { z } from "zod";

import { SilencedError } from "../../app/utilities/silenced-error";
import { processErrorsFromResponse } from "../../components/forms/form-helpers";
import type { QuickCreateInquirerFormValues } from "../../components/inquiry-form/quick-create-inquirer";
import type { QuickCreateStudentFormValues } from "../../components/inquiry-form/quick-create-student";
import { callLcosService, getHeaders, lcosEndpoint } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { useFormInput } from "../use-form-input";
import { inquiryQueryKeys } from "../use-inquiries/query-keys";
import { defaultFamilyContactDetail } from "./constants";
import { familyQueryKeys } from "./query-keys";
import {
  familyContactDetailResponsePayloadSchema,
  type FamilyContactDetail,
  type FamilyContactDetailResponsePayload,
  type FamilyContactDetailWithId
} from "./types";
import { familyMemberQueryKeys } from "./use-family-members";
import { addFamilyRelationship, removeFamilyRelationship } from "./use-family-relationships";

const useOnContactCreationSuccess = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: familyQueryKeys.base });
    queryClient.invalidateQueries({ queryKey: inquiryQueryKeys.baseDashboard() });
  };
};

export const useCreateInquirer = () => {
  const { getToken } = useAccessToken();

  return useMutation({
    mutationFn: async (payload: QuickCreateInquirerFormValues) =>
      createContact(await getToken(), { ...payload, contactType: "inquirer" }),
    onSuccess: useOnContactCreationSuccess()
  });
};

export const useCreateStudent = () => {
  const { getToken } = useAccessToken();

  return useMutation({
    mutationFn: async (payload: QuickCreateStudentFormValues) =>
      createContact(await getToken(), { ...payload, contactType: "student" }),
    onSuccess: useOnContactCreationSuccess()
  });
};

export const useFamilyContactDetail = (contactType: "inquirer" | "student", contactId?: number) => {
  const { getToken } = useAccessToken();
  const queryClient = useQueryClient();

  const getContactDetailWrapper = async (id?: number) =>
    id && id > 0 ? getContactDetail(await getToken(), id) : defaultFamilyContactDetail;

  const contactDetailQuery = useQuery({
    queryKey: familyQueryKeys.contactDetail(contactId),
    queryFn: () => getContactDetailWrapper(contactId),
    select: data => ({ ...data, gradeId: data?.grade?.value })
  });

  const createOrUpdateContactMutator = useMutation({
    mutationFn: async ({
      id,
      payload: { age, ...payload },
      oldRelationshipId,
      oldRelationshipValue,
      familyId,
      studentId,
      inquiryId
    }: {
      id?: number;
      payload: FamilyContactDetail;
      oldRelationshipId?: number;
      oldRelationshipValue?: string;
      familyId?: string;
      studentId?: number;
      inquiryId?: string;
    }) => {
      const formattedPayload = {
        ...payload,
        ...(age ? { age: typeof age === "string" ? parseInt(age) : age } : {}),
        contactType,
        inquiryId
      };
      const token = await getToken();
      return id
        ? updateContact({
            accessToken: token,
            payload: formattedPayload,
            id,
            oldRelationshipId,
            oldRelationshipValue,
            familyId,
            studentId
          })
        : createContact(token, formattedPayload);
    },
    onSuccess: (_data, payload) => {
      queryClient.invalidateQueries({ queryKey: familyQueryKeys.base });
      queryClient.invalidateQueries({ queryKey: inquiryQueryKeys.baseDashboard() });
      queryClient.invalidateQueries({ queryKey: familyMemberQueryKeys.detail(payload.familyId) });
    }
  });

  const { buffer, onChangeValue, resetBuffer, setBuffer } = useFormInput(
    contactDetailQuery.data ?? defaultFamilyContactDetail
  );

  useEffect(() => {
    setBuffer(contactDetailQuery.data || defaultFamilyContactDetail);
  }, [contactDetailQuery.data]);

  return {
    buffer,
    onChangeValue,
    resetBuffer,
    contactDetail: contactDetailQuery.data,
    createOrUpdateContactMutator,
    isLoading: contactDetailQuery.isLoading
  };
};

const getContactDetail = (accessToken: string, contactId: number) => {
  return callLcosService<FamilyContactDetailWithId>(accessToken, `/api/families/contacts/${contactId}`, "GET");
};

type CreateInquirerRequestPayload = QuickCreateInquirerFormValues & { contactType: "inquirer" };
type CreateStudentRequestPayload = QuickCreateStudentFormValues & { contactType: "student" };

const createContact = async (
  accessToken: string,
  payload: FamilyContactDetail | CreateInquirerRequestPayload | CreateStudentRequestPayload
) => {
  const headers = getHeaders(accessToken);
  const response = await fetch(lcosEndpoint + "/api/families/contacts", {
    method: "POST",
    headers,
    body: JSON.stringify(payload)
  });
  if (response.ok) {
    return (await response.json()) as FamilyContactDetailWithId;
  }

  return throwPossibleDuplicateError(response);
};

const updateContact = async ({
  accessToken,
  id,
  payload,
  oldRelationshipId,
  oldRelationshipValue,
  familyId,
  studentId
}: {
  accessToken: string;
  id: number;
  payload: FamilyContactDetail;
  oldRelationshipId: number | undefined;
  oldRelationshipValue: string | undefined;
  familyId: string | undefined;
  studentId: number | undefined;
}) => {
  const headers = getHeaders(accessToken);
  const response = await fetch(lcosEndpoint + `/api/families/contacts/${id}`, {
    method: "PUT",
    headers,
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    return throwPossibleDuplicateError(response);
  }

  if (familyId && studentId && payload.relationshipId) {
    if (oldRelationshipId && payload.relationshipId !== oldRelationshipValue) {
      await removeFamilyRelationship(accessToken, familyId, oldRelationshipId);
    }
    await addFamilyRelationship(accessToken, familyId, {
      subjectContactId: id,
      objectContactId: studentId,
      relationshipId: payload.relationshipId
    });
  }

  return (await response.json()) as FamilyContactDetailWithId;
};

const formatFamilyContactDetailWithId = ({
  age,
  address_line1: addressLine1,
  address_line2: addressLine2,
  business_phone: businessPhone,
  display_name: displayName,
  first_name: firstName,
  home_phone: homePhone,
  last_name: lastName,
  opt_out: optOut,
  personal_phone: personalPhone,
  preferred_contact_method: preferredContactMethod,
  grade,
  ...rest
}: FamilyContactDetailResponsePayload): FamilyContactDetailWithId => ({
  age: age ?? undefined,
  addressLine1,
  addressLine2,
  businessPhone,
  displayName,
  firstName,
  homePhone,
  lastName,
  optOut,
  personalPhone,
  preferredContactMethod,
  gradeId: grade?.value,
  ...rest
});

export class DuplicateContactError extends SilencedError {
  public readonly duplicateContacts: FamilyContactDetailWithId[];

  constructor(message: string, duplicateContacts: FamilyContactDetailResponsePayload[]) {
    super(message);
    this.name = "DuplicateContactError";
    this.duplicateContacts = duplicateContacts.map(formatFamilyContactDetailWithId);
  }
}

const throwPossibleDuplicateError = async (response: Response) => {
  if (response.status === 500) {
    throw new Error("Sorry, an unknown error occurred. Please try again later.");
  }

  await processErrorsFromResponse(response);

  const errorResponse = await response.json();

  const errorResponsePayload = z.object({}).passthrough().safeParse(errorResponse);
  if (!errorResponsePayload.success) {
    throw new Error(`non-OK api response: ${response.status} - ${response.statusText}`);
  }

  const detailResponse = z.object({ detail: z.string() }).safeParse(errorResponse);
  if (!detailResponse.success) {
    throw new Error(JSON.stringify(errorResponsePayload.data));
  }

  const duplicateContactsResponse = z
    .object({ body: z.object({ contacts: z.array(familyContactDetailResponsePayloadSchema) }) })
    .safeParse(errorResponse);
  if (!(response.status === 409 && duplicateContactsResponse.success)) {
    throw new Error(detailResponse.data.detail);
  }

  throw new DuplicateContactError(detailResponse.data.detail, duplicateContactsResponse.data.body.contacts);
};
