import { useQuery } from "@tanstack/react-query";

import { anyStaffMember } from "./constants";
import { callLcosService } from "../../data/lcosServices";
import { inquiryQueryKeys } from "./query-keys";
import { useAccessControl } from "../../app/use-access-control";
import { useAccessToken } from "../use-access-token";
import { useCallback } from "react";
import { type InquiryAppointmentStaffMember, type InquiryAppointmentStaffQueryParams } from "./types";

export const useInquiryAppointmentStaff = (centerId: string, queryParams: InquiryAppointmentStaffQueryParams) => {
  const { getToken } = useAccessToken();
  const { hasFeatureAccess } = useAccessControl();
  const { type_id, service_id, location_id, get_all } = queryParams;
  return useQuery({
    queryKey: inquiryQueryKeys.appointmentStaff(centerId, queryParams),
    queryFn: async () => getAppointmentStaffMembers(await getToken(), centerId, queryParams),
    enabled: !!(type_id?.length && service_id?.length && location_id?.length) || !!get_all,
    select: useCallback((data: InquiryAppointmentStaffMember[]) => {
      if (hasFeatureAccess?.("appointments_any_staff") && data?.length > 0 && !data.some(value => value.id === "")) {
        data.splice(0, 0, anyStaffMember);
      }
      return data || [];
    }, [])
  });
};

const getAppointmentStaffMembers = (
  accessToken: string,
  centerId: string,
  queryParams: InquiryAppointmentStaffQueryParams
) => {
  //@ts-ignore
  const searchParams = new URLSearchParams(queryParams);
  return callLcosService<InquiryAppointmentStaffMember[]>(
    accessToken,
    `/api/inquiries/${centerId}/appointments/staff_members?${searchParams.toString()}`
  );
};

// const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

// const convertObjectToSnakeCase = (object: object) => {
//   const newObject = {} as Record<string, string | number | (string | number)[]>;
//   Object.entries(object).forEach(([key, value]) => {
//     newObject[camelToSnakeCase(key)] = value;
//   });
// };
