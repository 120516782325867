import React, { useEffect, useState } from "react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
//import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AccessControlProvider } from "./access-control-context";
import { AppRouter } from "./app-router";
import { ModalProvider } from "../components/modal/modal-provider";
import { SilencedError } from "./utilities/silenced-error";

export const AppWithToast = () => {
  // this is deliberate because the queryClient needs to be referentially stable across re-renders
  const [queryClient] = useState<QueryClient>(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (error: any) => {
            const toastMethod = window.toasts.isModalOpen ? "addModalToast" : "add";
            window.toasts[toastMethod]({ content: `${error.message}`, keepOpen: toastMethod === "addModalToast" });
          }
        }),
        mutationCache: new MutationCache({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (error: any) => {
            // error instanceof SilencedError seems to work in the browser but not in tests
            if (error instanceof SilencedError || ("name" in error && error.name === "SilencedError")) {
              return;
            }

            const toastMethod = window.toasts.isModalOpen ? "addModalToast" : "add";
            if ("detail" in error && Array.isArray(error.detail)) {
              const feedback: { loc: string[]; msg: string }[] = error.detail;
              const content = (
                <ul>
                  {feedback.map(fb => {
                    return (
                      <li key={JSON.stringify(fb.loc)}>
                        {fb.loc[fb.loc.length - 1]} - {fb.msg}
                      </li>
                    );
                  })}
                </ul>
              );
              window.toasts[toastMethod]({ content, keepOpen: toastMethod === "addModalToast" });
            } else {
              window.toasts[toastMethod]({ content: `${error.message}`, keepOpen: toastMethod === "addModalToast" });
            }
          },
          onMutate: window.toasts.isModalOpen ? window.toasts.removeAllModalToasts : undefined
        })
      })
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ModalProvider>
        <AccessControlProvider>
          <AppRouter />
        </AccessControlProvider>
      </ModalProvider>

      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};

interface GlobalModalOverrideProps {
  children?: React.ReactNode;
}

export const GlobalModalOverride: React.FC<GlobalModalOverrideProps> = ({ children }) => {
  useEffect(() => {
    window.toasts.isModalOpen = true;
    return () => {
      window.toasts.isModalOpen = false;
    };
  }, []);
  return <div>{children}</div>;
};
