import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect, type FC } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { ContentPad, Utility } from "../../app/app-layout";
import { states } from "../../data/states";
import { useCityAndState } from "../../hooks/use-azure-maps-city-state";
import {
  defaultFamilyContactDetail,
  type FamilyContact,
  type FamilyContactDetailWithId
} from "../../hooks/use-families";
import { useFamilyConstants } from "../../hooks/use-families/use-family-constants";
import { useFamilyContactDetail } from "../../hooks/use-families/use-family-contacts";
import { FormHelpers } from "../forms";
import { isValidOptionalPhoneNumber, zipcodeSchema } from "../forms/form-helpers";
import { InputField } from "../forms/input-field";
import { PhoneNumberField } from "../forms/phone-number-field";
import { SelectField } from "../forms/select-field";
import { useDuplicateContactModal } from "./use-duplicate-contact-modal";

export const QuickCreateInquirer: FC<{
  onSave: (contact: FamilyContactDetailWithId) => void;
  onCancel: () => void;
  inquirer?: FamilyContact;
}> = ({ onCancel, onSave, inquirer }) => {
  const { activeTitles, activePreferredContactMethods } = useFamilyConstants();

  const { contactDetail: inquirerDetail, createOrUpdateContactMutator } = useFamilyContactDetail(
    "inquirer",
    inquirer?.id
  );

  const {
    register,
    watch,
    setValue,
    reset,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitted }
  } = useQuickCreateInquirerForm();
  const { renderDuplicateContactModal, onSubmitError } = useDuplicateContactModal({ onSubmit: onSave });

  useEffect(() => {
    reset(inquirerDetail || defaultFamilyContactDetail);
  }, [inquirerDetail]);

  // istanbul ignore next
  const handleSave = handleSubmit(formValues => {
    createOrUpdateContactMutator.mutate(
      { id: inquirer?.id, payload: formValues },
      { onSuccess: onSave, onError: onSubmitError }
    );
  });

  // TODO: Figure out reusable generic way to autofill city and state in any form with relevant fields
  const zipcode = watch("zipcode");
  const cityAndStateQuery = useCityAndState(zipcode, {
    enabled: FormHelpers.isValidZipcode(zipcode) && (!watch("city").length || !watch("state").length)
  });

  useEffect(() => {
    if (cityAndStateQuery?.data?.city && cityAndStateQuery?.data?.state) {
      setValue("city", cityAndStateQuery.data.city);
      setValue("state", cityAndStateQuery.data.state);
    }
  }, [cityAndStateQuery.data]);

  useEffect(() => {
    if (isSubmitted) {
      trigger(["email", "homePhone", "businessPhone", "personalPhone", "contact_root"]);
    }
  }, [isSubmitted, ...watch(["email", "homePhone", "businessPhone", "personalPhone"])]);

  return (
    <>
      <div className="sticky-top">
        <Utility>
          <div className="d-flex  stack-sm-down w-100 justify-content-end">
            <button className="btn btn-secondary me-2" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={handleSave}>
              Save Initial Inquirer
            </button>
          </div>
        </Utility>
      </div>
      <ContentPad>
        <h2 className="header-small">Initial Inquirer</h2>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col col-12 col-md-6">
                <SelectField disableColClass={true} label="Salutation" {...register("title")}>
                  <option value="">Select Salutation</option>
                  {activeTitles?.map(({ value, name }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </div>
              <div className="col col-12 col-md-6 col-lg-12">
                <InputField
                  disableColClass={true}
                  label="First Name"
                  error={errors.firstName}
                  isRequired
                  {...register("firstName")}
                />
              </div>
              <div className="col col-12 col-md-6 col-lg-12">
                <InputField
                  disableColClass={true}
                  label="Last Name"
                  error={errors.lastName}
                  isRequired
                  {...register("lastName")}
                />
              </div>

              <div className="col col-12 col-md-6">
                <InputField disableColClass={true} label="Suffix" error={errors.suffix} {...register("suffix")} />
              </div>
            </div>

            <h3
              className={classNames("card-subheader", {
                "is-invalid": errors["contact_root"]
              })}
            >
              Contact{" "}
              <span className="required" aria-hidden="true">
                *
              </span>
            </h3>
            {typeof errors["contact_root"]?.message === "string" && (
              <div className="invalid-feedback">{errors["contact_root"].message}</div>
            )}
            <div className="row">
              <div className="col col-12">
                <InputField
                  label="Email"
                  disableColClass={true}
                  validationState={errors?.contact_root && "error"}
                  error={errors.email}
                  {...register("email")}
                />
              </div>
              <div className="col col-12 col-md-6 col-lg-12">
                <PhoneNumberField
                  label="Home Phone"
                  disableColClass={true}
                  validationState={errors?.contact_root && "error"}
                  error={errors.homePhone}
                  {...register("homePhone")}
                />
              </div>
              <div className="col col-12 col-md-6 col-lg-12">
                <PhoneNumberField
                  label="Business Phone"
                  disableColClass={true}
                  validationState={errors?.contact_root && "error"}
                  error={errors.businessPhone}
                  {...register("businessPhone")}
                />
              </div>
              <div className="col col-12 col-md-6 col-lg-12">
                <PhoneNumberField
                  label="Mobile Phone"
                  disableColClass={true}
                  validationState={errors?.contact_root && "error"}
                  error={errors.personalPhone}
                  {...register("personalPhone")}
                />
              </div>
              <div className="col col-12">
                <SelectField
                  label="Preferred Method Of Contact"
                  disableColClass={true}
                  {...register("preferredContactMethod")}
                >
                  <option value="">Select Method</option>
                  {activePreferredContactMethods?.map(({ value, name }) => (
                    <option key={value} value={value}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </div>
              <div className="col col-12">
                <strong>Contact Opt Out</strong>
              </div>
              <div className="col col-12">
                <div className="checkboxes-inline">
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="optout1">
                      Phone
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="optout1"
                      value="phone"
                      {...register("optOut")}
                    />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="optout2">
                      Email
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="optout2"
                      value="email"
                      {...register("optOut")}
                    />
                  </div>
                  <div className="form-check">
                    <label className="form-check-label" htmlFor="optout3">
                      SMS
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="optout3"
                      value="sms"
                      {...register("optOut")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <h3 className="card-subheader">Address</h3>
            <div className="row">
              <div className="col col-12">
                <InputField
                  label="Address Line 1"
                  disableColClass={true}
                  error={errors.addressLine1}
                  {...register("addressLine1")}
                />
              </div>
              <div className="col col-12">
                <InputField
                  label="Address Line 2"
                  disableColClass={true}
                  error={errors.addressLine2}
                  {...register("addressLine2")}
                />
              </div>
              <div className="col col-12">
                <InputField label="ZIP" disableColClass={true} error={errors.zipcode} {...register("zipcode")} />
              </div>
              <div className="col col-12">
                <InputField label="City" disableColClass={true} error={errors.city} {...register("city")} />
              </div>
              <div className="col col-12">
                <SelectField label="State" disableColClass={true} {...register("state")}>
                  <option value="">Select State</option>
                  {states.map(({ abbreviation, name }) => (
                    <option key={abbreviation} value={abbreviation}>
                      {name}
                    </option>
                  ))}
                </SelectField>
              </div>
            </div>
          </div>
        </div>
        {renderDuplicateContactModal()}
      </ContentPad>
    </>
  );
};

const nameRegex = /^[a-zA-Z\s\-']+$/;
const suffixRegex = /^[a-zA-Z\s'\-.]+$/;
const addressRegex = /^[a-zA-Z\s'\-.0-9#]+$/;

const invalidCharactersMessage = "contains invalid characters";

export const quickCreateInquirerFormSchema = z
  .object({
    title: z.string(),
    firstName: z.string().min(1, "required").regex(nameRegex, invalidCharactersMessage).trim(),
    lastName: z.string().min(1, "required").regex(nameRegex, invalidCharactersMessage).trim(),
    suffix: z.string().regex(suffixRegex, invalidCharactersMessage).trim().or(z.literal("")),
    email: z.string().trim().email().or(z.literal("")),
    homePhone: z.string().trim().refine(isValidOptionalPhoneNumber, { message: "invalid phone number" }),
    businessPhone: z.string().trim().refine(isValidOptionalPhoneNumber, { message: "invalid phone number" }),
    personalPhone: z.string().trim().refine(isValidOptionalPhoneNumber, { message: "invalid phone number" }),
    preferredContactMethod: z.string(),
    optOut: z.array(z.enum(["phone", "email", "sms"])),
    addressLine1: z.string().regex(addressRegex, invalidCharactersMessage).trim().or(z.literal("")),
    addressLine2: z.string().regex(addressRegex, invalidCharactersMessage).trim().or(z.literal("")),
    zipcode: zipcodeSchema.or(z.literal("")),
    city: z.string().regex(nameRegex, invalidCharactersMessage).trim().or(z.literal("")),
    state: z.string().trim(),
    contact_root: z.any()
  })
  .refine(
    ({ email, homePhone, businessPhone, personalPhone }) =>
      Boolean(email || homePhone || businessPhone || personalPhone),
    { path: ["contact_root"], message: "At least one contact method is required" }
  )
  .transform(({ contact_root, ...rest }) => rest);

export type QuickCreateInquirerFormValuesInput = z.input<typeof quickCreateInquirerFormSchema>;
export type QuickCreateInquirerFormValues = z.infer<typeof quickCreateInquirerFormSchema & { contact: string }>;

const useQuickCreateInquirerForm = () =>
  useForm<QuickCreateInquirerFormValuesInput, unknown, QuickCreateInquirerFormValues>({
    defaultValues: defaultFamilyContactDetail,
    resolver: zodResolver(quickCreateInquirerFormSchema)
  });
