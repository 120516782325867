import React, { useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";

export interface ListHeaderProps {
  filter?: string;
  onFilterChange?: (value: string) => void;
  onPrintRequested?: () => void;
}

export const ListHeader: React.FC<React.PropsWithChildren<ListHeaderProps>> = ({
  filter,
  children,
  onFilterChange,
  onPrintRequested
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useHotkeys("ctrl+f, meta+f", e => {
    e.preventDefault();
    inputRef.current && inputRef.current.focus();
  });

  return (
    <div className="d-flex align-items-center w-100 mb-2">
      <div className="form-floating has-icon flex-grow-1">
        <input
          type="text"
          ref={inputRef}
          className="form-control"
          id="contentSearch"
          placeholder="Search"
          value={filter}
          onChange={e => {
            onFilterChange && onFilterChange(e.target.value);
          }}
        />
        <label htmlFor="contentSearch">Search</label>
        <span className="fas fa-search" aria-hidden="true"></span>
      </div>

      <button className="btn btn-sm" onClick={onPrintRequested}>
        <span className="fas fa-print"></span>
        <span className="sr-only">Print</span>
      </button>
      {children}
    </div>
  );
};
