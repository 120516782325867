import { DateTimeRange, DatesOfInterest, LegendKeyType } from "./types";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const getMonthString = (date: Date = new Date()) => {
  return MONTHS[date.getMonth()];
};

export const getCalendarDates = (
  date: Date = new Date(),
  datesOfInterest: DatesOfInterest = { default: { dateRanges: [], legendKey: {} } }
): DateAvailability[] => {
  const firstDateOfCurrentMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDateOfCurrentMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const daysInWeekBeforeFirstDay = firstDateOfCurrentMonth.getDay();
  const daysInCurrentMonth = lastDateOfCurrentMonth.getDate();
  const daysInWeekAfterLastDay = 6 - lastDateOfCurrentMonth.getDay();

  const startDate = new Date(
    firstDateOfCurrentMonth.getFullYear(),
    firstDateOfCurrentMonth.getMonth(),
    firstDateOfCurrentMonth.getDate() - daysInWeekBeforeFirstDay
  );

  return Array.from(Array(daysInWeekBeforeFirstDay + daysInCurrentMonth + daysInWeekAfterLastDay), (d, index) => {
    const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + index);

    // ASSUME NO OVERLAPS IN DATE RANGES ACROSS LEGEND TYPES OR ORDERED BY PRIORITY ON WHICH TO DISPLAY
    const record: [key: LegendKeyType, tooltipText?: string] | null =
      Object.keys(datesOfInterest)
        .map(key => {
          const dateRange = datesOfInterest[key as LegendKeyType]?.dateRanges?.find(range => {
            const dateComparison = compareDateTimeWithDateTimeRange(currentDate, range.range);
            if (dateComparison === 0) {
              if (!!range.weekday) {
                const currentDay = currentDate.getDay();
                const weekday = currentDay === 0 ? 7 : currentDay;
                return range.weekday === weekday;
              }
              return true;
            }
            return false;
          });

          if (dateRange) {
            return [key, dateRange.tooltipText] as [key: LegendKeyType, tooltipText?: string];
          } else {
            return undefined;
          }
        })
        .find(entry => !!entry) ?? null;

    const legendKey = record ? datesOfInterest[record[0]]?.legendKey : datesOfInterest["default"]?.legendKey;

    // check if there is an individual tooltipText for the date or uses the from the legend
    const tooltipText = record ? record[1] ?? legendKey?.tooltipText : legendKey?.tooltipText;

    return {
      date: currentDate,
      className: legendKey?.className,
      tooltipText: tooltipText,
      isSelectable: legendKey?.isSelectable
    };
  });
};

const isSameDate = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
const isNextDate = (date1: Date, date2: Date) => {
  const nextDay = new Date(date1);
  nextDay.setDate(nextDay.getDate() + 1);
  return isSameDate(nextDay, date2);
};

/**
 * Function to see if a date lies within a date range
 * @param date
 * @param dateRange
 * @returns -1 if the date is before, 0 if within, and 1 if after
 */
export const compareDateTimeWithDateTimeRange = (date: Date, dateRange: DateTimeRange) => {
  if (isSameDate(date, dateRange.startDateTime) || isSameDate(date, dateRange.endDateTime)) {
    return 0;
  }

  if (date < dateRange.startDateTime) {
    return -1;
  }
  if (date > dateRange.endDateTime) {
    return 1;
  }
  return 0;
};

export const consolidateDateRanges = (dateRanges: DateTimeRange[]) => {
  const consolidatedDateTimeRanges: DateTimeRange[] = [];
  for (const dateRange of dateRanges) {
    const lastDateTimeRange = consolidatedDateTimeRanges[consolidatedDateTimeRanges.length - 1];
    if (
      lastDateTimeRange === undefined ||
      (!isSameDate(lastDateTimeRange.endDateTime, dateRange.startDateTime) &&
        !isNextDate(lastDateTimeRange.endDateTime, dateRange.startDateTime))
    ) {
      const startOfDay = new Date(dateRange.startDateTime);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(startOfDay);
      endOfDay.setHours(23, 59, 59, 999);
      consolidatedDateTimeRanges.push({
        startDateTime: startOfDay,
        endDateTime: endOfDay
      });
      continue;
    }

    if (isSameDate(lastDateTimeRange.endDateTime, dateRange.startDateTime)) {
      continue;
    }
    if (isNextDate(lastDateTimeRange.endDateTime, dateRange.startDateTime)) {
      const endOfDay = new Date(dateRange.startDateTime);
      endOfDay.setHours(23, 59, 59, 999);
      lastDateTimeRange.endDateTime = endOfDay;
      continue;
    }
  }
  return consolidatedDateTimeRanges;
};

interface DateAvailability {
  date: Date;
  className?: string;
  tooltipText?: string;
  isSelectable?: boolean;
}
