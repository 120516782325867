import { useMutation, useQuery } from "@tanstack/react-query";

import { useAccessToken } from "./use-access-token";
import { callLcosService } from "../data/lcosServices";

export interface UserProfile {
  givenName: string;
  displayName: string;
  surname: string;
  mail: string;
  id: string;
  isCorporateAdmin: boolean;
  isCorporateUser: boolean;
}

export interface ChangeUserPasswordPayload {
  currentPassword: string;
  newPassword1: string;
  newPassword2: string;
}

export const useMyProfile = () => {
  const { getToken } = useAccessToken();

  const getMyProfileData = async () => {
    return getUserProfile(await getToken());
  };

  const profileQuery = useQuery({ queryKey: ["myProfileData"], queryFn: getMyProfileData });

  const changeMyPasswordMutator = useMutation({
    mutationFn: async (payload: ChangeUserPasswordPayload) => changeUserPassword(await getToken(), payload)
  });

  return {
    profile: profileQuery.data,
    isLoading: profileQuery.isLoading,
    changeMyPasswordMutator
  };
};

export async function getUserProfile(accessToken: string) {
  return callLcosService<UserProfile>(accessToken, "/api/whoami");
}

export async function changeUserPassword(accessToken: string, payload: ChangeUserPasswordPayload) {
  return callLcosService<void>(accessToken, "/api/users/change_my_password", "POST", payload);
}
