import { type FC, useId, useMemo } from "react";
import { type FieldError } from "react-hook-form";
import { type UseComboboxStateChange, useCombobox, useMultipleSelection } from "downshift";
import classNames from "classnames";

import { ComboboxItem } from "./search-select";

interface MultiSelectSearchBaseProps {
  onChangeSelectedItems: (selectedValues?: ComboboxItem[]) => void;
  options: ComboboxItem[];
  filteredOptions: ComboboxItem[];
  label: string;
  inputValue: string;
  onChangeInputValue: (inputValue: string) => void;
  error?: FieldError;
  isRequired?: boolean;
}
interface MultiSelectSearchWithSelectedValuesProps extends MultiSelectSearchBaseProps {
  selectedValues?: string[] | number[];
  selectedItems?: never;
}

interface MultiSelectSearchWithSelectedItemsProps extends MultiSelectSearchBaseProps {
  selectedItems?: ComboboxItem[];
  selectedValues?: never;
}

type MultiSelectSearchProps = MultiSelectSearchWithSelectedItemsProps | MultiSelectSearchWithSelectedValuesProps;

export const MultiSelectSearch: FC<MultiSelectSearchProps> = ({
  selectedItems,
  selectedValues,
  options,
  filteredOptions,
  label,
  onChangeSelectedItems,
  inputValue,
  onChangeInputValue,
  error,
  isRequired
}) => {
  const selectedItemsFromProps = useMemo(() => {
    if (selectedValues) {
      const newSelectedItems: ComboboxItem[] = [];

      selectedValues?.forEach(value => {
        const option = options?.find(item => item.value === value);
        if (option) {
          newSelectedItems.push(option);
        } else {
          newSelectedItems.push({ value, label: "" });
        }
      });
      return newSelectedItems;
    }

    if (selectedItems) {
      return selectedItems;
    }

    return [];
  }, [selectedItems, selectedValues]);

  const { getDropdownProps, selectedItems: selectedItemsForCombobox } = useMultipleSelection({
    selectedItems: selectedItemsFromProps,
    onStateChange({ selectedItems: newSelectedItems, type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          onChangeSelectedItems(newSelectedItems);
          break;
        default:
          break;
      }
    }
  });

  const itemsWithSelected = useMemo(() => {
    const missingItems = selectedItemsForCombobox.filter(
      item => !(filteredOptions || []).find(option => option.value === item.value)
    );
    return [...(filteredOptions || []), ...missingItems];
  }, [filteredOptions, selectedItemsForCombobox]);

  const id = useId();

  const { openMenu, isOpen, getLabelProps, getMenuProps, getInputProps, getToggleButtonProps, getItemProps } =
    useCombobox({
      items: itemsWithSelected,
      itemToString: item => item?.label || "",
      selectedItem: null,
      inputValue,
      stateReducer(state, actionAndChanges) {
        const { changes, type } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            return {
              ...changes,
              isOpen: true, // keep the menu open after selection.
              highlightedIndex: (options || []).findIndex(({ value }) => value === changes.selectedItem?.value)
            };
          case useCombobox.stateChangeTypes.InputClick:
            return {
              ...changes,
              isOpen: true
            };
          default:
            return changes;
        }
      },
      onStateChange({
        inputValue: newInputValue,
        type,
        selectedItem: newSelectedItem
      }: UseComboboxStateChange<ComboboxItem>) {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            if (newSelectedItem) {
              if (selectedItemsForCombobox.find(item => item.value === newSelectedItem.value)) {
                onChangeSelectedItems(selectedItemsForCombobox.filter(item => item.value !== newSelectedItem.value));
              } else {
                onChangeSelectedItems([...selectedItemsForCombobox, newSelectedItem]);
              }
            }
            break;
          case useCombobox.stateChangeTypes.InputChange:
            onChangeInputValue(newInputValue || "");

            break;
          default:
            break;
        }
      },
      id
    });

  return (
    <div className="dropdown form-input">
      <div className="form-floating">
        <div
          {...getToggleButtonProps(
            getDropdownProps({
              id: `toggle-${id}`,
              preventKeyAction: isOpen,
              className: classNames("form-select dropdown-toggle", {
                show: isOpen,
                "is-invalid": error
              })
            })
          )}
        >
          {selectedItemsForCombobox.map(({ label }) => label).join(", ")}
        </div>
        <label {...getLabelProps({ htmlFor: `toggle-${id}` })}>
          {label}
          {isRequired && (
            <>
              {" "}
              <span className="required" aria-hidden="true">
                *
              </span>
            </>
          )}
        </label>
        {!!error?.message && <div className="invalid-feedback">{error?.message}</div>}
      </div>

      <ul
        {...getMenuProps({
          className: classNames("dropdown-menu", { show: isOpen }),
          "aria-labelledby": `toggle-${id}`,
          style: {
            postion: "absolute",
            inset: "0px auto auto 0px",
            margin: "0px",
            transform: "translate(231px, 60px)"
          }
        })}
      >
        <li>
          <div className="form-floating has-icon p-2 pt-8">
            <input
              {...getInputProps(
                getDropdownProps({
                  preventKeyAction: isOpen,
                  type: "text",
                  placeholder: "Search",
                  id: `contentSearch-${id}`,
                  className: "form-control",
                  onClick: openMenu
                })
              )}
            />
            <label htmlFor={`contentSearch-${id}`}>Search</label>
            <span className="fas fa-search" aria-hidden="true"></span>
          </div>
          <hr className="mt-0" />
        </li>
        {(itemsWithSelected || []).map((option, index) => (
          <li key={option.value} {...getItemProps({ item: option, index })}>
            <div className="form-check">
              <input
                className="form-check-input"
                readOnly
                type="checkbox"
                checked={!!selectedItemsForCombobox.find(item => item.value === option.value)}
                id={`checkbox-input-${option.value}`}
              />
              <label className="form-check-label" htmlFor={`checkbox-input-${option.value}`}>
                {option.label}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
