import { useMutation, useQueryClient } from "@tanstack/react-query";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { InquiryAppointment } from "./types";
import { inquiryQueryKeys } from "./query-keys";

interface UseInquiryEventProps {
  inquiryId: string;
  centerId: string;
}

export const useInquiryAppointments = ({ inquiryId, centerId }: UseInquiryEventProps) => {
  const { getToken } = useAccessToken();

  const queryClient = useQueryClient();

  const createEventMutator = useMutation({
    mutationFn: async (payload: InquiryAppointmentRequestPayload) =>
      createInquiryAppointment({ accessToken: await getToken(), centerId, inquiryId, payload }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: inquiryQueryKeys.dashboard(centerId, inquiryId) });
    }
  });

  const updateEventMutator = useMutation({
    mutationFn: async (payload: InquiryAppointmentRequestPayload) =>
      updateInquiryAppointment({ accessToken: await getToken(), centerId, inquiryId, payload }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: inquiryQueryKeys.dashboard(centerId, inquiryId) });
      queryClient.invalidateQueries({
        predicate: query => {
          const listQueryKey = inquiryQueryKeys.list({ centerId, offset: 0 });
          return (
            query.queryKey[0] === listQueryKey[0] &&
            query.queryKey[1] === listQueryKey[1] &&
            (query.queryKey as typeof listQueryKey)[2]?.centerId === listQueryKey[2]?.centerId
          );
        }
      });
    }
  });

  return {
    createEventMutator,
    updateEventMutator
  };
};

export type InquiryAppointmentRequestPayload = Omit<InquiryAppointment, "staffMembers" | "attendees"> & {
  staffMembers: string[];
  attendees: number[];
};

export const createInquiryAppointment = ({
  accessToken,
  centerId,
  inquiryId,
  payload
}: {
  accessToken: string;
  centerId: string;
  inquiryId: string;
  payload: InquiryAppointmentRequestPayload;
}) => {
  return callLcosService(accessToken, `/api/inquiries/${centerId}/${inquiryId}/appointment`, "POST", payload);
};

export const updateInquiryAppointment = ({
  accessToken,
  centerId,
  inquiryId,
  payload
}: {
  accessToken: string;
  centerId: string;
  inquiryId: string;
  payload: InquiryAppointmentRequestPayload;
}) => {
  return callLcosService(
    accessToken,
    `/api/inquiries/${centerId}/${inquiryId}/appointment/${payload.id}`,
    "PUT",
    payload
  );
};
