import React, { forwardRef, useCallback, useEffect } from "react";
import classnames from "classnames";

import { ToastParameters } from "./types";

export type ToastProperties = Omit<ToastParameters, "content"> & {
  children?: React.ReactNode;
  className?: string;
  remove: () => void;
};

const Toast = forwardRef<HTMLDivElement, ToastProperties>(
  ({ children, remove, duration, onClose, onUserClose, keepOpen, id, type = "danger", className }, ref) => {
    useEffect(() => {
      if (!keepOpen) {
        const id = setTimeout(() => {
          // remove invokes close
          remove && remove();
        }, duration);

        return (): void => clearTimeout(id);
      }
    }, [duration, keepOpen, onClose, remove]);

    const onClickClose = useCallback((): void => {
      onUserClose && onUserClose();
      // remove invokes close
      remove && remove();
    }, [onUserClose, onClose]);

    return (
      <div
        ref={ref}
        className={classnames(className, "alert alert-dismissible fade show", {
          "alert-danger": type === "danger",
          "alert-warning": type === "warning",
          "alert-success": type === "success"
        })}
        role="alert"
        id={id + "__toast__id"}
      >
        {children}
        <button
          type="button"
          className="btn-close"
          data-testid={`toast-close-button-${id}`}
          id={`toast-close-button-${id}`}
          aria-label="Close"
          //data-bs-dismiss="alert"
          onClick={onClickClose}
        ></button>
      </div>
    );
  }
);

Toast.displayName = "Toast";

export default Toast;
