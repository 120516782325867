import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAccessControl } from "../../app/use-access-control";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { timelineQueryKey } from "./use-timeline";

export const useDeletePhoneActivity = (inquiryId: string) => {
  const { activeCenterId } = useAccessControl();
  const { getToken } = useAccessToken();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (phoneActivityEventId: string) =>
      deletePhoneActivity(await getToken(), activeCenterId, inquiryId, phoneActivityEventId),
    onSuccess: () => {
      queryClient.invalidateQueries(timelineQueryKey(activeCenterId, inquiryId));
    }
  });
};

const deletePhoneActivity = (accessToken: string, centerId: string, inquiryId: string, phoneActivityEventId: string) =>
  callLcosService(
    accessToken,
    `/api/inquiries/${centerId}/${inquiryId}/phone_activity/${phoneActivityEventId}`,
    "DELETE"
  );
