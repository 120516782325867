import { useQuery } from "@tanstack/react-query";
import { useAccessControl } from "../../app/use-access-control";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { familyQueryKeys } from "./query-keys";
import { FamilySelectionOption } from "./types";

interface UseFamilySelectionProps {
  inquirerId?: number;
  studentId?: number;
}

export const useFamilySelection = ({ inquirerId, studentId }: UseFamilySelectionProps) => {
  const { getToken } = useAccessToken();
  const { activeCenterId } = useAccessControl();

  return useQuery({
    queryKey: familyQueryKeys.selection(activeCenterId, inquirerId, studentId),
    enabled: !!inquirerId && inquirerId > 0 && !!studentId && studentId > 0,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => getFamilySelectionList(await getToken(), activeCenterId, inquirerId!, studentId!),
    select: data => data.families
  });
};

const getFamilySelectionList = (accessToken: string, centerId: string, inquirerId: number, studentId: number) => {
  return callLcosService<{ families: FamilySelectionOption[] }>(
    accessToken,
    `/api/families/${centerId}/contact_families?contact_id=${inquirerId}&contact_id=${studentId}`,
    "GET"
  );
};
