import React from "react";

import { AppointmentAvailabilityAccordion } from "../../accordion/appointment-availability-accordion";

export interface AppointmentAvailabilityProps {
  staffMemberId: string;
}

export const AppointmentAvailability: React.FC<AppointmentAvailabilityProps> = ({ staffMemberId }) => {
  return (
    <>
      <h3 className="card-subheader">Appointment Availability</h3>
      <AppointmentAvailabilityAccordion staffMemberId={staffMemberId} />
    </>
  );
};
