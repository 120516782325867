import React, { useMemo, useRef, useCallback } from "react";

import { useAccessControl } from "../../app/access-control-context";
import { AccessControlGate } from "../../app/access-control-gate";
import { Confirm, ConfirmHandle } from "../confirm/confirm";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import * as Permissions from "../../data/permissions";
import { format24HourTimeString, formatDateString } from "../forms/form-helpers";
import { useCenterOperatingHours, OperatingHours, OperatingHoursType, CenterDetail } from "../../hooks/use-center";
import { useReferenceData } from "../../hooks/use-reference-data";
import { useEditCenterHoursWeekModal } from "./edit-center-hours-week";
import { SimpleTable, SimpleTableHeader } from "../table";
import { IconButton } from "../buttons/icon-button";
import { Card, CardBody, CardHeader } from "react-bootstrap";
import { useEditCenterHoursModal } from "./edit-center-hours";

export interface CenterHoursProps {
  center?: CenterDetail | undefined;
  subset: OperatingHoursType;
}

export const requiredPermissionForEditing = Permissions.CENTER_MANAGE_OPERATIONAL_SCHEDULE;

export const CenterHours: React.FC<CenterHoursProps> = ({ center, subset }) => {
  const { hasPermission, hasFeatureAccess } = useAccessControl();
  const { operatingHours, isLoading, deleteOperatingHoursMutator } = useCenterOperatingHours(center?.id);
  const { referenceData, weekdayLookup } = useReferenceData();

  const hours = useMemo(() => {
    if (subset === "default") {
      return operatingHours?.default;
    } else {
      return operatingHours?.seasonal;
    }
  }, [operatingHours, subset, center]);

  const tableHeaders = useMemo<SimpleTableHeader<OperatingHours>[]>(() => {
    const sharedHoursHeaders: SimpleTableHeader<OperatingHours>[] = [
      {
        renderKey: "day",
        name: "Day",
        render: item => weekdayLookup[item.weekday],
        tableDataCellProps: {
          isPrimary: true
        }
      },
      {
        renderKey: "open",
        name: "Open",
        render: item => {
          if (!item.openTime && !item.closeTime) {
            return <em>Closed</em>;
          } else {
            return format24HourTimeString(item.openTime);
          }
        }
      },
      {
        renderKey: "close",
        name: "Close",
        render: item => format24HourTimeString(item.closeTime)
      }
    ];

    const actionHeaders: SimpleTableHeader<OperatingHours>[] =
      !!hasPermission && hasPermission(requiredPermissionForEditing)
        ? [
            {
              renderKey: "action",
              name: "",
              render: item => (
                <>
                  <IconButton
                    title="Edit"
                    icon="edit"
                    enableTooltip
                    className="btn btn-table"
                    onClick={() => showEditCenterHoursModal(item)}
                  />
                  <IconButton
                    title="Delete"
                    icon="trash"
                    enableTooltip
                    className="btn btn-table btn-danger"
                    onClick={() => onDeleteHours(item.id)}
                  />
                </>
              ),
              tableDataCellProps: {
                textAlign: "right",
                isAction: true
              }
            } as SimpleTableHeader<OperatingHours>
          ]
        : [];

    return subset === "default"
      ? [...sharedHoursHeaders, ...(hasFeatureAccess?.("center_operating_hours") ? [] : actionHeaders)]
      : [
          ...sharedHoursHeaders,
          {
            renderKey: "start",
            title: "Start",
            name: "seasonStart",
            render: item => formatDateString(item.seasonStart)
          },
          {
            renderKey: "end",
            title: "End",
            name: "seasonEnd",
            render: item => formatDateString(item.seasonEnd)
          },
          ...actionHeaders
        ];
  }, [subset, weekdayLookup]);

  const confirmDeleteHoursModal = useRef<ConfirmHandle>(null);

  const { renderEditCenterHoursWeekModal, showEditCenterHoursWeekModal } = useEditCenterHoursWeekModal({ subset });
  const { renderEditCenterHoursModal, showEditCenterHoursModal } = useEditCenterHoursModal({ subset });

  const onDeleteHours = useCallback((id: number | undefined) => {
    id &&
      confirmDeleteHoursModal.current &&
      confirmDeleteHoursModal.current.confirm(() => {
        deleteOperatingHoursMutator.mutate(id);
      });
  }, []);

  const onAddHours = useCallback(() => {
    showEditCenterHoursWeekModal(
      operatingHours?.default ?? [
        {
          id: 0,
          weekday: 0,
          openTime: "",
          closeTime: "",
          startApptTime: "",
          endApptTime: "",
          seasonStart: "",
          seasonEnd: ""
        }
      ]
    );
    showEditCenterHoursModal({
      id: 0,
      weekday: 0,
      openTime: "",
      closeTime: "",
      startApptTime: "",
      endApptTime: "",
      seasonStart: "",
      seasonEnd: ""
    });
  }, [operatingHours?.default]);

  if (!center || !hours || !referenceData) {
    return null; // delay render until we have these
  }

  return (
    <Card className="h-100">
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between">
          <h2>{subset === "default" ? "Standard Operating" : "Seasonal / Temporary"} Hours</h2>
          <AccessControlGate required={requiredPermissionForEditing}>
            {subset === "default" ? (
              <button className="btn btn-primary" onClick={onAddHours}>
                {hasFeatureAccess?.("center_operating_hours") ? "Edit" : "Add"} Operating Hours
              </button>
            ) : (
              <button className="btn btn-primary" onClick={onAddHours}>
                Add {"Seasonal"} Hours
              </button>
            )}

            {!!hasFeatureAccess?.("center_operating_hours") && subset === "default"
              ? renderEditCenterHoursWeekModal()
              : renderEditCenterHoursModal()}

            <Confirm
              ref={confirmDeleteHoursModal}
              title="Confirm"
              message="Would you like to delete this day's operating hours?"
            />
          </AccessControlGate>
        </div>
      </CardHeader>
      <CardBody>
        <LoadingOverlay loading={isLoading || deleteOperatingHoursMutator.isLoading}>
          <SimpleTable
            tableCollapseScreenSize="lg"
            tableHasIcons
            headers={tableHeaders}
            data={hours}
            uniqueIdKey="id"
          />
        </LoadingOverlay>
      </CardBody>
    </Card>
  );
};
