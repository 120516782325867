import { useCallback, useId } from "react";
import classnames from "classnames";

import { useTouched } from "../use-touched";

export interface SelectorOption {
  id: string | number;
  name: string;
  readOnly?: boolean;
}

export interface SelectorProps {
  value: string | number;
  label: string;
  mode: "edit" | "view";
  required?: boolean;
  emptyOption?: SelectorOption;
  options: SelectorOption[];
  onChangeValue?: ((newvalue: string) => void) | ((newvalue: number) => void);
  onValidate?: (input: string) => boolean;
  className?: string;
}

export type ExtendableSelectorProps = Omit<SelectorProps, "options" | "emptyOption">;

export const Selector: React.FC<SelectorProps> = ({
  value,
  label,
  mode,
  required,
  emptyOption,
  options,
  onChangeValue,
  onValidate,
  className
}) => {
  const id = useId();
  const { touched, onTouched } = useTouched();

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    e => {
      onChangeValue && onChangeValue(e.target.value as never);
    },
    [onChangeValue]
  );

  return (
    <div className={classnames("col form-floating")}>
      <select
        disabled={mode === "view"}
        className={classnames("form-control", className, {
          "is-invalid": !!onValidate && !onValidate(value?.toString()) && mode === "edit" && touched,
          "is-valid": !!onValidate && !!onValidate(value?.toString()) && mode === "edit" && touched
        })}
        value={value}
        id={id}
        required={required}
        onChange={onChange}
        onBlur={onTouched}
      >
        {!!emptyOption && <option value={emptyOption.id}>{emptyOption.name}</option>}
        {options.map(option => (
          <option key={`forms-selector-${id}-option-${option.id}`} value={option.id} disabled={option.readOnly}>
            {option.name}
          </option>
        ))}
      </select>
      <label className="text-nowrap" htmlFor={id}>
        {label}
        {required && (
          <>
            {" "}
            <span className="required" aria-hidden="true">
              *
            </span>
          </>
        )}
      </label>
    </div>
  );
};
