import { forwardRef, useMemo, useState } from "react";
import { Controller, type ControllerFieldState, type ControllerProps, type FieldValues } from "react-hook-form";

import { useFindFamilyContact, type FamilyContact } from "../../hooks/use-families";
import { ComboboxItem, SearchSelect } from "./search-select";
import { useDebounce } from "../../hooks/use-debounce";

interface ContactSearchProps {
  onSelect?: (contact: FamilyContact) => void;
  label?: string;
  addNewLabel?: string;
  selectedContact?: FamilyContact;
  onCreateNewClick?: () => void;
  onBlur?: () => void;
  fieldState?: ControllerFieldState;
  name: string;
  isRequired?: boolean;
  disabled?: boolean;
}

export const ContactSearch = forwardRef<HTMLDivElement, ContactSearchProps>(
  (
    {
      onSelect,
      label = "Contact",
      addNewLabel = "Create New Contact",
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCreateNewClick = () => {},
      selectedContact,
      onBlur,
      fieldState,
      name,
      isRequired,
      disabled
    },
    ref
  ) => {
    const [searchValue, setSearchValue] = useState<string>("");
    const debouncedSearchInput = useDebounce(searchValue, 350);
    const { contacts, isLoading } = useFindFamilyContact(debouncedSearchInput);

    const handleSearchChange = (searchValue = "") => {
      setSearchValue(searchValue);
    };

    const handleSelectChange = (item?: ComboboxItem | null) => {
      //@ts-ignore
      onSelect && onSelect(item?.contact);
    };

    const items = useMemo(
      () =>
        contacts.map(contact => ({
          value: contact.id,
          label: contact.displayName,
          contact
        })),
      [contacts]
    );

    const selectedItem = selectedContact
      ? {
          label: selectedContact.displayName,
          value: selectedContact.id
        }
      : undefined;

    return (
      <SearchSelect
        onSearchChange={handleSearchChange}
        createNewOptionProps={{ onCreateNewClick, label: addNewLabel }}
        selectedItem={selectedItem}
        options={items}
        onChange={handleSelectChange}
        toggleLabel={label}
        onBlur={onBlur}
        ref={ref}
        fieldState={fieldState}
        name={name}
        isLoadingOptions={isLoading}
        searchValue={searchValue}
        isRequired={isRequired}
        disabled={disabled}
      />
    );
  }
);

ContactSearch.displayName = "ContactSearch";

type ContactSearchControllerProps<T extends FieldValues> = Omit<ControllerProps<T>, "render"> &
  Omit<ContactSearchProps, "selectedContact" | "onBlur" | "fieldState" | "name">;

export const ContactSearchController = <T extends FieldValues>({
  onSelect,
  label,
  addNewLabel = "Add New Contact",
  onCreateNewClick,
  isRequired,
  disabled,
  ...controllerProps
}: ContactSearchControllerProps<T>) => {
  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => {
        const handleSelect = (value: FamilyContact) => {
          onSelect?.(value);
          field.onChange(value);
        };
        return (
          <ContactSearch
            addNewLabel={addNewLabel}
            onCreateNewClick={onCreateNewClick}
            label={label}
            selectedContact={field.value}
            onSelect={handleSelect}
            onBlur={field.onBlur}
            ref={field.ref}
            fieldState={fieldState}
            name={field.name}
            isRequired={isRequired}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
