import classNames from "classnames";
import { forwardRef, useId, type FC } from "react";

export const CheckboxFieldset: FC<React.FieldsetHTMLAttributes<HTMLFieldSetElement>> = props => (
  <fieldset {...props} className={classNames("checkboxes-inline", props.className)} />
);

interface CheckboxFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelStyle?: React.CSSProperties;
}

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  ({ label, labelStyle, ...checkboxProps }, ref) => {
    const inputId = useId();

    return (
      <div className="form-check">
        <label className="form-check-label" htmlFor={inputId} style={labelStyle}>
          {label}
        </label>
        <input
          {...checkboxProps}
          type="checkbox"
          id={inputId}
          className={classNames("form-check-input", checkboxProps.className)}
          ref={ref}
        />
      </div>
    );
  }
);

CheckboxField.displayName = "CheckboxField";

export const CheckboxFieldLabelFirst = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  ({ label, labelStyle, ...checkboxProps }, ref) => {
    const inputId = useId();

    return (
      <div className="row standard-form-row">
        <div className="col col-auto">
          <label className="form-check-label" htmlFor={inputId} style={labelStyle}>
            {label}
          </label>
        </div>
        <div className="col">
          <input
            {...checkboxProps}
            type="checkbox"
            id={inputId}
            className={classNames("form-check-input", checkboxProps.className)}
            ref={ref}
          />
        </div>
      </div>
    );
  }
);

CheckboxFieldLabelFirst.displayName = "CheckboxFieldLabelFirst";
