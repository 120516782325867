import { useReferenceData } from "../../../hooks/use-reference-data";
import { ExtendableSelectorProps, Selector } from "./selector";

export const SeparationReasonSelector: React.FC<ExtendableSelectorProps> = ({ ...selectorProps }) => {
  const { separationReasons, isLoading } = useReferenceData();

  if (!separationReasons || isLoading) {
    return null;
  }

  return (
    <Selector
      options={separationReasons}
      emptyOption={{ id: "", name: "Select a Deactivation Reason" }}
      {...selectorProps}
    />
  );
};
