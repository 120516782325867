import { cloneElement, type FormEventHandler, type ReactElement } from "react";
import { useFormContext, type UseFormReturn, type FieldValues, type SubmitHandler } from "react-hook-form";

export interface BasicFormProps<T extends FieldValues> {
  methods?: UseFormReturn<T>;
}

interface FormWrapperProps<T extends FieldValues> extends BasicFormProps<T> {
  formId: string;
  onFormSubmit?: FormEventHandler<HTMLFormElement>;
  formClassName?: string;
  children: ReactElement<BasicFormProps<T>>;
}

export const FormWrapper = <T extends FieldValues>({
  formId,
  onFormSubmit,
  formClassName,
  children,
  ...basicFormProps
}: FormWrapperProps<T>) => {
  const renderChildren = () => {
    return cloneElement(children as ReactElement<BasicFormProps<T>>, { ...basicFormProps });
  };
  return (
    <form id={formId} className={formClassName} onSubmit={onFormSubmit}>
      {renderChildren()}
    </form>
  );
};

interface FormWithContextProps<T extends FieldValues> extends Omit<FormWrapperProps<T>, "onFormSubmit" | "methods"> {
  onSubmit: SubmitHandler<FieldValues>;
}

export const FormWithContext = <T extends FieldValues>({
  formId,
  formClassName,
  onSubmit,
  children,
  ...formWrapperProps
}: FormWithContextProps<T>) => {
  const methods = useFormContext();
  return (
    <FormWrapper
      formId={formId}
      formClassName={formClassName}
      methods={methods}
      onFormSubmit={methods.handleSubmit(onSubmit)}
      {...formWrapperProps}
    >
      {children as ReactElement<BasicFormProps<FieldValues>>}
    </FormWrapper>
  );
};
