import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { v4 as uuid4 } from "uuid";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { ToastParameters, ToastFormat } from "./types";
import Toast from "./toast";

export interface ToastContextType {
  add: (params: ToastParameters) => void;
  addModalToast: (params: ToastParameters) => void;
  remove: (id: string) => void;
  removeModalToast: (id: string) => void;
  removeAll: () => void;
  removeAllModalToasts: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = /* istanbul ignore next */ () => {};

export const ToastContext = React.createContext<ToastContextType>({
  add: noop,
  addModalToast: noop,
  remove: noop,
  removeModalToast: noop,
  removeAll: noop,
  removeAllModalToasts: noop
});

export const buildToasts = (
  toasts: ToastParameters[],
  format: ToastFormat,
  remove: (id: string | undefined) => void
): JSX.Element[] => {
  return toasts
    .filter(t => t.format === format)
    .map(t => {
      const nodeRef = React.createRef<HTMLDivElement>();
      const toastRemove = (): void => remove(t.id);
      return (
        <CSSTransition
          key={t.id + "_transition"}
          in={true}
          timeout={200}
          classNames="transition"
          unmountOnExit={true}
          appear={true}
          nodeRef={nodeRef}
        >
          <Toast
            key={t.id}
            remove={toastRemove}
            action={t.action}
            actionLabel={t.actionLabel}
            keepOpen={t.keepOpen}
            duration={t.duration}
            onClose={t.onClose}
            onUserClose={t.onUserClose}
            id={t.id}
            type={t.type}
            ref={nodeRef}
          >
            {t.content}
          </Toast>
        </CSSTransition>
      );
    });
};

export const ToastProvider: React.FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [globalToasts, setGlobalToasts] = useState<ToastParameters[]>([]);
  const [modalToasts, setModalToasts] = useState<ToastParameters[]>([]);

  const add = useCallback(
    ({ id = uuid4(), duration = 15000, content, format = "large", ...rest }: ToastParameters): string => {
      // warned you that an empty message would be a no-op
      if (!content) {
        return "";
      }

      setGlobalToasts((prevState: ToastParameters[]) => {
        // if the toast id already exists, no-op
        if (prevState && prevState.find(ps => ps.id === id)) {
          return prevState;
        }
        const newState = [
          ...prevState,
          {
            id,
            content,
            duration,
            format,
            ...rest
          }
        ];

        return newState;
      });

      return id;
    },
    [setGlobalToasts]
  );

  const addModalToast = useCallback(
    ({ id = uuid4(), duration = 15000, content, format = "small", ...rest }: ToastParameters): string => {
      // warned you that an empty message would be a no-op
      if (!content) {
        return "";
      }

      setModalToasts((prevState: ToastParameters[]) => {
        // if the toast id already exists, no-op
        if (prevState && prevState.find(ps => ps.id === id)) {
          return prevState;
        }
        const newState = [
          ...prevState,
          {
            id,
            content,
            duration,
            format,
            ...rest
          }
        ];

        return newState;
      });

      return id;
    },
    [setModalToasts]
  );

  const remove = (id: string | undefined) => {
    globalToasts.forEach(t => {
      if (t.id === id && t.onClose) {
        t.onClose();
      }
    });
    setGlobalToasts((prevState: ToastParameters[]) => {
      const newState = [...prevState.filter(t => t.id !== id)];
      return newState;
    });
  };

  const removeModalToast = (id: string | undefined) => {
    modalToasts.forEach(t => {
      if (t.id === id && t.onClose) {
        t.onClose();
      }
    });
    setModalToasts((prevState: ToastParameters[]) => {
      const newState = [...prevState.filter(t => t.id !== id)];
      return newState;
    });
  };

  const removeAll = () => {
    globalToasts.forEach(t => {
      remove(t.id);
    });
  };

  const removeAllModalToasts = () => {
    modalToasts.forEach(toast => {
      removeModalToast(toast.id);
    });
  };

  window.toasts = {
    ...window.toasts,
    add,
    addModalToast,
    remove,
    removeAll,
    removeModalToast,
    removeAllModalToasts
  };

  useEffect(() => {
    window.toasts.modalToasts = [...modalToasts];
    const modalToastUpdateEvent = new Event("modal-toast-update");
    window.dispatchEvent(modalToastUpdateEvent);
  }, [modalToasts]);

  const providerValue = useMemo(
    () => ({ add, addModalToast, remove, removeModalToast, removeAll, removeAllModalToasts }),
    [add, addModalToast, remove, removeModalToast, removeAll, removeAllModalToasts]
  );

  const toastWrapper = (
    <React.Fragment>
      <div className="toasts__wrapper toasts__wrapper__large">
        <TransitionGroup>{buildToasts(globalToasts, "large", remove)}</TransitionGroup>
      </div>
      <div className="toasts__wrapper toasts__wrapper__small">
        <TransitionGroup>{buildToasts(globalToasts, "small", remove)}</TransitionGroup>
      </div>
      <div className="toasts__wrapper toasts__wrapper__screen__reader sr-only">
        <TransitionGroup>{buildToasts(globalToasts, "screen-reader", remove)}</TransitionGroup>
      </div>
    </React.Fragment>
  );

  const portal = ReactDOM.createPortal(toastWrapper, document.getElementById("utility-root") || document.body);

  return (
    <ToastContext.Provider value={providerValue}>
      {children}
      {portal}
    </ToastContext.Provider>
  );
};
