import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../config/auth-config";

export const useAccessToken = () => {
  const { instance, accounts } = useMsal();

  const getToken = async () => {
    const { accessToken } = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0]
    });

    return accessToken;
  };

  return {
    getToken
  };
};
