import React, { useMemo, useRef } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { useAccessControl } from "../../../app/access-control-context";
import { STUDENT_FAMILY_CREATE_EDIT } from "../../../data/permissions";
import {
  FamilyRelationshipDetails,
  FamilyRole,
  useFamilyConstants,
  useFamilyMembers
} from "../../../hooks/use-families";
import { useInquiryFamilyInfo } from "../../../hooks/use-inquiries";
import { Inquiry } from "../../../hooks/use-inquiries";
import { Confirm, ConfirmHandle } from "../../confirm/confirm";
import { FormHelpers } from "../../forms";
import { useFamilyContactDetailFormModal } from "./edit-family-contact-form";
import { SimpleTable, SimpleTableHeader } from "../../table";
import { useStudentDetailFormModal } from "./edit-student-contact-form";

export interface FamilyInfoTableRow {
  id: number;
  name: string;
  relationships: (string | number)[];
  preferredPhone: string;
  email: string;
  isPrimaryContact: boolean;
  emergencyContactRelationship: FamilyRelationshipDetails | undefined;
  primaryContactRole: FamilyRole | undefined;
  isExtraContact?: boolean;
  isStudent?: boolean; // Property to track if the family member is the student
}

export const useAllFamilyMembers = ({ inquiryId }: { inquiryId: string }) => {
  const { activeCenterId } = useAccessControl();
  const { familyId } = useInquiryFamilyInfo({ centerId: activeCenterId, inquiryId });
  return useFamilyMembers(familyId);
};

export const FamilyInfo: React.FC<{ inquiry: Inquiry }> = ({ inquiry }) => {
  const { activeCenterId, hasPermission } = useAccessControl();
  const {
    familyContactsData,
    addEmergencyContact,
    removeEmergencyContact,
    addPrimaryContact,
    isLoading: familyInfoLoading
  } = useInquiryFamilyInfo({ centerId: activeCenterId, inquiryId: inquiry.id });
  const { familyRelationshipLookup } = useFamilyConstants();
  const { data: allFamilyMembers } = useAllFamilyMembers({ inquiryId: inquiry.id });
  const confirmChangePrimaryContactModal = useRef<ConfirmHandle>(null);

  const displayFamilyMembers = useMemo(() => {
    const results: FamilyInfoTableRow[] = [...familyContactsData];

    const sorted =
      allFamilyMembers?.members?.slice?.()?.sort((left, right) => left.displayName.localeCompare(right.displayName)) ??
      [];

    sorted.forEach(member => {
      if (!results.find(r => r.id === member.id) && 0 !== member.id) {
        results.push({
          id: member.id,
          name: member.displayName,
          relationships: [],
          preferredPhone: member.preferredPhone.phoneNumber,
          email: member.email,
          isPrimaryContact: false,
          emergencyContactRelationship: undefined,
          primaryContactRole: undefined,
          isExtraContact: true,
          isStudent: member.id === inquiry.studentId // True if the member is the student based on their ID
        });
      }
    });

    return results;
  }, [familyContactsData, allFamilyMembers]);

  const { showFamilyContactFormModal, renderFamilyContactFormModal } = useFamilyContactDetailFormModal();
  const { showStudentDetailFormModal, renderStudentDetailFormModal } = useStudentDetailFormModal(inquiry);

  const handleChangePrimaryContact = (contactId: number) => {
    confirmChangePrimaryContactModal.current &&
      confirmChangePrimaryContactModal.current.confirm(() => {
        addPrimaryContact(contactId);
      });
  };

  const handleCreateNewContact = () => {
    showFamilyContactFormModal({ inquiryId: inquiry.id });
  };

  const familyInfoHeaders: SimpleTableHeader<FamilyInfoTableRow>[] = [
    {
      renderKey: "name",
      name: "Name",
      render: item => {
        return <span style={{ color: "#2a7b67", textDecoration: "underline" }}>{item.name}</span>;
      }
    },
    {
      renderKey: "relationships",
      name: "Relation",
      render: item => {
        // If the family member is the student, display "Student"
        if (item.isStudent) {
          return "Student";
        }
        // Otherwise, display the relationships as before
        return item.relationships
          .map(relationship => familyRelationshipLookup[relationship] || relationship)
          .join(", ");
      }
    },
    {
      renderKey: "phoneEmail",
      name: "Phone/Email",
      render: item => {
        const phone = !!item.preferredPhone ? (
          <>
            {FormHelpers.formatPhoneNumber(item.preferredPhone)}
            <br />
          </>
        ) : null;
        return (
          <>
            {phone}
            <OverlayTrigger
              placement={"top"}
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip>{item.email}</Tooltip>}
            >
              <span className="truncate">{item.email}</span>
            </OverlayTrigger>
          </>
        );
      }
    },
    {
      renderKey: "primaryContact",
      name: "Primary Contact",
      render: item => {
        if (item.isExtraContact) {
          return null;
        }
        return (
          <div
            className="primary-contact-cell"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: item.primaryContactRole ? undefined : "pointer"
            }}
            data-testid={`primary-contact-checkbox-${item.id}`}
            onClick={e => {
              e.stopPropagation();
              if (!item.primaryContactRole) {
                handleChangePrimaryContact(item.id);
              }
            }}
          >
            {item.primaryContactRole ? (
              <span className="fas fa-check-circle icon" style={{ color: "#c9178d", fontWeight: 600 }} />
            ) : (
              <span className="fas fa-times-circle icon" style={{ color: "#8f959f", fontWeight: 600 }} />
            )}
          </div>
        );
      }
    },
    {
      renderKey: "emergencyContact",
      name: "Emergency Contact",
      render: item => {
        if (item.isExtraContact) {
          return null;
        }
        return (
          <div
            className="emergency-contact-cell"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer"
            }}
            data-testid={`emergency-contact-checkbox-${item.id}`}
            onClick={e => {
              e.stopPropagation();
              if (item.emergencyContactRelationship) {
                removeEmergencyContact(item.emergencyContactRelationship);
              } else {
                addEmergencyContact(item.id);
              }
            }}
          >
            {item.emergencyContactRelationship ? (
              <span className="fas fa-check-circle icon" style={{ color: "#c9178d", fontWeight: 600 }} />
            ) : (
              <span className="fas fa-times-circle icon" style={{ color: "#8f959f", fontWeight: 600 }} />
            )}
          </div>
        );
      }
    }
  ];

  const handleRowClick = (row: FamilyInfoTableRow) => {
    const contactId = row.id;
    if (row.isExtraContact) {
      showStudentDetailFormModal(contactId);
    } else {
      showFamilyContactFormModal({ contactId, inquiryId: inquiry.id });
    }
  };

  return (
    <div className="card-body">
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="card-subheader">Family Info</h3>
        {hasPermission && hasPermission(STUDENT_FAMILY_CREATE_EDIT) && (
          <button className="btn btn-primary btn-sm ms-2 mb-4" onClick={handleCreateNewContact}>
            Create Contact
          </button>
        )}
      </div>

      <SimpleTable
        tableCollapseScreenSize="sm"
        headers={familyInfoHeaders}
        data={displayFamilyMembers}
        isLoading={familyInfoLoading}
        uniqueIdKey="id"
        onRowClick={handleRowClick}
      />
      <Confirm
        ref={confirmChangePrimaryContactModal}
        title="Change Primary Contact"
        message="Please confirm change of primary contact"
        okLabel="Confirm"
        cancelLabel="Cancel"
      />
      <>{renderFamilyContactFormModal()}</>
      <>{renderStudentDetailFormModal()}</>
    </div>
  );
};
