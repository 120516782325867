import { ChangeEventHandler, forwardRef } from "react";

import { InputField, type InputFieldProps } from "./input-field";
import { FormHelpers } from ".";

export const PhoneNumberField = forwardRef<HTMLInputElement, InputFieldProps>(
  ({ onChange, ...remainingProps }, ref) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
      event.target.value = FormHelpers.formatPhoneNumber(event.target.value);
      onChange?.(event);
    };
    return <InputField ref={ref} onChange={handleChange} {...remainingProps} />;
  }
);

PhoneNumberField.displayName = "PhoneNumberField";
