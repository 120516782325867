import { useState } from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { useCenterHolidays } from "../../hooks/use-center/use-center-holidays";
import { CenterHoliday } from "../../hooks/use-center/types";
import { useModal } from "../modal/modal";
import { InputField } from "../forms/input-field";
import { LoadingButton } from "../loading-button/loading-button";

export interface UseEditCenterHolidayModalProps {
  centerId: string | undefined;
}

const holidayHour = z.object({
  date: z.string().min(1, { message: "Date is required" }),
  name: z.string().min(1, { message: "Description is required" }).max(255, { message: "Description is too long" })
});

const defaultHolidayHour = {
  date: "",
  name: ""
};

export const useEditCenterHolidayModal = ({ centerId }: UseEditCenterHolidayModalProps) => {
  const { show, hide: hideEditCenterHolidayModal, renderModal } = useModal();
  const { holidayEditMutator, holidayCreateMutator } = useCenterHolidays(centerId);
  const [selectedHoliday, setSelectedHoliday] = useState<CenterHoliday>();
  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset
  } = useForm({
    defaultValues: defaultHolidayHour,
    values: {
      date: selectedHoliday?.date ?? "",
      name: selectedHoliday?.name ?? ""
    },
    resolver: zodResolver(holidayHour),
    mode: "onTouched"
  });

  const showEditCenterHolidayModal = (holiday: CenterHoliday) => {
    reset();
    setSelectedHoliday(holiday);
    show();
  };

  const localSave = handleSubmit(data => {
    if (selectedHoliday?.id) {
      holidayEditMutator.mutate(
        { id: selectedHoliday.id, ...data },
        {
          onSuccess: hideEditCenterHolidayModal
        }
      );
    } else {
      holidayCreateMutator.mutate(
        { id: "", ...data },
        {
          onSuccess: hideEditCenterHolidayModal
        }
      );
    }
  });

  const renderEditCenterHolidayModal = () =>
    renderModal({
      title: `Holiday / Emergency Hours`,
      body: (
        <div className="row form-standard">
          <div className="col col-12">
            <InputField
              label="Date"
              disableColClass
              type="date"
              isRequired
              error={errors["date"]}
              {...register("date")}
            />
          </div>
          <div className="col col-12">
            <InputField label="Description" disableColClass isRequired error={errors["name"]} {...register("name")} />
          </div>
        </div>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideEditCenterHolidayModal}>
            Cancel
          </button>
          <LoadingButton
            className="btn btn-primary"
            onClick={localSave}
            disabled={!isValid}
            isLoading={holidayCreateMutator.isLoading || holidayEditMutator.isLoading}
          >
            Add
          </LoadingButton>
        </>
      )
    });

  return {
    showEditCenterHolidayModal,
    hideEditCenterHolidayModal,
    renderEditCenterHolidayModal
  };
};
