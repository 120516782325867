import type { ReferenceRecord } from "../use-reference-data";
import type { StaffMember } from "../use-user";

export interface CenterStaffId {
  centerId: string;
  centerName: string;
  staffId?: string;
  employeeNumber: number | null;
}

interface CenterReferenceRecord extends ReferenceRecord {
  isActiveForCenter: boolean;
}

export interface CenterConstants {
  services: CenterReferenceRecord[];
  programs: CenterReferenceRecord[];
  subjects: CenterReferenceRecord[];
  tests: CenterReferenceRecord[];
  bookingsAppointmentDurations: Record<string, Record<string, Record<string, number>>>;
  testDurations: Record<string, number>;
  chainableFieldsDict: {
    appointmentTypesDict: Record<string, { services: ReferenceRecord[]; locations: ReferenceRecord[] }>;
    servicesDict: ServiceDictionary;
    subServicesDict: SubServiceDictionary;
  };
  appointmentLocations: CenterReferenceRecord[];
  appointmentTypes: CenterReferenceRecord[];
  timezone: string;
}
export type ServiceDictionary = Record<string, { subServices: ReferenceRecord[]; appointmentTypes: ReferenceRecord[] }>;
export type SubServiceDictionary = Record<string, { subjects: ReferenceRecord[]; tests: ReferenceRecord[] }>;

export interface CenterHoliday {
  id: string;
  name: string;
  date: string;
}

export interface CenterHolidays {
  holidays: CenterHoliday[];
}

export interface OperatingHours {
  id?: number;
  weekday: number;
  openTime: string;
  closeTime: string;
  startApptTime: string;
  endApptTime: string;
  seasonStart: string;
  seasonEnd: string;
}

export interface BrandStandardOperatingHours {
  id: number;
  weekday: number;
  openTime: string;
  closeTime: string;
  startApptTime: string;
  endApptTime: string;
  seasonStart: string;
  seasonEnd: string;
}

export type OperatingHoursType = "default" | "seasonal";

export interface CenterOperatingHours {
  default: OperatingHours[];
  seasonal: OperatingHours[];
}

export interface CenterStaffList {
  staff: StaffMember[];
}

export interface CenterStaffFilters {
  isRehireable?: boolean;
  position?: string;
  isActive?: boolean;
}

export interface CenterDetail {
  id: string;
  name: string;
  state: string;
  zipcode: string;
  city: string;
  line1: string;
  line2: string;
  phoneDirect: string;
  phoneTollfree: string;
  phoneAdvertised: string;
  email: string;
  url: string;
  latitude?: string;
  longitude?: string;
  existsInDynamics?: boolean;
}

export interface CentersList {
  centers: CenterDetail[];
}

export interface UserFormInput<T extends object, U extends object = T> {
  buffer: T;
  payloadFromBuffer: T | U;
  onChangeValue: Function;
  resetBuffer: Function;
  setBuffer: Function;
  isDirty: boolean;
}

export interface SocialMedia {
  id?: string;
  name: string;
  url: string;
}

export interface SocialMediaList {
  socialMedia: SocialMedia[];
}

export const DISCOUNT_TYPES = {
  DOLLAR: "DOL",
  PERCENT: "PER"
} as const;

type DiscountTypeKeys = keyof typeof DISCOUNT_TYPES;
export type DiscountTypeValues = (typeof DISCOUNT_TYPES)[DiscountTypeKeys];
export interface CenterWebsite {
  shortDescription: string;
  longDescription: string;
  areas: string;
  schools: string;
  directions: string;
  alumniDestination: string;
  discountType: DiscountTypeValues;
  discountValue?: number;
  socialMedia: SocialMedia[];
}
