/* istanbul ignore file */
// for some reason coverage shows missing on line 'super(message);' on the semicolon?
export class NoAssignedCenterError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NoAssignedCenterError";
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, NoAssignedCenterError.prototype);
  }
}
