/* istanbul ignore file */

import { type UseStaffAvailabilityProps } from "./types";

export const staffQueryKeys = {
  base: ["staff"],
  availability: (
    centerId: string,
    { appointmentTypeId, staffIds, startDatetime, endDatetime }: UseStaffAvailabilityProps
  ) =>
    [
      ...staffQueryKeys.base,
      "availability",
      { centerId, appointmentTypeId, staffIds, startDatetime, endDatetime }
    ] as const
} as const;
