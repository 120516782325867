import { useQuery } from "@tanstack/react-query";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { FamilyMembers } from "./types";

export const familyMemberQueryKeys = {
  base: ["familyMembers"],
  detail: (familyId?: string | null) => [...familyMemberQueryKeys.base, familyId] as const
} as const;

export const useFamilyMembers = (familyId?: string | null) => {
  const { getToken } = useAccessToken();

  const query = useQuery({
    queryKey: familyMemberQueryKeys.detail(familyId),
    enabled: !!familyId,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => getFamilyMembers(await getToken(), familyId!)
  });

  return query;
};

export const getFamilyMembers = (accessToken: string, familyId: string) => {
  return callLcosService<FamilyMembers>(accessToken, `/api/families/${familyId}/members`, "GET");
};
