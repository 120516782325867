import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { LoadingOverlay } from "../components/loading-overlay/loading-overlay";
import { loginRequest } from "../config/auth-config";

export const Login: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const [error, setError] = useState<unknown>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [working, setWorking] = useState<boolean>(false);

  const handleLogin = async () => {
    try {
      setWorking(true);
      await instance.loginRedirect(loginRequest);
    } catch (err) {
      setError(err);
      setWorking(false);
    }
  };

  useEffect(() => {
    if (error) {
      if (typeof error === "string") {
        setErrorMessage(error);
      } else if ((error as { message?: string }) instanceof Error) {
        setErrorMessage((error as Error).message);
      }
    } else {
      setErrorMessage("");
    }
  }, [error]);

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <div className="login-container">
        <div className="content-pad" style={{ maxWidth: "400px" }}>
          <LoadingOverlay loading={working}>
            <div className="card">
              <div className="login-card-row">
                <div className="login-card-logo">
                  <img src="/img/logo-huntington.png" alt="Huntington Learning Center" />
                </div>
              </div>
              <br />
              <h2>Learning Center Operations System</h2>
              <br />
              <div className="form">
                <div className="row">
                  <div className="col form-floating">
                    <button title="Sign In" className="btn btn-primary" onClick={() => handleLogin()}>
                      Sign In
                    </button>
                  </div>
                </div>
                <div className="row login-error">{errorMessage}</div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    </>
  );
};
