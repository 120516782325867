import { useAccessToken } from "./use-access-token";
import { callLcosService } from "../data/lcosServices";
import { FormHelpers } from "../components/forms";
import { EmploymentHistoryResponse } from "./use-employment-history";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface PositionChangePayload {
  effectiveDate: string;
  position: string;
  jobType: string;
  createMailbox: boolean;
}

export interface SeparationPayload {
  separationDate: string;
  separationReason: string;
  eligibleForRehire: boolean;
  disableNow: boolean;
  additionalCenters: string[];
}

export interface RehirePayload {
  effectiveDate: string;
  position: string;
  jobType: string;
  role: string;
  createMailbox: boolean;
  centers: string[];
}

export interface EditPositionPayload {
  id: number;
  effectiveDate: string;
  end?: string;
  position: string;
  jobType: string;
}

export const useModifyPosition = (centerId: string, staffMemberId: string, onMutateSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const centerStaffQueryKey = ["centerStaffData", centerId];
  const employmentHistoryQueryKey = ["employmentHistoryData", centerId, staffMemberId];

  const positionChangeMutator = useMutation({
    mutationFn: async (positionChange: PositionChangePayload) =>
      updatePositionChange(await getToken(), centerId, staffMemberId, positionChange),

    onSuccess: updatedData => {
      queryClient.invalidateQueries(centerStaffQueryKey);
      queryClient.setQueryData(employmentHistoryQueryKey, () => ({
        employeeHistory: [...updatedData.employeeHistory]
      }));
      onMutateSuccess?.();
    }
  });

  const separationMutator = useMutation({
    mutationFn: async (payload: SeparationPayload) =>
      updateSeparation(await getToken(), centerId, staffMemberId, payload),
    onSuccess: updatedData => {
      queryClient.invalidateQueries(centerStaffQueryKey);
      queryClient.setQueryData(employmentHistoryQueryKey, () => ({
        employeeHistory: [...updatedData.employeeHistory]
      }));
      onMutateSuccess?.();
    }
  });

  const rehireMutator = useMutation({
    mutationFn: async (payload: RehirePayload) => rehireStaffMember(await getToken(), centerId, staffMemberId, payload),
    onSuccess: updatedData => {
      queryClient.invalidateQueries(centerStaffQueryKey);
      queryClient.setQueryData(employmentHistoryQueryKey, () => ({
        employeeHistory: [...updatedData.employeeHistory]
      }));
      onMutateSuccess?.();
    }
  });

  const editPositionMutator = useMutation({
    mutationFn: async (position: EditPositionPayload) =>
      editPosition(await getToken(), centerId, staffMemberId, position),

    onSuccess: updatedData => {
      queryClient.invalidateQueries(centerStaffQueryKey);
      queryClient.setQueryData(employmentHistoryQueryKey, () => ({
        employeeHistory: [...updatedData.employeeHistory]
      }));
      onMutateSuccess?.();
    }
  });
  const deletePositionMutator = useMutation({
    mutationFn: async (positionHistoryId: number) =>
      deletePosition(await getToken(), centerId, staffMemberId, positionHistoryId),

    onSuccess: updatedData => {
      queryClient.invalidateQueries(centerStaffQueryKey);
      queryClient.setQueryData(employmentHistoryQueryKey, () => ({
        employeeHistory: [...updatedData.employeeHistory]
      }));
      onMutateSuccess?.();
    }
  });

  return {
    positionChangeMutator,

    separationMutator,

    rehireMutator,

    editPositionMutator,

    deletePositionMutator
  };
};

export const updatePositionChange = (
  accessToken: string,
  centerId: string | undefined,
  userId: string | undefined,
  positionChange: PositionChangePayload
) => {
  return callLcosService<EmploymentHistoryResponse>(
    accessToken,
    `/api/position/${centerId}/${userId}/change`,
    "POST",
    positionChange
  );
};

export const updateSeparation = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined,
  payload: SeparationPayload
) => {
  return callLcosService<EmploymentHistoryResponse>(
    accessToken,
    `/api/position/${centerId}/${staffMemberId}/separation`,
    "POST",
    payload
  );
};

export const rehireStaffMember = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined,
  payload: RehirePayload
) => {
  return callLcosService<EmploymentHistoryResponse>(
    accessToken,
    `/api/position/${centerId}/${staffMemberId}/rehire`,
    "POST",
    payload
  );
};

export const editPosition = (
  accessToken: string,
  centerId: string | undefined,
  userId: string | undefined,
  position: EditPositionPayload
) => {
  return callLcosService<EmploymentHistoryResponse>(
    accessToken,
    `/api/position/${centerId}/${userId}/${position.id}`,
    "PUT",
    position
  );
};

export const deletePosition = (
  accessToken: string,
  centerId: string | undefined,
  userId: string | undefined,
  positionHistoryId: number
) => {
  return callLcosService<EmploymentHistoryResponse>(
    accessToken,
    `/api/position/${centerId}/${userId}/${positionHistoryId}`,
    "DELETE"
  );
};

// going to try something new with this one, it occurred to me rather than
// dumping all the object validators in form-helpers.ts it makes more sense to
// have them be neighbors to the code most germane to them, if this works out
// well then future would be to refactor the other instances

// a imperative functional for procedural code
export const isValidRehire = (rehire: RehirePayload) => {
  return (
    !!rehire &&
    !!rehire.centers &&
    FormHelpers.isValidRequiredString(rehire.jobType) &&
    FormHelpers.isValidRequiredDateString(rehire.effectiveDate) &&
    FormHelpers.isValidRequiredString(rehire.role) &&
    FormHelpers.isValidRequiredString(rehire.position) &&
    rehire.centers.length > 0
  );
};

export const isValidSeparation = (separation: SeparationPayload) => {
  return (
    !!separation &&
    FormHelpers.isValidRequiredString(separation.separationReason) &&
    FormHelpers.isValidRequiredDateString(separation.separationDate)
  );
};

export const isValidPositionChange = (positionChange: PositionChangePayload) => {
  return (
    !!positionChange &&
    FormHelpers.isValidRequiredString(positionChange.jobType) &&
    FormHelpers.isValidRequiredDateString(positionChange.effectiveDate) &&
    FormHelpers.isValidRequiredString(positionChange.position)
  );
};

export const isValidEditPosition = (position: EditPositionPayload) => {
  return (
    !!position &&
    FormHelpers.isValidRequiredString(position.jobType) &&
    FormHelpers.isValidRequiredDateString(position.effectiveDate) &&
    FormHelpers.isValidRequiredString(position.position)
  );
};

export const useRehireValidator = FormHelpers.createValidatorHook<RehirePayload>(isValidRehire);
export const usePositionChangeValidator = FormHelpers.createValidatorHook<PositionChangePayload>(isValidPositionChange);
export const useSeparationValidator = FormHelpers.createValidatorHook<SeparationPayload>(isValidSeparation);
export const useEditPositionValidator = FormHelpers.createValidatorHook<EditPositionPayload>(isValidEditPosition);
