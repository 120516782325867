import classnames from "classnames";
import { useCallback, useId } from "react";

import { useTouched } from "../use-touched";

export interface FieldProps {
  value: string;
  label: string;
  mode: "edit" | "view";
  required?: boolean;
  type?: "text" | "date" | "time" | "password" | "number" | "datetime-local";
  max?: string;
  min?: string;
  addOnClassName?: string;
  onChangeValue?: (newvalue: string) => void;
  onValidate?: (input: string) => boolean;
  className?: string;
  onAddOnClick?: () => void;
  step?: string | number;
  labelClassName?: string;
  containerClassName?: string;
  autoComplete?: string;
  // TODO: we need to remove col class from all form so we can handle this in a container higher up
  disableColClass?: boolean;
}

export const Field: React.FC<React.PropsWithChildren<FieldProps>> = ({
  value,
  label,
  mode,
  required = false,
  children,
  addOnClassName = null,
  type,
  className,
  onChangeValue,
  onValidate,
  onAddOnClick,
  max,
  min,
  step,
  labelClassName,
  containerClassName,
  autoComplete,
  disableColClass = false
}) => {
  const id = useId();
  const { touched, onTouched } = useTouched();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      onChangeValue && onChangeValue(e.target.value);
    },
    [onChangeValue]
  );

  return (
    <div
      className={classnames(
        "form-floating",
        { col: !disableColClass },
        { "input-group": !!addOnClassName },
        containerClassName
      )}
    >
      <input
        type={type}
        id={id}
        className={classnames("form-control", className, {
          "is-invalid": onValidate && !onValidate(value) && mode === "edit" && touched,
          "is-valid": onValidate && !!onValidate(value) && mode === "edit" && touched
        })}
        onChange={onChange}
        value={value || ""}
        disabled={mode === "view"}
        readOnly={mode === "view"}
        required={required}
        onBlur={onTouched}
        max={max}
        min={min}
        step={step}
        // this will always use new-password in view mode, otherwise use
        // autoComplete prop value (which may be undefined and that is legit too)
        autoComplete={mode === "view" ? "new-password" : autoComplete}
        style={!!label ? {} : { paddingTop: "0.625rem" }}
      />
      {label && (
        <label htmlFor={id} className={labelClassName}>
          {label}
          {required && (
            <>
              {" "}
              <span className="required" aria-hidden="true">
                *
              </span>
            </>
          )}
        </label>
      )}
      <>{children}</>
      {addOnClassName && (
        <span data-testid={`${id}-add-on`} className="input-group-text" onClick={onAddOnClick}>
          <i className={addOnClassName}></i>
        </span>
      )}
    </div>
  );
};
