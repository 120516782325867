import { useCallback, useEffect, useRef, useState, type FC } from "react";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { useAccessControl } from "../../app/access-control-context";
import { ContentPad, ContentViewport, DetailPanelCloser, Leftside, Rightside, Utility } from "../../app/app-layout";
import { Confirm, type ConfirmHandle } from "../../components/confirm/confirm";
import { InquiryList } from "../../components/inquiry-list/inquiry-list";
import { FamilyInfo } from "../../components/inquiry-list/tab-content/family-info";
import { InquiryDashboardInfo, TimelineInfo } from "../../components/inquiry-list/tab-content/inquiry-dashboard";
import { InquiryDetail } from "../../components/inquiry-list/tab-content/inquiry-dashboard/inquiry-detail";
import { ListHeader } from "../../components/list-header/list-header";
import { INQUIRY_EDIT, INQUIRY_VIEW, STUDENT_FAMILY_CREATE_EDIT, STUDENT_FAMILY_VIEW } from "../../data/permissions";
import { useDebounce } from "../../hooks/use-debounce";
import type { Inquiry } from "../../hooks/use-inquiries";
import { useNavigationConfirmation } from "../../hooks/use-navigation-confirmation";

export type DirtyProps = { isFormDirty: true; resetForm: () => void } | { isFormDirty: false; resetForm: null };

export const InquiryManagement: FC = () => {
  const { inquiryId } = useParams<{ inquiryId: string }>();
  const { hasPermission, hasFeatureAccess } = useAccessControl();
  const navigate = useNavigate();
  const tableRef = useRef<HTMLTableElement>(null);

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  const [selectedInquiry, setSelectedInquiry] = useState<Inquiry | null>(null);

  const [activeRightsideTab, setActiveRightsideTab] = useState<TabValue>("summary");

  const confirmNavigationModalRef = useRef<ConfirmHandle>(null);
  // Inquiry detail form keeps this synced with isDirty flag
  const [inquiryDetailDirtyProps, setInquiryDetailDirtyProps] = useState<DirtyProps>({
    isFormDirty: false,
    resetForm: null
  });

  const { blocker } = useNavigationConfirmation({ confirmNavigation: inquiryDetailDirtyProps.isFormDirty });

  useEffect(() => {
    if (blocker.state === "blocked") {
      confirmNavigationModalRef.current?.confirm(blocker.proceed, () => {
        blocker.reset();
      });
    }
  }, [blocker.state]);

  const switchTabs = (eventKey: unknown) => {
    if (isTabValue(eventKey)) {
      setActiveRightsideTab(eventKey);
    }
  };

  const doWithDirtyCheck = useCallback(
    (cb: () => void) => {
      if (inquiryDetailDirtyProps.isFormDirty) {
        confirmNavigationModalRef.current?.confirm(() => {
          inquiryDetailDirtyProps.resetForm?.();
          cb();
        });
      } else {
        cb();
      }
    },
    [inquiryDetailDirtyProps.isFormDirty, inquiryDetailDirtyProps.resetForm]
  );

  const onSelectInquiry = useCallback(
    (inquiry: Inquiry) => {
      doWithDirtyCheck(() => {
        setSelectedInquiry(inquiry);
        setActiveRightsideTab("summary");
      });
    },
    [setSelectedInquiry, setActiveRightsideTab, doWithDirtyCheck]
  );

  const handleStatusChange = useCallback(() => {
    setSearch("");
  }, []);

  const onPrintRequested = useReactToPrint({
    content: () => tableRef.current
  });

  const closeRightPanel = useCallback(() => {
    setSelectedInquiry(null);
  }, []);

  const canEditCreateInquiry = hasPermission && hasPermission(INQUIRY_EDIT);
  const canViewInquiry = hasPermission && hasPermission([INQUIRY_EDIT, INQUIRY_VIEW]);
  const canViewStudentFamily = hasPermission && hasPermission([STUDENT_FAMILY_CREATE_EDIT, STUDENT_FAMILY_VIEW]);

  return (
    <ContentViewport className="slide-in-sidebar">
      <Leftside sizeClasses="col-xs-5 col-lg-5">
        <Utility>
          <ListHeader filter={search} onFilterChange={setSearch} onPrintRequested={onPrintRequested}>
            {canEditCreateInquiry && (
              <button className="btn btn-primary btn-sm" onClick={() => navigate("/inquiry/form")}>
                <span> Create Inquiry</span>
                <span className="sr-only">Create Inquiry</span>
              </button>
            )}
          </ListHeader>
        </Utility>
        <ContentPad>
          <InquiryList
            preselectedInquiryId={inquiryId}
            search={debouncedSearch}
            onInquirySelect={onSelectInquiry}
            onStatusChange={handleStatusChange}
            tableRef={tableRef}
          />
        </ContentPad>
      </Leftside>
      <Rightside showIf={!!selectedInquiry} sizeClasses="col-xs-7 col-lg-7">
        <DetailPanelCloser onClick={closeRightPanel} />
        <ContentPad className="scroll-container">
          <h2 className="scroll-container-fixed">{selectedInquiry?.studentName}</h2>
          <Tab.Container
            activeKey={activeRightsideTab}
            onSelect={(eventKey: unknown) => {
              doWithDirtyCheck(() => {
                switchTabs(eventKey);
              });
            }}
          >
            <Nav variant="tabs" className="scroll-container-fixed">
              <Nav.Item>
                <Nav.Link eventKey="summary">
                  <span className="fas icon fa-info-circle" aria-hidden="true" />
                  summary
                </Nav.Link>
              </Nav.Item>
              {canViewStudentFamily && (
                <Nav.Item>
                  <Nav.Link eventKey="family-info">
                    <span className="fas icon fa-home" aria-hidden="true" />
                    family info
                  </Nav.Link>
                </Nav.Item>
              )}
              {canViewInquiry && (
                <Nav.Item>
                  <Nav.Link eventKey="inquiry-detail">
                    <span className="fas icon fa-clipboard" aria-hidden="true" />
                    inquiry detail
                  </Nav.Link>
                </Nav.Item>
              )}

              {!!hasFeatureAccess?.("inquiry_timelines") && (
                <Nav.Item>
                  <Nav.Link eventKey="timeline">
                    <span className="fas icon fa-list" aria-hidden="true" />
                    time-line
                  </Nav.Link>
                </Nav.Item>
              )}
              {/* {!!hasFeatureAccess?.("staff_schedules") && (
                <Nav.Item>
                  <Nav.Link eventKey="student-info">
                    <span className="fas icon fa-mobile-android" aria-hidden="true" />
                    student info
                  </Nav.Link>
                </Nav.Item>
              )} */}

              {/* {!!hasFeatureAccess?.("staff_schedules") && (
                <Nav.Item>
                  <Nav.Link eventKey="scheduling">
                    <span className="fas icon fa-calendar" aria-hidden="true" />
                    scheduling
                  </Nav.Link>
                </Nav.Item>
              )} */}
            </Nav>
            <Tab.Content className="card scroll-container-scroll">
              <Tab.Pane eventKey="summary">
                {selectedInquiry && <InquiryDashboardInfo inquiry={selectedInquiry} />}
              </Tab.Pane>

              {canViewInquiry && (
                <Tab.Pane eventKey="inquiry-detail">
                  {selectedInquiry && (
                    <InquiryDetail
                      inquiryId={selectedInquiry.id}
                      setDirtyProps={setInquiryDetailDirtyProps}
                      confirmLeaveForm={confirmNavigationModalRef.current?.confirm}
                    />
                  )}
                </Tab.Pane>
              )}

              {/* when a component is created for this, move the card-body/card-subheader into it */}
              {/* {!!hasFeatureAccess?.("staff_schedules") && (
                <Tab.Pane eventKey="student-info">
                  {selectedInquiry && (
                    <div className="card-body">
                      <h3 className="card-subheader">Student Info</h3>
                    </div>
                  )}
                </Tab.Pane>
              )} */}

              {canViewStudentFamily && (
                <Tab.Pane eventKey="family-info">
                  {selectedInquiry && <FamilyInfo inquiry={selectedInquiry} />}
                </Tab.Pane>
              )}

              {hasFeatureAccess && hasFeatureAccess("inquiry_timelines") && (
                <Tab.Pane eventKey="timeline">{selectedInquiry && <TimelineInfo inquiry={selectedInquiry} />}</Tab.Pane>
              )}

              {/* when a component is created for this, move the card-body/card-subheader into it */}
              {/* {!!hasFeatureAccess?.("staff_schedules") && (
                <Tab.Pane eventKey="scheduling">
                  {selectedInquiry && (
                    <div className="card-body">
                      <h3 className="card-subheader">Scheduling</h3>
                    </div>
                  )}
                </Tab.Pane>
              )} */}
            </Tab.Content>
          </Tab.Container>
        </ContentPad>
      </Rightside>
      <Confirm
        ref={confirmNavigationModalRef}
        size="md"
        title="Confirm"
        message="Would you like to close the Inquiry Detail page and lose any entered data?"
        okLabel="Yes"
        cancelLabel="No"
      />
    </ContentViewport>
  );
};

const tabValues = ["summary", "inquiry-detail", "student-info", "family-info", "timeline", "scheduling"] as const;
type TabValue = (typeof tabValues)[number];
const isTabValue = (value: unknown): value is TabValue => tabValues.includes(value as TabValue);

export const inquiryManagementLoader = () => {
  return {
    activeTab: "Inquiry Management",
    tabQualifier: undefined
  };
};
