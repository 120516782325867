import React from "react";

import RLO from "react-loading-overlay-ts";

export interface LoadingOverlayProps {
  loading?: boolean;
  children?: React.ReactNode;
}

// so we can set shared settings in one place
export const LoadingOverlay: React.FC<React.PropsWithChildren<LoadingOverlayProps>> = ({ loading, children }) => {
  return (
    // @ts-ignore
    <RLO active={loading} spinner>
      {children}
    </RLO>
  );
};
