import { type FC } from "react";
import { Accordion, AccordionHeader, AccordionItem, AccordionBody } from "react-bootstrap";

import { useInquiryConstants, type InquiryAppointment } from "../../../../hooks/use-inquiries";
import { SortableTable, SortableTableHeader } from "../../../table";
import { AppointmentDateTime } from "./appointment-date-time";
import { useAccessControl } from "../../../../app/use-access-control";
import { useCenterConstants } from "../../../../hooks/use-center";

interface AppointmentHistoryAccordionProps {
  appointmentHistory?: InquiryAppointment[];
  className?: string;
  defaultActiveKey?: string;
}

export const AppointmentHistoryAccordion: FC<AppointmentHistoryAccordionProps> = ({
  appointmentHistory = [],
  className,
  defaultActiveKey
}) => {
  const { activeCenterId } = useAccessControl();
  const inquiryConstantsQuery = useInquiryConstants();
  const centerConstantsQuery = useCenterConstants(activeCenterId);

  const headers: SortableTableHeader<InquiryAppointment>[] = [
    {
      renderKey: "appointmentType",
      name: "Appointment Type",
      render: item => centerConstantsQuery.activeConstants.appointmentTypeLookup[item.typeId],
      tableDataCellProps: {
        isPrimary: true
      }
    },
    {
      renderKey: "scheduledTime",
      name: "Scheduled Time",
      render: item => <AppointmentDateTime appointment={item} />
    },
    {
      renderKey: "status",
      name: "Status",
      render: item => inquiryConstantsQuery.data?.appointmentStatusesLookup[item.statusId]
    }
  ];

  return (
    <Accordion defaultActiveKey={defaultActiveKey} className={className}>
      <AccordionItem eventKey="0">
        <AccordionHeader as="h3">Appointment History</AccordionHeader>
        <AccordionBody>
          <SortableTable tableCollapseScreenSize="sm" headers={headers} data={appointmentHistory} uniqueIdKey="id" />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};
