import type { FC, PropsWithChildren } from "react";
import classNames from "classnames";

export const TabContainer: FC<PropsWithChildren<React.HTMLAttributes<HTMLUListElement>>> = ({
  className,
  children,
  ...rest
}) => (
  <ul className={classNames("nav nav-tabs", className)} {...rest}>
    {children}
  </ul>
);

interface NavTabProps extends React.HTMLAttributes<HTMLAnchorElement> {
  href: string;
  isActive?: boolean;
  onClick?: () => void;
}

export const NavTab: FC<PropsWithChildren<NavTabProps>> = ({ isActive = false, className, children, ...props }) => (
  <li className={classNames("nav-item", className)}>
    <a className={classNames("nav-link", { active: isActive })} data-bs-toggle="tab" {...props}>
      {children}
    </a>
  </li>
);

export const TabContent: FC<PropsWithChildren> = props => <div className="tab-content" {...props} />;

export const TabPanel: FC<PropsWithChildren<{ id: string; isActive?: boolean; isCard?: boolean }>> = ({
  isActive = false,
  //small change for now, but we should change all these to cards and remove container
  isCard = false,
  children,
  ...props
}) => (
  <div className={classNames("tab-pane", { container: !isCard, card: isCard, active: isActive })} {...props}>
    {isCard ? <div className="card-body">{children}</div> : <>{children}</>}
  </div>
);
