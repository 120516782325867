import { type FC, useState } from "react";

import { type FamilySelectionOption, type Family, useFamilySelection } from "../../hooks/use-families";
import { SimpleTable, SimpleTableHeader } from "../table";
import { ContentPad, Utility } from "../../app/app-layout";

type ContactFamilyDetailsProps = {
  parentId: number;
  studentId: number;
  onSetSelectedFamily: (family: Family) => void;
  onCancel: () => void;
  family?: Family;
};

export const ContactFamilyDetails: FC<ContactFamilyDetailsProps> = ({
  parentId,
  studentId,
  onSetSelectedFamily,
  onCancel,
  family
}) => {
  const newFamily = {
    id: "",
    name: "Create a New Family"
  } as Family;
  const [selectedFamily, setSelectedFamily] = useState<Family | undefined>(family || newFamily);

  const { data: familySelectionList } = useFamilySelection({ inquirerId: parentId, studentId });

  const handleRadioClick = (family: Family) => {
    setSelectedFamily(family);
  };

  const familyDetailsHeaders: SimpleTableHeader<FamilySelectionOption>[] = [
    {
      renderKey: "isSelected",
      name: "",
      tableDataCellProps: {
        className: "td-icon"
      },
      render: item => (
        <input
          className="form-check-input"
          type="radio"
          checked={item.id === selectedFamily?.id}
          onChange={() => handleRadioClick(item)}
        />
      )
    },
    {
      key: "name",
      name: "Family Name"
    },
    {
      renderKey: "members",
      name: "Members",
      render: item => (
        <>
          {item.members.map(({ id, displayName }) => (
            <div key={id}>{displayName}</div>
          ))}
        </>
      )
    },
    {
      renderKey: "contact",
      name: "Contact",
      render: item => {
        if (!item.members.length) {
          return null;
        }
        const primary = item.members.find(m => m.isPrimaryContact) ?? item.members[0];
        return (
          <>
            {primary.email}
            <br />
            {primary.preferredPhone.phoneNumber}
          </>
        );
      }
    }
  ];

  const handleSelectFamily = () => {
    if (selectedFamily) {
      onSetSelectedFamily(selectedFamily);
    }
    onCancel();
  };

  return (
    <>
      <div className="sticky-top">
        <Utility>
          <div className="d-flex  stack-sm-down w-100 justify-content-end">
            <button className="btn btn-secondary me-2" onClick={onCancel}>
              Cancel
            </button>
            <button className="btn btn-primary" disabled={!selectedFamily} onClick={handleSelectFamily}>
              Select / Create Family
            </button>
          </div>
        </Utility>
      </div>
      <ContentPad>
        <h2 className="header-small">Family</h2>
        <div className="card">
          <div className="card-body pt-0">
            <SimpleTable
              tableCollapseScreenSize="sm"
              headers={familyDetailsHeaders}
              data={familySelectionList ?? []}
              uniqueIdKey="id"
              className="vertical-top"
              insertRows={
                <tr className="tr-highlight">
                  <td className="td-icon td-highlight w-auto">
                    <input
                      className="form-check-input"
                      id="createNewFamilyOption"
                      type="radio"
                      checked={selectedFamily?.id === ""}
                      onChange={() => handleRadioClick(newFamily)}
                      aria-labelledby="create-a-new-family-label"
                    />
                  </td>
                  <td colSpan={3} className="td-highlight w-100">
                    <strong id="create-a-new-family-label">Create a New Family</strong>
                    <br />I need to create a new family because an existing family match doesn&apos;t exist.
                  </td>
                </tr>
              }
            />
          </div>
        </div>
      </ContentPad>
    </>
  );
};
