import { useMemo } from "react";

import { CenterDetail } from "../../hooks/use-center";
import { useAccessControl } from "../../app/use-access-control";
import { SortableTable, SortableTableHeader } from "../table";

export interface CenterMultiSelectorProps {
  checkedCenters?: string[];
  availableCenters: CenterDetail[];
  onChangeValue?: (newvalue: string[]) => void;
}

export const CenterMultiSelector: React.FC<CenterMultiSelectorProps> = ({
  checkedCenters,
  availableCenters,
  onChangeValue
}) => {
  const { activeCenterId } = useAccessControl();
  const toggleCenterChecked = (centerId: string) => {
    if ((checkedCenters || []).includes(centerId)) {
      onChangeValue && onChangeValue((checkedCenters || []).filter(center => center !== centerId));
    } else {
      onChangeValue && onChangeValue([...(checkedCenters || []), centerId]);
    }
  };

  const sortedAvailableCenters = useMemo(() => {
    return availableCenters.slice().sort((left, right) => left.name.localeCompare(right.name));
  }, [availableCenters]);

  const centersHeaders: SortableTableHeader<CenterDetail>[] = [
    {
      renderKey: "center-checkbox",
      name: "Add",
      sortOptions: { enableSort: false },
      render: item => (
        <input
          type="checkbox"
          className="form-check-input"
          checked={(checkedCenters || []).includes(item.id)}
          style={{ height: "20px", width: "20px", marginTop: "6px" }}
          onClick={() => toggleCenterChecked(item.id)}
          disabled={item.id === activeCenterId}
          // required to keep react from complaining
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onChange={() => {}}
        />
      )
    },
    {
      key: "name",
      sortOptions: { enableSort: true },
      name: "Center"
    }
  ];

  return (
    <SortableTable
      tableCollapseScreenSize="sm"
      headers={centersHeaders}
      paginationOptions={{ itemsPerPage: 5 }}
      data={sortedAvailableCenters}
      uniqueIdKey="id"
    />
  );
};
