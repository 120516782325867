import React, { useMemo } from "react";

import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import * as Permissions from "../../data/permissions";

import { useCenterOperatingHours, CenterDetail, useCenterHolidays } from "../../hooks/use-center";
import { useReferenceData } from "../../hooks/use-reference-data";
import { Calendar } from "../../components/calendar/calendar";
import { DateRangeInfo, DatesOfInterest } from "../calendar/types";
import { format24HourTimeString } from "../forms/form-helpers";
import { Card, CardBody, CardHeader } from "react-bootstrap";

export interface CenterScheduleProps {
  center?: CenterDetail | undefined;
}

export const requiredPermissionForEditing = Permissions.CENTER_MANAGE_OPERATIONAL_SCHEDULE;

export const CenterSchedule: React.FC<CenterScheduleProps> = ({ center }) => {
  const { operatingHours, isLoading } = useCenterOperatingHours(center?.id);
  const { holidays, isLoading: isLoadingHolidays } = useCenterHolidays(center?.id);
  const { referenceData } = useReferenceData();

  const dates: DatesOfInterest = useMemo(() => {
    const dateHolidays: DateRangeInfo[] =
      holidays?.map(holiday => {
        const holidayDate = new Date(holiday.date + "T00:00:00");
        const holidayDateEnd = new Date(holiday.date + "T23:59:59");

        return {
          range: {
            startDateTime: holidayDate,
            endDateTime: holidayDateEnd
          },
          tooltipText: holiday.name
        };
      }) ?? [];

    const defaultStart = new Date("1000-01-01T00:00:00");
    const defaultEnd = new Date("3000-01-01T00:00:00");

    const defaultOperatingHours: DateRangeInfo[] =
      operatingHours?.default
        ?.filter(day => day.openTime !== "" && day.closeTime !== "")
        .map(day => {
          const weekday = day.weekday;
          const tooltip = format24HourTimeString(day.openTime) + "-" + format24HourTimeString(day.closeTime);

          return {
            range: { startDateTime: defaultStart, endDateTime: defaultEnd },
            weekday: weekday,
            tooltipText: tooltip
          };
        }) ?? [];

    const noHoursDays: DateRangeInfo[] = [{ range: { startDateTime: defaultStart, endDateTime: defaultEnd } }];

    const sesonalOperatingHours: DateRangeInfo[] =
      operatingHours?.seasonal?.map(day => {
        const weekday = day.weekday;
        const seasonStart = new Date(day.seasonStart + "T00:00:00");
        const seasonEnd = new Date(day.seasonEnd + "T23:59:59");
        const tooltip = format24HourTimeString(day.openTime) + "-" + format24HourTimeString(day.closeTime);

        return {
          range: { startDateTime: seasonStart, endDateTime: seasonEnd },
          weekday: weekday,
          tooltipText: tooltip
        };
      }) ?? [];

    return {
      closed: {
        dateRanges: dateHolidays,
        legendKey: {
          className: "closed",
          isSelectable: false,
          showLegendKey: true,
          legendKeyLabel: "Holiday / emergency closure"
        }
      },
      seasonal: {
        dateRanges: sesonalOperatingHours,
        legendKey: {
          className: "seasonal",
          isSelectable: false,
          showLegendKey: true,
          legendKeyLabel: "Seasonal / temporary hours"
        }
      },
      default: {
        dateRanges: defaultOperatingHours,
        legendKey: {
          className: "default",
          isSelectable: false,
          showLegendKey: false,
          legendKeyLabel: "Default Operating Hour"
        }
      },
      nohours: {
        dateRanges: noHoursDays,
        legendKey: {
          className: "nohours",
          isSelectable: false,
          showLegendKey: false,
          legendKeyLabel: "Closed"
        }
      }
    };
  }, [holidays, operatingHours, center]);

  if (!center || !referenceData) {
    return null; // delay render until we have these
  }

  return (
    <Card className="h-100">
      <CardHeader>
        <h2>Operating Schedule</h2>
      </CardHeader>
      <CardBody>
        <LoadingOverlay loading={isLoading || isLoadingHolidays}>
          <Calendar datesOfInterest={dates} />
        </LoadingOverlay>
      </CardBody>
    </Card>
  );
};
