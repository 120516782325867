import { type FC } from "react";

import type { Family, FamilyContact, FamilyContactDetailWithId } from "../../hooks/use-families";
import { ContactFamilyDetails } from "../family-management/contact-family-details";
import { InquiryRightSideAccordion } from "./inquiry-right-side-accordion";
import { QuickCreateInquirer } from "./quick-create-inquirer";
import { QuickCreateStudent } from "./quick-create-student";
import { InquiryFormData } from "../../hooks/use-inquiries";

export type InquiryFormRightPaneModes = "default" | "select-family" | "quick-create-inquirer" | "quick-create-student";

interface InquiryFormRightPaneProps {
  mode?: InquiryFormRightPaneModes;
  onSelectContact: (type: "inquirer" | "student", contact: FamilyContactDetailWithId) => void;
  onSelectFamily: (family: Family) => void;
  onSaveInquiry: (savedInquiry: InquiryFormData) => void;
  inquirer?: FamilyContact;
  student?: FamilyContact;
  family?: Family;
  onClose: () => void;
}

export const InquiryFormRightPane: FC<InquiryFormRightPaneProps> = ({
  mode,
  onSelectContact,
  onSelectFamily,
  onSaveInquiry,
  family,
  onClose,
  ...contact
}) => {
  const handleSaveContact = (type: "inquirer" | "student") => (contact: FamilyContactDetailWithId) => {
    onSelectContact(type, contact);
    onClose();
  };

  return (
    <>
      {mode === "quick-create-inquirer" && (
        <QuickCreateInquirer inquirer={contact.inquirer} onSave={handleSaveContact("inquirer")} onCancel={onClose} />
      )}
      {mode === "quick-create-student" && (
        <QuickCreateStudent
          inquirer={contact.inquirer}
          student={contact.student}
          onSave={handleSaveContact("student")}
          onCancel={onClose}
        />
      )}
      {mode === "select-family" && contact.inquirer && contact.student && (
        <ContactFamilyDetails
          parentId={contact.inquirer.id}
          studentId={contact.student.id}
          family={family}
          onSetSelectedFamily={onSelectFamily}
          onCancel={onClose}
        />
      )}
      {mode === "default" && (
        <InquiryRightSideAccordion familyId={family?.id} onSaveInquiry={onSaveInquiry} onClose={onClose} />
      )}
    </>
  );
};
