import React from "react";

import { FormRow, Field, PositionSelector, CreateMailbox, FormHelpers } from "../forms";
import { useFormInput } from "../../hooks/use-form-input";
import { PositionChangePayload, useModifyPosition, usePositionChangeValidator } from "../../hooks/use-modify-position";
import { useAccessControl } from "../../app/use-access-control";
import { useStaffMemberPermissions } from "../../hooks/use-staff";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import { useModal } from "../modal/modal";
import { StaffMember } from "../../hooks/use-user";
import { EmployeeHistory } from "../../hooks/use-employment-history";

export interface UsePositionChangeModalProps {
  staffMember: StaffMember;
  initialEmploymentHistory?: EmployeeHistory;
}

export const usePositionChangeModal = ({ staffMember, initialEmploymentHistory }: UsePositionChangeModalProps) => {
  const { activeCenterId } = useAccessControl();
  const { staffMemberPermissions } = useStaffMemberPermissions(activeCenterId, staffMember.id);

  const { show, hide: hidePositionChangeModal, renderModal } = useModal();

  const { buffer, onChangeValue, resetBuffer } = useFormInput<PositionChangePayload>({
    effectiveDate: "",
    position: initialEmploymentHistory?.position ?? "",
    jobType: "FT",
    createMailbox: staffMemberPermissions?.hasMailbox ?? false
  });
  const isValidPositionChange = usePositionChangeValidator(buffer);
  const { positionChangeMutator } = useModifyPosition(activeCenterId, staffMember.id, hidePositionChangeModal);

  const onSave = () => {
    positionChangeMutator.mutate(buffer);
  };

  const showPositionChangeModal = () => {
    resetBuffer();
    show();
  };

  const renderPositionChangeModal = () =>
    renderModal({
      title: `Position Change - ${staffMember.displayName}`,
      body: (
        <LoadingOverlay loading={positionChangeMutator.isLoading}>
          <FormRow>
            <PositionSelector
              label="New position"
              mode="edit"
              required
              value={buffer.position}
              onChangeValue={onChangeValue("position")}
              onValidate={FormHelpers.isValidRequiredString}
            />
          </FormRow>

          <FormRow>
            <Field
              type="date"
              label="Effective Date"
              required
              mode="edit"
              value={buffer.effectiveDate}
              onChangeValue={onChangeValue("effectiveDate")}
              onValidate={FormHelpers.isValidRequiredDateString}
            />
          </FormRow>

          <FormRow>
            <CreateMailbox
              value={buffer.createMailbox}
              disabled={staffMemberPermissions?.hasMailbox}
              onChangeValue={onChangeValue("createMailbox")}
            />
          </FormRow>
        </LoadingOverlay>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hidePositionChangeModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={onSave} disabled={!isValidPositionChange}>
            Save
          </button>
        </>
      )
    });

  return {
    hidePositionChangeModal,
    showPositionChangeModal,
    renderPositionChangeModal
  };
};
