import { FC, ReactElement, ReactNode, useContext, useEffect, useId, useMemo, useState } from "react";
import BsModal, { ModalProps as BsModalProps } from "react-bootstrap/Modal";
import { useForm, FieldValues, UseFormProps, FormProvider, SubmitHandler } from "react-hook-form";
import { UUID } from "crypto";

import { ModalToastHeader } from "../../toasts/modal-toast-header";
import { ModalContext } from "./modal-provider";
import { BasicFormProps, FormWithContext } from "../../forms/form-context-wrapper/form-context-wrapper";

interface BaseModalProps extends BsModalProps {
  hideCloseButton?: boolean;
  title?: string;
  body?: ReactNode;
  footer?: ReactNode;
  superZ?: boolean;
}

export const BaseModal: FC<BaseModalProps> = ({
  hideCloseButton = false,
  superZ = false,
  title,
  body,
  footer,
  ...bsModalProps
}) => {
  const id = useId();
  const modalId = `modal-${id}`;
  const labelId = `modal-label-${id}`;

  const overrideZ = superZ ? { zIndex: 11000 } : undefined;

  return (
    <BsModal
      scrollable={true}
      centered={true}
      backdrop="static"
      {...bsModalProps}
      id={modalId}
      aria-labelledby={labelId}
      style={overrideZ}
    >
      <BsModal.Header closeButton={!hideCloseButton}>
        <BsModal.Title as="h5">{title}</BsModal.Title>
      </BsModal.Header>
      <BsModal.Body>{body}</BsModal.Body>
      {!!footer && <BsModal.Footer>{footer}</BsModal.Footer>}
    </BsModal>
  );
};

interface UseModalProps {
  disableToast?: boolean;
  superZ?: boolean;
}
export const useModal = (useModalProps?: UseModalProps) => {
  // TODO: some time far in the future we may need to worry about lazy loading the modal
  const [showModal, setShowModal] = useState(false);
  const uuid = useMemo(() => crypto.randomUUID(), []);
  const { recordModalOpen } = useContext(ModalContext);

  useEffect(() => {
    if (!useModalProps?.disableToast) {
      recordModalOpen(uuid as UUID, showModal);
    }
  }, [showModal]);

  const hide = () => {
    setShowModal(false);
  };
  const show = () => {
    setShowModal(true);
  };

  const handleHide = (onHide?: () => void) => {
    onHide?.();
    hide();
  };

  const renderModal: FC<BaseModalProps> = ({ body, show, onHide, ...props }) => (
    <BaseModal
      show={show === undefined ? showModal : show}
      onHide={() => handleHide(onHide)}
      body={
        <>
          {!useModalProps?.disableToast && <ModalToastHeader />}
          {body}
        </>
      }
      centered
      {...props}
    />
  );

  return {
    show,
    hide,
    Modal: renderModal,
    renderModal
  };
};

interface UseFormModalOptions<T extends FieldValues> {
  name: string;
  useFormProps: UseFormProps<T>;
  onSubmit: SubmitHandler<T>;
}

export const useFormModal = <T extends FieldValues>({ name, useFormProps, onSubmit }: UseFormModalOptions<T>) => {
  const id = useId();
  const formId = `${name}-${id}`;
  const methods = useForm<T>(useFormProps);

  const { show, hide, renderModal } = useModal();

  const handleHide = (onHide?: () => void) => {
    onHide?.();
    methods.reset();
    hide();
  };

  const renderFormModal: FC<BaseModalProps> = ({ body, onHide, ...props }) => {
    return (
      <FormProvider {...methods}>
        {renderModal({
          ...props,
          body: (
            <FormWithContext formId={formId} onSubmit={onSubmit as SubmitHandler<FieldValues>}>
              {body as ReactElement<BasicFormProps<FieldValues>>}
            </FormWithContext>
          ),
          onHide: () => handleHide(onHide)
        })}
      </FormProvider>
    );
  };

  return {
    show,
    hide: () => handleHide(),
    formId,
    renderModal: renderFormModal,
    methods
  };
};
