import { useRef, type FC, useEffect } from "react";

export interface IconButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  enableTooltip?: boolean;
  icon: string;
}

export const IconButton: FC<IconButtonProps> = ({ enableTooltip = false, icon, ...buttonProps }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current && enableTooltip) {
      //@ts-expect-error
      const tooltip = new global.bootstrap.Tooltip(buttonRef.current);
      return () => {
        tooltip.hide();
      };
    }
  }, [buttonRef]);

  return (
    <button
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-original-title={buttonProps.title}
      {...buttonProps}
      ref={buttonRef}
    >
      <span className={`fas fa-${icon}`} />
      <span className="sr-only">{buttonProps.title}</span>
    </button>
  );
};
