/* istanbul ignore file */

import { type InquiryAppointmentStaffQueryParams, type InquiryListRequestParams } from "./types";

export const inquiryQueryKeys = {
  base: ["inquiries"],
  list: (options: Partial<InquiryListRequestParams>) => [...inquiryQueryKeys.base, "list", options] as const,
  detail: (centerId: string, inquiryId?: string) => [...inquiryQueryKeys.base, "get", { centerId, inquiryId }] as const,
  baseDashboard: () => [...inquiryQueryKeys.base, "dashboard"] as const,
  dashboard: (centerId: string, inquiryId?: string) =>
    [...inquiryQueryKeys.baseDashboard(), { centerId, inquiryId }] as const,
  appointmentStaff: (centerId: string, queryParams: InquiryAppointmentStaffQueryParams) =>
    [...inquiryQueryKeys.base, "appointmentStaff", { centerId, queryParams }] as const,
  appointmentAvailableStaff: (centerId: string, appointmentId?: number) =>
    [...inquiryQueryKeys.base, "appointmentStaff", { centerId, appointmentId }] as const
} as const;
