import { useCallback, useMemo, useState } from "react";
import rfdc from "rfdc";
import isEqual from "lodash/isEqual";

const deepCopier = rfdc();

// as the signature of onChangeValue would indicate, we are now able to change the
// value for nested properties... i.e. if we pass in "a.b" as properties string,
// we should expect the value of buffer.a.b to be changed to newvalue and all values
// are either all string, bools and arrays of string. We are now assuming that we
// are sending in the properties string correctly and that any errors will be fixed.
// If we want to do something more complex then we'll have to improve w/ some error handling.
export const useFormInput = <T extends object, U extends object = T>(
  initialValue: T,
  transformToPayload?: (from: T) => U
) => {
  // const initialOrDefault = useMemo(
  //   () =>
  //     deepCopier<T>(
  //       defaultValue
  //         ? Object.keys(initialValue).reduce(
  //             (acc, key) => ({ ...acc, [key]: initialValue[key as keyof T] || defaultValue[key as keyof T] }),
  //             defaultValue || ({} as T)
  //           )
  //         : initialValue
  //     ),
  //   [initialValue, defaultValue]
  // );
  const [buffer, setBuffer] = useState<T>(deepCopier<T>(initialValue));
  const payloadFromBuffer = useMemo(() => (transformToPayload ? transformToPayload(buffer) : buffer), [buffer]);

  const onChangeValue = useCallback(
    (propertiesString: string): ((newvalue: string | boolean | string[] | number) => void) =>
      newvalue => {
        const props = propertiesString.split(".");
        setBuffer(prev => {
          const newState = { ...prev };
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          let currentPointer: any = newState;
          for (const [index, prop] of Array.from(props.entries())) {
            if (index !== props.length - 1) {
              currentPointer = currentPointer[prop];
            } else {
              currentPointer[prop] = newvalue;
            }
          }
          return newState;
        });
      },
    []
  );

  const resetBuffer = useCallback(() => {
    setBuffer(deepCopier<T>(initialValue));
  }, [initialValue]);

  const isDirty = useMemo(() => !isEqual(buffer, initialValue), [buffer]);

  return {
    buffer,
    payloadFromBuffer,
    onChangeValue,
    resetBuffer,
    setBuffer,
    isDirty
  };
};
