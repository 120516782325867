import React, { useState, forwardRef, useId, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useCombobox, UseComboboxStateChange } from "downshift";
import classNames from "classnames";

interface GenericMultiSelectComboboxProps<T> {
  items: T[];
  getLabel: (item: T | null | undefined) => string;
  getValue: (item: T | null | undefined) => string;
  onChange: (selectedItems: T[]) => void;
  selectedItems?: T[];
  toggleLabel?: string;
  inputLabel?: string;
  containerClassName?: string;
}

export const GenericMultiSelectCombobox = <T extends {}>({
  items,
  getLabel,
  getValue,
  onChange,
  selectedItems = [],
  toggleLabel = "Select an Item",
  inputLabel = "Search",
  containerClassName
}: GenericMultiSelectComboboxProps<T>) => {
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState<T[]>(selectedItems);
  const [filteredItems, setFilteredItems] = useState(items);
  useEffect(() => {
    setFilteredItems(items);
  }, [items]);
  useEffect(() => {
    setSelected(items.filter(item => selectedItems.find(selected => getValue(selected) === getValue(item))));
  }, [selectedItems, items]);
  const id = useId();
  const inputId = `searchInput${id}`;
  const checkboxId = `checkboxInput${id}`;
  const { isOpen, getToggleButtonProps, getMenuProps, getInputProps, getItemProps, highlightedIndex } = useCombobox<T>({
    items: filteredItems,
    inputValue,
    onStateChange({ inputValue, type, selectedItem }: UseComboboxStateChange<T>) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (selected.includes(selectedItem)) {
              const newSelected = selected.filter(item => item !== selectedItem);
              setSelected(newSelected);
              onChange(newSelected);
            } else {
              const newSelected = [...selected, selectedItem];
              setSelected(newSelected);
              onChange(newSelected);
            }
          }
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || "");
          if (inputValue) {
            setFilteredItems(
              items.filter(item =>
                getLabel(item)
                  .toLowerCase()
                  .includes(inputValue?.toLowerCase() || "")
              )
            );
          } else {
            setFilteredItems(items);
          }

          break;
        default:
          break;
      }
    },
    selectedItem: null,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            inputValue: "",
            highlightedIndex: items.findIndex(item => getValue(item) === getValue(changes.selectedItem))
          };
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...changes,
            isOpen: true
          };
        default:
          return changes;
      }
    }
  });

  const renderSelectedItems = () => {
    return selected.map(item => getLabel(item)).join(", ");
  };

  return (
    <div className={classNames("form-floating", containerClassName)}>
      <Dropdown className="form-input">
        <Dropdown.Toggle
          {...getToggleButtonProps({ className: classNames("form-select", { show: isOpen }) })}
          as={GenericMultiSelectComboboxToggle}
          label={toggleLabel}
          data-testid={`toggle-${id}`}
        >
          {renderSelectedItems()}
        </Dropdown.Toggle>
        <Dropdown.Menu
          {...getMenuProps({ show: isOpen }, { suppressRefError: true })}
          as={GenericMultiSelectComboboxMenu}
        >
          <li>
            <div className="form-floating has-icon p-2 pt-0">
              <input
                {...getInputProps(
                  {
                    type: "text",
                    className: "form-control",
                    placeholder: inputLabel,
                    id: inputId
                  },
                  { suppressRefError: true }
                )}
              />
              <label htmlFor={inputId}>{inputLabel}</label>
              <span className="fas fa-search" aria-hidden="true"></span>
            </div>
            <hr className="mt-0" />
          </li>
          {filteredItems.length ? (
            filteredItems.map((item, index) => (
              <Dropdown.Item
                {...getItemProps({
                  item,
                  index,
                  active: highlightedIndex === index,
                  onClick: event => {
                    event.stopPropagation();
                  }
                })}
                key={getValue(item)}
                as={GenericMultiSelectComboboxItem}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={selected.includes(item)}
                    id={`${checkboxId}${getValue(item)}`}
                    readOnly
                  />
                  <label className="form-check-label" htmlFor={`${checkboxId}${getValue(item)}`}>
                    {getLabel(item)}
                  </label>
                </div>
              </Dropdown.Item>
            ))
          ) : (
            <li className="dropdown-item">No Results Found</li>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

interface GenericMultiSelectComboboxToggleProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  label: string;
}

const GenericMultiSelectComboboxToggle = forwardRef<HTMLDivElement, GenericMultiSelectComboboxToggleProps>(
  ({ label, ...props }, ref) => {
    return (
      <div className="form-floating">
        <div {...props} ref={ref} style={{ textOverflow: "ellipsis", display: "block", overflow: "hidden" }} />
        <label htmlFor={props.id}>{label}</label>
      </div>
    );
  }
);

GenericMultiSelectComboboxToggle.displayName = "GenericMultiSelectComboboxToggle";

interface GenericMultiSelectComboboxMenuProps extends React.HtmlHTMLAttributes<HTMLUListElement> {
  close?: boolean;
  show?: boolean;
}

const GenericMultiSelectComboboxMenu = forwardRef<HTMLUListElement, GenericMultiSelectComboboxMenuProps>(
  ({ close, show, ...props }, ref) => {
    return <ul {...props} ref={ref} />;
  }
);

GenericMultiSelectComboboxMenu.displayName = "GenericMultiSelectComboboxMenu";

const GenericMultiSelectComboboxItem = forwardRef<HTMLLIElement, React.HtmlHTMLAttributes<HTMLLIElement>>(
  ({ ...props }, ref) => {
    return <li {...props} ref={ref} />;
  }
);

GenericMultiSelectComboboxItem.displayName = "GenericMultiSelectComboboxItem";
