import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAccessToken } from "./use-access-token";
import { callLcosService } from "../data/lcosServices";

export interface Note {
  id?: number;
  title: string;
  notes: string;
}

export type NoteType = "staff" | "center";

export interface SavedNote extends Note {
  id: number;
  created_on: string;
  updated_date: string;
}

export interface StaffNote extends SavedNote {
  staff_member_id: number;
}

export interface CenterNote extends SavedNote {
  center_id: number;
}

export interface StaffNoteResponse {
  staffNotes: StaffNote[];
}

export interface CenterNoteResponse {
  centerNotes: CenterNote[];
}

export interface NotesUrlParams {
  centerId: string;
  userId?: string;
}

export const useNotes = (notesUrlParams: NotesUrlParams, noteType: NoteType) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();
  const getNotesData = async () => {
    return getNotes(await getToken(), notesUrlParams, noteType);
  };

  const queryKey = [`${noteType}NotesData`, notesUrlParams.centerId, notesUrlParams.userId];

  const { data, isLoading } = useQuery({ queryKey, queryFn: getNotesData });

  const createNoteMutator = useMutation({
    mutationFn: async (note: Note) => createNote(await getToken(), notesUrlParams, note, noteType),

    onSuccess: newNotes => {
      queryClient.setQueryData(queryKey, () => ({ ...newNotes }));
    }
  });

  const editNoteMutator = useMutation({
    mutationFn: async ({ noteId, note }: { noteId: number; note: Note }) =>
      editNote(await getToken(), notesUrlParams, noteId, note, noteType),

    onSuccess: newNotes => {
      queryClient.setQueryData(queryKey, () => ({ ...newNotes }));
    }
  });

  const deleteNoteMutator = useMutation({
    mutationFn: async (noteId: number) => deleteNote(await getToken(), notesUrlParams, noteId, noteType),

    onSuccess: newNotes => {
      queryClient.setQueryData(queryKey, () => ({ ...newNotes }));
    }
  });

  return {
    notes: noteType === "staff" ? (data as StaffNoteResponse)?.staffNotes : (data as CenterNoteResponse)?.centerNotes,
    isLoading,
    createNoteMutator,
    editNoteMutator,
    deleteNoteMutator
  };
};

export const getNotes = (accessToken: string, notesUrlParams: NotesUrlParams, noteType: NoteType) => {
  if (noteType === "staff") {
    return callLcosService<StaffNoteResponse>(
      accessToken,
      `/api/notes/staff/${notesUrlParams.centerId}/${notesUrlParams.userId}`
    );
  }
  return callLcosService<CenterNoteResponse>(accessToken, `/api/notes/center/${notesUrlParams.centerId}`);
};

export const createNote = (accessToken: string, notesUrlParams: NotesUrlParams, note: Note, noteType: NoteType) => {
  if (noteType === "staff") {
    return callLcosService<StaffNoteResponse>(
      accessToken,
      `/api/notes/staff/${notesUrlParams.centerId}/${notesUrlParams.userId}`,
      "POST",
      note
    );
  }
  return callLcosService<CenterNoteResponse>(accessToken, `/api/notes/center/${notesUrlParams.centerId}`, "POST", note);
};

export const editNote = (
  accessToken: string,
  notesUrlParams: NotesUrlParams,
  noteId: number,
  note: Note,
  noteType: NoteType
) => {
  if (noteType === "staff") {
    return callLcosService<StaffNoteResponse>(
      accessToken,
      `/api/notes/staff/${notesUrlParams.centerId}/${notesUrlParams.userId}/${noteId}`,
      "PUT",
      note
    );
  }

  return callLcosService<CenterNoteResponse>(
    accessToken,
    `/api/notes/center/${notesUrlParams.centerId}/${noteId}`,
    "PUT",
    note
  );
};

export const deleteNote = (accessToken: string, notesUrlParams: NotesUrlParams, noteId: number, noteType: NoteType) => {
  if (noteType === "staff") {
    return callLcosService<StaffNoteResponse>(
      accessToken,
      `/api/notes/staff/${notesUrlParams.centerId}/${notesUrlParams.userId}/${noteId}`,
      "DELETE"
    );
  }

  return callLcosService<CenterNoteResponse>(
    accessToken,
    `/api/notes/center/${notesUrlParams.centerId}/${noteId}`,
    "DELETE"
  );
};
