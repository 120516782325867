import React, { useMemo } from "react";

import type { CenterDetail } from "../../hooks/use-center";
import { SortableTable, SortableTableHeader } from "../table";

interface CenterSelectorProps {
  centers: CenterDetail[];
  activeCenterId: string;
  onSelectCenter: (id: string) => void;
  filter: string;
}

export const CenterSelector = React.forwardRef<HTMLTableElement, CenterSelectorProps>(
  ({ centers, activeCenterId, onSelectCenter, filter }, tableRef) => {
    const centerHeaders: SortableTableHeader<CenterDetail>[] = [
      {
        renderKey: "active",
        name: "Active Center",
        render: item => {
          return (
            <span
              className={`fas ${item.id === activeCenterId ? "fa-check-circle active" : "fa-times-circle"}`}
              style={{ fontWeight: 600 }}
            />
          );
        }
      },
      {
        key: "name",
        sortOptions: { enableSort: true },
        name: "Name"
      },

      {
        key: "state",
        sortOptions: { enableSort: true },
        name: "State"
      },

      {
        key: "zipcode",
        sortOptions: { enableSort: true },
        name: "Zipcode"
      }
    ];

    const sortedFilteredCenters = useMemo(() => {
      return (centers ?? [])
        .filter(c => {
          const filterableProps = `${c.name} ${c.state} ${c.zipcode}`;
          return filterableProps.toLowerCase().includes(filter.toLowerCase());
        })
        .sort((left, right) => {
          if (left.id === activeCenterId) {
            return -1;
          } else if (right.id === activeCenterId) {
            return 1;
          } else {
            return left.name.localeCompare(right.name);
          }
        });
    }, [centers, filter]);

    return (
      <SortableTable
        tableCollapseScreenSize="sm"
        headers={centerHeaders}
        paginationOptions={{ itemsPerPage: 10 }}
        data={sortedFilteredCenters}
        activeItem={centers.find(c => c.id === activeCenterId)}
        onRowClick={item => {
          activeCenterId !== item.id && onSelectCenter(item.id);
        }}
        uniqueIdKey="id"
        tableRef={tableRef}
        tableHasIcons
        printCaption="Centers"
      />
    );
  }
);

CenterSelector.displayName = "CenterSelector";
