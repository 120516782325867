import { useQuery } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { inquiryQueryKeys } from "./query-keys";
import type { InquiryDashboardResponsePayload } from "./types";
import { useFamilyConstants } from "../use-families";

interface UseInquiryDashboardProps {
  centerId: string;
  inquiryId?: string;
}

const formatDashboardData = (
  {
    student,
    service,
    source,
    familyInfo,
    upcomingEvents,
    pastEvents
  }: Pick<
    InquiryDashboardResponsePayload,
    "student" | "service" | "source" | "familyInfo" | "upcomingEvents" | "pastEvents"
  >,
  EMERGENCY_CONTACT_VALUE: string
) => {
  const attendees = familyInfo.map(({ id, displayName }) => ({ value: id, label: displayName })) ?? [];
  attendees.push({ value: student.id, label: student.displayName });
  attendees.sort((left, right) => left.label.localeCompare(right.label));
  return {
    inquiry: [
      {
        studentId: student.id,
        studentName: student.displayName,
        grade: student.grade,
        service: service,
        source: source,
        gender: student.gender,
        school: student.schoolName
      }
    ],
    contacts: familyInfo
      .filter(
        ({ isPrimaryContact, subjectRelationships }) =>
          isPrimaryContact ||
          subjectRelationships.some(
            subjectRelationship => subjectRelationship.relationship.value === EMERGENCY_CONTACT_VALUE
          )
      )
      .map(familyMemberInfo => {
        const isEmergencyContact = familyMemberInfo.subjectRelationships.some(
          subjectRelationship => subjectRelationship.relationship.value === EMERGENCY_CONTACT_VALUE
        );

        return {
          id: familyMemberInfo.id,
          displayName: familyMemberInfo.displayName,
          email: familyMemberInfo.email,
          preferredPhone: familyMemberInfo.preferredPhone.phoneNumber,
          source: source,
          contactType:
            familyMemberInfo.isPrimaryContact && isEmergencyContact
              ? "Primary/Emergency"
              : familyMemberInfo.isPrimaryContact
                ? "Primary"
                : isEmergencyContact
                  ? "Emergency"
                  : "",
          relation: familyMemberInfo.subjectRelationships.find(
            ({ relationship: { value } }) => value !== EMERGENCY_CONTACT_VALUE
          )?.relationship.name
        };
      }),
    upcomingEvents,
    pastEvents,
    familyInfo,
    student,
    attendees
  };
};

export type InquiryDashboard = ReturnType<typeof formatDashboardData>;
export type DashboardInquiry = InquiryDashboard["inquiry"][number];
export type DashboardContact = InquiryDashboard["contacts"][number];
export type DashboardUpcomingEvent = InquiryDashboard["upcomingEvents"][number];

export const useInquiryDashboard = ({ centerId, inquiryId }: UseInquiryDashboardProps) => {
  const { getToken } = useAccessToken();
  const { EMERGENCY_CONTACT_VALUE } = useFamilyConstants();
  return useQuery({
    queryKey: inquiryQueryKeys.dashboard(centerId, inquiryId),
    enabled: Boolean(centerId.length && inquiryId?.length),
    queryFn: async () => {
      invariant(inquiryId, "centerId is required");
      return getInquiryDashboard(await getToken(), centerId, inquiryId);
    },
    select: data => formatDashboardData(data, EMERGENCY_CONTACT_VALUE)
  });
};

export const getInquiryDashboard = async (accessToken: string, centerId: string, inquiryId: string) => {
  return callLcosService<InquiryDashboardResponsePayload>(
    accessToken,
    `/api/inquiries/${centerId}/${inquiryId}/dashboard`,
    "GET"
  );
};
