import classNames from "classnames";
import { FC } from "react";

export interface LoadingButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  loadingMessage?: string;
}

export const LoadingButton: FC<LoadingButtonProps> = ({
  isLoading,
  loadingMessage,
  children,
  disabled,
  className,
  ...buttonProps
}) => {
  return (
    <button disabled={disabled || isLoading} {...buttonProps} className={classNames(className, { loading: isLoading })}>
      {isLoading ? (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> {loadingMessage}
        </>
      ) : (
        children
      )}
    </button>
  );
};
