import React from "react";

import { ContentPad, ContentViewport, SinglePanel } from "../../app/app-layout";
import { useAccessControl } from "../../app/access-control-context";
import { CenterHours } from "../../components/center-hours/center-hours";
import { CenterSchedule } from "../../components/center-schedule/center-schedule";
import { CenterHolidays } from "../../components/center-holidays/center-holidays";
import { CenterSeasonalHours } from "../../components/center-seasonal-hours/center-seasonal-hours";

export const CenterHoursScreen: React.FC = () => {
  const { activeCenter, hasFeatureAccess } = useAccessControl();

  if (!activeCenter) {
    return null;
  }

  return (
    <ContentViewport>
      <SinglePanel>
        <ContentPad>
          <div className="row">
            <div className="col col-12 col-lg-6 mb-4">
              <CenterSchedule center={activeCenter} />
            </div>
            <div className="col col-12 col-lg-6 mb-4">
              <CenterHours center={activeCenter} subset={"default"} />
            </div>
            <div className="col col-12 col-lg-6 mb-4">
              <CenterHolidays center={activeCenter} />
            </div>
            <div className="col col-12 col-lg-6 mb-4">
              {hasFeatureAccess?.("center_seasonal_hours") ? (
                <CenterSeasonalHours activeCenterId={activeCenter.id} />
              ) : (
                <CenterHours center={activeCenter} subset={"seasonal"} />
              )}
            </div>
          </div>
        </ContentPad>
      </SinglePanel>
    </ContentViewport>
  );
};

export const centerHoursLoader = () => {
  return {
    activeTab: "Centers",
    tabQualifier: "Hours"
  };
};
