import React, { useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { useIdleTimer, workerTimers } from "react-idle-timer";

import { useAccessControl } from "../../app/access-control-context";
import { useAccessToken } from "../../hooks/use-access-token";
import { getAdminLogin } from "../../data/lcosServices";
import { useChangePasswordModal } from "./change-password";
import { dispatchSidebarMenuTriggerEvent } from "../../app/app-menu";

export type HeaderTab = "Staff" | "Centers" | "Students" | "Dashboard" | "Inquiry Management";

export interface HeaderProps {
  activeTab?: HeaderTab;
  nonTerminalCrumbs?: string;
  tabQualifier?: string;
}

export const IDLE_TIMEOUT = 1000 * 60 * 60; // 1 hour
export const IDLE_TIMEOUT_PROMPT = 10000; // after the user is considered idle

export const Header: React.FC<HeaderProps> = ({ activeTab = "Unknown", nonTerminalCrumbs, tabQualifier }) => {
  const { instance, accounts } = useMsal();
  const { getToken } = useAccessToken();
  const { activeCenter, profile } = useAccessControl();
  const account = accounts[0];

  const activeTabLabel = !!process.env.REACT_APP_ENVIRONMENT_FLAG
    ? `${process.env.REACT_APP_ENVIRONMENT_FLAG} - ${activeTab}`
    : activeTab;

  const headerStyle = !!process.env.REACT_APP_ENVIRONMENT_HEADER_BACKGROUND
    ? { backgroundColor: process.env.REACT_APP_ENVIRONMENT_HEADER_BACKGROUND }
    : {};

  const { showChangePasswordModal, renderChangePasswordModal } = useChangePasswordModal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  const handleAdminSite = async () => {
    try {
      const redirect = await getAdminLogin(await getToken());
      window.open(redirect, "_blank");
    } catch (err) {
      window.toasts.add({ content: "Unable to reach the admin site at this time, please try again later..." });
    }
  };

  const onIdle = useCallback(() => {
    instance.logoutRedirect();
  }, [instance]);

  const onPrompt = useCallback(() => {
    window.toasts.add({
      content: "Due to inactivity, your session will auto-logout soon...",
      type: "warning"
    });
  }, []);

  useIdleTimer({
    onIdle,
    onPrompt,
    timers: workerTimers,
    timeout: IDLE_TIMEOUT,
    promptBeforeIdle: IDLE_TIMEOUT_PROMPT
  });

  return (
    <>
      <header className="app-header">
        <button className="nav-trigger" onClick={() => dispatchSidebarMenuTriggerEvent()}>
          <span className="fas fa-bars" aria-hidden="true"></span>
          <span className="sr-only">Menu</span>
        </button>
        <div className="hlc-brand">
          <a href="/">
            <img src="/img/logo-huntington.png" alt="Huntington Learning Center" />
          </a>
        </div>
        <div className="page-header" style={headerStyle}>
          <h1>
            {activeTabLabel}
            {!!nonTerminalCrumbs ? ` // ${nonTerminalCrumbs}` : ""}
            {!!tabQualifier ? ` // ` : ""}
            <strong>{!!tabQualifier ? `${tabQualifier}` : ""}</strong>
          </h1>
          <p className="secondary"></p>
        </div>
        <div className="header-icon-block move-on-mobile">
          <div className="dropdown">
            <button
              className="header-icon"
              id="userDropdown"
              data-testid="userDropdown-button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="fas fa-user-circle"></span>
              <span className="icon-text">
                <span className="icon-text-secondary">{activeCenter.name}</span>
                <span className="icon-name">{account?.name}</span>
              </span>
            </button>
            <ul className="dropdown-menu" id="main-header-user-dropdown-menu" aria-labelledby="userDropdown">
              {profile?.isCorporateAdmin && (
                <li>
                  <button
                    className="dropdown-item terminal"
                    data-testid="header-admin-login-button"
                    onClick={handleAdminSite}
                  >
                    Admin Site
                  </button>
                </li>
              )}

              <li>
                <button
                  className="dropdown-item terminal"
                  data-testid="header-change-password-button"
                  onClick={showChangePasswordModal}
                >
                  Change Password
                </button>
              </li>

              <li>
                <button className="dropdown-item terminal" data-testid="header-logout-button" onClick={handleLogout}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <>{renderChangePasswordModal()}</>
    </>
  );
};
