import { Fragment, useCallback, useMemo, useRef, type FC } from "react";

import { useAccessControl } from "../../../app/access-control-context";
import * as Permissions from "../../../data/permissions";
import { useReferenceData } from "../../../hooks/use-reference-data";
import {
  useStaffMemberSchedules,
  type StaffMemberSchedule,
  type StaffMemberScheduleItem
} from "../../../hooks/use-staff";
import { Confirm, ConfirmHandle } from "../../confirm/confirm";
import { format24HourTimeString, formatDateString } from "../../forms/form-helpers";
import { useEditScheduleFormModal } from "./edit-schedule";
import { AccessControlGate } from "../../../app/access-control-gate";
import { IconButton } from "../../buttons/icon-button";
import { SimpleTable, SimpleTableHeader } from "../../table";

export interface SchedulesProps {
  staffMemberId: string;
}

export const Schedules: FC<SchedulesProps> = ({ staffMemberId }) => {
  const { activeCenterId, hasPermission } = useAccessControl();
  const { schedules, isLoading, deleteStaffScheduleMutator } = useStaffMemberSchedules(activeCenterId, staffMemberId);

  const confirmDeleteScheduleModal = useRef<ConfirmHandle>(null);

  const { renderEditScheduleFormModal, showEditScheduleFormModal } = useEditScheduleFormModal({ staffMemberId });

  const onDeleteSchedule = useCallback((id: number) => {
    confirmDeleteScheduleModal.current &&
      confirmDeleteScheduleModal.current.confirm(() => {
        deleteStaffScheduleMutator.mutate(id);
      });
  }, []);

  const onEditAddSchedule = useCallback((input?: StaffMemberSchedule) => {
    showEditScheduleFormModal(
      input ?? {
        id: 0,
        startDate: "",
        endDate: "",
        days: [
          { id: 1, startTime: "", endTime: "", deliveryMethod: "center", sessionRatio: "one_to_one", dayOfWeek: 0 }
        ]
      }
    );
  }, []);

  const schedulesHeaders = useMemo<SimpleTableHeader<StaffMemberSchedule>[]>(() => {
    const headers: SimpleTableHeader<StaffMemberSchedule>[] = [
      {
        renderKey: "start",
        name: "Start",
        render: item => formatDateString(item.startDate),
        tableDataCellProps: {
          verticalAlign: "top"
        }
      },
      {
        renderKey: "end",
        name: "End",
        render: item => formatDateString(item.endDate),
        tableDataCellProps: {
          verticalAlign: "top"
        }
      },

      {
        renderKey: "schedules",
        name: "Schedule",
        render: item => <ScheduleItems items={item.days} scheduleId={item.id} />
      }
    ];

    if (!!hasPermission && hasPermission(Permissions.STAFF_EDIT)) {
      headers.push({
        renderKey: "buttons",
        name: "",
        render: item => {
          return (
            <>
              <IconButton
                title="Edit"
                icon="edit"
                enableTooltip
                data-testid={`schedules-edit-button-${item.id}`}
                className="btn btn-table"
                onClick={() => onEditAddSchedule(item)}
              />
              <IconButton
                title="Delete"
                icon="trash"
                enableTooltip
                data-testid={`schedules-delete-button-${item.id}`}
                className="btn btn-table btn-danger"
                onClick={() => onDeleteSchedule(item.id)}
              />
            </>
          );
        },
        tableDataCellProps: {
          textAlign: "right",
          isAction: true
        }
      } as SimpleTableHeader<StaffMemberSchedule>);
    }

    return headers;
  }, [hasPermission]);

  return (
    <>
      <h3 className="card-subheader">
        Schedules
        <AccessControlGate required={Permissions.STAFF_EDIT}>
          <button
            className="btn btn-primary btn-sm ms-2"
            onClick={() => {
              onEditAddSchedule();
            }}
          >
            <span className="fas fa-plus" aria-hidden="true"></span>
            <span className="sr-only">Add Schedule</span>
          </button>
        </AccessControlGate>
      </h3>

      <SimpleTable
        tableCollapseScreenSize="sm"
        headers={schedulesHeaders}
        data={schedules}
        isLoading={isLoading}
        uniqueIdKey="id"
      />

      <Confirm ref={confirmDeleteScheduleModal} title="Confirm" message="Would you like to delete this schedule?" />
      <>{renderEditScheduleFormModal()}</>
    </>
  );
};

export const ScheduleItems: FC<{ scheduleId: number; items: StaffMemberScheduleItem[] }> = ({ scheduleId, items }) => {
  const { weekdayLookup, sessionRatioLookup, deliveryMethods } = useReferenceData();

  const groupedByDay = items.reduce((grouped: Record<string, StaffMemberScheduleItem[]>, item) => {
    const { dayOfWeek } = item;
    const key = dayOfWeek;
    grouped[key] = grouped[key] ?? [];
    grouped[key].push(item);

    grouped[key].sort((left, right) => left.startTime.localeCompare(right.startTime));
    return grouped;
  }, {});

  return (
    <>
      {(["1", "2", "3", "4", "5", "6", "7"] as const).map(day => {
        if (!groupedByDay[day] || groupedByDay[day].length === 0) {
          return null;
        }
        const rowdata = groupedByDay[day].map(
          i =>
            `${format24HourTimeString(i.startTime, false)} - ${format24HourTimeString(i.endTime, false)} ${
              deliveryMethods.find(dm => dm.value === i.deliveryMethod)?.name
            } ${sessionRatioLookup[i.sessionRatio]}`
        );
        return (
          <Fragment key={`${scheduleId}-${day}`}>
            {weekdayLookup[day]} {rowdata.join("; ")} <br />
          </Fragment>
        );
      })}
    </>
  );
};
