import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import type {
  CreateStaffScheduleRequestPayload,
  StaffMemberScheduleList,
  UpdateStaffScheduleRequestPayload
} from "./types";

export const useStaffMemberSchedules = (centerId: string, staffMemberId: string) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const getSchedulesData = async () => {
    return getStaffSchedules(await getToken(), centerId, staffMemberId);
  };

  const queryKey = ["staffSchedulesData", centerId, staffMemberId] as const;

  const schedulesQuery = useQuery<StaffMemberScheduleList>({
    queryKey,
    queryFn: getSchedulesData,
    enabled: !!centerId && !!staffMemberId
  });

  const updateStaffScheduleMutator = useMutation({
    mutationFn: async (updatedSchedule: UpdateStaffScheduleRequestPayload) =>
      updateStaffSchedule(await getToken(), centerId, staffMemberId, updatedSchedule),

    onSuccess: updatedScheduleList => {
      queryClient.setQueryData<StaffMemberScheduleList>(queryKey, updatedScheduleList);
    }
  });

  const createStaffScheduleMutator = useMutation({
    mutationFn: async (schedule: CreateStaffScheduleRequestPayload) =>
      createStaffSchedule(await getToken(), centerId, staffMemberId, schedule),

    onSuccess: updatedScheduleList => {
      queryClient.setQueryData<StaffMemberScheduleList>(queryKey, updatedScheduleList);
    }
  });

  const deleteStaffScheduleMutator = useMutation({
    mutationFn: async (scheduleId: number) =>
      deleteStaffSchedule(await getToken(), centerId, staffMemberId, scheduleId),

    onSuccess: updatedScheduleList => {
      queryClient.setQueryData<StaffMemberScheduleList>(queryKey, () => {
        return updatedScheduleList;
      });
    }
  });

  return {
    schedules: schedulesQuery.data?.schedules ?? [],
    isLoading: schedulesQuery.isLoading,
    updateStaffScheduleMutator,
    createStaffScheduleMutator,
    deleteStaffScheduleMutator
  };
};

const getStaffSchedules = (accessToken: string, centerId: string | undefined, userId: string | undefined) => {
  return callLcosService<StaffMemberScheduleList>(accessToken, `/api/availability/${centerId}/${userId}`);
};

const updateStaffSchedule = (
  accessToken: string,
  centerId: string | undefined,
  userId: string | undefined,
  schedule: UpdateStaffScheduleRequestPayload
) => {
  return callLcosService<StaffMemberScheduleList>(
    accessToken,
    `/api/availability/${centerId}/${userId}/${schedule.id}`,
    "PUT",
    schedule
  );
};

const createStaffSchedule = (
  accessToken: string,
  centerId: string,
  userId: string,
  schedule: CreateStaffScheduleRequestPayload
) => callLcosService<StaffMemberScheduleList>(accessToken, `/api/availability/${centerId}/${userId}`, "POST", schedule);

const deleteStaffSchedule = (
  accessToken: string,
  centerId: string | undefined,
  userId: string | undefined,
  scheduleId: number
) => {
  return callLcosService<StaffMemberScheduleList>(
    accessToken,
    `/api/availability/${centerId}/${userId}/${scheduleId}`,
    "DELETE"
  );
};
