import React from "react";
import { useAccessControl } from "./access-control-context";

export interface AccessControlGateProps {
  required?: string | string[] | undefined;
}

// this little wrapper keeps JSX cleaner by not requiring use of conditional render syntax
export const AccessControlGate: React.FC<React.PropsWithChildren<AccessControlGateProps>> = ({
  required,
  children
}) => {
  const { hasPermission } = useAccessControl();

  if (hasPermission && !hasPermission(required)) {
    return null;
  }

  return <>{children}</>;
};
