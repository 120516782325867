import { useState, useMemo, useEffect, type ReactNode } from "react";

import { SimpleTable, type SimpleTableHeader, type SimpleTableProps } from "./simple-table";
import { usePaginator } from "../pagination";

export type SortDirection = "asc" | "des";

interface SortOptions<T> {
  enableSort?: boolean;
  customSortFunction?: (a: T, b: T, direction: SortDirection) => number;
  secondarySortKey?: keyof T;
  customSortKey?: string;
}

interface PaginationOptions {
  itemsPerPage?: number;
  onPageChange?: (pageNumber: number) => void;
}

export interface SortConfig<T> {
  key: keyof T | null;
  secondaryKey: keyof T | null;
  direction: SortDirection;
}

interface UsePaginatedSorterProps<T> {
  data: T[];
  headers: SortableTableHeader<T>[];
  activeItem?: T;
  paginationOptions?: PaginationOptions;
  isLoading?: boolean;
}

interface UsePaginatedSorterReturn<T> {
  paginatedData: T[];
  requestSort: (header: SortableTableHeader<T>) => void;
  renderPaginator: () => ReactNode;
}

export type UsePaginatedSorterHook<T> = ({ data, headers }: UsePaginatedSorterProps<T>) => UsePaginatedSorterReturn<T>;

export const useDefaultPaginatedSorter = <T extends {}>({
  data,
  headers,
  activeItem,
  paginationOptions: { itemsPerPage, onPageChange } = {},
  isLoading = false
}: UsePaginatedSorterProps<T>): UsePaginatedSorterReturn<T> => {
  const [sortConfig, setSortConfig] = useState<SortConfig<T>>({ key: null, secondaryKey: null, direction: "asc" });

  const sortedData = useMemo(() => {
    const sortableItems = [...data];
    if (sortConfig.key !== null) {
      const sortingHeader = headers.find(header => header.key === sortConfig.key);
      if (sortingHeader?.sortOptions?.enableSort) {
        const customSortFunction = sortingHeader.sortOptions.customSortFunction;

        sortableItems.sort((a, b) => {
          if (customSortFunction) {
            return customSortFunction(a, b, sortConfig.direction);
          }
          if (a[sortConfig.key as keyof T] < b[sortConfig.key as keyof T]) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (a[sortConfig.key as keyof T] > b[sortConfig.key as keyof T]) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          if (sortConfig.secondaryKey != null) {
            if (a[sortConfig.secondaryKey] < b[sortConfig.secondaryKey as keyof T]) {
              return sortConfig.direction === "asc" ? -1 : 1;
            }
            if (a[sortConfig.secondaryKey] > b[sortConfig.secondaryKey]) {
              return sortConfig.direction === "asc" ? 1 : -1;
            }
          }
          return 0;
        });
      }
    }
    return sortableItems;
  }, [data, sortConfig, headers]);

  const numberOfPages = useMemo(
    () => (itemsPerPage ? Math.ceil(sortedData.length / itemsPerPage) : 1),
    [sortedData, itemsPerPage]
  );
  const { currentPage, renderPaginator, setCurrentPage } = usePaginator({ onPageChange, numberOfPages, isLoading });
  const paginatedData = useMemo(
    () =>
      itemsPerPage
        ? sortedData.slice((currentPage - 1) * itemsPerPage, (currentPage - 1) * itemsPerPage + itemsPerPage)
        : sortedData,
    [currentPage, sortedData, itemsPerPage]
  );

  useEffect(() => {
    // pages is a one-based index therefore this is correct
    if (currentPage > numberOfPages) {
      setCurrentPage(1);
    }
  }, [numberOfPages, currentPage, setCurrentPage]);

  useEffect(() => {
    if (itemsPerPage && activeItem) {
      const activeItemIndex = sortedData.findIndex(item => item === activeItem);
      if (activeItemIndex !== -1) {
        const pageNumber = Math.ceil((activeItemIndex + 1) / itemsPerPage) || 1;
        setCurrentPage(pageNumber);
      }
    }
  }, [activeItem, itemsPerPage, sortedData]);

  const requestSort = ({ key, sortOptions }: SortableTableHeader<T>) => {
    let direction: SortDirection = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "des";
    }
    setSortConfig(oldSort => ({
      key: key ?? null,
      secondaryKey: sortOptions?.secondarySortKey ?? oldSort.key,
      direction
    }));
    setCurrentPage(1);
  };

  return {
    paginatedData,
    requestSort,
    renderPaginator
  };
};

export type SortableTableHeader<T> = SimpleTableHeader<T> & {
  sortOptions?: SortOptions<T>;
};

interface SortableTableProps<T> extends SimpleTableProps<T> {
  headers: SortableTableHeader<T>[];
  paginationOptions?: PaginationOptions;
  usePaginatedSorter?: UsePaginatedSorterHook<T>;
}

export const SortableTable = <T extends {}>({
  headers,
  data = [],
  onHeaderClick,
  usePaginatedSorter = useDefaultPaginatedSorter,
  paginationOptions: { itemsPerPage, onPageChange } = {},
  isLoading,
  activeItem,
  ...simpleTableProps
}: SortableTableProps<T>) => {
  const { paginatedData, requestSort, renderPaginator } = usePaginatedSorter({
    data,
    headers,
    activeItem,
    paginationOptions: { itemsPerPage, onPageChange },
    isLoading
  });

  const handleHeaderClick = (header: SortableTableHeader<T>) => {
    if (header.sortOptions?.enableSort) {
      requestSort(header);
    }
    onHeaderClick?.(header);
  };

  return (
    <>
      <SimpleTable
        headers={headers}
        data={paginatedData}
        activeItem={activeItem}
        onHeaderClick={handleHeaderClick}
        isLoading={isLoading}
        {...simpleTableProps}
      />
      {renderPaginator()}
    </>
  );
};
