import React from "react";
import classnames from "classnames";

export const ContentViewport: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={classnames("content-viewport", className)} {...rest}>
      {children}
    </div>
  );
};

export interface LeftsideProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  sizeClasses?: string;
}

export const Leftside: React.FC<LeftsideProps> = ({
  className,
  sizeClasses = "col-xs-6 col-lg-6",
  children,
  ...rest
}) => {
  return (
    <div className={classnames(`col col-content-primary ${sizeClasses}`, className)} {...rest}>
      {children}
    </div>
  );
};

export interface RightsideProps extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  showIf?: boolean;
  sizeClasses?: string;
}

export const Rightside: React.FC<RightsideProps> = ({
  showIf = true,
  sizeClasses = "col-xs-6 col-lg-6",
  children,
  className,
  ...rest
}) => {
  return (
    <div className={classnames(`col col-sidebar ${sizeClasses}`, { show: showIf }, className)} {...rest}>
      {children}
    </div>
  );
};

export const Utility: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={classnames("utility-bar", className)} {...rest}>
      {children}
    </div>
  );
};

export const ContentPad: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={classnames("content-pad", className)} {...rest}>
      {children}
    </div>
  );
};

export const DetailPanelCloser: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button type="button" className="btn-close sidebar-close" aria-label="Close" onClick={onClick}>
      <span className="sr-only">Close</span>
    </button>
  );
};

export const SinglePanel: React.FC<React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={classnames("col col-content-primary col-xs-12", className)} {...rest}>
      {children}
    </div>
  );
};
