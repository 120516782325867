import { useCallback, useId } from "react";

export interface CheckboxProps {
  value?: boolean;
  label: string;
  onChangeValue?: (newvalue: boolean) => void;
  large?: boolean;
  "data-testid"?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  value,
  label,
  large = false,
  "data-testid": testId,
  onChangeValue
}) => {
  const checkboxId = useId();

  const style = large ? { height: "20px", width: "20px", marginRight: "10px" } : {};

  const onOptionCheck: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    onChangeValue && onChangeValue(e.target.checked);
  }, []);

  return (
    <div className="col">
      <div className="form-check">
        <input
          type="checkbox"
          id={checkboxId}
          checked={value}
          style={style}
          className="form-check-input"
          onChange={onOptionCheck}
          data-testid={testId}
        />
        <label className="form-check-label" htmlFor={checkboxId}>
          {label}
        </label>
      </div>
    </div>
  );
};
