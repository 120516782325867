import classNames from "classnames";
import { useId, type FC, forwardRef } from "react";
import BsTable, { type TableProps as BsTableProps } from "react-bootstrap/Table";

export interface TableProps extends BsTableProps {
  tableCollapseScreenSize?: "xl" | "lg" | "md" | "sm";
  tableHasIcons?: boolean;
  tableHover?: boolean;
}

export const Table = forwardRef<HTMLTableElement, TableProps>(
  (
    {
      tableCollapseScreenSize = "lg",
      tableHover = true,
      tableHasIcons = true,
      bsPrefix = "table",
      className,
      ...bsTableProps
    },
    ref
  ) => {
    return (
      <BsTable
        bsPrefix={classNames(className, bsPrefix, {
          [`table-collapse-${tableCollapseScreenSize}`]: !!tableCollapseScreenSize,
          "table-hover": tableHover,
          "table-has-icons": tableHasIcons
        })}
        {...bsTableProps}
        ref={ref}
      />
    );
  }
);
Table.displayName = "Table";

export interface TableCellProps extends React.HtmlHTMLAttributes<HTMLTableCellElement> {
  textAlign?: "center" | "right";
  verticalAlign?: "middle" | "top" | "bottom";
  hasIcon?: boolean;
  isAction?: boolean;
}

export interface TableDataCellProps extends TableCellProps {
  isPrimary?: boolean;
  label?: string;
}

export const TableDataCell: FC<TableDataCellProps> = ({
  isPrimary = false,
  label,
  textAlign,
  verticalAlign,
  className,
  hasIcon = false,
  isAction = false,
  children,
  ...tdProps
}) => {
  return (
    <td
      className={classNames(className, {
        "td-primary": isPrimary,
        [`text-${textAlign}`]: !!textAlign,
        [`align-${verticalAlign}`]: !!verticalAlign,
        "td-icon": hasIcon,
        "td-action": isAction
      })}
      {...tdProps}
    >
      {!!label && <span className="th-label">{label}: </span>} {children}
    </td>
  );
};

export const TableHeaderCell: FC<TableCellProps> = ({
  textAlign,
  verticalAlign,
  hasIcon = false,
  className,
  ...thProps
}) => {
  return (
    <th
      scope="col"
      className={classNames(className, {
        [`text-${textAlign}`]: !!textAlign,
        [`vertical-align-${verticalAlign}`]: !!verticalAlign,
        "th-icon": hasIcon
      })}
      {...thProps}
    />
  );
};

interface TableRowProps extends React.HtmlHTMLAttributes<HTMLTableRowElement> {
  isActive?: boolean;
}

export const TableRow: FC<TableRowProps> = ({ isActive = false, className, ...trProps }) => {
  return <tr className={classNames(className, { active: isActive })} {...trProps} />;
};

interface TableCheckInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

export const TableCheckInput: FC<TableCheckInputProps> = ({ label, ...inputProps }) => {
  const id = useId();
  return (
    <div className="styled-check">
      <label htmlFor={id}>
        <span className="sr-only">{label}</span>
        <input {...inputProps} className="form-check-input" type="checkbox" id={id} />
        <span className="icon" />
      </label>
    </div>
  );
};
