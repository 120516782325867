import { FC, useEffect, useState } from "react";
import { ToastParameters } from "./types";
import Toast from "./toast";

export const ModalToastHeader: FC = () => {
  const [toasts, setToasts] = useState<ToastParameters[]>([]);

  useEffect(() => {
    const modalToastUpdateListener = () => {
      setToasts(window.toasts.modalToasts);
    };
    window.addEventListener("modal-toast-update", modalToastUpdateListener);
    return () => {
      window.toasts.removeAllModalToasts();
      window.removeEventListener("modal-toast-update", modalToastUpdateListener);
    };
  }, []);

  if (!toasts.length) {
    return null;
  }

  return (
    <div className="modal-toast-container">
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          remove={() => window.toasts.removeModalToast(toast.id)}
          action={toast.action}
          actionLabel={toast.actionLabel}
          keepOpen={toast.keepOpen}
          duration={toast.duration}
          onClose={toast.onClose}
          onUserClose={toast.onUserClose}
          id={toast.id}
          type={toast.type}
          className="modal-toast"
        >
          {toast.content}
        </Toast>
      ))}
    </div>
  );
};
