import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { ContentViewport, SinglePanel, Utility } from "../../app/app-layout";
import { useAccessControl } from "../../app/access-control-context";
import { InputField } from "../../components/forms/input-field";
import { TextAreaField } from "../../components/forms/text-area-field";
import { useCenterWebsite } from "../../hooks/use-center/use-center-website";
import { LoadingOverlay } from "../../components/loading-overlay/loading-overlay";
import { LoadingButton } from "../../components/loading-button/loading-button";
import { SimpleTable, SimpleTableHeader } from "../../components/table/simple-table";
import { IconButton } from "../../components/buttons/icon-button";
import { useReferenceData } from "../../hooks/use-reference-data";
import { dollarsSchema, isValidUrl } from "../../components/forms/form-helpers";
import { CenterWebsite, DISCOUNT_TYPES } from "../../hooks/use-center";

const centerWebsiteFormSchema = z.object({
  shortDescription: z.string(),
  longDescription: z.string(),
  areas: z.string(),
  schools: z.string(),
  directions: z.string(),
  alumniDestination: z.string(),
  discountType: z.string(),
  discountValue: dollarsSchema,
  socialMedia: z.array(
    z.object({ id: z.string().optional(), url: z.string().url().or(z.literal("")), name: z.string() })
  )
});

type CenterWebsiteFormSchema = z.infer<typeof centerWebsiteFormSchema>;

export const CenterWebsiteScreen: React.FC = () => {
  const { activeCenterId } = useAccessControl();

  const { website, isLoading, websiteMutator } = useCenterWebsite(activeCenterId);
  const { socialMediaTypes } = useReferenceData();

  const websiteForm = useForm<CenterWebsiteFormSchema>({
    mode: "onTouched",
    resolver: zodResolver(centerWebsiteFormSchema),
    defaultValues: {
      shortDescription: "",
      longDescription: "",
      areas: "",
      schools: "",
      directions: "",
      alumniDestination: "",
      discountType: DISCOUNT_TYPES.DOLLAR,
      discountValue: 0,
      socialMedia: []
    }
  });

  const {
    register,
    reset,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = websiteForm;

  useEffect(() => {
    reset({ ...website, discountType: website?.discountType || DISCOUNT_TYPES.DOLLAR });
  }, [website]);

  const onCancel = () => {
    reset({ ...website, discountType: website?.discountType || DISCOUNT_TYPES.DOLLAR });
  };

  const onSubmit = handleSubmit(data => {
    websiteMutator.mutate(data as CenterWebsite);
  });

  const discountType = watch("discountType");

  useEffect(() => {
    if (!discountType) {
      setValue("discountType", DISCOUNT_TYPES.DOLLAR);
    }
  }, [discountType]);

  const socialMediaSpecs: SimpleTableHeader<[string, string]>[] = [
    {
      renderKey: "platform",
      name: "social platform",
      render: ([displayName, className]: [string, string]) => (
        <div className="social-media">
          <span aria-hidden={true} className={className} />
          {displayName}
        </div>
      ),
      tableDataCellProps: { isAction: true, textAlign: "right", className: "td-primary" }
    },
    {
      renderKey: "url",
      name: "url",
      render: ([displayName], index) => {
        return (
          <>
            <input
              type="url"
              className="form-control off-brand-input"
              data-testid="social-media-link-textbox"
              aria-label={`${displayName} URL`}
              {...register(`socialMedia.${index}.url` as const)}
            />
          </>
        );
      },
      tableDataCellProps: { isAction: true }
    },
    {
      renderKey: "action",
      name: "",
      render: ([displayName, className], index) => {
        return (
          <IconButton
            title={`Visit ${displayName}`}
            icon="up-right-from-square"
            enableTooltip
            data-testid={`open-social-media-link-${className.replace(" ", "-")}`}
            className="btn btn-table"
            onClick={() => {
              const url = getValues(`socialMedia.${index}.url`);

              if (url && isValidUrl(url)) {
                window.open(url, "_blank");
              } else {
                window.toasts.add({ type: "warning", content: "not a valid web site address", duration: 5000 });
              }
            }}
          />
        );
      },
      tableDataCellProps: {
        isAction: true,
        textAlign: "right"
      }
    }
  ];

  if (!activeCenterId) {
    return null;
  }

  return (
    <ContentViewport>
      <SinglePanel>
        <div className="sticky-top">
          <Utility className="mb-0 justify-content-end">
            <button className="me-2 btn btn-secondary" onClick={onCancel}>
              Cancel
            </button>

            <LoadingButton
              className="btn btn-primary"
              onClick={onSubmit}
              isLoading={websiteMutator.isLoading || isLoading}
              loadingMessage="Publish"
              style={{ width: "150px" }}
            >
              Publish
            </LoadingButton>
          </Utility>
        </div>

        <div className="content-pad">
          <LoadingOverlay loading={isLoading || websiteMutator.isLoading}>
            <div className="row">
              <div className="col col-12 col-lg-6 mb-4">
                <div className="card">
                  <div className="card-header">
                    <h2>Website Information</h2>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col col-12">
                        <TextAreaField
                          label="About the Center (Short)"
                          maxLength={415}
                          disableColClass
                          placeholder="Write a short  description about the center."
                          error={errors.shortDescription}
                          style={{ height: "100px" }}
                          data-testid="about-the-center-short"
                          {...register("shortDescription")}
                        />
                      </div>
                      <div className="col col-12">
                        <TextAreaField
                          label="About the Center (Long)"
                          maxLength={2000}
                          disableColClass
                          placeholder="Write a long description about the center."
                          error={errors.longDescription}
                          style={{ height: "200px" }}
                          {...register("longDescription")}
                        />
                      </div>
                      <div className="col col-12">
                        <TextAreaField
                          label="Areas We Serve"
                          maxLength={520}
                          disableColClass
                          placeholder="Write a description about areas served."
                          error={errors.areas}
                          style={{ height: "100px" }}
                          {...register("areas")}
                        />
                      </div>
                      <div className="col col-12">
                        <TextAreaField
                          label="Schools We Service"
                          maxLength={520}
                          disableColClass
                          placeholder="Write a description about schools serviced."
                          error={errors.schools}
                          style={{ height: "100px" }}
                          {...register("schools")}
                        />
                      </div>
                      <div className="col col-12">
                        <TextAreaField
                          label="Directions"
                          maxLength={23}
                          disableColClass
                          placeholder="Write a description about directions to the center."
                          error={errors.directions}
                          style={{ height: "60px" }}
                          {...register("directions")}
                        />
                      </div>
                      <div className="col col-12">
                        <TextAreaField
                          label="Where Our Graduates Have Gone"
                          maxLength={520}
                          disableColClass
                          placeholder="Write a description about where graduates have gone."
                          error={errors.alumniDestination}
                          style={{ height: "100px" }}
                          {...register("alumniDestination")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-6 mb-4">
                <div className="card">
                  <div className="card-header">
                    <h2>Coupon / Offer</h2>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col col-12">
                        <div className="d-flex">
                          <div className="me-3">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="discountTypeDollar"
                                value={DISCOUNT_TYPES.DOLLAR}
                                {...register("discountType")}
                              />
                              <label className="form-check-label" htmlFor="discountTypeDollar">
                                $
                              </label>
                            </div>
                            <div className="form-check flex-grow-1">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="discountTypePercentage"
                                value={DISCOUNT_TYPES.PERCENT}
                                {...register("discountType")}
                              />
                              <label className="form-check-label" htmlFor="discountTypePercentage">
                                %
                              </label>
                            </div>
                          </div>
                          <div className="w-100">
                            <InputField
                              disableColClass
                              type="number"
                              step=".01"
                              label={`Discount`}
                              error={errors.discountValue}
                              {...register("discountValue", {
                                setValueAs: v => (v === "" ? undefined : parseFloat(v))
                              })}
                              labelClassName="text-nowrap single-line-label mx-5 px-sm-2 bg-transparent"
                              addOnClassName={
                                discountType === DISCOUNT_TYPES.DOLLAR
                                  ? "fas icon fa-dollar-sign"
                                  : "fas icon fa-percent"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h2>Social Media Links</h2>
                  </div>
                  <div className="card-body">
                    <SimpleTable
                      tableCollapseScreenSize="sm"
                      tableHasIcons
                      data={socialMediaTypes}
                      headers={socialMediaSpecs}
                      uniqueIdKey="0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </SinglePanel>
    </ContentViewport>
  );
};

export const centerWebsiteLoader = () => {
  return {
    activeTab: "Centers",
    tabQualifier: "Website"
  };
};
