import { FC } from "react";

import { DashboardUpcomingEvent, InquiryAppointment } from "../../../../hooks/use-inquiries";
import { SimpleTable, type SimpleTableHeader } from "../../../table";
import { useAccessControl } from "../../../../app/use-access-control";
import { AppointmentDateTime } from "./appointment-date-time";
import { StatusDropdown } from "./status-dropdown";
import { SCHEDULE_EDIT } from "../../../../data/permissions";
import { useCenterConstants } from "../../../../hooks/use-center";

interface UpcomingEventsTableProps {
  upcomingEvents?: DashboardUpcomingEvent[];
  onClickUpcomingEvent?: (appointment: InquiryAppointment) => void;
  onUpdateAppointmentStatus: (appointment: InquiryAppointment) => void;
  onRescheduleAppointment: (appointment: InquiryAppointment) => void;
}

export const UpcomingEventsTable: FC<UpcomingEventsTableProps> = ({
  upcomingEvents,
  onClickUpcomingEvent,
  onUpdateAppointmentStatus,
  onRescheduleAppointment
}) => {
  const { hasPermission, activeCenterId } = useAccessControl();
  const { activeConstants: { appointmentTypeLookup } = { appointmentTypeLookup: {} as Record<string, string> } } =
    useCenterConstants(activeCenterId);

  const headers: SimpleTableHeader<DashboardUpcomingEvent>[] = [
    {
      renderKey: "typeId",
      name: "Appointment Type",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: event => <a href="#">{appointmentTypeLookup?.[`${event.typeId}`]}</a>,
      tableDataCellProps: {
        isPrimary: true
      }
      // onCellClick: event => onClickUpcomingEvent?.(event)
    },
    {
      renderKey: "scheduledDateTime",
      name: "Scheduled Time",
      label: "Scheduled",
      render: event => <AppointmentDateTime appointment={event} />
    },
    ...(hasPermission?.(SCHEDULE_EDIT)
      ? [
          {
            renderKey: "action",
            name: "Action",
            label: "",
            render: (event: InquiryAppointment) => {
              const id = `status-dropdown-${event.id}`;
              return (
                <>
                  <label htmlFor={id} className="sr-only">
                    Action
                  </label>
                  <StatusDropdown
                    id={id}
                    appointment={event}
                    onUpdateAppointmentStatus={onUpdateAppointmentStatus}
                    onRescheduleAppointment={onRescheduleAppointment}
                  />
                </>
              );
            },
            onCellClick: () => {
              // do nothing, we need to let the dropdown function properly
            }
          }
        ]
      : [])
  ];

  return (
    <SimpleTable
      tableCollapseScreenSize="sm"
      tableHover
      tableHasIcons
      data={upcomingEvents}
      headers={headers}
      uniqueIdKey="id"
      onRowClick={hasPermission?.(SCHEDULE_EDIT) ? row => onClickUpcomingEvent?.(row) : undefined}
    />
  );
};
