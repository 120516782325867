import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import type { StaffMemberPermissions } from "./types";

const queryKeys = {
  base: ["permissions"],
  detail: (options: { centerId: string; staffMemberId?: string }) => [...queryKeys.base, options] as const
} as const;

export const usePermissions = (options: { centerId: string; staffMemberId?: string }) => {
  const { getToken } = useAccessToken();

  return useQuery({
    queryKey: queryKeys.detail(options),
    queryFn: async () => {
      invariant(options.staffMemberId, "Staff member ID is required");

      return getStaffMemberPermissions(await getToken(), options.centerId, options.staffMemberId);
    },
    enabled: Boolean(options.staffMemberId)
  });
};

export const useStaffMemberPermissions = (centerId: string | undefined, staffMemberId: string | undefined) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const queryKey = ["staffMemberPermissionsData", centerId, staffMemberId];

  const getStaffMemberPermissionData = async () => {
    if (!centerId || !staffMemberId) {
      return {} as unknown as StaffMemberPermissions;
    }

    return getStaffMemberPermissions(await getToken(), centerId, staffMemberId);
  };

  const staffMemberPositionsQuery = useQuery({
    queryKey,
    queryFn: getStaffMemberPermissionData
  });

  const roleMutator = useMutation({
    mutationFn: async (newRole: { role: string }) =>
      updateStaffMemberRole(await getToken(), centerId, staffMemberId, newRole),

    onSuccess: newPermissions => {
      queryClient.invalidateQueries({ queryKey: ["centerStaffData", centerId] });
      return queryClient.setQueryData(queryKey, () => ({ ...newPermissions }));
    }
  });

  const permissionMutator = useMutation({
    mutationFn: async (newPermission: { action: string; isAllowed: boolean }) =>
      updateStaffMemberPermission(await getToken(), centerId, staffMemberId, newPermission),

    onSuccess: newPermissions => {
      return queryClient.setQueryData(queryKey, () => ({ ...newPermissions }));
    }
  });

  const deletePermissionsMutator = useMutation({
    mutationFn: async () => deleteStaffMemberPermissions(await getToken(), centerId, staffMemberId),

    onSuccess: newPermissions => {
      return queryClient.setQueryData(queryKey, () => ({ ...newPermissions }));
    }
  });

  const staffMailboxMutator = useMutation({
    mutationFn: async (mailbox: { hasMailbox: boolean }) =>
      mailbox.hasMailbox
        ? createStaffMailbox(await getToken(), centerId, staffMemberId)
        : deleteStaffMailbox(await getToken(), centerId, staffMemberId),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    }
  });

  const changeRoleAndResetPermissionsMutator = useMutation({
    mutationFn: async (newRole: { role?: string }) => {
      await updateStaffMemberRole(await getToken(), centerId, staffMemberId, newRole);
      return deleteStaffMemberPermissions(await getToken(), centerId, staffMemberId);
    },
    onSuccess: newPermissions => {
      return queryClient.setQueryData(queryKey, () => ({ ...newPermissions }));
    }
  });

  return {
    staffMemberPermissions: staffMemberPositionsQuery.data,
    isLoading: staffMemberPositionsQuery.isLoading,
    roleMutator,
    permissionMutator,
    deletePermissionsMutator,
    staffMailboxMutator,
    changeRoleAndResetPermissionsMutator
  };
};

const getStaffMemberPermissions = (accessToken: string, centerId: string, staffMemberId: string) => {
  return callLcosService<StaffMemberPermissions>(accessToken, `/api/staff/${centerId}/${staffMemberId}/access`);
};

const updateStaffMemberRole = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined,
  payload: unknown
) => {
  return callLcosService<StaffMemberPermissions>(
    accessToken,
    `/api/staff/${centerId}/${staffMemberId}/role`,
    "PUT",
    payload
  );
};

const updateStaffMemberPermission = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined,
  payload: unknown
) => {
  return callLcosService<StaffMemberPermissions>(
    accessToken,
    `/api/staff/${centerId}/${staffMemberId}/permission`,
    "PUT",
    payload
  );
};

// TBC it deletes all a la carte granted permissions, permissions that are granted
// by the user's role remain in force
const deleteStaffMemberPermissions = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined
) => {
  return callLcosService<StaffMemberPermissions>(
    accessToken,
    `/api/staff/${centerId}/${staffMemberId}/permissions`,
    "DELETE"
  );
};

const createStaffMailbox = (accessToken: string, centerId: string | undefined, staffMemberId: string | undefined) => {
  return callLcosService(accessToken, `/api/staff/${centerId}/${staffMemberId}/mailbox`, "PUT");
};

const deleteStaffMailbox = (accessToken: string, centerId: string | undefined, staffMemberId: string | undefined) => {
  return callLcosService(accessToken, `/api/staff/${centerId}/${staffMemberId}/mailbox`, "DELETE");
};
