import React, { useCallback, useMemo, useState } from "react";

import { FormRow, FormHelpers, Field, DaySelector } from "../forms";
import {
  OperatingHours,
  OperatingHoursType,
  useCenterOperatingHours,
  useCenterOperatingHoursValidator
} from "../../hooks/use-center";
import { useFormInput } from "../../hooks/use-form-input";
import { useAccessControl } from "../../app/use-access-control";
import { Checkbox } from "../forms/legacy/checkbox";
import { useModal } from "../modal/modal";

export interface UseEditCenterHoursModalProps {
  subset: OperatingHoursType;
}

export const useEditCenterHoursModal = ({ subset }: UseEditCenterHoursModalProps) => {
  const { show, hide: hideEditCenterHoursModal, renderModal } = useModal();
  const { activeCenterId } = useAccessControl();
  const { operatingHoursEditMutator } = useCenterOperatingHours(activeCenterId);
  const { buffer, onChangeValue, setBuffer } = useFormInput<OperatingHours>({
    id: 0,
    weekday: 0,
    openTime: "",
    closeTime: "",
    startApptTime: "",
    endApptTime: "",
    seasonStart: "",
    seasonEnd: ""
  });
  const isValidCenterHours = useCenterOperatingHoursValidator(buffer, subset);
  const [isCenterClosed, setIsCenterClosed] = useState<boolean>(
    !!buffer.weekday && !buffer.openTime && !buffer.closeTime
  );

  const localSave = () => {
    operatingHoursEditMutator.mutate(buffer, {
      onSuccess: hideEditCenterHoursModal
    });
  };

  const showEditCenterHoursModal = (hours: OperatingHours) => {
    setBuffer(hours);
    setIsCenterClosed(!!hours.weekday && !hours.openTime && !hours.closeTime);
    show();
  };

  const onClickIsCenterClosed = useCallback(() => {
    setIsCenterClosed(previous => !previous);
    if (!isCenterClosed) {
      onChangeValue("openTime")("");
      onChangeValue("closeTime")("");
    }
  }, [onChangeValue, isCenterClosed]);

  const disableSave = useMemo(() => {
    if (isCenterClosed && !!buffer.weekday) {
      return false;
    } else {
      return !isValidCenterHours;
    }
  }, [isValidCenterHours, isCenterClosed, buffer]);

  const renderEditCenterHoursModal = () =>
    renderModal({
      title: `Edit ${subset === "default" ? "Operating" : "Seasonal"} Hours`,
      body: (
        <>
          <FormRow>
            <DaySelector
              value={buffer.weekday}
              mode={"edit"}
              label="Day"
              onValidate={FormHelpers.isValidRequiredString}
              onChangeValue={onChangeValue("weekday")}
              required
            />
          </FormRow>

          {!isCenterClosed && (
            <FormRow>
              <Field
                value={buffer.openTime}
                type="time"
                mode={"edit"}
                label="Open"
                onValidate={FormHelpers.isValidRequiredTimeString}
                onChangeValue={onChangeValue("openTime")}
                required={!isCenterClosed}
              />
              <Field
                value={buffer.closeTime}
                type="time"
                mode={"edit"}
                label="Close"
                onValidate={FormHelpers.isValidRequiredTimeString}
                onChangeValue={onChangeValue("closeTime")}
                required={!isCenterClosed}
              />
            </FormRow>
          )}

          <FormRow show={subset === "seasonal"}>
            <Field
              value={buffer.seasonStart}
              type="date"
              mode={"edit"}
              label="Start"
              onValidate={FormHelpers.isValidRequiredDateString}
              onChangeValue={onChangeValue("seasonStart")}
              required
            />
            <Field
              value={buffer.seasonEnd}
              type="date"
              mode={"edit"}
              label="End"
              onValidate={FormHelpers.isValidRequiredDateString}
              onChangeValue={onChangeValue("seasonEnd")}
              required
            />
          </FormRow>

          <FormRow>
            <Checkbox label="Center is closed" value={isCenterClosed} onChangeValue={onClickIsCenterClosed} />
          </FormRow>
        </>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideEditCenterHoursModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={localSave} disabled={disableSave}>
            Save
          </button>
        </>
      )
    });

  return {
    renderEditCenterHoursModal,
    showEditCenterHoursModal,
    hideEditCenterHoursModal
  };
};
