import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useMediaQuery } from "usehooks-ts";

import { useAccessControl } from "../../app/access-control-context";
import { ContentPad, ContentViewport, DetailPanelCloser, Leftside, Rightside, Utility } from "../../app/app-layout";
import { useLegacyLcosCheckerModal } from "../../components/legacy-lcos-checker/legacy-lcos-checker";
import { useModal } from "../../components/modal/modal";
import { StaffAdministrationOnboardingWizard } from "../../components/onboarding-wizard/staff-administration-onboarding-wizard";
import { StaffDetail } from "../../components/staff-detail/staff-detail";
import { StaffSelector } from "../../components/staff-selector/staff-selector";
import type { CenterStaffFilters } from "../../hooks/use-center";
import { DefaultCenterStaffFilters, useCenterStaff } from "../../hooks/use-center";
import { usePermissions } from "../../hooks/use-staff";
import type { StaffMember } from "../../hooks/use-user";
import { StaffAdministrationListHeader } from "./staff-administration-list-header";
import { useReferenceData } from "../../hooks/use-reference-data";

// note that commented code in this file and the scss file is left on purpose in case of changed minds later

export const StaffAdministration: FC = () => {
  const { activeCenterId, hasFeatureAccess, activeCenter } = useAccessControl();
  const isSmallScreen = useMediaQuery("(max-width: 991.98px)", { defaultValue: false });
  const [staffFilters, setStaffFilters] = useState<CenterStaffFilters>({ ...DefaultCenterStaffFilters });
  const tabQualifier = useMemo(() => (!!staffFilters.isActive ? "Active" : "Inactive"), [staffFilters.isActive]);
  const { positionsLookup } = useReferenceData();

  const { staff } = useCenterStaff(activeCenterId, true, staffFilters);

  const [selectedStaff, setSelectedStaff] = useState<StaffMember>();
  const onChangeSelectedStaffMember = useCallback(
    (staff: StaffMember) => {
      if (staff) {
        setSelectedStaff(staff);
      }
    },
    [staff]
  );
  const initialPasswordModal = useModal();

  const permissionsQuery = usePermissions({
    centerId: activeCenterId,
    staffMemberId: selectedStaff?.id
  });

  // useEffect(() => {
  //   setSelectedStaffMemberId("");
  // }, [tabQualifier]);

  useEffect(() => {
    // do not autoselect on smaller screens
    if (isSmallScreen) {
      return;
    }

    if (staff && staff.length) {
      const foundStaff = selectedStaff && staff.find(st => st.id === selectedStaff.id);
      if (!foundStaff) {
        const sorted = staff.slice().sort((a, b) => {
          if (!!a.staffId === !!b.staffId) {
            return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
          }
          if (!a.staffId) {
            return -1;
          }
          return 1;
        });
        setSelectedStaff(sorted[0]);
      } else {
        setSelectedStaff(foundStaff);
      }
    } else if (!staff || !staff.length) {
      setSelectedStaff(undefined);
    }
  }, [staff, isSmallScreen]);

  const closeRightPanel = useCallback(() => {
    setSelectedStaff(undefined);
  }, []);

  // const onCloseDetail = useCallback(() => {
  //   setSelectedStaffMemberId("");
  // }, []);

  // const headerLabel = useMemo(() => {
  //   return staff?.find(s => s.id === selectedStaffMemberId)?.displayName ?? "";
  // }, [selectedStaffMemberId, staff]);

  const [filter, setFilter] = useState<string>("");
  const onFilterChange = useCallback((newvalue: string) => {
    setFilter(newvalue);
  }, []);
  const tableRef = useRef<HTMLTableElement>(null);
  const onPrintRequested = useReactToPrint({
    content: () => tableRef.current
  });

  const [showOnboardingWizard, setShowOnboardingWizard] = useState(false);
  const openOnboardingWizardModal = useCallback(() => {
    setShowOnboardingWizard(true);
  }, []);
  const closeOnboardingWizardModal = useCallback(() => {
    setShowOnboardingWizard(false);
  }, []);

  const handleAfterSuccessfulSave = (newStaffMember: StaffMember) => {
    setSelectedStaff(newStaffMember);
    showLegacyLcosCheckerModal(newStaffMember);
  };

  const { renderLegacyLcosCheckerModal, showLegacyLcosCheckerModal } = useLegacyLcosCheckerModal({
    activeCenterId,
    onSuccessfulSave: () => {
      if (permissionsQuery.data?.initialPw) {
        initialPasswordModal.show();
      }
    }
  });

  return (
    <ContentViewport className="slide-in-sidebar">
      <Leftside sizeClasses="col-xs-5 col-lg-5">
        <Utility className="flex-column">
          <StaffAdministrationListHeader
            filter={filter}
            onFilterChange={onFilterChange}
            onPrintRequested={onPrintRequested}
            onChangeStaffFilters={(newvalue: CenterStaffFilters) => setStaffFilters(newvalue)}
            onStartOnboardingWizard={openOnboardingWizardModal}
          />
        </Utility>
        <ContentPad>
          <StaffSelector
            ref={tableRef}
            filter={filter}
            staff={staff}
            selected={selectedStaff}
            onSelect={onChangeSelectedStaffMember}
            key={`staff-selector-${tabQualifier}`}
          />
          <StaffAdministrationOnboardingWizard
            show={showOnboardingWizard}
            onClose={closeOnboardingWizardModal}
            onSuccessfulSave={staffMember => {
              if (hasFeatureAccess?.("staff_id")) {
                handleAfterSuccessfulSave(staffMember);
              }
            }}
          />
          {!!hasFeatureAccess?.("staff_id") && <>{renderLegacyLcosCheckerModal()}</>}
          {initialPasswordModal.renderModal({
            title: "New User Credentials",
            body: (
              <>
                <p>
                  Please share this login with {selectedStaff?.displayName}. Keep in mind, they will not be able to log
                  in until their start date.
                </p>
                <span className="fw-bold">User ID: </span>
                {selectedStaff?.businessEmail ?? "Loading..."}
                <br />
                <span className="fw-bold">Temporary Password: </span>
                {permissionsQuery.data?.initialPw ?? "Loading..."}
              </>
            ),
            footer: (
              <button className="btn btn-primary" onClick={initialPasswordModal.hide}>
                Done
              </button>
            )
          })}
        </ContentPad>
      </Leftside>
      <Rightside showIf={!!selectedStaff} sizeClasses="col-xs-7 col-lg-7">
        <DetailPanelCloser onClick={closeRightPanel} />
        <ContentPad className="scroll-container">
          <div className="scroll-container-fixed">
            <h2>
              {activeCenter.name} - {selectedStaff?.displayName}
            </h2>
            <div className="h2-helper">{positionsLookup[selectedStaff?.position ?? ""]}</div>
          </div>
          <StaffDetail staff={selectedStaff} />
        </ContentPad>
      </Rightside>
    </ContentViewport>
  );
};

export const staffAdministrationLoader = ({ params }: { params: { status?: string } }) => {
  return {
    activeTab: "Staff",
    tabQualifier: params.status
  };
};
