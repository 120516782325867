import React from "react";

import { ContentPad, ContentViewport, SinglePanel } from "../../app/app-layout";
import { useAccessControl } from "../../app/access-control-context";
import { CenterInformation } from "../../components/center-information/center-information";

export const CenterContactScreen: React.FC = () => {
  const { activeCenter } = useAccessControl();

  if (!activeCenter) {
    return null;
  }

  return (
    <ContentViewport>
      <SinglePanel>
        <ContentPad>
          <div className="card">
            <div className="card-body">
              <div className="card-header">
                <h2>Contact</h2>
              </div>
              <CenterInformation center={activeCenter} />
            </div>
          </div>
        </ContentPad>
      </SinglePanel>
    </ContentViewport>
  );
};

export const centerContactLoader = () => {
  return {
    activeTab: "Centers",
    tabQualifier: "Contact"
  };
};
