import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import invariant from "tiny-invariant";

import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import type { StaffMemberCenters } from "./types";
import { alphabeticalSorter } from "./utilities";

export const useStaffMemberCenters = (centerId: string | undefined, staffMemberId: string | undefined) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const queryKey = ["staffMemberCentersData", centerId, staffMemberId];

  const staffCentersQuery = useQuery({
    queryKey,
    queryFn: async () => {
      invariant(centerId, "centerId is required");
      invariant(staffMemberId, "staffMemberId is required");
      return getStaffMemberCenters(await getToken(), centerId, staffMemberId);
    },
    enabled: !!centerId && !!staffMemberId,
    select: ({ available, current }) => ({
      availableCenters: [...available].sort(alphabeticalSorter),
      currentCenters: [...current].filter(({ active }) => active === undefined || active).sort(alphabeticalSorter)
    })
  });

  const mutator = useMutation({
    mutationFn: async (newCenterId: string) =>
      updateStaffMemberCenters(await getToken(), centerId, staffMemberId, { centerId: newCenterId }),

    onSuccess: updated => {
      queryClient.setQueryData<StaffMemberCenters>(queryKey, { ...updated });
    }
  });

  const addTrackStaffCenterMutator = useMutation({
    mutationFn: async () => addTrackStaffMemberCenters(await getToken(), centerId, staffMemberId)
  });

  return {
    ...staffCentersQuery.data,
    isLoading: staffCentersQuery.isLoading,
    mutator,
    addTrackStaffCenterMutator
  };
};

const getStaffMemberCenters = (accessToken: string, centerId: string, staffMemberId: string) => {
  return callLcosService<StaffMemberCenters>(accessToken, `/api/staff/${centerId}/${staffMemberId}/centers`);
};

const updateStaffMemberCenters = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined,
  payload: { centerId: string }
) => {
  return callLcosService<StaffMemberCenters>(
    accessToken,
    `/api/staff/${centerId}/${staffMemberId}/copy`,
    "POST",
    payload
  );
};

const addTrackStaffMemberCenters = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined
) => {
  return callLcosService<StaffMemberCenters>(accessToken, `/api/staff/${centerId}/${staffMemberId}/track`, "POST");
};
