import { useState, useCallback, useEffect } from "react";

export const triggerTouched = () => document.dispatchEvent(new CustomEvent("trigger-touched"));

export const useTouched = () => {
  const [touched, setTouched] = useState<boolean>(false);
  const onTouched = useCallback(() => {
    setTouched(true);
  }, []);

  useEffect(() => {
    const saveAttempted: EventListenerOrEventListenerObject = e => {
      e.stopPropagation();
      setTouched(true);
    };
    document.addEventListener("trigger-touched", saveAttempted);
    return () => {
      document.removeEventListener("trigger-touched", saveAttempted);
    };
  }, []);

  return {
    touched,
    onTouched
  };
};
