import classNames from "classnames";
import { CSSProperties, forwardRef, useId, useMemo } from "react";
import { type FieldError } from "react-hook-form";

export interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  isRequired?: boolean;
  validationState?: "success" | "error";
  containerClassName?: string;
  labelClassName?: string;
  //TODO: haven't done anything w/ this just yet well want to match style guidelines https://preview.jbecker.com/hlc3/components-forms.html
  error?: FieldError;
  // serverValidationError?: any;
  // TODO: we need to remove col class from all form so we can handle this in a container higher up
  disableColClass?: boolean;
  addOnClassName?: string;
  hideErrorMessage?: boolean;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      label,
      isRequired = false,
      validationState,
      containerClassName,
      labelClassName,
      error,
      disableColClass = false,
      autoComplete,
      addOnClassName = null,
      hideErrorMessage = false,
      ...inputProps
    },
    ref
  ) => {
    const inputId = useId();

    const style = useMemo(() => {
      const results: CSSProperties = {};
      if (!label) {
        results["paddingTop"] = "0.625rem";
      }
      if (!!addOnClassName) {
        results["borderTopRightRadius"] = "0.5rem";
        results["borderBottomRightRadius"] = "0.5rem";
      }
      return results;
    }, [label, addOnClassName]);

    return (
      <div
        className={classNames(
          containerClassName,
          { "form-floating": !!label, "input-group": !!addOnClassName },
          { col: !disableColClass }
        )}
      >
        {addOnClassName && (
          <span data-testid={`${inputId}-add-on`} className="input-group-text">
            <i className={addOnClassName}></i>
          </span>
        )}
        <input
          {...inputProps}
          id={inputId}
          aria-invalid={validationState === "error" || error?.message ? "true" : "false"}
          className={classNames("form-control", inputProps.className, {
            "is-valid": validationState === "success",
            "is-invalid": validationState === "error" || error?.message
          })}
          ref={ref}
          // this will always use new-password in readonly/disabled mode, otherwise use
          // autoComplete prop value (which may be undefined and that is legit too)
          autoComplete={inputProps.readOnly || inputProps.disabled ? "new-password" : autoComplete}
          style={style}
        />
        {label && (
          <label htmlFor={inputId} className={labelClassName}>
            {label}
            {isRequired && (
              <>
                {" "}
                <span className="required" aria-hidden="true">
                  *
                </span>
              </>
            )}
          </label>
        )}
        {!hideErrorMessage && !!error?.message && <div className="invalid-feedback"> {error.message}</div>}
      </div>
    );
  }
);

InputField.displayName = "TextField";
