import { FC } from "react";
import { z } from "zod";

import { FormRow } from "../forms/form-row";
import { InputField } from "../forms/input-field";
import { TextAreaField } from "../forms/text-area-field";
import { BasicFormProps } from "../../forms/form-context-wrapper/form-context-wrapper";

export const notesSchema = z.object({
  title: z.string().trim().min(1, { message: "Title is Required" }),
  notes: z.string().trim().min(1, { message: "Notes are Required" })
});

type NotesSchema = z.infer<typeof notesSchema>;

export const EditNoteForm: FC<BasicFormProps<NotesSchema>> = ({ methods }) => {
  return (
    <div className="form edit-note-form" data-testid="edit-note-form">
      {/* <FormHeaderRow>Edit/Update Note</FormHeaderRow> */}
      <FormRow>
        <InputField
          type="text"
          label="Title"
          isRequired
          error={methods?.formState.errors["title"]}
          {...methods?.register("title")}
        />
      </FormRow>
      <FormRow>
        <TextAreaField
          label="Enter notes here..."
          isRequired
          autoResize
          error={methods?.formState.errors["notes"]}
          {...methods?.register("notes")}
        />
      </FormRow>
    </div>
  );
};
