import classNames from "classnames";
import { useCombobox } from "downshift";
import { useId, useState, type FC } from "react";

export interface SearchComboboxItem {
  value: string;
  label: string;
  searchable: string;
  disabled?: boolean;
}

export const emptyItem: SearchComboboxItem = {
  value: "",
  label: "",
  searchable: ""
};

export interface SearchComboboxProps {
  onSelectChange: (item?: SearchComboboxItem | null) => void;
  items: SearchComboboxItem[];
  label: string;
  isLoading?: boolean;
  initialInputValue: string;
  validationState?: "error" | "success";
}

export const SearchCombobox: FC<SearchComboboxProps> = ({
  onSelectChange,
  items,
  label,
  isLoading,
  initialInputValue,
  validationState
}) => {
  const [listItems, setListItems] = useState(items);

  const id = useId();
  const { isOpen, getLabelProps, getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    onInputValueChange({ inputValue }) {
      setListItems(
        items.filter(item => {
          if (!inputValue) {
            return true;
          } else {
            return item.searchable.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase());
          }
        })
      );
    },
    items: listItems,
    // istanbul ignore next
    itemToString: item => item?.label || "",
    onSelectedItemChange: changes => {
      onSelectChange(changes.selectedItem);
    },
    initialInputValue,
    id: id
  });

  return (
    <div className="combobox-container form-floating col">
      <div className="form-floating has-icon">
        <input
          placeholder={label}
          className={classNames("form-control", {
            "is-valid": validationState === "success",
            "is-invalid": validationState === "error"
          })}
          {...getInputProps()}
        />
        <label {...getLabelProps()}>{label}</label>
        <span className="fas fa-search"></span>
      </div>
      <ul
        {...getMenuProps({
          className: "form-select combobox-select-list",
          style: { maxHeight: "120px", display: isOpen ? "block" : "none" }
        })}
      >
        {isLoading ? (
          <li style={{ padding: "5px" }}>
            <div className="d-flex align-items-center">
              <span>Loading...</span>
              <div className="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
          </li>
        ) : (
          listItems.map((item, index) => (
            <li
              key={`${item.value}${index}`}
              style={{
                backgroundColor: highlightedIndex === index ? "#bde4ff" : undefined
              }}
              {...getItemProps({ item, index })}
              {...(item.disabled ? { onClick: undefined, onMouseMove: undefined } : {})}
            >
              {item.label}
            </li>
          ))
        )}
      </ul>
    </div>
  );
};
