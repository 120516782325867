import React, { useMemo, useRef, useCallback } from "react";
import { Accordion, Card, CardBody, CardHeader } from "react-bootstrap";
import moment from "moment";

import { useAccessControl } from "../../app/access-control-context";
import { AccessControlGate } from "../../app/access-control-gate";
import { Confirm, ConfirmHandle } from "../confirm/confirm";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import * as Permissions from "../../data/permissions";
import { format24HourTimeString } from "../forms/form-helpers";
import {
  useCenterOperatingHours,
  OperatingHours,
  operatingHoursSorter,
  CenterOperatingHours
} from "../../hooks/use-center";
import { useReferenceData } from "../../hooks/use-reference-data";
import { useEditCenterSeasonalHoursModal } from "./edit-center-seasonal-hours";
import { SimpleTable, SimpleTableHeader } from "../table";
import { IconButton } from "../buttons/icon-button";

export interface CenterHoursProps {
  activeCenterId?: string;
}

export const requiredPermissionForEditing = Permissions.CENTER_MANAGE_OPERATIONAL_SCHEDULE;

export const CenterSeasonalHours: React.FC<CenterHoursProps> = ({ activeCenterId }) => {
  const { hasPermission } = useAccessControl();
  const { operatingHours, isLoading, operatingHoursWeeklyDeleteMutator } = useCenterOperatingHours(activeCenterId);
  const { referenceData, weekdayLookup } = useReferenceData();

  const confirmDeleteSeasonalHoursModal = useRef<ConfirmHandle>(null);

  const seasonalTransform = (hours: OperatingHours[]) => {
    // Sorting the OperatingHours array by date (for the accordion header)
    const sortedByDate = hours?.sort((a, b) => {
      return moment(a.seasonStart).diff(b.seasonStart);
    });
    const transformedData: Map<string, OperatingHours[]> = new Map();

    for (let i = 0; i < sortedByDate?.length; i++) {
      const key = `${moment(sortedByDate[i].seasonStart).format("M/D/YYYY")} - ${moment(
        sortedByDate[i].seasonEnd
      ).format("M/D/YYYY")}`;

      const previousKeyValue = transformedData.get(key);
      if (!previousKeyValue) {
        transformedData.set(key, [sortedByDate[i]]);
      } else {
        transformedData.set(key, [...previousKeyValue, sortedByDate[i]]);
      }
    }
    // Sorting each calendarGroups array by day (for the accordion body)
    transformedData.forEach(value => value.sort(operatingHoursSorter));
    return Array.from(transformedData);
  };

  const seasonalHours = useMemo(
    () => seasonalTransform(operatingHours?.seasonal as OperatingHours[]),
    [operatingHours, activeCenterId]
  );

  const seasonalTableHeaders: SimpleTableHeader<OperatingHours>[] = [
    {
      renderKey: "day",
      name: "Day",
      render: item => `${weekdayLookup[item.weekday]}s`,
      tableDataCellProps: {
        isPrimary: true
      }
    },
    {
      renderKey: "open",
      name: "Open",
      render: item => {
        if (!item.closeTime) {
          return <em>Closed</em>;
        } else {
          return format24HourTimeString(item.openTime);
        }
      }
    },
    {
      renderKey: "close",
      name: "Close",
      render: item => {
        if (item.closeTime) {
          return format24HourTimeString(item.closeTime);
        } else {
          return <></>;
        }
      }
    }
  ];

  const { renderSeasonalHoursModal, showEditSeasonalHoursModal } = useEditCenterSeasonalHoursModal();

  const onDeleteSeasonalHours = useCallback((deleteHours: OperatingHours[]) => {
    deleteHours &&
      confirmDeleteSeasonalHoursModal.current &&
      confirmDeleteSeasonalHoursModal.current.confirm(() => {
        operatingHoursWeeklyDeleteMutator.mutate(deleteHours);
      });
  }, []);

  const onAddHours = () => {
    showEditSeasonalHoursModal([], false);
  };

  if (!activeCenterId || !referenceData || !seasonalHours) {
    return null; // delay render until we have these
  }

  return (
    <Card className="h-100">
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between">
          <h2>Seasonal / Temporary Hours</h2>
          <AccessControlGate required={requiredPermissionForEditing}>
            <button className="btn btn-primary" onClick={onAddHours}>
              Add Seasonal Hours
            </button>

            {renderSeasonalHoursModal()}

            <Confirm
              ref={confirmDeleteSeasonalHoursModal}
              title="Confirm"
              message="Would you like to delete the seasonal hours for this range of dates?"
            />
          </AccessControlGate>
        </div>
      </CardHeader>
      <CardBody>
        <LoadingOverlay loading={isLoading || operatingHoursWeeklyDeleteMutator.isLoading}>
          <Accordion>
            {seasonalHours.map(item => {
              const [range, seasonalHours] = item;
              const displayHours = constructDisplayHours(seasonalHours, operatingHours);
              return (
                <Accordion.Item eventKey={range} key={range}>
                  <Accordion.Header>{range}</Accordion.Header>
                  <Accordion.Body>
                    <SimpleTable
                      tableCollapseScreenSize="lg"
                      tableHasIcons
                      headers={seasonalTableHeaders}
                      data={displayHours}
                      uniqueIdKey="weekday"
                    />
                    {hasPermission && hasPermission(requiredPermissionForEditing) && (
                      <div className="col text-end">
                        <IconButton
                          title="Edit"
                          icon="edit"
                          enableTooltip
                          className="btn btn-table"
                          onClick={() => showEditSeasonalHoursModal(seasonalHours, true)}
                        />
                        <IconButton
                          title="Delete Date Range"
                          icon="trash"
                          enableTooltip
                          className="btn btn-table btn-danger"
                          onClick={() => onDeleteSeasonalHours(seasonalHours)}
                        />
                      </div>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </LoadingOverlay>
      </CardBody>
    </Card>
  );
};

export const constructDisplayHours = (
  seasonalHours: OperatingHours[] | undefined,
  operatingHours: CenterOperatingHours | undefined
) => {
  if (!seasonalHours || !operatingHours || !operatingHours.default || seasonalHours.length === 0) {
    return [] as OperatingHours[];
  } else if (seasonalHours.length === 1) {
    return seasonalHours;
  }

  const results = new Map();
  seasonalHours.forEach(seasonalH => results.set(seasonalH.weekday, seasonalH));

  const earliestWeekday = seasonalHours[0].weekday;
  const latestWeekday = seasonalHours[seasonalHours.length - 1].weekday;

  for (let index = earliestWeekday + 1; index < latestWeekday; index++) {
    if (!results.has(index)) {
      const value = operatingHours.default.find(standardH => standardH.weekday === index);
      if (value) {
        results.set(index, value);
      }
    }
  }

  return Array.from<OperatingHours>(results.values()).sort(operatingHoursSorter);
};
