import { useCallback } from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";

interface UseNavigationConfirmationProps {
  confirmNavigation: boolean;
  message?: string;
}

export const useNavigationConfirmation = ({ confirmNavigation }: UseNavigationConfirmationProps) => {
  useBeforeUnload(
    useCallback(
      (event: BeforeUnloadEvent) => {
        if (confirmNavigation) {
          event.preventDefault();
          event.returnValue = true;
        }
      },
      [confirmNavigation]
    ),
    { capture: true }
  );

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      confirmNavigation &&
      (currentLocation.pathname !== nextLocation.pathname ||
        (currentLocation.pathname === nextLocation.pathname && currentLocation.search !== nextLocation.search))
    );
  });
  return {
    blocker
  };
};
