import { useEffect, useMemo, useRef, useState } from "react";

import type { CenterStaffId } from "../../hooks/use-center";
import { useStaffMemberCenters } from "../../hooks/use-staff";
import type { StaffMember } from "../../hooks/use-user";
import { useUserStaffIds } from "../../hooks/use-user-staff-ids";
import { Confirm, type ConfirmHandle } from "../confirm/confirm";
import { SelectField } from "../forms/select-field";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import { LegacyUserSearch } from "../search-combobox/legacy-user-search";
import { useModal } from "../modal/modal";
import { SortableTable, SortableTableHeader } from "../table";
import { CenterStaff } from "../../hooks/use-legacy-staff-id-list";

export interface UseLegacyLcosCheckerModalProps {
  activeCenterId: string;
  onSuccessfulSave?: () => void;
}

interface LegacyLcosTableRow extends CenterStaffId {
  useLegacyId?: string;
}

export const useLegacyLcosCheckerModal = ({ activeCenterId, onSuccessfulSave }: UseLegacyLcosCheckerModalProps) => {
  const [staff, setStaff] = useState<StaffMember>();
  const { assignedStaffIdsLookup, updateStaffIdsMutator, isLoading } = useUserStaffIds(staff?.id);
  const { currentCenters } = useStaffMemberCenters(activeCenterId, staff?.id);
  const confirmSaveModal = useRef<ConfirmHandle>(null);

  const { show, hide, renderModal } = useModal();

  const centerStaffIdList = useMemo(
    () =>
      [...(currentCenters ?? [])]
        .sort((a, b) => {
          if (
            !assignedStaffIdsLookup ||
            !!assignedStaffIdsLookup[a.id]?.staffId === !!assignedStaffIdsLookup[b.id]?.staffId
          ) {
            return a.name.localeCompare(b.name);
          }
          if (!assignedStaffIdsLookup[a.id]?.staffId) {
            return -1;
          }
          return 1;
        })
        .map(({ id, name }) => ({
          centerId: id,
          centerName: name,
          staffId: assignedStaffIdsLookup?.[id]?.staffId,
          employeeNumber: assignedStaffIdsLookup?.[id]?.employeeNumber ?? null
        })),
    [currentCenters, assignedStaffIdsLookup]
  );

  const [buffer, setBuffer] = useState<LegacyLcosTableRow[]>(centerStaffIdList);
  const enableSave = useMemo(() => {
    const updates = buffer.filter(item => item.useLegacyId);
    const hasCompleteUpdates = updates.every(
      item => item.useLegacyId === "no" || (item.useLegacyId === "yes" && item.staffId)
    );
    return updates.length && hasCompleteUpdates;
  }, [buffer]);

  useEffect(() => {
    setBuffer(centerStaffIdList);
  }, [centerStaffIdList]);

  // istanbul ignore next
  const handleExistInLcosChange = (index: number, value: string) => {
    setBuffer(oldBuffer => {
      const newBuffer = [...oldBuffer];
      newBuffer[index].useLegacyId = value;
      newBuffer[index].staffId = undefined;
      return newBuffer;
    });
  };

  // istanbul ignore next
  const handleLegacyIdSelect = (index: number, staff?: CenterStaff) => {
    setBuffer(oldBuffer => {
      const newBuffer = [...oldBuffer];
      newBuffer[index].staffId = staff?.staffId;
      newBuffer[index].employeeNumber = staff?.employeeNumber ?? null;
      return newBuffer;
    });
  };

  const centersHeaders: SortableTableHeader<LegacyLcosTableRow>[] = [
    {
      key: "centerName",
      name: "Center",
      tableDataCellProps: {
        isPrimary: true
      }
    },
    {
      renderKey: "existsInLcos",
      name: "Exists in LCOS 1.0",
      tableDataCellProps: {
        style: { width: "220px" }
      },
      render: ({ staffId, useLegacyId }, rowIndex) =>
        staffId && !useLegacyId ? (
          ""
        ) : (
          <SelectField
            label="Exists In LCOS 1.0?"
            value={useLegacyId ?? ""}
            onChange={({ target: { value } }) => {
              handleExistInLcosChange(rowIndex, value);
            }}
          >
            <option value="">Select an Option</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </SelectField>
        )
    },
    {
      renderKey: "staffId",
      name: "LCOS 1.0 Staff ID",
      tableDataCellProps: {
        style: { minWidth: "350px" }
      },
      render: ({ staffId, useLegacyId, centerId }, rowIndex) => {
        if (staffId && !useLegacyId) {
          return staffId;
        }
        return (
          <div style={{ display: useLegacyId === "yes" ? "block" : "none" }}>
            <LegacyUserSearch
              selected={staffId ?? ""}
              onSelect={item => handleLegacyIdSelect(rowIndex, item)}
              centerId={centerId}
            />
          </div>
        );
      }
    }
  ];

  const handleSave = () => {
    confirmSaveModal.current?.confirm(() => {
      updateStaffIdsMutator.mutate(
        {
          staffIds: buffer
            .filter(item => item.useLegacyId)
            .map(item => ({
              centerId: item.centerId,
              existsInLegacy: item.useLegacyId === "yes",
              staffId: item.staffId,
              employeeNumber: item.employeeNumber
            }))
        },
        {
          onSuccess: () => {
            hideLegacyLcosCheckerModal();
            onSuccessfulSave?.();
          }
        }
      );
    });
  };

  const hideLegacyLcosCheckerModal = () => {
    setStaff(undefined);
    hide();
  };

  const showLegacyLcosCheckerModal = (staffMember: StaffMember) => {
    setStaff(staffMember);
    show();
  };

  const renderLegacyLcosCheckerModal = () =>
    renderModal({
      size: "lg",
      fullscreen: "md-down",
      title: `Does ${staff?.displayName} Exist in LCOS 1.0?`,
      body: (
        <LoadingOverlay loading={isLoading || updateStaffIdsMutator.isLoading}>
          <div className="legacy-lcos-checker-container">
            <div className="body" style={{ minHeight: "200px" }}>
              <SortableTable
                size="lg"
                tableCollapseScreenSize="lg"
                headers={centersHeaders}
                data={buffer}
                paginationOptions={{ itemsPerPage: 10 }}
                uniqueIdKey="centerId"
              />
              {/* reserve some space for the dropdowns to appear */}
              <div style={{ height: "60px" }} />
            </div>
            <Confirm
              ref={confirmSaveModal}
              title="Are you sure?"
              message="Once you save the Staff ID you will not be able to change it."
            />
          </div>
        </LoadingOverlay>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideLegacyLcosCheckerModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleSave} disabled={!enableSave}>
            Save
          </button>
        </>
      )
    });

  return {
    renderLegacyLcosCheckerModal,
    showLegacyLcosCheckerModal,
    hideLegacyLcosCheckerModal
  };
};
