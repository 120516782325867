export const STAFF_EDIT = "staff.edit";
export const STAFF_VIEW = "staff.view";
export const STAFF_CREATE = "staff.create";
export const STAFF_DELETE = "staff.delete";
export const STAFF_EDIT_PERMISSIONS = "staff.edit_permissions";

export const CENTER_EDIT_NOTES = "center.edit_notes";
export const CENTER_EDIT = "center.edit";
export const CENTER_CREATE = "center.create";
export const CENTER_DELETE = "center.delete";
export const CENTER_MANAGE_OPERATIONAL_SCHEDULE = "center.manage_operational_schedule";
export const CENTER_VIEW_OPERATIONAL_SCHEDULE = "center.view_operational_schedule";

export const STUDENT_MANAGEMENT_VIEW = "student_management.view";
export const STUDENT_FAMILY_VIEW = "student_family.view";
export const STUDENT_FAMILY_CREATE_EDIT = "student_family.create_edit";

export const INQUIRY_EDIT = "inquiry.edit";
export const INQUIRY_VIEW = "inquiry.view";

export const SCHEDULE_VIEW = "schedule.view";
export const SCHEDULE_EDIT = "schedule.edit";

const allPermissions = [
  STAFF_EDIT,
  STAFF_CREATE,
  STAFF_CREATE,
  STAFF_DELETE,
  STAFF_EDIT_PERMISSIONS,

  CENTER_EDIT_NOTES,
  CENTER_EDIT,
  CENTER_CREATE,
  CENTER_DELETE,
  CENTER_MANAGE_OPERATIONAL_SCHEDULE,
  CENTER_VIEW_OPERATIONAL_SCHEDULE,

  STUDENT_MANAGEMENT_VIEW,
  STUDENT_FAMILY_VIEW,
  STUDENT_FAMILY_CREATE_EDIT,

  INQUIRY_EDIT,
  INQUIRY_VIEW,

  SCHEDULE_VIEW,
  SCHEDULE_EDIT
];

export type Permission = (typeof allPermissions)[number];

export const CORPORATE_USER_PERMISSIONS: Record<Permission, boolean> = {
  [STAFF_VIEW]: true,
  [STUDENT_MANAGEMENT_VIEW]: true,
  [STUDENT_FAMILY_VIEW]: true,
  [INQUIRY_VIEW]: true,
  [CENTER_VIEW_OPERATIONAL_SCHEDULE]: true,
  [SCHEDULE_VIEW]: true
};
