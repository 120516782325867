import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { useCallback, useEffect, type FC } from "react";
import { useForm } from "react-hook-form";

import { useAccessControl } from "../../../../app/access-control-context";
import {
  defaultInquiryFormData,
  useCreateOrUpdateInquiry,
  useInquiry,
  useInquiryConstants,
  type InquiryFormData
} from "../../../../hooks/use-inquiries";
import type { DirtyProps } from "../../../../screens/inquiry/inquiry-management";
import {
  discountTypeCodes,
  InquiryFormLeftPane,
  InquiryFormLeftPaneProps,
  inquirySchema
} from "../../../inquiry-form/inquiry-form-left-pane";
import { LoadingButton } from "../../../loading-button/loading-button";
import { LoadingOverlay } from "../../../loading-overlay/loading-overlay";
import { INQUIRY_EDIT } from "../../../../data/permissions";

export const InquiryDetail: FC<{
  inquiryId: string;
  setDirtyProps: (dirtyProps: DirtyProps) => void;
  confirmLeaveForm?: (cb: () => void) => void;
}> = ({ inquiryId, setDirtyProps, confirmLeaveForm }) => {
  const inquiryQuery = useInquiry(inquiryId);

  const createOrUpdateInquiryMutator = useCreateOrUpdateInquiry();
  const inquiryConstantsQuery = useInquiryConstants();
  const { hasPermission } = useAccessControl();

  const getDefaultValues = useDefaultValuesFactory();

  const inquiryForm = useForm({
    values: inquiryQuery.data ?? getDefaultValues(),
    mode: "onTouched",
    resolver: zodResolver(inquirySchema)
  });

  const resetForm = useCallback(() => {
    inquiryForm.reset(inquiryQuery.data ?? getDefaultValues());

    const discountType = inquiryConstantsQuery.data?.discountTypes?.find(
      type => type.value === inquiryQuery.data?.aeFee?.discountTypeId
    )?.name;

    const discountTypeCode = discountTypeCodes?.get(discountType || "");
    inquiryForm.setValue("aeFee.discountType", discountTypeCode ?? "$");
  }, [
    inquiryForm.reset,
    inquiryForm.setValue,
    inquiryQuery.data,
    inquiryConstantsQuery.data?.discountTypes,
    getDefaultValues
  ]);

  // It's an antipattern to use useEffect to keep this value synced, but the alternative is lifting ALL the form stuff up to the parent... no thanks
  // Also, there's an issue with formState.isDirty staying true even when there are no dirty fields, so this is a workaround.
  // I think it's because of an issue with our default values; hopefully nailing down our types better will shed some light eventually
  const isFormDirty = Boolean(Object.keys(inquiryForm.formState.dirtyFields).length);
  useEffect(() => {
    setDirtyProps(isFormDirty ? { isFormDirty: true, resetForm } : { isFormDirty: false, resetForm: null });
  }, [setDirtyProps, isFormDirty, resetForm]);

  useEffect(() => {
    resetForm();
  }, [inquiryQuery.data, inquiryConstantsQuery.data?.discountTypes]);

  const updateInquiry = inquiryForm.handleSubmit(formValues => {
    createOrUpdateInquiryMutator.mutate(
      { inquiryId: formValues.id, formData: formValues },
      {
        onSuccess: () => {
          inquiryQuery.refetch();
        }
      }
    );
  });

  const leftPaneProps: InquiryFormLeftPaneProps = {
    onSelectInquirer: () => undefined,
    onSelectStudent: () => undefined,
    showContactFamilies: () => undefined,
    inquiryForm,
    onCreateNewContact: () => undefined,
    handleShowStudent: () => undefined,
    handleShowInquirer: () => undefined,
    isDetailsTab: true,
    detailsTabHeader: (
      <div className="d-flex align-items-center justify-content-end">
        {hasPermission && hasPermission(INQUIRY_EDIT) && (
          <div className="mb-4">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              disabled={createOrUpdateInquiryMutator.isLoading}
              onClick={() => {
                if (confirmLeaveForm) {
                  confirmLeaveForm(resetForm);
                } else {
                  resetForm();
                }
              }}
            >
              Cancel
            </button>
            <LoadingButton
              type="submit"
              className="btn btn-primary btn-sm ms-2"
              loadingMessage="Saving..."
              onClick={updateInquiry}
              isLoading={createOrUpdateInquiryMutator.isLoading}
            >
              Save
            </LoadingButton>
          </div>
        )}
      </div>
    )
  };

  return (
    <LoadingOverlay loading={inquiryQuery.isLoading || inquiryConstantsQuery.isLoading}>
      <InquiryFormLeftPane {...leftPaneProps} readOnly={!hasPermission?.(INQUIRY_EDIT)} />
    </LoadingOverlay>
  );
};

const useDefaultValuesFactory = () => {
  const { profile } = useAccessControl();

  return useCallback(() => {
    const inquiryStart = moment();

    return {
      ...defaultInquiryFormData,
      inquiryDate: inquiryStart.format("YYYY-MM-DD"),
      inquiryTimeReceived: inquiryStart.format("HH:mm"),
      notes: "",
      takenBy: profile
    } as InquiryFormData;
  }, [profile]);
};
