import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";

import { DuplicateContactError, type FamilyContactDetailWithId } from "../../hooks/use-families";
import { formatPhoneNumber, isValidPhoneNumber } from "../forms/form-helpers";
import { useModal } from "../modal/modal";

export const useDuplicateContactModal = ({
  onSubmit,
  isSubmitDisabled = false
}: {
  onSubmit: (contact: FamilyContactDetailWithId) => void;
  isSubmitDisabled?: boolean;
}) => {
  const { show, hide, renderModal } = useModal({ disableToast: true });
  const [duplicateContacts, setDuplicateContacts] = useState<FamilyContactDetailWithId[] | null>(null);

  const { register, watch, handleSubmit } = useForm({ defaultValues: { duplicateContactId: "" } });

  return {
    onSubmitError: (error: unknown) => {
      if (error instanceof DuplicateContactError) {
        setDuplicateContacts(error.duplicateContacts);
        show();
      }
    },
    renderDuplicateContactModal: () =>
      renderModal({
        title: "Duplicate Contact Found",
        body: (
          <fieldset>
            <p>
              The <strong>Email</strong> or <strong>Phone</strong> you entered matches an existing contact.
            </p>
            <h6>Which contact would you like to use?</h6>
            <table className="table table-hover table-cursor-normal table-collapse-lg table-has-icons vertical-top">
              <tbody>
                {duplicateContacts?.map(contact => {
                  const phoneNumbers = [
                    { label: "H", value: contact.homePhone },
                    { label: "B", value: contact.businessPhone },
                    { label: "M", value: contact.personalPhone }
                  ]
                    .filter(({ value }) => value && isValidPhoneNumber(value))
                    .map(({ label, value }) => `${label}: ${formatPhoneNumber(value)}`);
                  const address = formatAddress(contact);
                  return (
                    <tr key={`duplicate-contact-row-${contact.id}`}>
                      <td className="text-nowrap">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`existing-contact-radio-id-${contact.id}`}
                            value={contact.id}
                            {...register("duplicateContactId")}
                          />
                          <label className="form-check-label" htmlFor={`existing-contact-radio-id-${contact.id}`}>
                            <strong>Existing Contact</strong>
                          </label>
                        </div>
                      </td>
                      <td className="td-primary">
                        <strong>{contact.displayName}</strong>
                        {contact.email && (
                          <>
                            <br />
                            {contact.email}
                          </>
                        )}
                        {phoneNumbers.map(pn => (
                          <Fragment key={pn}>
                            <br />
                            {pn}
                          </Fragment>
                        ))}
                        {address && (
                          <>
                            <br />
                            {address}
                          </>
                        )}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
                <tr>
                  <td className="text-nowrap">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        id="new-contact-radio"
                        value="new"
                        {...register("duplicateContactId")}
                      />
                      <label className="form-check-label" htmlFor="new-contact-radio">
                        <strong>New Contact</strong>
                      </label>
                    </div>
                  </td>
                  <td className="td-primary">
                    Return to the page and update the <strong>Email</strong> and <strong>Phone</strong> entered. These
                    must be unique to save a new contact.
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        ),
        footer: (
          <>
            <button className="btn btn-secondary" onClick={hide}>
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={handleSubmit(({ duplicateContactId }) => {
                const contact = duplicateContacts?.find(({ id }) => `${id}` === `${duplicateContactId}`);
                if (contact) {
                  onSubmit(contact);
                }
                hide();
              })}
              disabled={!watch("duplicateContactId") || isSubmitDisabled}
            >
              Continue
            </button>
          </>
        )
      })
  };
};

const formatAddress = ({
  addressLine1,
  addressLine2,
  city,
  state,
  zipcode
}: Pick<FamilyContactDetailWithId, "addressLine1" | "addressLine2" | "city" | "state" | "zipcode">) => {
  let address = "";
  const lines = [addressLine1, addressLine2].filter(Boolean).join(" ");
  if (lines) {
    address += `${lines}, `;
  }
  if (city) {
    address += `${city}, `;
  }
  if (state) {
    address += `${state} `;
  }
  if (zipcode) {
    address += `${zipcode}`;
  }
  return address.trim();
};
