import React, { useEffect, useId, useState } from "react";
import slugify from "slugify";

export interface FilterDropdownOption {
  label: string;
  value: string;
}

export interface FilterDropdownItemState extends FilterDropdownOption {
  checked: boolean;
}

export interface FilterDropdownProps {
  title: string;
  options: FilterDropdownOption[];
  onChange?: (values: string[]) => void;
}

export const FilterDropdown: React.FC<FilterDropdownProps> = ({ title, options, onChange }) => {
  const [optionsState, setOptionsState] = useState<FilterDropdownItemState[]>(() => {
    return options.map(opt => ({ ...opt, checked: true }));
  });

  useEffect(() => {
    setOptionsState(options.map(opt => ({ ...opt, checked: true })));
  }, [options]);

  const id = useId();

  useEffect(() => {
    onChange && onChange(optionsState.filter(os => os.checked).map(os => os.value));
  }, [optionsState]);

  const onCheckboxChange = (value: string) => () => {
    setOptionsState(prev => {
      return prev.map(os => {
        if (os.value === value) {
          return { ...os, checked: !os.checked };
        } else {
          return os;
        }
      });
    });
  };

  const onBatchCheckboxChange = (value: boolean) => () => {
    setOptionsState(prev => {
      return prev.map(os => {
        return { ...os, checked: value };
      });
    });
  };

  return (
    <div className="dropdown">
      <button
        className="btn border dropdown-toggle"
        id={id}
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        aria-expanded="false"
      >
        {title}
      </button>
      <ul className="dropdown-menu" aria-labelledby={id}>
        <li className="px-1">
          <button className="btn btn-link" onClick={onBatchCheckboxChange(true)}>
            Select All
          </button>
          <button className="btn btn-link" onClick={onBatchCheckboxChange(false)}>
            Deselect All
          </button>
        </li>
        {optionsState.map(os => {
          return (
            <li key={`${id}-${os.value}`} data-testid={`${slugify(title)}-listitem-${os.value}`}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={os.value}
                  id={`${id}-${os.value}`}
                  checked={os.checked}
                  onChange={onCheckboxChange(os.value)}
                />
                <label className="form-check-label" htmlFor={`${id}-${os.value}`}>
                  {os.label}
                </label>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
