import { type FC } from "react";
import moment from "moment";
import { InquiryAppointment } from "../../../../hooks/use-inquiries";

interface AppointmentDateTimeProps {
  appointment: InquiryAppointment;
}

export const AppointmentDateTime: FC<AppointmentDateTimeProps> = ({ appointment }) => {
  const { scheduledDateTime, endDateTime } = appointment;
  const dateMoment = moment(scheduledDateTime);
  const date = dateMoment.format("M/DD/YYYY");
  const dayOfWeek = dateMoment.format("dddd");
  const startTime = dateMoment.format("h:mm a");
  const endTime = moment(endDateTime).format("h:mm a");
  return (
    <>
      {date} ({dayOfWeek})<br />
      {startTime} - {endTime}
    </>
  );
};
