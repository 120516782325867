import { useEffect } from "react";
import { useDebounceValue } from "usehooks-ts";

export const useDebounce = <T>(value: T, timeout: number) => {
  const [debouncedValue, setDebouncedValue] = useDebounceValue(value, timeout);

  useEffect(() => {
    setDebouncedValue(value);
  }, [value]);

  return debouncedValue;
};
