import { useMemo, type FC } from "react";

import { useAccessControl } from "../../../app/access-control-context";
import { AccessControlGate } from "../../../app/access-control-gate";
import * as Permissions from "../../../data/permissions";
import { useReferenceData } from "../../../hooks/use-reference-data";
import {
  StaffMemberCenterPayload,
  useStaffEditabilityCheck,
  useStaffMemberCenters,
  useStaffMemberPermissions
} from "../../../hooks/use-staff";
import type { StaffMember } from "../../../hooks/use-user";
import { useUserStaffIds } from "../../../hooks/use-user-staff-ids";
import { FormButtonRow } from "../../forms/form-row";
import { useLegacyLcosCheckerModal } from "../../legacy-lcos-checker/legacy-lcos-checker";
import { useAddStaffToCenterModal } from "./add-staff-to-centers";
import { SimpleTableHeader, SortableTable } from "../../table";

export interface CenterAccessProps {
  staff?: StaffMember;
}

export const CenterAccess: FC<CenterAccessProps> = ({ staff }) => {
  const { activeCenterId, profile, hasPermission, hasFeatureAccess } = useAccessControl();
  const { referenceData, isLoading: isReferenceDataLoading } = useReferenceData();
  const { staffMemberPermissions, isLoading: isLoadingStaffMemberPermissions } = useStaffMemberPermissions(
    activeCenterId,
    staff?.id
  );

  const {
    availableCenters,
    currentCenters,
    isLoading: isStaffMemberCentersLoading
  } = useStaffMemberCenters(activeCenterId, staff?.id);

  const { permissionsDisabled, isEditingSelf } = useStaffEditabilityCheck(staff?.id);

  const hasLcosLegacyFeatureAccess = useMemo(() => {
    return !!hasFeatureAccess && hasFeatureAccess("staff_id");
  }, [hasFeatureAccess]);

  const { assignedStaffIdsLookup, isLoading: isUserStaffIdsLoading } = useUserStaffIds(
    hasLcosLegacyFeatureAccess ? staff?.id : undefined
  );

  const { renderAddStaffToCenterModal, showAddStaffToCenterModal } = useAddStaffToCenterModal({
    staffMemberId: staff?.id
  });

  const { renderLegacyLcosCheckerModal, showLegacyLcosCheckerModal } = useLegacyLcosCheckerModal({ activeCenterId });

  const data = useMemo(
    () => currentCenters?.map(center => ({ ...center, staffId: assignedStaffIdsLookup?.[center.id]?.staffId })),
    [currentCenters, assignedStaffIdsLookup]
  );

  const centersHeaders = useMemo<SimpleTableHeader<StaffMemberCenterPayload>[]>(() => {
    const headers: SimpleTableHeader<StaffMemberCenterPayload>[] = [
      {
        key: "name",
        name: "Center"
      }
    ];

    if (hasLcosLegacyFeatureAccess) {
      headers.push({
        key: "staffId",
        name: "LCOS 1.0 Staff ID"
      } as unknown as SimpleTableHeader<StaffMemberCenterPayload>);
    }

    return headers;
  }, [hasLcosLegacyFeatureAccess]);

  if (
    !currentCenters ||
    !availableCenters ||
    !referenceData ||
    !staffMemberPermissions ||
    !profile ||
    !hasPermission ||
    !data ||
    !staff
  ) {
    return null;
  }

  return (
    <>
      <h3 className="card-subheader">
        Centers
        <AccessControlGate required={Permissions.STAFF_EDIT}>
          <button
            className="btn btn-primary btn-sm ms-2"
            onClick={() => {
              showAddStaffToCenterModal();
            }}
            disabled={isEditingSelf || permissionsDisabled}
          >
            <span className="fas fa-plus" aria-hidden="true"></span>
            <span className="sr-only">Add Center</span>
          </button>
        </AccessControlGate>
      </h3>

      <SortableTable
        tableCollapseScreenSize="sm"
        headers={centersHeaders}
        paginationOptions={{ itemsPerPage: 10 }}
        activeItem={data.find(c => c.id === activeCenterId)}
        data={data}
        isLoading={
          isStaffMemberCentersLoading ||
          isReferenceDataLoading ||
          isLoadingStaffMemberPermissions ||
          (isUserStaffIdsLoading && hasLcosLegacyFeatureAccess)
        }
        uniqueIdKey="id"
      />

      <FormButtonRow>
        {hasLcosLegacyFeatureAccess && (
          <AccessControlGate required={Permissions.STAFF_CREATE}>
            <button
              className="btn btn-primary"
              onClick={() => showLegacyLcosCheckerModal(staff)}
              disabled={isEditingSelf || permissionsDisabled}
            >
              Link to LCOS 1.0 user
            </button>
          </AccessControlGate>
        )}
      </FormButtonRow>

      <>{renderAddStaffToCenterModal()}</>

      <>{renderLegacyLcosCheckerModal()}</>
    </>
  );
};
