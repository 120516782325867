import type { FC } from "react";

import { useFamilyConstants } from "../../../../hooks/use-families";
import type { DashboardInquiry } from "../../../../hooks/use-inquiries";
import { SimpleTable, type SimpleTableHeader } from "../../../table";
import { useAccessControl } from "../../../../app/use-access-control";
import { INQUIRY_EDIT, STUDENT_FAMILY_CREATE_EDIT, STUDENT_FAMILY_VIEW } from "../../../../data/permissions";

interface StudentDetailsTableProps {
  inquiryDashboardInfo: DashboardInquiry[];
  onClickStudent: (id: number) => void;
}

export const StudentDetailsTable: FC<StudentDetailsTableProps> = ({ inquiryDashboardInfo, onClickStudent }) => {
  const { genderLookup } = useFamilyConstants();
  const { hasPermission } = useAccessControl();
  const headers: SimpleTableHeader<DashboardInquiry>[] = [
    {
      renderKey: "studentName",
      name: "Student Name",
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: (info: DashboardInquiry) => <a href="#">{info.studentName}</a>,
      tableDataCellProps: {
        isPrimary: true
      }
    },
    {
      renderKey: "grade",
      name: "Grade",
      render: info => info.grade?.name
    },
    {
      key: "service",
      name: "Service"
    },
    {
      renderKey: "gender",
      name: "Gender",
      render: info => genderLookup[info.gender]
    },
    {
      key: "school",
      name: "Current School"
    }
  ];

  return (
    <SimpleTable
      tableCollapseScreenSize="sm"
      tableHover
      tableHasIcons
      data={inquiryDashboardInfo}
      headers={headers}
      onRowClick={
        hasPermission?.([STUDENT_FAMILY_CREATE_EDIT, STUDENT_FAMILY_VIEW, INQUIRY_EDIT])
          ? row => onClickStudent(row.studentId as number)
          : undefined
      }
      uniqueIdKey="studentId"
    />
  );
};
