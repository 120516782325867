/*
Service API handling for the LCOS Backend server
*/
export const lcosEndpoint = process.env.REACT_APP_API_HOST;

export interface ApiValidationError {
  detail: [
    {
      loc: string[];
      msg: string;
      type: string;
    }
  ];
}

export const getAdminLogin = async (accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options: RequestInit = {
    headers: headers,
    credentials: "include"
  };

  const response = await fetch(lcosEndpoint + "/api/admin-login", options);
  if (response.ok) {
    const results = (await response.json()) as { redirect: string };
    return results.redirect;
  } else {
    return Promise.reject(Error(`admin redirect failed`));
  }
};

export const getHeaders = (accessToken: string) => {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("X-Center-Id", window.localStorage.getItem("lcos-default-center-id") || "");

  return headers;
};

//TODO: move error handling to use query global onError handler
export const callLcosService = async <Type>(
  accessToken: string,
  endpoint: string,
  method: "GET" | "PUT" | "POST" | "DELETE" = "GET",
  payload: unknown = undefined
) => {
  const headers = getHeaders(accessToken);

  const options: RequestInit = { method, headers };
  if (payload) {
    options.body = JSON.stringify(payload);
  }

  // going to leave this here a while so I can get a feel for how often
  // we actually hitting the API (vs cache usage)
  // eslint-disable-next-line no-console
  // console.debug(`endpoint call: ${method} ${endpoint}`, !!payload ? payload : "<no payload>");

  const response = await fetch(lcosEndpoint + endpoint, options);
  if (response.ok) {
    const body = await response.text();
    if (body && body.length) {
      return JSON.parse(body) as Type;
    } else {
      return {} as Type;
    }
  } else {
    if (response.status === 403) {
      const responseData = await response.json();
      if ("detail" in responseData) {
        return Promise.reject(Error(responseData.detail));
      }
    } else if (response.status >= 400 && response.status < 500 && response.status !== 404) {
      const responseData = await response.json();
      if ("detail" in responseData && typeof responseData.detail === "string") {
        return Promise.reject(Error(responseData.detail));
      }
      return Promise.reject(responseData);
    } else if (response.status === 500) {
      return Promise.reject(Error("Sorry, an unknown error occurred. Please try again later."));
    }

    return Promise.reject(Error(`non-OK api response: ${response.status} - ${response.statusText}`));
  }
};
