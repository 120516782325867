import { useMemo } from "react";

import { useReferenceData } from "../../../hooks/use-reference-data";
import { ExtendableSelectorProps, Selector } from "./selector";

export interface PositionSelectorProps extends ExtendableSelectorProps {
  includeCenterOwner?: boolean;
}

export const PositionSelector: React.FC<PositionSelectorProps> = ({ includeCenterOwner, ...selectorProps }) => {
  const { positions, isLoading } = useReferenceData();

  const positionSelectorOptions = useMemo(() => {
    if (positions) {
      const positionsCopy = [...positions];
      if (!includeCenterOwner) {
        positionsCopy.shift();
      }
      return positionsCopy.map(position => ({ id: position.value, name: position.name }));
    } else {
      return [];
    }
  }, [positions]);

  if (!positions || isLoading) {
    return null;
  }

  return (
    <Selector
      {...selectorProps}
      options={positionSelectorOptions}
      emptyOption={{ id: "", name: "Select a Position" }}
    />
  );
};
