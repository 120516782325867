import { useCallback, useId } from "react";

// this might seem like over specialization at first glance but
// we are in fact reusing this quite a few places

export interface CreateMailboxProps {
  value?: boolean;
  disabled?: boolean;
  onChangeValue?: (newvalue: boolean) => void;
}

export const CreateMailbox: React.FC<CreateMailboxProps> = ({ value, disabled = false, onChangeValue }) => {
  const checkboxId = useId();

  const onClickCreateMailbox: React.ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    onChangeValue && onChangeValue(e.target.checked);
  }, []);

  return (
    <div className="row standard-form-row">
      <div className="col col-auto">
        <label htmlFor={checkboxId} style={{ paddingLeft: "5px" }}>
          Grant HLC Email Account Access
        </label>
      </div>
      <div className="col">
        <input
          type="checkbox"
          id={checkboxId}
          checked={value}
          className="form-check-input"
          style={{ height: "20px", width: "20px", marginTop: "6px" }}
          disabled={disabled}
          onChange={onClickCreateMailbox}
        />
      </div>
    </div>
  );
};
