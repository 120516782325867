import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAccessToken } from "../use-access-token";
import { callLcosService } from "../../data/lcosServices";
import { FormHelpers } from "../../components/forms";
import { OperatingHours, CenterOperatingHours, OperatingHoursType, BrandStandardOperatingHours } from "./types";

export const isValidCenterHours = (input: OperatingHours, subset: OperatingHoursType = "default") => {
  const sharedProperties =
    FormHelpers.isValidRequiredString(input.weekday?.toString?.()) &&
    FormHelpers.isValidRequiredTimeString(input.openTime) &&
    FormHelpers.isValidRequiredTimeString(input.closeTime) &&
    FormHelpers.isValidTimeString(input.startApptTime) &&
    FormHelpers.isValidTimeString(input.endApptTime);

  const seasonalProperties =
    subset !== "seasonal" ||
    (FormHelpers.isValidRequiredDateString(input.seasonStart) &&
      FormHelpers.isValidRequiredDateString(input.seasonEnd));

  const validOpenClose = FormHelpers.parseToMoment(input.openTime).isBefore(FormHelpers.parseToMoment(input.closeTime));

  return sharedProperties && seasonalProperties && validOpenClose;
};

export const useCenterOperatingHoursValidator = FormHelpers.createValidatorHook<OperatingHours, OperatingHoursType>(
  isValidCenterHours
);

export const useCenterOperatingHours = (centerId: string | undefined, enabled = true) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const getCenterOperatingHoursData = async () => {
    return getCenterOperatingHoursList(await getToken(), centerId);
  };

  const getCenterBrandStandardHoursData = async () => {
    return getCenterBrandStandardHoursList(await getToken(), centerId);
  };

  const centerOperatingHoursQueryKey = ["centerOperatingHours", centerId];

  const brandStandardOperatingHoursQueryKey = ["brandStandardOperatingHours", centerId];

  const operatingHoursQuery = useQuery({
    queryKey: centerOperatingHoursQueryKey,
    queryFn: getCenterOperatingHoursData,
    enabled: !!centerId && enabled,
    select: (data: CenterOperatingHours) => {
      const { default: operatingHours, seasonal } = data;
      if (operatingHours.length < 7) {
        for (let weekday = 1; weekday <= 7; weekday++) {
          const index = weekday - 1;
          if (!operatingHours[index] || operatingHours[index].weekday != weekday) {
            operatingHours.splice(index, 0, {
              weekday,
              openTime: "",
              closeTime: "",
              startApptTime: "",
              endApptTime: "",
              seasonStart: "",
              seasonEnd: ""
            });
          }
        }
      }
      return {
        default: operatingHours,
        seasonal
      };
    }
  });

  const brandStandardHoursQuery = useQuery({
    queryKey: brandStandardOperatingHoursQueryKey,
    queryFn: getCenterBrandStandardHoursData,
    enabled: !!centerId && enabled
  });

  const operatingHoursEditMutator = useMutation({
    mutationFn: async (operatingHours: OperatingHours) => {
      const payload: OperatingHours = {
        ...operatingHours,
        openTime: FormHelpers.format24HourTimeStringForApi(operatingHours.openTime),
        closeTime: FormHelpers.format24HourTimeStringForApi(operatingHours.closeTime),
        startApptTime: FormHelpers.format24HourTimeStringForApi(operatingHours.openTime),
        endApptTime: FormHelpers.format24HourTimeStringForApi(operatingHours.closeTime)
      };
      return !operatingHours.id
        ? createCenterOperatingHours(await getToken(), centerId, payload)
        : updateCenterOperatingHours(await getToken(), centerId, payload);
    },

    onSuccess: newOperatingHours => {
      queryClient.setQueryData(centerOperatingHoursQueryKey, () => ({ ...newOperatingHours }));
    }
  });

  const deleteOperatingHoursMutator = useMutation({
    mutationFn: async (hoursId: number) => deleteCenterOperatingHours(await getToken(), centerId, hoursId),

    onSuccess: newOperatingHours => {
      queryClient.setQueryData(centerOperatingHoursQueryKey, () => ({ ...newOperatingHours }));
    }
  });

  const operatingHoursWeeklyEditMutator = useMutation({
    mutationFn: async (params: { operatingHours: OperatingHours[]; seasonalHours: boolean }) => {
      const payload: OperatingHours[] = params.operatingHours.map((hours: OperatingHours) => {
        return {
          ...hours,
          id: params.seasonalHours ? hours.id ?? 0 : undefined,
          openTime: FormHelpers.format24HourTimeStringForApi(hours.openTime),
          closeTime: FormHelpers.format24HourTimeStringForApi(hours.closeTime),
          startApptTime: FormHelpers.format24HourTimeStringForApi(hours.openTime),
          endApptTime: FormHelpers.format24HourTimeStringForApi(hours.closeTime)
        };
      });

      return updateWeeklyCenterOperatingHours(await getToken(), centerId, payload);
    },

    onSuccess: newOperatingHours => {
      queryClient.setQueryData(centerOperatingHoursQueryKey, () => ({ ...newOperatingHours }));
    }
  });

  const operatingHoursWeeklyDeleteMutator = useMutation({
    mutationFn: async (operatingHours: OperatingHours[]) => {
      return deleteWeeklyCenterOperatingHours(await getToken(), centerId, operatingHours);
    },

    onSuccess: newOperatingHours => {
      queryClient.setQueryData(centerOperatingHoursQueryKey, () => ({ ...newOperatingHours }));
    }
  });

  return {
    operatingHours: operatingHoursQuery.data,
    brandStandardHours: brandStandardHoursQuery.data ?? [],
    isLoading: operatingHoursQuery.isLoading,
    operatingHoursEditMutator,
    deleteOperatingHoursMutator,
    operatingHoursWeeklyEditMutator,
    operatingHoursWeeklyDeleteMutator
  };
};

export const getCenterOperatingHoursList = (accessToken: string, centerId: string | undefined) => {
  return callLcosService<CenterOperatingHours>(accessToken, `/api/centers/${centerId}/operating_hours`);
};

export const getCenterBrandStandardHoursList = (accessToken: string, centerId: string | undefined) => {
  return callLcosService<BrandStandardOperatingHours[]>(accessToken, `/api/centers/${centerId}/brand_standard_hours`);
};

export const createCenterOperatingHours = (
  accessToken: string,
  centerId: string | undefined,
  payload: OperatingHours
) => {
  return callLcosService<CenterOperatingHours>(
    accessToken,
    `/api/centers/${centerId}/operating_hours`,
    "POST",
    payload
  );
};

export const updateCenterOperatingHours = (
  accessToken: string,
  centerId: string | undefined,
  payload: OperatingHours
) => {
  return callLcosService<CenterOperatingHours>(
    accessToken,
    `/api/centers/${centerId}/operating_hours/${payload.id}`,
    "PUT",
    payload
  );
};

export const updateWeeklyCenterOperatingHours = (
  accessToken: string,
  centerId: string | undefined,
  payload: OperatingHours[]
) => {
  return callLcosService<CenterOperatingHours>(accessToken, `/api/centers/${centerId}/operating_hours`, "PUT", payload);
};

const deleteCenterOperatingHours = (accessToken: string, centerId: string | undefined, hoursId: number | undefined) => {
  return callLcosService<CenterOperatingHours>(
    accessToken,
    `/api/centers/${centerId}/operating_hours/${hoursId}`,
    "DELETE"
  );
};

export const deleteWeeklyCenterOperatingHours = (
  accessToken: string,
  centerId: string | undefined,
  payload: OperatingHours[]
) => {
  return callLcosService<CenterOperatingHours>(
    accessToken,
    `/api/centers/${centerId}/operating_hours`,
    "DELETE",
    payload
  );
};

export const operatingHoursSorter = (a: OperatingHours, b: OperatingHours) => {
  if (a.weekday === 7) {
    return 1;
  }
  if (b.weekday === 7) {
    return -1;
  }
  return a.weekday - b.weekday;
};
