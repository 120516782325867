import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect, useState, type FC } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import z from "zod";

import { states } from "../../../data/states";
import type { BasicFormProps } from "../../../forms/form-context-wrapper/form-context-wrapper";
import { useCityAndState } from "../../../hooks/use-azure-maps-city-state";
import {
  defaultFamilyContactDetail,
  useFamilyConstants,
  useFamilyContactDetail,
  useReplaceContact,
  type FamilyContactDetailWithId
} from "../../../hooks/use-families";
import { useInquiry, useInquiryDashboard } from "../../../hooks/use-inquiries";
import { FormHelpers } from "../../forms";
import { InputField } from "../../forms/input-field";
import { PhoneNumberField } from "../../forms/phone-number-field";
import { SelectField } from "../../forms/select-field";
import { quickCreateInquirerFormSchema } from "../../inquiry-form/quick-create-inquirer";
import { useDuplicateContactModal } from "../../inquiry-form/use-duplicate-contact-modal";
import { LoadingButton } from "../../loading-button/loading-button";
import { useFormModal } from "../../modal/modal";
import { useAccessControl } from "../../../app/use-access-control";
import { STUDENT_FAMILY_CREATE_EDIT } from "../../../data/permissions";

interface CreateContactParameters {
  inquiryId: string;
  contactId?: never;
}

interface UpdateContactParameters {
  inquiryId: string;
  contactId: number;
}

export type ContactParameters = CreateContactParameters | UpdateContactParameters;

const relationshipSchema = z.object({
  relationshipId: z.string().uuid()
});

const editFamilyContactFormSchema = z.intersection(quickCreateInquirerFormSchema, relationshipSchema);

type EditFamilyContactFormValuesInput = z.input<typeof editFamilyContactFormSchema>;

const EditFamilyContactForm: FC<BasicFormProps<EditFamilyContactFormValuesInput> & { readOnly?: boolean }> = ({
  methods,
  readOnly = false
}) => {
  const { activeTitles, activeRelationships, activePreferredContactMethods } = useFamilyConstants();
  const {
    register,
    setValue,
    control,
    formState: { errors }
  } = methods ?? ({ formState: {} } as UseFormReturn<EditFamilyContactFormValuesInput>);

  const zipCodeFieldValue = useWatch({ control, name: "zipcode", defaultValue: "" });
  const cityFieldValue = useWatch({ control, name: "city", defaultValue: "" });
  const stateFieldValue = useWatch({ control, name: "state", defaultValue: "" });

  const cityAndStateQuery = useCityAndState(zipCodeFieldValue, {
    enabled: FormHelpers.isValidZipcode(zipCodeFieldValue) && (!cityFieldValue || !stateFieldValue)
  });

  useEffect(() => {
    if (cityAndStateQuery?.data?.city && cityAndStateQuery?.data?.state) {
      setValue("city", cityAndStateQuery.data.city);
      setValue("state", cityAndStateQuery.data.state);
    }
  }, [cityAndStateQuery.data]);

  return (
    <>
      <div className="row form-standard">
        <div className="col col-12">
          <h3>Details</h3>
        </div>
        <div className="col col-12">
          <SelectField label="Salutation" disableColClass disabled={readOnly} {...register("title")}>
            <option value="">Select Salutation</option>
            {activeTitles?.map(({ value, name }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
        <div className="col col-12 col-md-6">
          <InputField
            label="First Name"
            disableColClass
            error={errors.firstName}
            isRequired
            disabled={readOnly}
            {...register("firstName")}
          />
        </div>
        <div className="col col-12 col-md-6">
          <InputField
            label="Last Name"
            disableColClass
            error={errors.lastName}
            isRequired
            disabled={readOnly}
            {...register("lastName")}
          />
        </div>
        <div className="col col-12">
          <InputField
            label="Suffix"
            disableColClass
            error={errors.suffix}
            disabled={readOnly}
            {...register("suffix")}
          />
        </div>
        <div className="col col-12">
          <SelectField
            label="Relationship to Student"
            validationState={errors["relationshipId"] ? "error" : undefined}
            disableColClass
            isRequired
            disabled={readOnly}
            {...register("relationshipId")}
          >
            <option value="">Relationship to Student</option>
            {activeRelationships
              .sort(({ name: left }, { name: right }) => left.localeCompare(right))
              .map(({ value, name }) => (
                <option key={value} value={value}>
                  {name}
                </option>
              ))}
          </SelectField>
        </div>
      </div>

      <div className="row form-standard mt-4">
        <h3
          className={classNames({
            "is-invalid": errors["contact_root"]
          })}
        >
          Contact{" "}
          <span className="required" aria-hidden="true">
            *
          </span>
        </h3>
        {!!errors["contact_root"] && (
          // @ts-ignore
          <div className="invalid-feedback"> {errors["contact_root"]?.message}</div>
        )}
        <div className="col col-12">
          <InputField label="Email" error={errors.email} disableColClass disabled={readOnly} {...register("email")} />
        </div>
        <div className="col col-12 col-md-6">
          <PhoneNumberField
            label="Home Phone"
            error={errors.homePhone}
            disableColClass
            disabled={readOnly}
            {...register("homePhone")}
          />
        </div>
        <div className="col col-12 col-md-6">
          <PhoneNumberField
            label="Business Phone"
            error={errors.businessPhone}
            disableColClass
            disabled={readOnly}
            {...register("businessPhone")}
          />
        </div>
        <div className="col col-12 col-md-6">
          <PhoneNumberField
            label="Mobile Phone"
            error={errors.personalPhone}
            disableColClass
            disabled={readOnly}
            {...register("personalPhone")}
          />
        </div>
        <div className="col col-12 col-md-6">
          <SelectField
            label="Preferred Method Of Contact"
            disableColClass={true}
            {...register("preferredContactMethod")}
          >
            <option value="">Select Method</option>
            {activePreferredContactMethods?.map(({ value, name }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
        <div className="col col-12">
          <strong>Contact Opt Out</strong>
        </div>
        <div className="col col-12">
          <div className="checkboxes-inline">
            <div className="form-check">
              <label className="form-check-label" htmlFor="optout1">
                Phone
              </label>
              <input className="form-check-input" type="checkbox" id="optout1" value="phone" {...register("optOut")} />
            </div>
            <div className="form-check">
              <label className="form-check-label" htmlFor="optout2">
                Email
              </label>
              <input className="form-check-input" type="checkbox" id="optout2" value="email" {...register("optOut")} />
            </div>
            <div className="form-check">
              <label className="form-check-label" htmlFor="optout3">
                SMS
              </label>
              <input className="form-check-input" type="checkbox" id="optout3" value="sms" {...register("optOut")} />
            </div>
          </div>
        </div>
      </div>

      <div className="row form-standard mt-4">
        <h3>Address</h3>

        <div className="col col-12 col-md-6">
          <InputField
            label="Address Line 1"
            error={errors.addressLine1}
            disableColClass
            disabled={readOnly}
            {...register("addressLine1")}
          />
        </div>
        <div className="col col-12 col-md-6">
          <InputField
            label="Address Line 2"
            disableColClass
            error={errors.addressLine2}
            disabled={readOnly}
            {...register("addressLine2")}
          />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <InputField label="ZIP" error={errors.zipcode} disableColClass disabled={readOnly} {...register("zipcode")} />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <InputField label="City" error={errors.city} disableColClass disabled={readOnly} {...register("city")} />
        </div>
        <div className="col col-12 col-md-6 col-lg-4">
          <SelectField label="State" disableColClass disabled={readOnly} {...register("state")}>
            <option value="">Select State</option>
            {states.map(({ abbreviation, name }) => (
              <option key={abbreviation} value={abbreviation}>
                {name}
              </option>
            ))}
          </SelectField>
        </div>
      </div>
    </>
  );
};

export const useFamilyContactDetailFormModal = () => {
  const [contactParameters, setContactParamenters] = useState<ContactParameters>();
  const { contactDetail, createOrUpdateContactMutator: mutator } = useFamilyContactDetail(
    "inquirer",
    contactParameters?.contactId
  );
  const inquiryQuery = useInquiry(contactParameters?.inquiryId);
  const { hasPermission, activeCenterId } = useAccessControl();
  const dashboardQuery = useInquiryDashboard({ centerId: activeCenterId, inquiryId: contactParameters?.inquiryId });

  const oldContact = dashboardQuery.data?.familyInfo.find(({ id }) => id === contactParameters?.contactId);
  const relationship = oldContact?.subjectRelationships.find(
    rel => rel.objectContactId === inquiryQuery.data?.studentId
  );

  const replaceContactMutator = useReplaceContact();

  const {
    hide: hideFamilyContactFormModal,
    show,
    formId,
    renderModal: renderFormModal,
    methods: { setError, watch }
  } = useFormModal({
    name: "edit-family-contact-form",
    useFormProps: {
      values: {
        ...(contactDetail ?? defaultFamilyContactDetail),
        relationshipId: relationship?.relationship.value ?? ""
      },
      resolver: zodResolver(editFamilyContactFormSchema),
      mode: "onTouched"
    },
    onSubmit: formValues => {
      mutator.mutate(
        {
          id: contactParameters?.contactId,
          payload: formValues,
          oldRelationshipId: relationship?.id,
          oldRelationshipValue: relationship?.relationship.value,
          familyId: inquiryQuery.data?.familyId ?? undefined,
          inquiryId: contactParameters?.inquiryId,
          studentId: inquiryQuery.data?.studentId
        },
        {
          onSuccess: hideFamilyContactFormModal,
          onError: (error: any) => {
            onSubmitError(error);
            error.errors.forEach((e: any) => {
              setError(e.field, { message: e.message });
            });
          }
        }
      );
    }
  });

  const submitExistingContact = (contact: FamilyContactDetailWithId) => {
    const relationshipId = watch("relationshipId");
    if (inquiryQuery.data?.familyId && inquiryQuery.data.studentId && relationshipId && oldContact) {
      replaceContactMutator.mutate(
        {
          familyId: inquiryQuery.data.familyId,
          relationshipId,
          studentId: inquiryQuery.data.studentId,
          oldContact: { ...oldContact, roles: oldContact.roles.map(role => role.role) },
          newContact: contact
        },
        {
          onSuccess: () => {
            if (contactParameters?.inquiryId) {
              showFamilyContactFormModal({ inquiryId: contactParameters.inquiryId, contactId: contact.id });
            }
          }
        }
      );
    }
  };

  const { renderDuplicateContactModal, onSubmitError } = useDuplicateContactModal({
    onSubmit: submitExistingContact
  });

  const hasEditPermission = !!hasPermission?.(STUDENT_FAMILY_CREATE_EDIT);
  const renderFamilyContactFormModal = () =>
    renderFormModal({
      title: !contactParameters?.contactId
        ? `Create Contact`
        : `${hasEditPermission ? "Edit" : "View"} Contact - ${contactDetail?.lastName}, ${contactDetail?.firstName}`,
      body: <EditFamilyContactForm readOnly={!hasEditPermission} />,
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideFamilyContactFormModal}>
            Cancel
          </button>
          {hasEditPermission && (
            <LoadingButton
              className="btn btn-primary"
              type="submit"
              form={formId}
              isLoading={mutator.isLoading}
              loadingMessage="Saving..."
            >
              Save
            </LoadingButton>
          )}
          {renderDuplicateContactModal()}
        </>
      ),
      size: "lg",
      fullscreen: "lg-down"
    });

  const showFamilyContactFormModal = (contactParameters: ContactParameters) => {
    setContactParamenters(contactParameters);
    show();
  };

  return {
    hideFamilyContactFormModal,
    showFamilyContactFormModal,
    renderFamilyContactFormModal
  };
};
