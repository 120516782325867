import { FC, useMemo, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

import { useNotes, Note, NoteType, SavedNote } from "../../hooks/use-notes";
import { Confirm, ConfirmHandle } from "../confirm/confirm";
import { EditNoteForm, notesSchema } from "./edit-note-form";
import * as FormHelpers from "../forms/form-helpers";
import { useFormModal } from "../modal/modal";
import { SimpleTable, SimpleTableHeader } from "../table";
import { IconButton } from "../buttons/icon-button";
import { LoadingButton } from "../loading-button/loading-button";

export type NotesHeaderComponent = (props: { handleAddNote: () => void }) => React.ReactElement;

export interface NotesProps {
  centerId: string;
  staffId?: string;
  noteType: NoteType;
  canCreateOrEdit: boolean;
  renderHeader: NotesHeaderComponent;
}

export const Notes: FC<NotesProps> = ({ centerId, staffId, noteType, canCreateOrEdit, renderHeader }) => {
  const [editNote, setEditNote] = useState<Note>();
  const { notes, isLoading, createNoteMutator, editNoteMutator, deleteNoteMutator } = useNotes(
    { centerId, userId: staffId },
    noteType
  );

  const confirmDeleteNoteModal = useRef<ConfirmHandle>(null);

  const handleSaveNote = (note: Note, noteId?: number) => {
    const onSuccess = () => {
      hide();
      setEditNote(undefined);
    };
    if (noteId) {
      editNoteMutator.mutate({ noteId, note: { title: note.title, notes: note.notes } }, { onSuccess });
    } else {
      createNoteMutator.mutate(note, { onSuccess });
    }
  };

  const handleEditClick = (savedNote: SavedNote) => {
    setEditNote(savedNote);
    show();
  };

  const handleDelete = (savedNote: SavedNote) => {
    confirmDeleteNoteModal.current &&
      confirmDeleteNoteModal.current.confirm(() => {
        deleteNoteMutator.mutate(savedNote.id);
      });
  };

  const handleAddNote = () => {
    setEditNote({ title: "", notes: "" });
    show();
  };

  const notesHeaders = useMemo<SimpleTableHeader<SavedNote>[]>(() => {
    const headers: SimpleTableHeader<SavedNote>[] = [
      {
        renderKey: "title",
        name: "Title/Note",
        render: item => {
          return (
            <>
              <strong>{item.title}</strong>
              <br />
              <span style={{ whiteSpace: "pre-wrap" }}>{item.notes}</span>
            </>
          );
        }
      },

      {
        renderKey: "created_on",
        name: "Created/Updated",
        tableDataCellProps: { verticalAlign: "top" },
        render: item => {
          return (
            <>
              {FormHelpers.formatDateString(item.created_on)}
              <br />
              {FormHelpers.formatDateString(item.updated_date)}
            </>
          );
        }
      }
    ];

    if (canCreateOrEdit) {
      headers.push({
        renderKey: "buttons",
        name: "",
        tableDataCellProps: {
          textAlign: "right",
          isAction: true,
          verticalAlign: "top"
        },
        render: item => {
          return (
            <>
              <IconButton
                title="Edit"
                icon="edit"
                enableTooltip
                data-testid={`notes-edit-button-${item.id}`}
                className="btn btn-table"
                onClick={() => handleEditClick(item)}
              />
              <IconButton
                title="Delete"
                icon="trash"
                enableTooltip
                data-testid={`notes-delete-button-${item.id}`}
                className="btn btn-table btn-danger"
                onClick={() => handleDelete(item)}
              />
            </>
          );
        }
      } as SimpleTableHeader<SavedNote>);
    }

    return headers;
  }, [canCreateOrEdit]);

  const { show, hide, formId, renderModal } = useFormModal({
    name: "notes-form",
    useFormProps: { values: editNote, resolver: zodResolver(notesSchema), mode: "onTouched" },
    onSubmit: formValues => handleSaveNote(formValues, editNote?.id)
  });

  return (
    <>
      <>{renderHeader({ handleAddNote })}</>
      <SimpleTable
        tableCollapseScreenSize="sm"
        headers={notesHeaders}
        data={notes}
        isLoading={isLoading || createNoteMutator.isLoading || editNoteMutator.isLoading || deleteNoteMutator.isLoading}
        uniqueIdKey="id"
      />

      <Confirm ref={confirmDeleteNoteModal} title="Confirm" message="Would you like to delete this note?" />
      {renderModal({
        title: "Edit/Update Note",
        centered: true,
        body: <EditNoteForm />,
        footer: (
          <>
            <button className="btn btn-secondary" onClick={hide}>
              Cancel
            </button>
            <LoadingButton
              type="submit"
              form={formId}
              className="btn btn-primary"
              isLoading={editNoteMutator.isLoading || createNoteMutator.isLoading}
            >
              Save
            </LoadingButton>
          </>
        )
      })}
    </>
  );
};
