import { type StaffMemberContactDetails } from "../../hooks/use-user";
import { FormDividerRow } from "../forms/form-row";
import { Field } from "../forms/legacy/field";
import { SortableTable, SortableTableHeader } from "../table";

interface SearchExistingUsersProps {
  isCurrentStep: boolean;

  searchTerm: string;
  onChangeSearchTerm: (value: string) => void;
  selectedStaffId: string;
  onChangeSelectedStaffId: (value: string) => void;

  findResults: StaffMemberContactDetails[];
}

export const SearchExistingUsers: React.FC<SearchExistingUsersProps> = ({
  isCurrentStep,

  searchTerm,
  onChangeSearchTerm,
  selectedStaffId,
  onChangeSelectedStaffId,
  findResults
}) => {
  const onClickClear = () => {
    onChangeSearchTerm("");
    onChangeSelectedStaffId("");
  };

  if (!isCurrentStep) {
    return null;
  }

  return (
    <div className="form step-body">
      <p className="text-wrap">
        If you are certain that this person has never worked at a Center, you can skip this step.
      </p>
      <div className="row standard-form-row">
        <Field value={searchTerm} onChangeValue={onChangeSearchTerm} label="Search For" mode="edit" />

        <div className="col col-2 form-floating">
          <button className="btn btn-primary" onClick={onClickClear}>
            Clear
          </button>
        </div>
      </div>

      <FormDividerRow />

      <div>
        <SortableTable
          tableCollapseScreenSize="sm"
          headers={staffSelectorHeaders}
          paginationOptions={{ itemsPerPage: 5 }}
          data={findResults}
          activeItem={findResults.find(sm => sm.id === selectedStaffId)}
          onRowClick={item => onChangeSelectedStaffId(item.id)}
          uniqueIdKey="id"
        />
      </div>
    </div>
  );
};

const staffSelectorHeaders: SortableTableHeader<StaffMemberContactDetails>[] = [
  {
    key: "displayName",
    sortOptions: { enableSort: true },
    name: "Name"
  },

  {
    key: "businessEmail",
    sortOptions: { enableSort: true },
    name: "Email Address"
  }
];
