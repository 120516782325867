import { useMemo } from "react";
import { useAccessControl } from "../../app/use-access-control";
import { useReferenceData } from "../use-reference-data";
import { useStaffMemberPermissions } from "./use-staff-member-permissions";

export const useStaffEditabilityCheck = (staffMemberId?: string) => {
  const { profile, activeCenterId } = useAccessControl();
  const { referenceData } = useReferenceData();
  const { staffMemberPermissions } = useStaffMemberPermissions(activeCenterId, staffMemberId);

  const isRoleImmutable = useMemo(() => {
    const staffMemberRole = (referenceData?.roles || []).find(r => r.value === staffMemberPermissions?.role);
    //should be defaulting to true here if role is not found?
    return !staffMemberRole || staffMemberRole.isImmutable;
  }, [referenceData, staffMemberPermissions]);

  const isEditingSelf = useMemo(
    () => !!profile?.id && !!staffMemberId && profile.id === staffMemberId,
    [profile, staffMemberId]
  );

  const permissionsDisabled = useMemo(() => isEditingSelf || isRoleImmutable, [isRoleImmutable, isEditingSelf]);

  return {
    isEditingSelf,
    isRoleImmutable,
    permissionsDisabled
  };
};
