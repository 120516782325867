import { forwardRef, type FC, type HtmlHTMLAttributes, useState, useRef, type ReactNode, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";

import { type InquiryAppointment, useInquiryConstants } from "../../../../hooks/use-inquiries";
import { Confirm, type ConfirmHandle } from "../../../confirm/confirm";
import { SelectField } from "../../../forms/select-field";
import { useModal } from "../../../modal/modal";

interface StatusDropdownProps {
  id: string;
  appointment: InquiryAppointment;
  onUpdateAppointmentStatus: (appointment: InquiryAppointment) => void;
  onRescheduleAppointment: (appointment: InquiryAppointment) => void;
  dropdownClassName?: string;
  menuClassName?: string;
  hideConfirmation?: boolean;
  onActiveStatusChange?: (activeStatuseId: string) => void;
}

export const StatusDropdown: FC<StatusDropdownProps> = ({
  id,
  appointment,
  onUpdateAppointmentStatus,
  onRescheduleAppointment,
  dropdownClassName,
  menuClassName,
  hideConfirmation = false,
  onActiveStatusChange
}) => {
  const [title, setTitle] = useState<string>();
  const [message, setMessage] = useState<ReactNode>();
  // const menuRef = useRef<HTMLUListElement>(null);
  const confirmRef = useRef<ConfirmHandle>(null);
  const inquiryConstantsQuery = useInquiryConstants();
  const {
    APPOINTMENT_STATUS_SCHEDULED_ID = "",
    APPOINTMENT_STATUS_RESCHEDULED_ID = "",
    APPOINTMENT_STATUS_CANCELLED_ID = "",
    APPOINTMENT_STATUS_AS_SCHEDULED_ID = "",
    APPOINTMENT_STATUS_NO_SHOW_ID = ""
  } = inquiryConstantsQuery.data ?? {};

  const [activeStatusId, setActiveStatusId] = useState<string>(APPOINTMENT_STATUS_SCHEDULED_ID);

  useEffect(() => {
    onActiveStatusChange?.(activeStatusId);
  }, [activeStatusId]);

  const { renderCancelConfirmationModal, showCancelConfirmationModal } = useAppointmentCancelConfirmationModal({
    onConfirm: (reasonToCancelId: string) => {
      onUpdateAppointmentStatus({ ...appointment, statusId: APPOINTMENT_STATUS_CANCELLED_ID, reasonToCancelId });
    },
    onCancel: () => setActiveStatusId(APPOINTMENT_STATUS_SCHEDULED_ID)
  });

  const handleStatusClick = (statusId?: string) => {
    if (statusId && inquiryConstantsQuery.data) {
      if (hideConfirmation) {
        setActiveStatusId(statusId);
        if (statusId === APPOINTMENT_STATUS_RESCHEDULED_ID) {
          onRescheduleAppointment({ ...appointment, statusId });
        } else {
          onUpdateAppointmentStatus({ ...appointment, statusId });
        }
      } else {
        switch (statusId) {
          case APPOINTMENT_STATUS_AS_SCHEDULED_ID:
            setTitle(`"As Scheduled" Confirmation`);
            setMessage(
              <p>
                Select &ldquo;confirm&rdquo; if the appointment was held <strong>As Scheduled</strong>.
              </p>
            );
            confirmRef.current?.confirm(() => {
              onUpdateAppointmentStatus({ ...appointment, statusId });
            });
            break;
          case APPOINTMENT_STATUS_RESCHEDULED_ID:
            onRescheduleAppointment(appointment);
            break;
          case APPOINTMENT_STATUS_NO_SHOW_ID:
            setTitle(`"No Show" Confirmation`);
            setMessage(
              <p>
                Select &ldquo;confirm&rdquo; if the appointment was a <strong>No Show</strong>.
              </p>
            );
            confirmRef.current?.confirm(() => {
              onUpdateAppointmentStatus({ ...appointment, statusId });
            });
            break;
          case APPOINTMENT_STATUS_CANCELLED_ID:
            showCancelConfirmationModal();
            break;
          case APPOINTMENT_STATUS_SCHEDULED_ID:
          default:
            break;
        }
      }
    }
  };

  return (
    <>
      <Dropdown id={id} className={dropdownClassName}>
        <Dropdown.Toggle as={StatusDropdownToggle}>
          {inquiryConstantsQuery.data?.appointmentStatusesLookup?.[activeStatusId] ?? "Scheduled"}
        </Dropdown.Toggle>
        <Dropdown.Menu as={StatusDropdownMenu} className={menuClassName}>
          <Dropdown.Item
            as={StatusDropdownItem}
            eventKey="1"
            onClick={() => handleStatusClick(APPOINTMENT_STATUS_SCHEDULED_ID)}
            active={!activeStatusId || activeStatusId === APPOINTMENT_STATUS_SCHEDULED_ID}
          >
            <span className="fas fa-calendar" aria-hidden="true" />
            Scheduled
          </Dropdown.Item>
          <Dropdown.Item
            as={StatusDropdownItem}
            eventKey="2"
            onClick={() => handleStatusClick(APPOINTMENT_STATUS_AS_SCHEDULED_ID)}
            active={activeStatusId === APPOINTMENT_STATUS_AS_SCHEDULED_ID}
          >
            <span className="fas fa-check" aria-hidden="true" />
            As Scheduled
          </Dropdown.Item>
          <Dropdown.Item
            as={StatusDropdownItem}
            eventKey="3"
            onClick={() => handleStatusClick(APPOINTMENT_STATUS_RESCHEDULED_ID)}
            active={activeStatusId === APPOINTMENT_STATUS_RESCHEDULED_ID}
          >
            <span className="fas fa-rotate-right" aria-hidden="true" />
            Rescheduled
          </Dropdown.Item>
          <Dropdown.Item
            as={StatusDropdownItem}
            eventKey="4"
            onClick={() => handleStatusClick(APPOINTMENT_STATUS_NO_SHOW_ID)}
            active={activeStatusId === APPOINTMENT_STATUS_NO_SHOW_ID}
          >
            <span className="fas fa-question" aria-hidden="true" />
            No Show
          </Dropdown.Item>
          <Dropdown.Item
            as={StatusDropdownItem}
            eventKey="5"
            onClick={() => handleStatusClick(APPOINTMENT_STATUS_CANCELLED_ID)}
            active={activeStatusId === APPOINTMENT_STATUS_CANCELLED_ID}
          >
            <span className="fas fa-times" aria-hidden="true" />
            Cancelled
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Confirm title={title} message={message} okLabel="Confirm" ref={confirmRef} size="md" cancelLabel="Cancel" />
      {renderCancelConfirmationModal()}
    </>
  );
};

const StatusDropdownToggle = forwardRef<HTMLButtonElement, HtmlHTMLAttributes<HTMLButtonElement>>(
  ({ className, ...props }, ref) => {
    return <button className={classNames("btn border", className)} {...props} ref={ref} />;
  }
);

StatusDropdownToggle.displayName = "StatusDropdownToggle";

//the following added b/c they are passed by dropdown but cannot be placed on UL
interface StatusDropdownMenuProps extends HtmlHTMLAttributes<HTMLUListElement> {
  show?: boolean;
  close?: boolean;
}
const StatusDropdownMenu = forwardRef<HTMLUListElement, StatusDropdownMenuProps>(({ show, close, ...props }, ref) => {
  return <ul {...props} ref={ref} />;
});

StatusDropdownMenu.displayName = "StatusDropdownMenu";

const StatusDropdownItem = forwardRef<HTMLButtonElement, HtmlHTMLAttributes<HTMLButtonElement>>((props, ref) => {
  return (
    <li>
      <button {...props} ref={ref} />
    </li>
  );
});

StatusDropdownItem.displayName = "StatusDropdownItem";

interface UseCancelConfirmationModalProps {
  onConfirm: (reasonToCancelId: string) => void;
  onCancel?: () => void;
}

// Cancel Confirmation will use its own modal here because the function passed to the Confirm modal doesnt change with a rerender
export const useAppointmentCancelConfirmationModal = ({ onConfirm, onCancel }: UseCancelConfirmationModalProps) => {
  const [reasonToCancelId, setReasonToCancelId] = useState("");
  const inquiryConstantsQuery = useInquiryConstants();
  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    setReasonToCancelId(event.target.value);
  };
  const { renderModal, show, hide } = useModal({ disableToast: true });
  const handleConfirm = () => {
    onConfirm(reasonToCancelId);
    hide();
    setReasonToCancelId("");
  };
  const handleCancel = () => {
    onCancel?.();
    hide();
    setReasonToCancelId("");
  };
  const renderCancelConfirmationModal = () =>
    renderModal({
      title: `"Cancelled" Confirmation`,
      body: (
        <>
          <p>
            Provide Reason for Cancellation and select &ldquo;confirm&rdquo; if the appointment was{" "}
            <strong>Cancelled</strong>.
          </p>
          <SelectField
            isRequired
            label="Reason for Cancellation"
            onChange={handleSelectChange}
            data-testid="cancel-select"
            value={reasonToCancelId}
          >
            <option value="">Select reason for cancellation</option>
            {(inquiryConstantsQuery?.data?.activeAppointmentCancelChoices || []).map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </SelectField>
        </>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={handleCancel}>
            Cancel
          </button>
          <button
            autoFocus={true}
            className="btn btn-primary"
            onClick={handleConfirm}
            disabled={reasonToCancelId === ""}
          >
            Confirm
          </button>
        </>
      ),
      centered: true,
      backdrop: "static"
    });

  return {
    renderCancelConfirmationModal,
    showCancelConfirmationModal: show,
    hideCancelConfirmationModal: hide
  };
};
