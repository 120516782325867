import React, { ReactNode } from "react";
import Accordion from "react-bootstrap/Accordion";
import classnames from "classnames";

export interface BaseAccordionProps {
  items?: {
    header: ReactNode;
    body: ReactNode;
  }[];
  darkHeaders?: boolean;
}

export const BaseAccordion: React.FC<BaseAccordionProps> = ({ items, darkHeaders }) => {
  if (!items?.length) {
    return null;
  }

  return (
    <Accordion defaultActiveKey="0" className={classnames({ "accordion-headers--dark": !!darkHeaders })}>
      {items.map((item, index: number) => (
        <Accordion.Item key={index} eventKey={`${index}`}>
          <Accordion.Header>{item.header}</Accordion.Header>
          <Accordion.Body>{item.body}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};
