import React, { createContext, useCallback, useMemo, useState, useEffect } from "react";

import { useCenters, CenterDetail } from "../hooks/use-center";
import { useMyProfile, UserProfile } from "../hooks/use-my-profile";
import { StaffMemberPermissions, useStaffMemberPermissions } from "../hooks/use-staff";
import { Feature, useFeatureFlags } from "../hooks/use-feature-flags";
import { useQueryClient } from "@tanstack/react-query";
import * as Permissions from "../data/permissions";

export interface AccessControlContextType {
  activeCenterId: string;
  activeCenter: CenterDetail;
  loading: boolean;
  label: string;
  permissions?: StaffMemberPermissions;
  profile?: UserProfile;
  onChangeActiveCenter?: (id: string) => void;
  hasPermission?: (required: string | string[] | undefined, checkAll?: boolean) => boolean;
  centerCount?: number;
  hasFeatureAccess?: (featureFlag: Feature) => boolean;
}

export const AccessControlContext = createContext<AccessControlContextType>({
  loading: false,
  activeCenterId: "",
  activeCenter: {} as unknown as CenterDetail,
  label: "Center",
  onChangeActiveCenter: undefined,
  hasPermission: undefined,
  centerCount: 0,
  hasFeatureAccess: undefined
});

export const defaultCenterIdStorageKey = "lcos-default-center-id";
export const getDefaultCenterIdFromStorage = () => {
  return window.localStorage.getItem(defaultCenterIdStorageKey) ?? "";
};
export const setDefaultCenterIdInStorage = (newId: string) => {
  window.localStorage.setItem(defaultCenterIdStorageKey, newId);
};

export const AccessControlProvider: React.FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => {
  // yes indeed, leaving off the () is intentional
  const [activeCenterId, setActiveCenterId] = useState<string>(getDefaultCenterIdFromStorage());
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [label, setLabel] = useState<string>("Center");

  const { centers, isLoading: centersIsLoading } = useCenters();
  const { profile, isLoading: profileIsLoading } = useMyProfile();
  const { staffMemberPermissions, isLoading: permissionsIsLoading } = useStaffMemberPermissions(
    activeCenterId,
    // istanbul ignore next
    profile?.id
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    setActiveCenterId(getDefaultCenterIdFromStorage());
  }, []);
  useEffect(() => {
    setDefaultCenterIdInStorage(activeCenterId);
    queryClient.invalidateQueries({ queryKey: ["getFeatureFlags"] });
  }, [activeCenterId]);

  const centerCount = useMemo(() => {
    return centers ? centers.length : 0;
  }, [centers]);
  const activeCenter = useMemo(() => {
    if (activeCenterId && centers && centers.length && centers.find(c => c.id === activeCenterId)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return centers.find(c => c.id === activeCenterId)!;
    } else if (centers && centers.length) {
      setActiveCenterId(centers[0].id); // will cascade back and cause the if to resolve
    }
    return {} as unknown as CenterDetail;
  }, [centers, activeCenterId]);

  useEffect(() => {
    if (loadingInitial && !centersIsLoading && !profileIsLoading && !permissionsIsLoading) {
      setLoadingInitial(false);
    }
  }, [centersIsLoading, loadingInitial, profileIsLoading, permissionsIsLoading]);

  const onChangeActiveCenter = useCallback((id: string) => {
    setActiveCenterId(id);
  }, []);

  useEffect(() => {
    setLabel(centerCount > 1 ? "Centers" : "Center");
  }, [centerCount]);

  // the thinking on returning true for empty input scenarios is that empty === open to all
  // also this is treated as a "one of" check, so if the user has any one of the required
  // permissions, it will pass
  const hasPermission = useCallback(
    (required: Permissions.Permission | Permissions.Permission[] | undefined, checkAll = false) => {
      if (!profile || required === "NO_ACCESS") {
        return false;
      }

      if (profile.isCorporateAdmin) {
        return true;
      }

      if (!required) {
        return true;
      }
      const normalized = Array.isArray(required) ? [...required] : [required];
      if (!normalized.length) {
        return true;
      }

      if (profile.isCorporateUser) {
        return checkAll
          ? !normalized.some(permission => !Permissions.CORPORATE_USER_PERMISSIONS[permission])
          : normalized.some(permission => Permissions.CORPORATE_USER_PERMISSIONS[permission]);
      }

      if (!staffMemberPermissions || !staffMemberPermissions.permissions) {
        return false;
      }

      const staffMemberPermissionsLookup = staffMemberPermissions.permissions.reduce(
        (acc: Record<string, boolean>, current) => {
          acc[current.action] = current.isAllowed;
          return acc;
        },
        {}
      );

      return checkAll
        ? !normalized.some(permission => !staffMemberPermissionsLookup[permission])
        : normalized.some(permission => staffMemberPermissionsLookup[permission]);
    },
    [activeCenterId, centers, staffMemberPermissions, profile]
  );

  const { featureFlagSettings } = useFeatureFlags();

  const hasFeatureAccess = useCallback(
    (featureFlag: Feature) => {
      return !!featureFlagSettings?.[featureFlag];
    },
    [featureFlagSettings]
  );

  useEffect(() => {
    window.allowedPermissions = (staffMemberPermissions?.permissions || [])
      .filter(permission => permission.isAllowed)
      .map(permission => permission.action);
  }, [staffMemberPermissions]);

  return (
    <AccessControlContext.Provider
      value={{
        activeCenterId,
        activeCenter,
        label,
        loading: loadingInitial,
        onChangeActiveCenter,
        permissions: staffMemberPermissions,
        profile,
        hasPermission,
        centerCount,
        hasFeatureAccess
      }}
    >
      {!loadingInitial && children}
    </AccessControlContext.Provider>
  );
};

export * from "./use-access-control";
