import { UseFormReturn } from "react-hook-form";
import { InquiryFormData, useInquiryConstants } from "../../../../hooks/use-inquiries";
import { FC, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../../../forms/input-field";
import { SelectField } from "../../../forms/select-field";
import { useMyProfile } from "../../../../hooks/use-my-profile";
import moment from "moment";

export interface InitialContactProps {
  inquiryForm: UseFormReturn<InquiryFormData>;
  isDetailsTab?: boolean;
}

export const InitialContact: FC<InitialContactProps> = ({
  inquiryForm: { watch, register, getValues, formState },
  isDetailsTab
}) => {
  const { profile } = useMyProfile();
  const inquiryConstantsQuery = useInquiryConstants();

  const referredById = watch("referredById");
  const referredByDescriptionLabel = useMemo(() => {
    const selection = inquiryConstantsQuery.data?.activeReferralSources?.find(afs => afs.value === referredById);
    if (selection) {
      return `Referred by ${selection.name}`;
    } else {
      return "";
    }
  }, [referredById, inquiryConstantsQuery.data?.activeReferralSources]);

  return (
    <Row>
      <Col md={6}>
        <InputField
          disableColClass
          type="date"
          label="Inquiry Date"
          validationState={!isDetailsTab && formState?.errors["inquiryDate"] ? "error" : undefined}
          disabled={isDetailsTab}
          max={moment().format("YYYY-MM-DD")}
          min={moment().subtract(180, "days").format("YYYY-MM-DD")}
          {...register("inquiryDate")}
        />
      </Col>
      <Col md={6}>
        <InputField
          disableColClass
          type="time"
          label="Time Received"
          {...register("inquiryTimeReceived")}
          disabled={isDetailsTab}
        />
      </Col>
      <Col md={6}>
        {isDetailsTab && (
          <InputField
            disableColClass
            label="Taken By"
            value={`${getValues("takenBy.surname")}, ${getValues("takenBy.givenName")}`}
            disabled
          />
        )}
        {/* TODO: update with the appropriate drop down logic */}
        {!isDetailsTab && (
          <SelectField disableColClass label="Taken By" {...register("takenById")}>
            <option value={profile?.id}>{profile?.displayName}</option>
          </SelectField>
        )}
      </Col>
      <Col md={6}>
        <InputField
          disableColClass
          type="number"
          label="Duration"
          error={formState?.errors["durationMinutes"]}
          disabled={isDetailsTab}
          {...register("durationMinutes")}
        />
      </Col>
      <Col lg={12}>
        <SelectField
          disableColClass
          label="Inquiry Origination"
          defaultValue=""
          isRequired={!isDetailsTab}
          validationState={formState?.errors.sourceId ? "error" : undefined}
          disabled={isDetailsTab}
          {...register("sourceId")}
        >
          <option disabled value="" />
          {inquiryConstantsQuery.data?.contactMethodSources.map(({ name, value }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </SelectField>
      </Col>
      <Col md={isDetailsTab ? 6 : 12}>
        <SelectField
          data-testid="inquiry-referred-by-dropdown"
          disableColClass
          label="Referred By"
          defaultValue=""
          isRequired={!isDetailsTab}
          validationState={formState?.errors.referredById ? "error" : undefined}
          disabled={isDetailsTab}
          {...register("referredById")}
        >
          <option disabled value="" />
          {inquiryConstantsQuery.data?.activeReferralSources.map(({ name, value }) => (
            <option key={value} value={value}>
              {name}
            </option>
          ))}
        </SelectField>
      </Col>

      {!!referredByDescriptionLabel && (
        <Col md={isDetailsTab ? 6 : 12}>
          <InputField
            data-testid="inquiry-referred-by-description"
            type="text"
            label={referredByDescriptionLabel}
            disabled={isDetailsTab}
            {...register("referredByOther")}
          />
        </Col>
      )}
    </Row>
  );
};
