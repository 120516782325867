import classNames from "classnames";
import { PropsWithChildren } from "react";

export interface FormRowProps {
  show?: boolean;
  className?: string;
}

export const FormRow: React.FC<PropsWithChildren<FormRowProps>> = ({ show = true, className, children }) => {
  if (!show) {
    return null;
  }
  return <div className={classNames("row standard-form-row", className)}>{children}</div>;
};

export const FormDividerRow: React.FC<FormRowProps> = ({ show = true }) => {
  if (!show) {
    return null;
  }
  return (
    <div className="col sm-12 end">
      <hr style={{ width: "100%" }} />
    </div>
  );
};

export const FormButtonRow: React.FC<PropsWithChildren<FormRowProps>> = ({ show = true, children }) => {
  if (!show) {
    return null;
  }
  return <div className="row form-button-row">{children}</div>;
};

export const FormHeaderRow: React.FC<PropsWithChildren<FormRowProps>> = ({ show = true, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="card">
      <div className="card-header" style={{ paddingLeft: "0px" }}>
        <h2>{children}</h2>
      </div>
    </div>
  );
};
