import { Outlet, useMatches, useRouteLoaderData } from "react-router-dom";

import { Header, HeaderTab } from "../components/header/header";
import { AppMenu } from "./app-menu";
import { useAccessControl } from "./use-access-control";

export const RootLayout = () => {
  const { activeCenter } = useAccessControl();

  const matches = useMatches();
  const routeLoaderData = useRouteLoaderData(matches[matches.length - 1].id) as {
    activeTab: HeaderTab;
    tabQualifier?: string;
  };

  const { activeTab, tabQualifier } = routeLoaderData ?? {};

  return (
    <>
      <Header tabQualifier={tabQualifier} activeTab={activeTab} nonTerminalCrumbs={activeCenter.name} />
      <main>
        <AppMenu />
        <Outlet />
      </main>
    </>
  );
};
