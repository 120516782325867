import { useMutation } from "@tanstack/react-query";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { InquiryFormData } from "./types";

export const useInquirySaveAll = (centerId: string) => {
  const { getToken } = useAccessToken();

  const saveAllInquiriesMutator = useMutation({
    mutationFn: async (payload: InquiryFormData[]) => upsertMultipleInquiries(await getToken(), centerId, payload)
  });

  return { saveAllInquiriesMutator };
};

export interface UpsertMultipleInquiriesResponse {
  inquiries: InquiryFormData[];
  filteredCount: number;
  totalCount: number;
  stagesCount: {
    [key: string]: number;
  };
}
export const upsertMultipleInquiries = (accessToken: string, centerId: string, payload: InquiryFormData[]) => {
  return callLcosService<UpsertMultipleInquiriesResponse>(accessToken, `/api/inquiries/${centerId}`, "PUT", payload);
};
