import { useQuery } from "@tanstack/react-query";

import { callLcosService } from "../data/lcosServices";
import { useAccessToken } from "./use-access-token";

export const useLegacyStaffIdList = (centerId: string) => {
  const { getToken } = useAccessToken();

  const getStaffIdListQuery = useQuery({
    queryKey: ["getStaffIdList", centerId],
    queryFn: async () => getStaffIdList(await getToken(), centerId),
    select: data => data.staffIds
  });

  return {
    staffIds: getStaffIdListQuery.data || [],
    isLoading: getStaffIdListQuery.isLoading
  };
};

export interface CenterStaff {
  firstName: string;
  lastName: string;
  staffId: string;
  employeeNumber: number | null;
}

const getStaffIdList = (accessToken: string, centerId: string) => {
  return callLcosService<{ staffIds: CenterStaff[] }>(accessToken, `/api/centers/${centerId}/staff-ids`, "GET");
};
