/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, type FC, useMemo } from "react";
import classNames from "classnames";

const MAX_PAGINATION_NUMBERS = 9;
const CURRENT_PAGE_PADDING_AMOUNT = 2;

interface PaginatorProps {
  numberOfPages: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
  isLoading?: boolean;
}

export const Paginator: FC<PaginatorProps> = ({ numberOfPages, currentPage, onPageChange, isLoading }) => {
  const pages = useMemo<("start" | "end" | number)[]>(() => {
    if (numberOfPages <= MAX_PAGINATION_NUMBERS) {
      return Array.from(Array(numberOfPages).keys());
    }
    const pages: ("start" | "end" | number)[] = [];
    pages.push(0);
    const currentIndex = currentPage - 1;
    const lastIndex = numberOfPages - 1;
    let rangeStart = Math.max(1, currentIndex - CURRENT_PAGE_PADDING_AMOUNT);
    let rangeEnd = Math.min(lastIndex - 1, rangeStart + 4);

    if (currentIndex <= 4) {
      rangeEnd = 6;
    }
    if (lastIndex - currentIndex <= 4) {
      rangeStart = lastIndex - 6;
    }
    if (rangeStart > 2) {
      pages.push("start");
    } else if (rangeStart > 0) {
      pages.push(1);
      if (rangeStart === 1) {
        rangeStart += 1;
      }
    }
    for (let i = rangeStart; i <= rangeEnd; i++) {
      pages.push(i);
    }
    if (rangeEnd < lastIndex - 2) {
      pages.push("end");
    } else if (rangeEnd < lastIndex - 1) {
      pages.push(lastIndex - 1);
    }
    pages.push(lastIndex);
    return pages;
  }, [numberOfPages, currentPage]);

  const previousDisabled = isLoading || currentPage === 1;
  const nextDisabled = isLoading || currentPage === numberOfPages;

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className={classNames("page-item", { disabled: previousDisabled })}>
          <a
            className="page-link"
            href="#"
            aria-label="Previous"
            onClick={() => !isLoading && onPageChange(currentPage - 1)}
            {...{ "aria-disabled": previousDisabled ? "true" : undefined }}
          >
            <span className="fas fa-chevron-left" {...{ "aria-hidden": previousDisabled ? "true" : undefined }}></span>
          </a>
        </li>
        {pages.map(pageIndex =>
          pageIndex !== "start" && pageIndex !== "end" ? (
            <li
              key={`pageNumber-${pageIndex}`}
              className={classNames("page-item", { active: currentPage === pageIndex + 1, disabled: isLoading })}
              {...{ "aria-current": currentPage === pageIndex + 1 ? "true" : undefined }}
            >
              <a className="page-link" href="#" onClick={() => !isLoading && onPageChange(pageIndex + 1)}>
                {pageIndex + 1}
              </a>
            </li>
          ) : (
            <PaginationEllipsis position={pageIndex} key={`pageNumber-${pageIndex}`} />
          )
        )}
        <li className={classNames("page-item", { disabled: nextDisabled })}>
          <a
            className="page-link"
            href="#"
            aria-label="Next"
            onClick={() => !isLoading && onPageChange(currentPage + 1)}
            {...{ "aria-disabled": nextDisabled ? "true" : undefined }}
          >
            <span className="fas fa-chevron-right" {...{ "aria-hidden": nextDisabled ? "true" : undefined }}></span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

interface PaginationEllipsisProps {
  position: "start" | "end";
}

const PaginationEllipsis: FC<PaginationEllipsisProps> = ({ position }) => {
  return (
    <li className="page-item disabled">
      <a
        className="page-link"
        href="#"
        aria-label={`Skipped pages ${position === "start" ? "after page 1" : "before the last page"}`}
        aria-disabled="true"
      >
        ...
      </a>
    </li>
  );
};

interface UsePaginatorProps {
  onPageChange?: (currentPage: number) => void;
  numberOfPages: number;
  isLoading?: boolean;
}

export const usePaginator = ({ onPageChange, numberOfPages, isLoading }: UsePaginatorProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber: number) => {
    if (!isLoading && pageNumber >= 1 && pageNumber <= numberOfPages) {
      setCurrentPage(pageNumber);
      onPageChange?.(pageNumber);
    }
  };

  const renderPaginator = () =>
    numberOfPages > 1 ? (
      <Paginator
        onPageChange={handlePageChange}
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        isLoading={isLoading}
      />
    ) : null;

  return {
    currentPage,
    setCurrentPage,
    renderPaginator
  };
};
