import React from "react";

import { StaffMember } from "../../hooks/use-user";
import { useAccessControl } from "../../app/use-access-control";
import { useRehireModal } from "./rehire";
import { usePositionChangeModal } from "./position-change";
import { useSeparationModal } from "./separation";
import { useEmploymentHistory } from "../../hooks/use-employment-history";

export interface ModifyPositionProps {
  activeStaff: StaffMember;
}

export type ModifyPositionType = "position-change" | "rehire" | "separation";

export const ModifyPosition: React.FC<ModifyPositionProps> = ({ activeStaff }) => {
  const { activeCenterId } = useAccessControl();
  const { latest, isLoading: isEmploymentHistoryLoading } = useEmploymentHistory(activeCenterId, activeStaff.id);

  const { renderRehireModal, showRehireModal } = useRehireModal({
    staffMember: activeStaff,
    initialEmploymentHistory: latest
  });

  const { renderPositionChangeModal, showPositionChangeModal } = usePositionChangeModal({
    staffMember: activeStaff,
    initialEmploymentHistory: latest
  });

  const { renderSeparationModal, showSeparationModal } = useSeparationModal({
    staffMember: activeStaff
  });

  const onClickActionButton = (type: ModifyPositionType) => () => {
    if (type === "rehire") {
      showRehireModal();
    } else if (type === "position-change") {
      showPositionChangeModal();
    } else if (type === "separation") {
      showSeparationModal();
    }
  };

  if (!activeStaff || isEmploymentHistoryLoading) {
    return null;
  }

  return (
    <>
      <div className="col col-5">
        <div className="btn-group">
          <button
            type="button"
            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="m-2">Modify Position</span>
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu">
            {activeStaff.isActive && (
              <>
                <li>
                  <button className="dropdown-item" type="button" onClick={onClickActionButton("position-change")}>
                    Position Change
                  </button>
                </li>
                <li>
                  <button className="dropdown-item" type="button" onClick={onClickActionButton("separation")}>
                    Deactivate
                  </button>
                </li>
              </>
            )}
            {!activeStaff.isActive && (
              <>
                <li>
                  <button className="dropdown-item" type="button" onClick={onClickActionButton("rehire")}>
                    Reactivate
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      <>{renderRehireModal()}</>

      <>{renderPositionChangeModal()}</>

      <>{renderSeparationModal()}</>
    </>
  );
};
