import React, { FC } from "react";

import { useAccessControl } from "../../../app/access-control-context";
import * as Permissions from "../../../data/permissions";
import { Notes } from "../../notes/notes";

export interface StaffNotesProps {
  staffMemberId?: string;
}

export const StaffNotes: FC<StaffNotesProps> = ({ staffMemberId }) => {
  const { activeCenterId, hasPermission, profile } = useAccessControl();

  const isEditingSelf = profile && profile.id === staffMemberId;
  const hasStaffEditPermission = !!(hasPermission && hasPermission(Permissions.STAFF_EDIT));
  const canCreateOrEditNotes = !isEditingSelf && hasStaffEditPermission;

  return (
    <>
      <Notes
        renderHeader={({ handleAddNote }) => (
          <h3 className="card-subheader">
            Preferences / Notes
            {canCreateOrEditNotes && (
              <button
                data-testid="add-note-staff-notes"
                className="btn btn-primary btn-sm ms-2"
                onClick={() => {
                  handleAddNote && handleAddNote();
                }}
              >
                <span className="fas fa-plus" aria-hidden="true"></span>
                <span className="sr-only">Add Note</span>
              </button>
            )}
          </h3>
        )}
        staffId={staffMemberId || ""}
        centerId={activeCenterId}
        noteType="staff"
        canCreateOrEdit={canCreateOrEditNotes}
      />
    </>
  );
};
