import React, { useCallback, useId, useMemo, useRef } from "react";
import moment from "moment";

import { LoadingOverlay } from "../../loading-overlay/loading-overlay";
import { useAccessControl } from "../../../app/access-control-context";
import * as Permissions from "../../../data/permissions";
import { FormDividerRow, FormRow } from "../../forms/form-row";
import { useStaffMemberPosition, useStaffMemberPermissions, useStaffEditabilityCheck } from "../../../hooks/use-staff";
import { useReferenceData } from "../../../hooks/use-reference-data";
import { EmployeeHistory, useEmploymentHistory } from "../../../hooks/use-employment-history";
import * as FormHelpers from "../../forms/form-helpers";
import { Confirm, ConfirmHandle } from "../../confirm/confirm";
import { ModifyPosition } from "../../modify-position/modify-position";
import { StaffMember } from "../../../hooks/use-user";
import { useEditPositionHistoryModal } from "../../modify-position/edit-position-history";
import { useModifyPosition } from "../../../hooks/use-modify-position";
import { SimpleTable, SimpleTableHeader } from "../../table";
import { IconButton } from "../../buttons/icon-button";

export interface PositionProps {
  staff?: StaffMember;
}

export const requiredPermissionForEditing = Permissions.STAFF_EDIT_PERMISSIONS;

export const Position: React.FC<PositionProps> = ({ staff }) => {
  const { activeCenterId, hasPermission, profile, hasFeatureAccess } = useAccessControl();
  const { staffMemberPermissions, isLoading: isStaffMemberPermissionsLoading } = useStaffMemberPermissions(
    activeCenterId,
    staff?.id
  );
  const { employmentHistory, isLoading: isEmploymentHistoryLoading } = useEmploymentHistory(activeCenterId, staff?.id);
  const { isRehireableMutator } = useStaffMemberPosition(activeCenterId, staff?.id);
  const { referenceData, isLoading: isReferenceDataLoading, positionsLookup } = useReferenceData();
  const hasRehireAccess = !!hasFeatureAccess?.("eligible_for_rehire_edit");
  const rehireCheckboxId = useId();
  const { permissionsDisabled } = useStaffEditabilityCheck(staff?.id);
  const isEditDisabled = useMemo(
    () => permissionsDisabled || !hasPermission?.(requiredPermissionForEditing),
    [permissionsDisabled, hasPermission]
  );
  const staffId = typeof staff === "object" && staff !== null && staff.id ? staff.id : "";
  const { deletePositionMutator } = useModifyPosition(activeCenterId, staffId);

  const onClickIsRehireable: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      isRehireableMutator.mutate(e.target.checked);
    },
    [isRehireableMutator]
  );

  const confirmDeleteHolidayModal = useRef<ConfirmHandle>(null);

  const handleDeleteClick = useCallback((employeeHistory: EmployeeHistory) => {
    confirmDeleteHolidayModal.current &&
      confirmDeleteHolidayModal.current.confirm(() => {
        deletePositionMutator.mutate(employeeHistory.id);
      });
  }, []);

  const today = moment(new Date());

  const positionHistoryHeaders = useMemo<SimpleTableHeader<EmployeeHistory>[]>(() => {
    const headers: SimpleTableHeader<EmployeeHistory>[] = [
      {
        renderKey: "position",
        name: "Position",
        render: item => positionsLookup[item.position]
      },
      {
        renderKey: "start",
        name: "Start",
        render: item => FormHelpers.formatDateString(item.start)
      },
      {
        renderKey: "end",
        name: "End",
        render: item => FormHelpers.formatDateString(item.end)
      }
    ];

    if (!isEditDisabled) {
      headers.push({
        renderKey: "buttons",
        name: "",
        render: item => {
          const isPositionEditable = !item.end || moment(item.end).isSameOrAfter(today, "day");
          const isPositionDeletable = item.start && moment(item.start).isAfter(today, "day");
          return (
            <>
              {isPositionEditable && (
                <IconButton
                  title="Edit"
                  icon="edit"
                  enableTooltip
                  data-testid={`position-history-edit-button-${item.id}`}
                  className="btn btn-table"
                  onClick={() => handleEditClick(item)}
                />
              )}

              {isPositionDeletable && (
                <IconButton
                  title="Delete"
                  icon="trash"
                  enableTooltip
                  data-testid={`position-history-delete-button-${item.id}`}
                  className="btn btn-table btn-danger"
                  onClick={() => handleDeleteClick(item)}
                />
              )}
            </>
          );
        },
        tableDataCellProps: {
          textAlign: "right",
          isAction: true
        }
      } as SimpleTableHeader<EmployeeHistory>);
    }

    return headers;
  }, [isEditDisabled, handleDeleteClick, handleDeleteClick, positionsLookup]);

  const { renderEditPositionHistoryModal, showEditPositionHistoryModal } = useEditPositionHistoryModal({
    staffMember: staff
  });

  const handleEditClick = (employeeHistory: EmployeeHistory) => {
    showEditPositionHistoryModal(employeeHistory);
  };

  if (!referenceData || !staff || !profile || !staffMemberPermissions || !employmentHistory || !positionsLookup) {
    return null; // delay render until we have these
  }

  return (
    <>
      <h3 className="card-subheader">Position</h3>

      <FormRow show={!isEditDisabled}>
        <ModifyPosition activeStaff={staff} />
      </FormRow>
      <FormDividerRow show={!isEditDisabled} />

      <LoadingOverlay loading={isRehireableMutator.isLoading}>
        <FormRow show={hasRehireAccess}>
          <div className="col col-6">
            <label htmlFor={rehireCheckboxId}>Eligible for Reactivation</label>
          </div>
          <div className="col">
            <input
              type="checkbox"
              id={rehireCheckboxId}
              checked={staff.isRehireable}
              className="form-check-input"
              style={{ height: "20px", width: "20px", marginTop: "6px" }}
              onChange={onClickIsRehireable}
              disabled={isEditDisabled}
            />
          </div>
        </FormRow>
        <FormDividerRow show={hasRehireAccess} />
      </LoadingOverlay>

      <h3 className="card-subheader">Position History</h3>

      <SimpleTable
        tableCollapseScreenSize="sm"
        headers={positionHistoryHeaders}
        data={employmentHistory}
        isLoading={
          isReferenceDataLoading ||
          isRehireableMutator.isLoading ||
          isEmploymentHistoryLoading ||
          isStaffMemberPermissionsLoading
        }
        uniqueIdKey="id"
      />

      <Confirm
        ref={confirmDeleteHolidayModal}
        title="Confirm"
        message="Would you like to delete this employment history?"
      />

      <>{renderEditPositionHistoryModal()}</>
    </>
  );
};
