import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";

import { CenterDetail, DefaultEditableCenterDetail, useCenters } from "../../hooks/use-center";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import { Field } from "../forms/legacy/field";
import { StateSelector } from "../forms/legacy/state-selector";
import { FormButtonRow, FormRow, FormDividerRow } from "../forms/form-row";
import { PencilButton } from "../forms/pencil-button";
import * as FormHelpers from "../forms/form-helpers";
import { ModalToastHeader } from "../../toasts/modal-toast-header";

export interface CenterInformationProps {
  center: CenterDetail;
  mode?: "edit" | "view";
  onSave?: (updated: CenterDetail) => void;
  onCancel?: () => void;
  onEdit?: () => void;
}

export const CenterInformation: React.FC<CenterInformationProps> = ({
  center,
  mode = "view",
  onEdit,
  onSave,
  onCancel
}) => {
  const [isValidForSave, setIsValidForSave] = useState<boolean>(false);

  const [buffer, setBuffer] = useState<CenterDetail>({
    ...DefaultEditableCenterDetail,
    ...center,
    phoneAdvertised: FormHelpers.formatPhoneNumber(center?.phoneAdvertised ?? ""),
    phoneDirect: FormHelpers.formatPhoneNumber(center?.phoneDirect ?? ""),
    phoneTollfree: FormHelpers.formatPhoneNumber(center?.phoneTollfree ?? "")
  });

  const { centerMutator } = useCenters();

  useEffect(() => {
    setBuffer(prev => ({
      ...DefaultEditableCenterDetail,
      ...prev,
      ...center,
      phoneAdvertised: FormHelpers.formatPhoneNumber(center?.phoneAdvertised ?? ""),
      phoneDirect: FormHelpers.formatPhoneNumber(center?.phoneDirect ?? ""),
      phoneTollfree: FormHelpers.formatPhoneNumber(center?.phoneTollfree ?? "")
    }));
  }, [center]);

  //TODO: RHF-ify
  const onChangeValue = useCallback(
    (property: string): ((newvalue: string) => void) =>
      newvalue => {
        if (property === "phoneAdvertised" || property === "phoneDirect" || property === "phoneTollfree") {
          newvalue = FormHelpers.formatPhoneNumber(newvalue);
        }
        setBuffer(prev => ({ ...prev, [property]: newvalue }));
      },
    []
  );

  useEffect(() => {
    setIsValidForSave(FormHelpers.isValidCenterDetail(buffer));
  }, [buffer]);

  const localSave = () => {
    centerMutator.mutate(buffer, {
      onSuccess: updated => onSave && onSave(updated)
    });
  };

  return (
    <LoadingOverlay loading={centerMutator.isLoading}>
      <div className={classnames("center-information-outer-container form", { "edit-mode": mode === "edit" })}>
        {mode === "edit" && <ModalToastHeader />}
        <FormRow>
          <Field
            value={buffer.name}
            mode={mode}
            label="Center Name"
            onValidate={FormHelpers.isValidRequiredString}
            onChangeValue={onChangeValue("name")}
          >
            <PencilButton
              onEdit={mode === "view" ? onEdit : undefined}
              data-testid="pencil-button-center-information"
            />
          </Field>
        </FormRow>
        <FormRow>
          <Field
            value={buffer.line1}
            mode={mode}
            label="Address 1"
            onValidate={FormHelpers.isValidRequiredString}
            onChangeValue={onChangeValue("line1")}
            required
          />
        </FormRow>
        <FormRow>
          <Field
            value={buffer.line2}
            mode={mode}
            label="Address 2"
            onValidate={FormHelpers.isValidOptionalString}
            onChangeValue={onChangeValue("line2")}
          />
        </FormRow>
        <FormRow>
          <Field
            value={buffer.zipcode}
            mode={mode}
            label="ZIP"
            onValidate={FormHelpers.isValidZipcode}
            onChangeValue={onChangeValue("zipcode")}
            required
          />
          <Field
            value={buffer.city}
            mode={mode}
            label="City"
            onValidate={FormHelpers.isValidRequiredString}
            onChangeValue={onChangeValue("city")}
            required
          />
          <StateSelector
            value={buffer.state}
            mode={mode}
            label="State"
            onValidate={FormHelpers.isValidRequiredString}
            onChangeValue={onChangeValue("state")}
            required
          />
        </FormRow>
        <FormRow>
          <Field
            value={buffer.email}
            mode={mode}
            label="Email Address"
            onValidate={FormHelpers.isValidEmailAddress}
            onChangeValue={onChangeValue("email")}
            required
          />
        </FormRow>
        <FormRow>
          <Field
            value={FormHelpers.formatPhoneNumber(buffer.phoneDirect)}
            mode={mode}
            label="Phone Direct"
            onValidate={FormHelpers.isValidOptionalPhoneNumber}
            onChangeValue={onChangeValue("phoneDirect")}
            required
          />
          <Field
            value={FormHelpers.formatPhoneNumber(buffer.phoneAdvertised)}
            mode={mode}
            label="Phone Advertised"
            onValidate={FormHelpers.isValidPhoneNumber}
            onChangeValue={onChangeValue("phoneAdvertised")}
            required
          />
        </FormRow>
        <FormRow>
          <Field
            value={buffer.url}
            mode={mode}
            label="Url"
            onValidate={FormHelpers.isValidRequiredString}
            onChangeValue={onChangeValue("url")}
            required
          >
            {mode === "view" && !!buffer.url && (
              <a href={buffer.url} rel="noreferrer" target="_blank" style={{ marginLeft: "10px" }}>
                <i className="fas fa-external-link-alt"></i>
              </a>
            )}
          </Field>
        </FormRow>
        <FormDividerRow show={mode === "view"} />
        <FormRow show={mode === "view"}>
          <Field
            value={buffer.latitude ?? ""}
            mode={mode}
            label="Latitude"
            onValidate={FormHelpers.isValidOptionalString}
            onChangeValue={onChangeValue("latitude")}
          />
          <Field
            value={buffer.longitude ?? ""}
            mode={mode}
            label="Longitude"
            onValidate={FormHelpers.isValidOptionalString}
            onChangeValue={onChangeValue("longitude")}
          />
        </FormRow>

        <FormButtonRow show={mode === "edit"}>
          <button className="btn btn-secondary" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={localSave} disabled={!isValidForSave}>
            Save
          </button>
        </FormButtonRow>
      </div>
    </LoadingOverlay>
  );
};
