import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { useAccessToken } from "../use-access-token";
import { callLcosService } from "../../data/lcosServices";
import { lexicalSorter } from "./utilities";
import { CenterHoliday, CenterHolidays } from "./types";

export const useCenterHolidays = (centerId: string | undefined, enabled = true) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const getCenterHolidaysData = async () => {
    return getCenterHolidaysList(await getToken(), centerId);
  };

  const queryKey = ["centerHolidaysData", centerId];

  const holidayQuery = useQuery({ queryKey, queryFn: getCenterHolidaysData, enabled: !!centerId && enabled });

  const currentHolidays = useMemo(() => {
    return holidayQuery.data?.holidays.sort(lexicalSorter);
  }, [holidayQuery.data, centerId, enabled]);

  const holidayEditMutator = useMutation({
    mutationFn: async (holiday: CenterHoliday) => editCenterHoliday(await getToken(), centerId, holiday),

    onSuccess: newHolidays => {
      queryClient.setQueryData(queryKey, () => ({ ...newHolidays }));
    }
  });

  const holidayCreateMutator = useMutation({
    mutationFn: async (holiday: CenterHoliday) => createCenterHoliday(await getToken(), centerId, holiday),

    onSuccess: newHolidays => {
      queryClient.setQueryData(queryKey, () => ({ ...newHolidays }));
    }
  });

  const deleteHolidayMutator = useMutation({
    mutationFn: async (holidayId: string) => deleteStaffMemberPermissions(await getToken(), centerId, holidayId),

    onSuccess: newHolidays => {
      queryClient.setQueryData(queryKey, () => ({ ...newHolidays }));
    }
  });

  return {
    holidays: currentHolidays,
    isLoading: holidayQuery.isLoading,
    holidayEditMutator,
    holidayCreateMutator,
    deleteHolidayMutator
  };
};

export const getCenterHolidaysList = (accessToken: string, centerId: string | undefined) => {
  return callLcosService<CenterHolidays>(accessToken, `/api/centers/${centerId}/holidays`);
};

export const createCenterHoliday = (accessToken: string, centerId: string | undefined, holiday: CenterHoliday) => {
  return callLcosService<CenterHolidays>(accessToken, `/api/centers/${centerId}/holiday`, "POST", holiday);
};

export const editCenterHoliday = (accessToken: string, centerId: string | undefined, holiday: CenterHoliday) => {
  return callLcosService<CenterHolidays>(accessToken, `/api/centers/${centerId}/holiday/${holiday.id}`, "PUT", holiday);
};

const deleteStaffMemberPermissions = (
  accessToken: string,
  centerId: string | undefined,
  holidayId: string | undefined
) => {
  return callLcosService<CenterHolidays>(accessToken, `/api/centers/${centerId}/holiday/${holidayId}`, "DELETE");
};
