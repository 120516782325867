import { type FC, type ForwardedRef, type PropsWithChildren, type ReactNode } from "react";
import isEqual from "lodash/isEqual";

import {
  Table,
  TableDataCell,
  TableHeaderCell,
  TableRow,
  type TableProps,
  type TableCellProps,
  type TableDataCellProps
} from "./table";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";

interface HeaderWithRenderKey<T> {
  key?: never;
  renderKey: string;
  render: (item: T, rowIndex: number) => ReactNode;
}

interface HeaderWithKey<T> {
  key: keyof T;
  renderKey?: never;
  render?: never;
}

/** one and only one of either key or renderKey and render are required */
type Header<T> = HeaderWithKey<T> | HeaderWithRenderKey<T>;

export type SimpleTableHeader<T> = Header<T> & {
  // column name:
  name: string;
  // label override for mobile view
  label?: string;
  onCellClick?: (item: T, value?: T[keyof T]) => void;
  tableHeaderCellProps?: TableCellProps;
  tableDataCellProps?: TableDataCellProps;
};

export interface SimpleTableProps<T> extends TableProps {
  headers: SimpleTableHeader<T>[];
  data?: T[];
  onRowClick?: (row: T) => void;
  isLoading?: boolean;
  isScrollable?: boolean;
  onHeaderClick?: (header: SimpleTableHeader<T>) => void;
  activeItem?: T;
  tableRef?: ForwardedRef<HTMLTableElement>;
  uniqueIdKey: keyof T;
  printCaption?: string;
  // to insert arbitrary rows at the top of the table
  insertRows?: ReactNode;
}

export const SimpleTable = <T extends {}>({
  headers,
  data = [],
  isLoading,
  onRowClick,
  onHeaderClick,
  activeItem,
  isScrollable,
  tableRef,
  uniqueIdKey,
  printCaption,
  insertRows = null,
  ...tableProps
}: SimpleTableProps<T>) => {
  const handleCellClick = ({
    onCellClick,
    item,
    value
  }: {
    onCellClick?: (item: T, value?: T[keyof T]) => void;
    item: T;
    value?: T[keyof T];
  }) => {
    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      onCellClick?.(item, value);
    };
    return handleClick;
  };

  const TableWrapper: FC<PropsWithChildren> = ({ children }) =>
    isScrollable ? <div className="table-scrollable-container">{children}</div> : <>{children}</>;

  return (
    <LoadingOverlay loading={isLoading}>
      <TableWrapper>
        <Table {...tableProps} ref={tableRef}>
          {!!printCaption && <caption className="print-only-caption">{printCaption}</caption>}
          <thead>
            <TableRow>
              {headers.map(header => (
                <TableHeaderCell
                  key={`${header.renderKey ?? (header.key as string)}-header-cell`}
                  onClick={() => onHeaderClick?.(header)}
                  {...header.tableHeaderCellProps}
                >
                  {header.name}
                </TableHeaderCell>
              ))}
            </TableRow>
          </thead>
          <tbody>
            {!data.length && (
              <tr>
                <td colSpan={headers.length} className="text-center">
                  <strong>No data found.</strong>
                </td>
              </tr>
            )}
            {insertRows}
            {data.map((item, index) => (
              <TableRow
                isActive={isEqual(item, activeItem)}
                key={item[uniqueIdKey] as string}
                onClick={() => onRowClick?.(item)}
              >
                {headers.map(header => {
                  const value = header.key ? item[header.key] : undefined;
                  const { onCellClick } = header;
                  return (
                    <TableDataCell
                      key={`${item[uniqueIdKey]}-${header.renderKey ?? (header.key as string)}-data-cell`}
                      onClick={onCellClick ? handleCellClick({ onCellClick, item, value }) : undefined}
                      label={header.label !== undefined ? header.label : header.name}
                      {...header.tableDataCellProps}
                    >
                      {header.render ? header.render(item, index) : (value as string)}
                    </TableDataCell>
                  );
                })}
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </LoadingOverlay>
  );
};
