import { useQuery } from "@tanstack/react-query";

const fetchCityAndState = async (zipcode: string) => {
  const urlParams = new URLSearchParams({
    "api-version": "1.0",
    "subscription-key": process.env.REACT_APP_AZURE_MAPS_SUBSCRIPTION_KEY ?? "",
    query: `${zipcode} United States`,
    entityType: "PostalCodeArea"
  });

  const response = await fetch(`https://atlas.microsoft.com/search/address/json?${urlParams}`, {
    headers: { "x-ms-client-id": process.env.REACT_APP_AZURE_MAPS_CLIENT_ID ?? "" }
  });

  try {
    const {
      results: [
        {
          address: { postalName: city, countrySubdivisionCode: stateId }
        }
      ]
    } = await response.json();

    return { city, state: stateId };
  } catch {
    // silence errors if the search doesn't return a result-- this is a non-critical feature that we don't need to alert the user about, if it fails.
    // eslint-disable-next-line no-console
    console.log(`No results found for ZIP Code: ${zipcode}`);
    return { city: "", state: "" };
  }
};

export const useCityAndState = (zipcode: string, options: { enabled: boolean }) =>
  useQuery({
    queryKey: ["cityAndState", zipcode],
    queryFn: () => fetchCityAndState(zipcode),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options
  });
