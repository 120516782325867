import React from "react";
import { useAccessControl } from "../../../app/access-control-context";
import { Subject, useSubjects } from "../../../hooks/use-subjects";

import { SubjectsAccordion } from "../../accordion/subjects-accordion";
import * as Permissions from "../../../data/permissions";

export interface SubjectProps {
  staffMemberId: string;
}

export const Subjects: React.FC<SubjectProps> = ({ staffMemberId }) => {
  const { activeCenterId, hasPermission, profile } = useAccessControl();

  const { subjects, updateSingleSubjectMutator, updateMultipleSubjectsMutator } = useSubjects(
    activeCenterId,
    staffMemberId
  );

  const isEditingSelf = profile?.id === staffMemberId;
  const hasStaffEditPermission = !!hasPermission?.(Permissions.STAFF_EDIT);
  const canEdit = !isEditingSelf && hasStaffEditPermission;

  const handleSelectSubject = (subject: Subject, isActive: boolean) => {
    updateSingleSubjectMutator.mutate({ ...subject, isActive });
  };
  const handleSelectSubjectsGroup = (subjects: Subject[], isActive: boolean) => {
    updateMultipleSubjectsMutator.mutate(subjects.map(subject => ({ ...subject, isActive })));
  };

  return (
    <>
      <h3 className="card-subheader">Subjects</h3>
      <SubjectsAccordion
        subjectGroups={subjects}
        readOnly={!canEdit}
        onSelectSubject={handleSelectSubject}
        onSelectSubjectsGroup={handleSelectSubjectsGroup}
      />
    </>
  );
};
