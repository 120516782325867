import { FC, forwardRef, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { Controller, ControllerFieldState, ControllerProps } from "react-hook-form";

import { getMonthString, getCalendarDates } from "./helper";
import { DatesOfInterest, LegendKey, LegendKeyType } from "./types";

const DAYS_OF_THE_WEEK = ["S", "M", "T", "W", "T", "F", "S"];

interface CalendarProps {
  compact?: boolean;
  shaded?: boolean;
  containerClassName?: string;
  datesOfInterest?: DatesOfInterest;
  field?: {
    onBlur?: () => void;
    onChange?: (value: Date) => void;
    value?: Date;
  };
  fieldState?: ControllerFieldState;
  onShownDatesChange?: (startDate: Date, endDate: Date) => void;
}

export const Calendar: FC<CalendarProps> = forwardRef<HTMLDivElement, CalendarProps>(
  (
    { compact = false, containerClassName, shaded = false, datesOfInterest, field, onShownDatesChange, fieldState },
    ref
  ) => {
    const today = useMemo(() => new Date(), []);
    const [currentMonth, setCurrentMonth] = useState(today);
    const calendarDates = useMemo(
      () => getCalendarDates(currentMonth, datesOfInterest),
      [currentMonth, datesOfInterest]
    );

    const [selectedDate, setSelectedDate] = useState<Date | undefined>();

    useEffect(() => {
      setSelectedDate(field?.value);
    }, [field?.value]);

    const legendKeyRecords = useMemo(
      () =>
        Object.keys(datesOfInterest || {}).filter(
          key => datesOfInterest?.[key as LegendKeyType]?.legendKey.showLegendKey
        ) as LegendKeyType[],
      [datesOfInterest]
    );

    useEffect(() => {
      onShownDatesChange?.(calendarDates[0].date, calendarDates[calendarDates.length - 1].date);
    }, [calendarDates]);

    const handlePreviousMonthClick = () => {
      setCurrentMonth(oldState => new Date(oldState.getFullYear(), oldState.getMonth() - 1));
    };

    const handleNextMonthClick = () => {
      setCurrentMonth(oldState => new Date(oldState.getFullYear(), oldState.getMonth() + 1));
    };

    const handleSelectDate = (date: Date) => {
      setSelectedDate(date);
      field?.onChange?.(date);
    };

    return (
      <>
        <div
          className={classNames("calendar-widget", containerClassName, {
            "calendar-widget--compact": compact,
            shaded,
            "is-invalid": fieldState?.error
          })}
          ref={ref}
          onBlur={field?.onBlur}
        >
          <div className="calendary-header">
            <button className="btn btn-secondary" onClick={handlePreviousMonthClick}>
              <span className="fas fa-chevron-left" />
            </button>
            <h3>{`${getMonthString(currentMonth)} ${currentMonth.getFullYear()}`}</h3>
            <button className="btn btn-secondary" onClick={handleNextMonthClick}>
              <span className="fas fa-chevron-right" />
            </button>
          </div>
          <ul className="calendar-simple">
            {DAYS_OF_THE_WEEK.map((day, index) => (
              <li className="day" key={index}>
                {DAYS_OF_THE_WEEK[index]}
              </li>
            ))}
            {calendarDates.map(({ date, className, tooltipText, isSelectable }) => (
              <CalendarDay
                key={`${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`}
                isSelected={!!selectedDate && +date === +selectedDate}
                className={className}
                tooltipText={tooltipText}
                isToday={
                  date.getFullYear() === today.getFullYear() &&
                  date.getMonth() === today.getMonth() &&
                  date.getDate() === today.getDate()
                }
                isOtherMonth={date.getMonth() !== currentMonth.getMonth()}
                date={date.getDate()}
                onSelect={isSelectable ? () => handleSelectDate(date) : undefined}
              />
            ))}
          </ul>
          {!!legendKeyRecords.length && (
            <ul className="calendar-legend">
              {legendKeyRecords.map((legendKeyRecord, index) => (
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                <CalendarLegendKey key={index} lengendKey={datesOfInterest![legendKeyRecord]!.legendKey} />
              ))}
            </ul>
          )}
        </div>
        {!!fieldState?.error?.message && <span className="invalid-feedback">{fieldState.error.message}</span>}
      </>
    );
  }
);

Calendar.displayName = "Calendar";

interface CalendarDayProps {
  className?: string;
  isSelected: boolean;
  onSelect?: () => void;
  isToday: boolean;
  isOtherMonth: boolean;
  date: number;
  tooltipText?: string;
}
export const CalendarDay: FC<CalendarDayProps> = ({
  className,
  isSelected,
  onSelect,
  isToday,
  isOtherMonth,
  date,
  tooltipText
}) => {
  const liRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (liRef.current) {
      //@ts-expect-error
      const tooltip = new global.bootstrap.Tooltip(liRef.current);
      return () => {
        tooltip.hide();
      };
    }
  }, [liRef]);

  const tooltipProps = isSelected
    ? { title: "Selected Day", "data-bs-original-title": "Selected Day" }
    : tooltipText
      ? { title: tooltipText, "data-bs-original-title": tooltipText }
      : {};

  return (
    <li
      className={classNames(isSelected ? undefined : className, {
        othermonth: isOtherMonth,
        selected: isSelected,
        today: isToday
      })}
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      {...tooltipProps}
      ref={liRef}
      onClick={onSelect}
    >
      <span className="date">{date}</span>
    </li>
  );
};

interface CalendarLegendKeyProps {
  lengendKey: LegendKey;
}

const CalendarLegendKey: FC<CalendarLegendKeyProps> = ({ lengendKey }) => {
  return (
    <li>
      <span className={classNames("number", lengendKey.className)}>&nbsp;</span>
      <span>{lengendKey.legendKeyLabel}</span>
    </li>
  );
};

type CalendarControllerProps = Omit<CalendarProps, "field" | "ref"> & Omit<ControllerProps, "render">;

export const CalendarController: FC<CalendarControllerProps> = ({
  compact,
  shaded,
  containerClassName,
  datesOfInterest,
  onShownDatesChange,
  ...controllerProps
}) => {
  const calendarProps = {
    compact,
    shaded,
    containerClassName,
    datesOfInterest,
    onShownDatesChange
  };
  return (
    <Controller
      {...controllerProps}
      render={({ field: { onBlur, onChange, value }, fieldState }) => (
        <Calendar field={{ onBlur, onChange, value }} fieldState={fieldState} {...calendarProps} />
      )}
    />
  );
};
