import { useQuery } from "@tanstack/react-query";
import uniqBy from "lodash/uniqBy";
import invariant from "tiny-invariant";

import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { EXTENDED_CACHE_TIME, referenceRecordToCodeLookup, referenceRecordsToLookup } from "../use-reference-data";
import type { CenterConstants } from "./types";

export const useCenterConstants = (centerId?: string) => {
  const { getToken } = useAccessToken();
  const query = useQuery({
    queryKey: ["getCenterConstants", centerId],
    queryFn: async () => {
      invariant(centerId, "Query disabled without centerId");
      return getCenterConstants(await getToken(), centerId);
    },
    enabled: !!centerId?.length,
    cacheTime: EXTENDED_CACHE_TIME,
    staleTime: EXTENDED_CACHE_TIME,
    select: formatConstants
  });

  return {
    activeConstants: query.data ?? defaultConstants,
    isLoading: query.isLoading
  };
};

const formatConstants = ({
  services,
  programs,
  subjects,
  tests,
  appointmentLocations,
  appointmentTypes,
  ...rest
}: CenterConstants) => ({
  services: uniqBy(services, "value"),
  activeServices: services.filter(service => service.isActiveForCenter),
  programs,
  activePrograms: programs.filter(program => program.isActiveForCenter),
  subjects,
  activeSubjects: subjects.filter(subject => subject.isActiveForCenter),
  tests,
  activeTests: tests.filter(test => test.isActiveForCenter),
  appointmentLocations,
  activeAppointmentLocations: appointmentLocations.filter(location => location.isActiveForCenter),
  appointmentTypes,
  activeAppointmentTypes: appointmentTypes.filter(appointmentType => appointmentType.isActiveForCenter),
  appointmentTypeLookup: referenceRecordsToLookup(appointmentTypes),
  appointmentTypeCodeLookup: referenceRecordToCodeLookup(appointmentTypes),
  ...rest
});

const defaultConstants: ReturnType<typeof formatConstants> = {
  services: [],
  activeServices: [],
  programs: [],
  activePrograms: [],
  subjects: [],
  activeSubjects: [],
  tests: [],
  activeTests: [],
  bookingsAppointmentDurations: {},
  testDurations: {},
  chainableFieldsDict: {
    appointmentTypesDict: {},
    servicesDict: {},
    subServicesDict: {}
  },
  appointmentLocations: [],
  activeAppointmentLocations: [],
  appointmentTypes: [],
  activeAppointmentTypes: [],
  appointmentTypeLookup: {},
  appointmentTypeCodeLookup: {},
  timezone: "UTC"
};

const getCenterConstants = (accessToken: string, centerId: string) => {
  return callLcosService<CenterConstants>(accessToken, `/api/centers/${centerId}/constants`, "GET");
};
