import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAccessToken } from "./use-access-token";
import { callLcosService } from "../data/lcosServices";

export interface Subject {
  subjectId: string;
  subjectName: string;
  isActive: boolean;
  serviceName: string;
  group?: string;
}

export interface SubjectGroup {
  displayName: string;
  subjects: Subject[];
  group?: SubjectGroup[] | null;
}

export const useSubjects = (centerId: string, userId: string) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const getSubjectsData = async () => {
    return getSubjects(await getToken(), centerId, userId);
  };

  const queryKey = ["subjectsData", centerId, userId];

  const subjectsQuery = useQuery({
    queryKey,
    queryFn: getSubjectsData,
    enabled: !!centerId && !!userId
  });

  const updateSingleSubjectMutator = useMutation({
    mutationFn: async (updatedSubject: Subject) =>
      updateSingleSubject(await getToken(), centerId, userId, updatedSubject),

    onSuccess: newSubjects => {
      queryClient.setQueryData(queryKey, newSubjects);
    }
  });

  const updateMultipleSubjectsMutator = useMutation({
    mutationFn: async (updatedSubjects: Subject[]) =>
      updateMultipleSubjects(await getToken(), centerId, userId, updatedSubjects),

    onSuccess: newSubjects => {
      queryClient.setQueryData(queryKey, newSubjects);
    }
  });

  return {
    subjects: subjectsQuery.data || [],
    isLoading: subjectsQuery.isLoading,
    updateSingleSubjectMutator,
    updateMultipleSubjectsMutator
  };
};

export const getSubjects = (accessToken: string, centerId: string | undefined, userId: string | undefined) => {
  return callLcosService<SubjectGroup[]>(accessToken, `/api/subjects/${centerId}/${userId}`);
};

export const updateSingleSubject = (
  accessToken: string,
  centerId: string | undefined,
  userId: string | undefined,
  subject: Subject
) => {
  return callLcosService<SubjectGroup[]>(
    accessToken,
    `/api/subjects/${centerId}/${userId}/${subject.subjectId}`,
    "PUT",
    subject
  );
};

export const updateMultipleSubjects = (
  accessToken: string,
  centerId: string | undefined,
  userId: string | undefined,
  subjects: Subject[]
) => {
  return callLcosService<SubjectGroup[]>(accessToken, `/api/subjects/${centerId}/${userId}`, "PUT", {
    subjects
  });
};
