import { useQuery } from "@tanstack/react-query";

import { anyStaffMember } from "./constants";
import { callLcosService } from "../../data/lcosServices";
import { inquiryQueryKeys } from "./query-keys";
import { useAccessToken } from "../use-access-token";
import { useAccessControl } from "../../app/use-access-control";
import { useCallback } from "react";
import { type InquiryAppointmentStaffMember } from "./types";

export const useInquiryAppointmentAvailableStaff = (centerId: string, appointmentId?: number) => {
  const { getToken } = useAccessToken();
  const { hasFeatureAccess } = useAccessControl();

  return useQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: inquiryQueryKeys.appointmentAvailableStaff(centerId, appointmentId!),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => getAppointmentStaffMembers(await getToken(), centerId, appointmentId!),
    enabled: !!(centerId && appointmentId),
    select: useCallback((data: InquiryAppointmentStaffMember[]) => {
      if (hasFeatureAccess?.("appointments_any_staff") && data?.length > 0) {
        data.splice(0, 0, anyStaffMember);
      }
      return data || [];
    }, [])
  });
};

const getAppointmentStaffMembers = (accessToken: string, centerId: string, appointmentId: number) => {
  return callLcosService<InquiryAppointmentStaffMember[]>(
    accessToken,
    `/api/inquiries/${centerId}/appointments/${appointmentId}/available_staff_members`
  );
};
