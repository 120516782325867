import { useQuery } from "@tanstack/react-query";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { useMemo } from "react";
import { WithId, FamilyContact } from "./types";

export const useFindFamilyContact = (search?: string) => {
  const { getToken } = useAccessToken();

  const query = useQuery({
    queryKey: ["findFamilyContact", search],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: async () => findFamilyContact(await getToken(), search!),
    enabled: !!search?.length
  });

  const contacts = useMemo(() => query.data?.contacts || [], [query.data]);

  return {
    contacts,
    // contacts: search ? contacts : [],
    isLoading: query.isLoading
  };
};

const findFamilyContact = (accessToken: string, search: string) => {
  return callLcosService<{ contacts: WithId<FamilyContact>[] }>(
    accessToken,
    `/api/families/contacts?search=${search}`,
    "GET"
  );
};
