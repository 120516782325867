import React, { useMemo, useState } from "react";

import { ContentPad, ContentViewport, SinglePanel } from "../../app/app-layout";
import { useAccessControl } from "../../app/access-control-context";
import { useInquiryAppointmentStaff } from "../../hooks/use-inquiries";
import { useCenterConstants } from "../../hooks/use-center";
import { FilterDropdown, FilterDropdownOption } from "../../components/filter-dropdown/filter-dropdown";

export const AppointmentCalendarScreen: React.FC = () => {
  const { activeCenterId } = useAccessControl();

  const { activeConstants } = useCenterConstants(activeCenterId);

  const [eventTypes, setEventTypes] = useState<string[]>([]);
  const [locations, setLocations] = useState<string[]>([]);
  const [staff, setStaff] = useState<string[]>([]);

  const eventTypeOptions = useMemo(() => {
    return activeConstants.activeAppointmentTypes.map(at => ({ value: at.value.toString(), label: at.name }));
  }, [activeConstants.activeAppointmentTypes]);

  const locationOptions = useMemo(() => {
    return activeConstants.activeAppointmentLocations.map(at => ({ value: at.value.toString(), label: at.name }));
  }, [activeConstants.activeAppointmentTypes]);

  const staffQuery = useInquiryAppointmentStaff(activeCenterId, { get_all: true });

  const staffOptions: FilterDropdownOption[] = useMemo(() => {
    return (staffQuery.data ?? [])
      .map(sd => ({ value: sd.id, label: sd.displayName }))
      .sort((left, right) => left.label.localeCompare(right.label));
  }, [staffQuery.data]);

  const onEventTypeFilterChange = (values: string[]) => {
    setEventTypes(values);
  };

  const onStaffFilterChange = (values: string[]) => {
    setStaff(values);
  };

  const onLocationFilterChange = (values: string[]) => {
    setLocations(values);
  };

  return (
    <ContentViewport>
      <SinglePanel>
        <ContentPad>
          <div className="row calendar-nav-filters">
            <div className="col">
              <div className="d-flex align-items-center">
                <div className="calendar-filters-left">
                  <FilterDropdown title="Event Type" options={eventTypeOptions} onChange={onEventTypeFilterChange} />
                  <FilterDropdown title="Staff" options={staffOptions} onChange={onStaffFilterChange} />
                  <FilterDropdown title="Location" options={locationOptions} onChange={onLocationFilterChange} />
                </div>
                <div className="calendar-filters-right">
                  <ul className="calendar-legend">
                    <li>
                      <span className="number ae">&nbsp;</span>
                      <span>Academic Evaluation</span>
                    </li>
                    <li>
                      <span className="number conference">&nbsp;</span>
                      <span>Conference</span>
                    </li>
                    <li>
                      <span className="number consultation">&nbsp;</span>
                      <span>Consultation</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ContentPad>
      </SinglePanel>
    </ContentViewport>
  );
};

export const appointmentCalendarLoader = () => {
  return {
    activeTab: "Dashboard",
    tabQualifier: "Calendar"
  };
};
