import { useReferenceData } from "../../../hooks/use-reference-data";
import { ExtendableSelectorProps, Selector } from "./selector";

export const DaySelector: React.FC<ExtendableSelectorProps> = ({ ...selectorProps }) => {
  const { referenceData } = useReferenceData();

  if (!referenceData || !referenceData.weekdays) {
    return null;
  }

  return (
    <Selector
      {...selectorProps}
      options={referenceData.weekdays.map(wd => ({ id: wd.value, name: wd.name }))}
      emptyOption={{ id: "", name: "Select a Day" }}
    />
  );
};
