import React from "react";

import { ContentPad, ContentViewport, SinglePanel } from "../../app/app-layout";
import { useAccessControl } from "../../app/access-control-context";
import { Notes } from "../../components/notes/notes";
import * as Permissions from "../../data/permissions";
import { AccessControlGate } from "../../app/access-control-gate";

export const CenterNotesScreen: React.FC = () => {
  const { activeCenter, hasPermission } = useAccessControl();

  if (!activeCenter) {
    return null;
  }

  return (
    <ContentViewport>
      <SinglePanel>
        <ContentPad>
          <div className="card">
            <div className="card-body">
              <Notes
                renderHeader={({ handleAddNote }) => (
                  <div className="card-header">
                    <h2>
                      Preferences / Notes
                      <AccessControlGate required={Permissions.CENTER_EDIT_NOTES}>
                        <button
                          className="btn btn-primary btn-sm ms-2"
                          onClick={() => {
                            handleAddNote && handleAddNote();
                          }}
                        >
                          <span className="fas fa-plus" aria-hidden="true"></span>
                          <span className="sr-only">Add Note</span>
                        </button>
                      </AccessControlGate>
                    </h2>
                  </div>
                )}
                centerId={activeCenter.id}
                noteType="center"
                canCreateOrEdit={(hasPermission && hasPermission(Permissions.CENTER_EDIT_NOTES)) ?? false}
              />
            </div>
          </div>
        </ContentPad>
      </SinglePanel>
    </ContentViewport>
  );
};

export const centerNotesLoader = () => {
  return {
    activeTab: "Centers",
    tabQualifier: "Notes"
  };
};
