import { useEffect, useState } from "react";
import moment from "moment";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { FormRow } from "../forms";
import { OperatingHours, operatingHoursSorter, useCenterOperatingHours } from "../../hooks/use-center";
import { useAccessControl } from "../../app/use-access-control";
import { useModal } from "../modal/modal";
import { InputField } from "../forms/input-field";
import { LoadingButton } from "../loading-button/loading-button";
import { Checkbox } from "../forms/legacy/checkbox";
import { SimpleTable, SimpleTableHeader } from "../table";
import { useReferenceData } from "../../hooks/use-reference-data";

const daySchema = z
  .object({
    id: z.number().optional(),
    weekday: z.number(),
    isClosed: z.boolean().default(false),
    openTime: z.string(),
    closeTime: z.string(),
    startApptTime: z.string(),
    endApptTime: z.string(),
    seasonStart: z.string(),
    seasonEnd: z.string()
  })
  .refine(val => !(!val.isClosed && !val.openTime), {
    message: "error",
    path: ["openTime"]
  })
  .refine(val => !(!val.isClosed && !val.closeTime), {
    message: "error",
    path: ["closeTime"]
  });

const seasonalHoursGroup = z.object({
  seasonStart: z.string().min(1),
  seasonEnd: z.string().min(1),
  days: z.array(daySchema)
});

type SeasonalHoursDaySchema = z.infer<typeof daySchema>;

type SeasonalHoursGroupSchema = z.infer<typeof seasonalHoursGroup>;

export const transformForApi = (input: SeasonalHoursGroupSchema): OperatingHours[] => {
  return input.days.map(row => {
    return {
      ...row,
      seasonStart: row.seasonStart || input.seasonStart,
      seasonEnd: row.seasonEnd || input.seasonEnd
    };
  });
};

const coerceId = (id: number | undefined) => {
  if (!id || Number.isNaN(id)) {
    return 0;
  } else {
    return Number(id);
  }
};

export const useEditCenterSeasonalHoursModal = () => {
  const { show, hide: hideSeasonalHoursModal, renderModal } = useModal();
  const { weekdayLookup } = useReferenceData();

  const { activeCenterId } = useAccessControl();
  const { operatingHoursWeeklyEditMutator, brandStandardHours } = useCenterOperatingHours(activeCenterId);
  const [updatingExisting, setUpdatingExisting] = useState<boolean>(false);

  const [allowOutsideBrandStandardHours, setAllowOutsideBrandStandardHours] = useState<boolean>(false);
  const [isOutsideBrandStandardHours, setIsOutsideBrandStandardHours] = useState<boolean>(false);

  const {
    register,
    reset,
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<SeasonalHoursGroupSchema>({
    mode: "onTouched",
    resolver: zodResolver(seasonalHoursGroup)
  });

  const { fields, replace } = useFieldArray({ name: "days", control, keyName: "rhfKey" });
  const seasonStart = watch("seasonStart");
  const seasonEnd = watch("seasonEnd");

  useEffect(() => {
    if (!updatingExisting) {
      const days = getDefaultDailyHours(seasonStart, seasonEnd);
      replace(days);
    }
  }, [seasonStart, seasonEnd, updatingExisting]);

  const localSave = handleSubmit(data => {
    if (!allowOutsideBrandStandardHours) {
      const days = getValues("days");
      let outsideBrandHours = false;
      clearErrors(`days`);

      brandStandardHours.forEach(standard => {
        const index = days.findIndex(d => +d.weekday === +standard.weekday);

        // Brand Standard hours are not defined for closed days
        if (days[index]?.isClosed) {
          setError(`days.${index}.isClosed`, { type: "custom" });
        }

        const isOpenAfterStandard = moment(days[index]?.openTime, "HH:mm:ss").isAfter(
          moment(standard.openTime, "HH:mm:ss")
        );
        const isCloseBeforeStandard = moment(days[index]?.closeTime, "HH:mm:ss").isBefore(
          moment(standard.closeTime, "HH:mm:ss")
        );

        if (isOpenAfterStandard) {
          setError(`days.${index}.openTime`, { type: "custom" });
        }
        if (isCloseBeforeStandard) {
          setError(`days.${index}.closeTime`, { type: "custom" });
        }

        outsideBrandHours = outsideBrandHours || days[index]?.isClosed || isOpenAfterStandard || isCloseBeforeStandard;
      });

      if (outsideBrandHours) {
        setIsOutsideBrandStandardHours(true);
        return;
      }
    }

    operatingHoursWeeklyEditMutator.mutate(
      { operatingHours: transformForApi(data), seasonalHours: true },
      {
        onSuccess: () => {
          hideSeasonalHoursModal();
        }
      }
    );
  });

  const showEditSeasonalHoursModal = (dailyHours: OperatingHours[], existing: boolean) => {
    const schemaData: SeasonalHoursGroupSchema = {
      seasonStart: dailyHours[0]?.seasonStart ?? "",
      seasonEnd: dailyHours[0]?.seasonEnd ?? "",
      days: dailyHours.map(dh => {
        return { ...dh, isClosed: !dh.openTime && !dh.closeTime, id: coerceId(dh.id) };
      })
    };

    setAllowOutsideBrandStandardHours(false);
    setIsOutsideBrandStandardHours(false);

    reset(schemaData);
    setUpdatingExisting(existing);
    show();
  };

  const tableHeaders: SimpleTableHeader<SeasonalHoursDaySchema>[] = [
    {
      renderKey: "weekday",
      name: "Day",
      tableDataCellProps: { isPrimary: true },
      render: item => <>{weekdayLookup[item.weekday]}s</>
    },
    {
      renderKey: "openTime",
      name: "Open",
      render: (item, index) => {
        const isClosed = watch(`days.${index}.isClosed` as const);
        if (!isClosed) {
          return (
            <InputField
              disableColClass
              type="time"
              step="900"
              label=""
              validationState={errors.days?.[index]?.openTime === undefined ? undefined : "error"}
              data-testid={`week-hours-edit-open-time-${item.weekday}`}
              key={`week-hours-edit-open-time-${item.weekday}`}
              {...register(`days.${index}.openTime` as const)}
            />
          );
        }
        return (
          <em className={errors.days?.[index]?.isClosed ? "outside-standard-hours no-border" : undefined}>Closed</em>
        );
      }
    },
    {
      renderKey: "closeTime",
      name: "Close",
      render: (item, index) => {
        const isClosed = watch(`days.${index}.isClosed` as const);
        if (!isClosed) {
          return (
            <InputField
              disableColClass
              type="time"
              step="900"
              label=""
              validationState={errors.days?.[index]?.closeTime === undefined ? undefined : "error"}
              data-testid={`week-hours-edit-close-time-${item.weekday}`}
              key={`week-hours-edit-close-time-${item.weekday}`}
              {...register(`days.${index}.closeTime` as const)}
            />
          );
        }
        return <em></em>;
      }
    },
    {
      renderKey: "close",
      name: "Close",
      tableDataCellProps: { verticalAlign: "middle", textAlign: "center" },
      render: (item, index) => {
        if (item.seasonEnd && item.seasonStart && item.seasonEnd === item.seasonStart) {
          return <UnavailableEntry />;
        }
        const isClosed = watch(`days.${index}.isClosed` as const);

        const onChangeValue = (newvalue: boolean) => {
          setValue(`days.${index}.isClosed`, newvalue);
          setValue(`days.${index}.openTime`, "");
          setValue(`days.${index}.closeTime`, "");
        };

        return (
          <Checkbox
            value={isClosed}
            large
            onChangeValue={onChangeValue}
            label=""
            data-testid={`week-hours-edit-isclosed-${item.weekday}`}
            key={`week-hours-edit-isclosed-${item.weekday}`}
          />
        );
      }
    }
  ];

  const renderSeasonalHoursModal = () =>
    renderModal({
      size: "lg",
      title: "Seasonal / Temporary Hours",
      body: (
        <>
          <p>
            Define a period of time that seasonal or temporary hours should be in effect. You&apos;ll be able to modify
            individual days within this range.
          </p>
          <FormRow>
            <InputField
              type="date"
              label="Start Date"
              validationState={errors.seasonStart === undefined ? undefined : "error"}
              data-testid={`seasonal-hours-edit-start-date`}
              {...register("seasonStart")}
              disabled={updatingExisting}
            />
            <InputField
              type="date"
              label="End Date"
              validationState={errors.seasonEnd === undefined ? undefined : "error"}
              data-testid={`seasonal-hours-edit-end-date`}
              {...register("seasonEnd")}
              disabled={updatingExisting}
            />
          </FormRow>

          <br />

          <SimpleTable
            className="center-seasonal-hour-week-edit-table"
            tableCollapseScreenSize="lg"
            tableHasIcons
            headers={tableHeaders}
            data={fields}
            uniqueIdKey="weekday"
          />
        </>
      ),
      footer: (
        <>
          <div
            style={{
              width: "100%",
              padding: "10px",
              marginBottom: "15px",
              display: isOutsideBrandStandardHours ? "block" : "none"
            }}
            className="outside-standard-hours"
          >
            <Checkbox
              label="The selected hours are outside of the Brand Standards. Check the box to continue."
              value={allowOutsideBrandStandardHours}
              large
              onChangeValue={setAllowOutsideBrandStandardHours}
              data-testid="allow-outside-brand-standard-checkbox"
            />
          </div>
          <button
            className="btn btn-secondary"
            onClick={hideSeasonalHoursModal}
            disabled={operatingHoursWeeklyEditMutator.isLoading}
          >
            Cancel
          </button>
          <LoadingButton
            className="btn btn-primary"
            onClick={localSave}
            loadingMessage="Saving..."
            isLoading={operatingHoursWeeklyEditMutator.isLoading}
          >
            Save
          </LoadingButton>
        </>
      ),
      onHide: hideSeasonalHoursModal
    });

  return {
    renderSeasonalHoursModal,
    showEditSeasonalHoursModal,
    hideSeasonalHoursModal
  };
};

export const getDefaultDailyHours = (seasonStart: string, seasonEnd: string): SeasonalHoursDaySchema[] => {
  if (moment(seasonEnd).diff(moment(seasonStart), "days") < 0) {
    return [];
  }
  const weekday = parseInt(moment(seasonStart).format("d"));
  const range = Math.min(moment(seasonEnd).diff(moment(seasonStart), "days") + 1, 7);
  const week: SeasonalHoursDaySchema[] = [];

  for (let i = weekday; i < weekday + range; i++) {
    const weekdayValue = i % 7 || 7;
    week.push({
      id: 0,
      openTime: "",
      closeTime: "",
      startApptTime: "",
      endApptTime: "",
      weekday: weekdayValue,
      seasonStart: seasonStart,
      seasonEnd: seasonEnd,
      isClosed: false
    });
  }

  return week.sort(operatingHoursSorter);
};

export const UnavailableEntry: React.FC = () => {
  return (
    <OverlayTrigger
      placement={"top"}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip style={{ zIndex: 100000 }}>Set single day closure under Holiday / Emergency Closures</Tooltip>}
    >
      <span>
        <span className="fas fa-info-circle hlc-green" style={{ fontSize: "1em" }} /> Unavailable
      </span>
    </OverlayTrigger>
  );
};
