import { Field, FormRow, PositionSelector, RoleSelector, CreateMailbox, FormHelpers } from "../forms";
import { useAccessControl } from "../../app/use-access-control";
import { StaffMember, useAvailableCenters, useStaffMember } from "../../hooks/use-user";
import { useFormInput } from "../../hooks/use-form-input";
import { RehirePayload, useModifyPosition, useRehireValidator } from "../../hooks/use-modify-position";
import { useStaffMemberPermissions } from "../../hooks/use-staff";
import { LoadingOverlay } from "../loading-overlay/loading-overlay";
import { useModal } from "../modal/modal";
import { EmployeeHistory } from "../../hooks/use-employment-history";

export interface UseRehireModalProps {
  staffMember: StaffMember;
  initialEmploymentHistory?: EmployeeHistory;
}

export const useRehireModal = ({ staffMember, initialEmploymentHistory }: UseRehireModalProps) => {
  const { show, hide: hideRehireModal, renderModal } = useModal();

  const { activeCenterId } = useAccessControl();

  const availableCentersQuery = useAvailableCenters(activeCenterId);

  const { staffMemberPermissions, isLoading: isLoadingStaffMemberPermissions } = useStaffMemberPermissions(
    activeCenterId,
    staffMember.id
  );

  const staffMemberQuery = useStaffMember({ centerId: activeCenterId, staffMemberId: staffMember.id });

  const { rehireMutator } = useModifyPosition(activeCenterId, staffMember.id, hideRehireModal);

  const { buffer, onChangeValue, resetBuffer } = useFormInput<RehirePayload>({
    effectiveDate: "",
    position: initialEmploymentHistory?.position ?? "",
    jobType: "FT",
    role: staffMemberPermissions?.role ?? "",
    createMailbox: staffMemberQuery.data?.hasMailbox ?? false,
    centers: [activeCenterId]
  });

  const isValidRehire = useRehireValidator(buffer);

  const showRehireModal = () => {
    resetBuffer();
    show();
  };

  const onSave = () => {
    rehireMutator.mutate(buffer);
  };

  const renderRehireModal = () =>
    renderModal({
      fullscreen: "sm-down",
      title: `Reactivate - ${staffMember.displayName}`,
      body: (
        <LoadingOverlay loading={availableCentersQuery.isLoading || isLoadingStaffMemberPermissions}>
          <FormRow>
            <Field
              type="date"
              label="Reactivation Date"
              required
              mode="edit"
              value={buffer.effectiveDate}
              onChangeValue={onChangeValue("effectiveDate")}
              onValidate={FormHelpers.isValidRequiredDateString}
            />
          </FormRow>

          <FormRow>
            <PositionSelector
              label="New Position"
              mode="edit"
              required
              value={buffer.position}
              onChangeValue={onChangeValue("position")}
              onValidate={FormHelpers.isValidRequiredString}
            />
          </FormRow>

          <FormRow>
            <RoleSelector
              label="New System Access Level"
              mode="edit"
              required
              value={buffer.role}
              onChangeValue={onChangeValue("role")}
              includeImmutable={false}
              onValidate={FormHelpers.isValidRequiredString}
            />
          </FormRow>

          <FormRow>
            <CreateMailbox
              value={buffer.createMailbox}
              onChangeValue={onChangeValue("createMailbox")}
              disabled={staffMemberQuery.data?.hasMailbox}
            />
          </FormRow>
        </LoadingOverlay>
      ),
      footer: (
        <>
          <button className="btn btn-secondary" onClick={hideRehireModal}>
            Cancel
          </button>
          <button className="btn btn-primary" onClick={onSave} disabled={!isValidRehire}>
            Save
          </button>
        </>
      )
    });

  return {
    hideRehireModal,
    showRehireModal,
    renderRehireModal
  };
};
