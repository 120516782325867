import classNames from "classnames";
import { ChangeEventHandler, forwardRef, useEffect, useId } from "react";
import { type FieldError } from "react-hook-form";

export interface TextAreaFieldProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  isRequired?: boolean;
  validationState?: "success" | "error";
  containerClassName?: string;
  labelClassName?: string;
  error?: FieldError;
  // serverValidationError?: any;
  minHeight?: number;
  maxHeight?: number;
  autoResize?: boolean;
  // TODO: we need to remove col class from all form so we can handle this in a container higher up
  disableColClass?: boolean;
}

export const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>(
  (
    {
      label,
      isRequired = false,
      validationState,
      containerClassName,
      labelClassName,
      error,
      minHeight = 100,
      maxHeight = 200,
      onChange,
      autoResize,
      disableColClass,
      ...textAreaProps
    },
    ref
  ) => {
    const textAreaId = useId();

    const handleResize = () => {
      if (autoResize) {
        const textArea = document.getElementById(textAreaId);
        if (textArea) {
          textArea.style.height = "0px";
          const scrollHeight = textArea.scrollHeight;
          // istanbul ignore next : scrollHeight is always 0 in tests
          const height = maxHeight < scrollHeight ? maxHeight : minHeight > scrollHeight ? minHeight : scrollHeight;
          textArea.style.height = height + "px";
        }
      }
    };

    useEffect(() => {
      handleResize();
    }, [textAreaProps.value]);

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
      handleResize();
      onChange?.(event);
    };

    return (
      <div className={classNames("col form-floating", containerClassName, { col: !disableColClass })}>
        <textarea
          {...textAreaProps}
          id={textAreaId}
          aria-invalid={validationState === "error" ? "true" : "false"}
          className={classNames("form-control", textAreaProps.className, {
            "is-valid": validationState === "success",
            "is-invalid": error?.message ?? validationState === "error"
          })}
          onChange={handleChange}
          ref={ref}
        />
        <label htmlFor={textAreaId} className={labelClassName}>
          {label}
          {isRequired && (
            <>
              {" "}
              <span className="required" aria-hidden="true">
                *
              </span>
            </>
          )}
        </label>
        {!!error && <div className="invalid-feedback"> {error.message}</div>}
      </div>
    );
  }
);

TextAreaField.displayName = "TextAreaField";
