import { FC, PropsWithChildren, createContext, useEffect, useMemo, useState } from "react";
import { UUID } from "crypto";

interface ModalContextProps {
  recordModalOpen: (modal: UUID, isOpen: boolean) => void;
}

export const ModalContext = createContext<ModalContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  recordModalOpen: () => {}
});

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modalOpenRecord, setModalOpenRecord] = useState<Record<string, boolean>>({});

  const isModalOpen = useMemo(
    () => Object.entries(modalOpenRecord).some(([, value]) => value === true),
    [modalOpenRecord]
  );

  useEffect(() => {
    window.toasts.isModalOpen = isModalOpen;
  }, [isModalOpen]);

  const recordModalOpen = (modal: UUID, isOpen: boolean) => {
    setModalOpenRecord(oldState => ({ ...oldState, [modal]: isOpen }));
  };
  return <ModalContext.Provider value={{ recordModalOpen }}>{children}</ModalContext.Provider>;
};
