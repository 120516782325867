import React from "react";

import { NoAssignedCenterError } from "./no-assigned-center-error";

export const ErrorFallback: React.FC<{
  error?: { message: string };
  resetErrorBoundary?: () => void;
  onLogout?: () => void;
}> = ({ error, resetErrorBoundary, onLogout }) => {
  const handleLogout = () => {
    onLogout && onLogout();
    resetErrorBoundary && resetErrorBoundary();
  };
  return (
    <div className="error-boundary-message-container">
      <div className="content-pad">
        <div role="alert" className="card">
          <h4>Oops, something has gone wrong...</h4>
          {error instanceof NoAssignedCenterError ? (
            <>
              <p>
                You are not active in any centers currently. If you feel this is a mistake, please reach out to your
                LCOS administrator.
              </p>
              <button className="btn btn-primary" onClick={handleLogout}>
                Logout and try again.
              </button>
            </>
          ) : (
            <>
              <p>
                You can reset the application with the button below and try again. If you continue to receive this
                message, please contact technical support.
              </p>
              <button className="btn btn-primary" onClick={resetErrorBoundary}>
                Try again
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
