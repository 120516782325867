import { useMutation, useQueryClient } from "@tanstack/react-query";

import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { familyQueryKeys } from "./query-keys";
import { FamilyRole } from "./types";

export const useFamilyRoles = (familyId?: string) => {
  const { getToken } = useAccessToken();
  const queryClient = useQueryClient();

  const addFamilyRoleMutator = useMutation({
    mutationFn: async (payload: FamilyRole) =>
      familyId ? addFamilyRole(await getToken(), familyId, payload) : undefined,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: familyQueryKeys.base });
    }
  });

  const removeFamilyRoleMutator = useMutation({
    mutationFn: async (roleId: number) => (familyId ? removeFamilyRole(await getToken(), familyId, roleId) : undefined),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: familyQueryKeys.base });
    }
  });

  return {
    addFamilyRoleMutator,
    removeFamilyRoleMutator
  };
};

export const addFamilyRole = (accessToken: string, familyId: string, payload: FamilyRole) => {
  const modifiedPayload = {
    ...payload,
    contactId: payload.id
  };

  return callLcosService<FamilyRole>(accessToken, `/api/families/${familyId}/roles`, "POST", modifiedPayload);
};

export const removeFamilyRole = (accessToken: string, familyId: string, roleId: number) => {
  return callLcosService<{}>(accessToken, `/api/families/${familyId}/roles/${roleId}`, "DELETE");
};
