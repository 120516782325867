import { useCallback, useEffect, useState, useMemo, type FC } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";

import {
  parseToInquiryStage,
  useInquiryList,
  useInquirySummary,
  type Inquiry,
  type InquiryStage
} from "../../hooks/use-inquiries";
import { formatDateString } from "../forms/form-helpers";
import {
  type SortConfig,
  type SortDirection,
  SortableTable,
  type SortableTableHeader,
  type UsePaginatedSorterHook
} from "../table";
import { usePaginator } from "../pagination";
import { useDynamicPageSize } from "../table/use-dynamic-page-size";

export interface InquiryListProps {
  preselectedInquiryId: string | undefined;
  search?: string;
  onInquirySelect?: (inquiry: Inquiry) => void;
  onStatusChange?: () => void;
  tableRef?: React.RefObject<HTMLTableElement>;
}

export const InquiryList: FC<InquiryListProps> = ({
  preselectedInquiryId,
  search,
  onInquirySelect,
  onStatusChange,
  tableRef
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 991.98px)", { defaultValue: false });
  const [selectedInquiry, setSelectedInquiry] = useState<Inquiry>();
  const [hasInitialData, setHasInitialData] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [sortBy, setSortBy] = useState("student_name");

  const { limit } = useDynamicPageSize({ utilityHeight: 90, tableRowHeight: 45 });

  const { search: searchParams } = useLocation();
  const stageFilter = useMemo(() => new URLSearchParams(searchParams).get("stage"), [searchParams]);

  const stages = useMemo(
    () =>
      stageFilter
        ?.split(",")
        .map(parseToInquiryStage)
        .filter((stage): stage is InquiryStage => Boolean(stage)),
    [stageFilter]
  );
  const { data: { inquiries, totalPages } = { inquiries: [], totalPages: 0 }, isFetching } = useInquiryList({
    search,
    stages,
    sortBy,
    limit,
    pageIndex
  });
  const { data: preselectedInquiry } = useInquirySummary(preselectedInquiryId);

  useEffect(() => {
    if (!isFetching && inquiries && !hasInitialData) {
      // don't auto select on small screens
      if (!isSmallScreen) {
        if (preselectedInquiryId) {
          const inquiryToSelect = inquiries.find(inquiry => inquiry.id === preselectedInquiryId) ?? preselectedInquiry;
          if (!inquiryToSelect) {
            setSelectedInquiry(inquiries[0]);
            onInquirySelect?.(inquiries[0]);
          } else {
            setSelectedInquiry(inquiryToSelect);
            onInquirySelect?.(inquiryToSelect);
          }
        } else if (!selectedInquiry) {
          setSelectedInquiry(inquiries[0]);
          onInquirySelect?.(inquiries[0]);
        } else {
          const inquiryToSelect = inquiries.find(inquiry => inquiry.id === selectedInquiry.id);
          if (!inquiryToSelect) {
            setSelectedInquiry(inquiries[0]);
            onInquirySelect?.(inquiries[0]);
          } else {
            setSelectedInquiry(inquiryToSelect);
          }
        }
      }
      setHasInitialData(true);
    }
  }, [isFetching, inquiries, hasInitialData, isSmallScreen, preselectedInquiry]);

  useEffect(() => {
    setHasInitialData(false);
  }, [stages]);

  const startDateColumnName = (() => {
    if (stages?.length === 1) {
      switch (stages[0]) {
        case "INQ":
          return "Inquiry Start Date";
        case "AE":
          return "AE Start Date";
        case "ENR":
          return "Enrolled Start Date";
        case "DROP":
          return "Dropped Date";
        case "CONS_CONF":
          return "Consult/Conference Start Date";
      }
    }
    return "Status Start Date";
  })();

  const inquiryListHeaders: SortableTableHeader<Inquiry>[] = [
    {
      renderKey: "student",
      name: "Student",
      tableDataCellProps: { isPrimary: true },
      sortOptions: { enableSort: true, customSortKey: "student_name" },
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      render: item => <a href="#">{item.studentName}</a>
    },
    {
      key: "service",
      name: "Service",
      sortOptions: { enableSort: true }
    },
    ...(stageFilter
      ? []
      : [
          {
            key: "stage" as keyof Inquiry,
            name: "Status",
            sortOptions: { enableSort: true }
          }
        ]),
    {
      renderKey: "stageStartDate",
      name: startDateColumnName,
      render: item => formatDateString(item.stageStartDate),
      sortOptions: { enableSort: true, customSortKey: "inquiry_date" }
    }
  ];

  const handleSelectItem = (item: Inquiry) => {
    setSelectedInquiry(item);
    onInquirySelect?.(item);
  };

  const handleSortByChange = useCallback(
    (newSort: string) => {
      setPageIndex(0);
      setSortBy(oldSort => {
        if (oldSort === newSort) {
          return `-${newSort}`;
        }
        return newSort;
      });
    },
    [setSortBy]
  );

  useEffect(() => {
    setPageIndex(0);
  }, [searchParams, search, limit, sortBy]);

  useEffect(() => {
    setSortBy("student_name");
    onStatusChange?.();
  }, [searchParams]);

  const paginationOptions = {
    itemsPerPage: limit,
    onPageChange: (pageNumber: number) => setPageIndex(pageNumber - 1)
  };

  // TODO: might be wiser to create a hook factory for manual pagination
  const useInquiryPaginatedSorter: UsePaginatedSorterHook<Inquiry> = ({
    data,
    paginationOptions: { onPageChange } = {},
    isLoading
  }) => {
    const [sortConfig, setSortConfig] = useState<SortConfig<Inquiry>>({
      key: null,
      secondaryKey: null,
      direction: "asc"
    });

    const { renderPaginator, setCurrentPage } = usePaginator({
      onPageChange,
      isLoading,
      numberOfPages: totalPages
    });

    const requestSort = ({ key, sortOptions }: SortableTableHeader<Inquiry>) => {
      let direction: SortDirection = "asc";
      if (sortConfig.key === key && sortConfig.direction === "asc") {
        direction = "des";
      }
      setSortConfig({ key: key ?? null, secondaryKey: null, direction });
      handleSortByChange(sortOptions?.customSortKey ?? key ?? "");
      setCurrentPage(1);
    };

    return {
      paginatedData: data,
      requestSort,
      renderPaginator
    };
  };

  return (
    <SortableTable
      tableCollapseScreenSize="sm"
      headers={inquiryListHeaders}
      data={inquiries}
      activeItem={selectedInquiry}
      paginationOptions={paginationOptions}
      usePaginatedSorter={useInquiryPaginatedSorter}
      isLoading={isFetching}
      onRowClick={handleSelectItem}
      tableRef={tableRef}
      uniqueIdKey="id"
      printCaption="Inquiries"
    />
  );
};
