import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { InquiryFamilyInfo } from "./types";
import { PRIMARY_CONTACT_VALUE } from "./constants";
import {
  useFamilyRelationships,
  useFamilyRoles,
  FamilyMemberInfo,
  FamilyRelationship,
  FamilyRelationshipDetails,
  FamilyRole,
  useFamilyConstants
} from "../use-families";
import { familyQueryKeys } from "../use-families/query-keys";
import { inquiryQueryKeys } from "./query-keys";

interface useInquiryFamilyInfoProps {
  centerId: string;
  inquiryId: string;
}

export const formatFamilyContact = (familyMemberInfo: FamilyMemberInfo, EMERGENCY_CONTACT_VALUE: string) => {
  const emergencyContactRelationship = familyMemberInfo.subjectRelationships.find(
    subjectRelationship => subjectRelationship.relationship.value === EMERGENCY_CONTACT_VALUE
  );

  const otherRelationships = familyMemberInfo.subjectRelationships
    .map(subjectRelationship => subjectRelationship.relationship.value)
    .filter(relationshipValue => relationshipValue !== EMERGENCY_CONTACT_VALUE);
  const primaryContactRole = familyMemberInfo.roles.find(role => role.role === PRIMARY_CONTACT_VALUE);

  return {
    id: familyMemberInfo.id,
    name: familyMemberInfo.displayName,
    relationships: otherRelationships,
    preferredPhone: familyMemberInfo.preferredPhone.phoneNumber,
    email: familyMemberInfo.email,
    isPrimaryContact: familyMemberInfo.isPrimaryContact, // primaryContactRole,
    emergencyContactRelationship,
    primaryContactRole
  };
};

export type FamilyContact = ReturnType<typeof formatFamilyContact>;

export const useInquiryFamilyInfo = ({ centerId, inquiryId }: useInquiryFamilyInfoProps) => {
  const { getToken } = useAccessToken();
  const queryClient = useQueryClient();

  const { EMERGENCY_CONTACT_VALUE } = useFamilyConstants();

  const query = useQuery({
    queryKey: familyQueryKeys.detail({ centerId, inquiryId }),
    queryFn: async () => getFamilyInformation(await getToken(), centerId, inquiryId),
    enabled: !!centerId.length && !!inquiryId.length
  });

  const familyContactsData = useMemo(
    () => (query.data?.members ?? []).map(data => formatFamilyContact(data, EMERGENCY_CONTACT_VALUE)),
    [query.data?.members, EMERGENCY_CONTACT_VALUE]
  );

  const { addFamilyRelationshipMutator, removeFamilyRelationshipMutator } = useFamilyRelationships(query.data?.id);
  const { addFamilyRoleMutator, removeFamilyRoleMutator } = useFamilyRoles(query.data?.id);

  const addEmergencyContact = (subjectContactId: number) => {
    const payload: FamilyRelationship = {
      subjectContactId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      objectContactId: query.data!.studentId,
      relationshipId: EMERGENCY_CONTACT_VALUE
    };
    addFamilyRelationshipMutator.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries(familyQueryKeys.detail({ centerId, inquiryId }));
        queryClient.invalidateQueries(inquiryQueryKeys.dashboard(centerId, inquiryId));
      }
    });
  };

  const removeEmergencyContact = (emergencyContact: FamilyRelationshipDetails) => {
    removeFamilyRelationshipMutator.mutate(emergencyContact.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(familyQueryKeys.detail({ centerId, inquiryId }));
        queryClient.invalidateQueries(inquiryQueryKeys.dashboard(centerId, inquiryId));
      }
    });
  };

  const addPrimaryContact = (id: number) => {
    const payload: FamilyRole = {
      id,
      role: PRIMARY_CONTACT_VALUE
    };
    addFamilyRoleMutator.mutate(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries(familyQueryKeys.detail({ centerId, inquiryId }));
        queryClient.invalidateQueries(inquiryQueryKeys.dashboard(centerId, inquiryId));
      }
    });
  };

  const removePrimaryContact = (primaryContact: FamilyRole) => {
    removeFamilyRoleMutator.mutate(primaryContact.id, {
      onSuccess: () => {
        queryClient.invalidateQueries(familyQueryKeys.detail({ centerId, inquiryId }));
        queryClient.invalidateQueries(inquiryQueryKeys.dashboard(centerId, inquiryId));
      }
    });
  };

  return {
    familyContactsData,
    familyId: query.data?.id,
    addEmergencyContact,
    removeEmergencyContact,
    addPrimaryContact,
    removePrimaryContact,
    isLoading: query.isLoading
  };
};

const getFamilyInformation = (accessToken: string, centerId: string, inquiryId: string) => {
  return callLcosService<InquiryFamilyInfo>(accessToken, `/api/inquiries/${centerId}/${inquiryId}/family`, "GET");
};
