import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAccessToken } from "../use-access-token";
import { callLcosService } from "../../data/lcosServices";
import { ReferenceRecord } from "../use-reference-data";
import { CODES } from "../../data/dynamics-codes";

export interface AppointmentType {
  appointmentTypeId: string;
  appointmentTypeName: string;
  availability: Availability[];
}

export interface Availability {
  serviceId: string;
  name: string;
  isAvailable: boolean;
  locations: ReferenceRecord[];
}

export interface AppointmentListItem {
  appointmentType: {
    value: string;
    name: string;
    isActive: boolean;
  };
  service: {
    value: string;
    name: string;
    isActive: boolean;
    internalCode: string;
  };
  locations: ReferenceRecord[];
  isAvailable: boolean;
}

export const useStaffAppointmentTypes = (centerId: string, staffMemberId: string) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const getAppointmentTypesData = async () => {
    return getAppointmentTypes(await getToken(), centerId, staffMemberId);
  };

  const queryKey = ["appointmentTypesDataForCenterStaff", centerId, staffMemberId];

  const appointmentTypesQuery = useQuery({
    queryKey,
    queryFn: getAppointmentTypesData,
    enabled: !!centerId && !!staffMemberId,
    select: ({ appointmentList }) => {
      const appointmentTypes = Array.from(
        new Set(appointmentList.map((item: AppointmentListItem) => item.appointmentType.value))
      );

      const results: AppointmentType[] = [];
      appointmentTypes.forEach((id: string) => {
        const getOne = appointmentList.find(item => item.appointmentType.value === id);
        if (getOne) {
          const item: AppointmentType = {
            appointmentTypeId: getOne.appointmentType.value,
            appointmentTypeName: getOne.appointmentType.name,
            availability: []
          };

          const services = appointmentList.filter(
            item => item.appointmentType.value === id && item.service.internalCode !== CODES.SERVICES.NOT_APPLICABLE
          );

          services
            .sort((left, right) => left.service.name.localeCompare(right.service.name))
            .forEach(apptListItem => {
              item.availability.push({
                serviceId: apptListItem.service.value,
                name: apptListItem.service.name,
                locations: apptListItem.locations,
                isAvailable: apptListItem.isAvailable
              });
            });

          results.push(item);
        }
      });
      return results;
    }
  });

  const updateAvailabilityForAppointmentTypeMutator = useMutation({
    mutationFn: async ({
      appointmentTypeId,
      updatedAvailability
    }: {
      appointmentTypeId: string;
      updatedAvailability: { serviceId: string; isAvailable: boolean; locations: string[] };
      skipQueryUpdate: boolean;
    }) =>
      updateAvailabilityForAppointmentType(
        await getToken(),
        centerId,
        staffMemberId,
        appointmentTypeId,
        updatedAvailability
      ),

    onSuccess: (newAppointmentType, { skipQueryUpdate }) => {
      if (!skipQueryUpdate) {
        queryClient.setQueryData(queryKey, newAppointmentType);
      }
    }
  });

  return {
    appointmentTypes: appointmentTypesQuery.data || [],
    isLoading: appointmentTypesQuery.isLoading,
    updateAvailabilityForAppointmentTypeMutator
  };
};

export const getAppointmentTypes = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined
) => {
  return callLcosService<{ appointmentList: AppointmentListItem[] }>(
    accessToken,
    `/api/availability/${centerId}/${staffMemberId}/appointments`
  );
};

export const updateAvailabilityForAppointmentType = (
  accessToken: string,
  centerId: string,
  staffMemberId: string,
  appointmentTypeId: string,
  availability: { serviceId: string; isAvailable: boolean; locations?: string[] }
) => {
  return callLcosService<{ appointmentList: AppointmentListItem[] }>(
    accessToken,
    `/api/availability/${centerId}/${staffMemberId}/appointments/${appointmentTypeId}`,
    "PUT",
    availability
  );
};
