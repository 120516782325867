import React, { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ErrorBoundary } from "react-error-boundary";

import { Login } from "./login";
import { ToastProvider } from "../toasts/context";
import { ErrorFallback } from "./error-fallback";
import { AppWithToast } from "./app-with-toast";

export const App: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const handleLogout = () => {
    instance.logoutRedirect();
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT_FLAG) {
      document.title = `${process.env.REACT_APP_ENVIRONMENT_FLAG} - Huntington Learning Center`;
    }
  }, []);

  if (isAuthenticated) {
    return (
      <ErrorBoundary fallbackRender={props => <ErrorFallback onLogout={handleLogout} {...props} />}>
        <ToastProvider>
          <AppWithToast />
        </ToastProvider>
        {/* <ToastProvider /> */}
      </ErrorBoundary>
    );
  }
  return <Login />;
};
