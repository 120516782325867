import React, { useMemo } from "react";

import * as FormHelpers from "../forms/form-helpers";
import { StaffMember } from "../../hooks/use-user";
import { useReferenceData } from "../../hooks/use-reference-data";
import { SortableTable, SortableTableHeader } from "../table";
import { useDynamicPageSize } from "../table/use-dynamic-page-size";

export interface StaffSelectorProps {
  staff: StaffMember[] | undefined;
  selected?: StaffMember | undefined;
  onSelect?: (staff: StaffMember) => void;
  filter: string;
}

export const StaffSelector = React.forwardRef<HTMLTableElement, StaffSelectorProps>(
  ({ staff, selected, onSelect, filter }, tableRef) => {
    const { positionsLookup } = useReferenceData();

    const nameFilteredStaff = useMemo(() => {
      return staff?.filter(s => s.displayName.toLowerCase().includes(filter.toLowerCase())) ?? [];
    }, [staff, filter]);

    const { paginationOptions } = useDynamicPageSize({
      utilityHeight: 145,
      tableRowHeight: 65,
      tablePadding: 0,
      containerPadding: 0
    });

    const onSelectItem = (item: StaffMember) => {
      onSelect && onSelect(item);
    };

    const staffListHeaders: SortableTableHeader<StaffMember>[] = [
      {
        renderKey: "displayName",
        name: "Staff",
        tableDataCellProps: { isPrimary: true },
        sortOptions: { enableSort: true },
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        render: item => (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#">{item.displayName}</a>
            <br />
            <span className="secondary">{item.position ? positionsLookup[item.position] : ""}&nbsp;</span>
          </>
        )
      },
      {
        renderKey: "businessEmail",
        name: "Email/Phone",
        render: item => (
          <>
            {item.businessEmail.toLowerCase()}
            <br />
            {FormHelpers.formatPhoneNumber(item.phone)}
          </>
        )
      },
      {
        key: "staffId",
        name: "LCOS 1.0 Staff ID",
        sortOptions: { enableSort: true }
      }
    ];

    return (
      <SortableTable
        tableCollapseScreenSize="sm"
        headers={staffListHeaders}
        data={nameFilteredStaff}
        activeItem={selected}
        paginationOptions={paginationOptions}
        onRowClick={onSelectItem}
        tableRef={tableRef}
        isLoading={false}
        uniqueIdKey="id"
        printCaption="Staff"
      />
    );
  }
);

StaffSelector.displayName = "StaffSelector";
