import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useAccessToken } from "../use-access-token";
import { callLcosService } from "../../data/lcosServices";
import { StaffMemberPosition } from "./types";

export const useStaffMemberPosition = (centerId: string | undefined, staffMemberId: string | undefined) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const isRehireableMutator = useMutation({
    mutationFn: async (newIsRehireable: boolean) =>
      updateStaffMemberIsRehireable(await getToken(), centerId, staffMemberId, newIsRehireable),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["centerStaffData", centerId] });
    }
  });

  return {
    isRehireableMutator
  };
};

const updateStaffMemberIsRehireable = (
  accessToken: string,
  centerId: string | undefined,
  staffMemberId: string | undefined,
  isRehireable: boolean
) => {
  return callLcosService<StaffMemberPosition>(
    accessToken,
    `/api/staff/${centerId}/${staffMemberId}/rehireable`,
    "PUT",
    {
      value: isRehireable
    }
  );
};
