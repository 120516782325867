import React, { useCallback, useEffect, useState } from "react";

import { ContentPad, ContentViewport, DetailPanelCloser, Leftside, Rightside } from "../../app/app-layout";
import { useMediaQuery } from "usehooks-ts";

export const Dashboard: React.FC = () => {
  const isSmallScreen = useMediaQuery("(max-width: 991.98px)", { defaultValue: false });
  const [showRight, setShowRight] = useState<boolean>(isSmallScreen);

  useEffect(() => {
    setShowRight(isSmallScreen);
  }, [isSmallScreen]);

  const closeRightPanel = useCallback(() => {
    setShowRight(false);
  }, []);

  return (
    <ContentViewport className="slide-in-sidebar">
      <Leftside>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          Dashboard Left Pane Placeholder
        </div>
      </Leftside>
      <Rightside showIf={showRight}>
        <DetailPanelCloser onClick={closeRightPanel} />
        <ContentPad>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            Dashboard Right Pane Placeholder
          </div>
        </ContentPad>
      </Rightside>
    </ContentViewport>
  );
};

export const dashboardLoader = () => {
  return {
    activeTab: "Dashboard",
    tabQualifier: undefined
  };
};
