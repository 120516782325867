import { FC, useId } from "react";

export interface RadioGroupProps {
  value: string;
  label: string;
  options: { id: string; name: string }[];
  // defaultSelected?: string;
  onChangeValue: (value: string) => void;
}

export const RadioGroup: FC<RadioGroupProps> = ({ value, options, label, onChangeValue }) => {
  const id = useId();

  const name = `radioGroup-${id}`;

  const handleOptionChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;
    //setSelectedOption(value);
    onChangeValue(value);
  };

  // we can split out radio options when we need to generalize it further, but for now:
  return (
    <fieldset className="col radio-group-container small" id={id}>
      <legend className="radio-group-label">{label}</legend>
      {options.map(option => (
        <div className="form-check radio-group-option" key={option.id}>
          <input
            className="form-check-input"
            type="radio"
            checked={value === option.id}
            name={name}
            value={option.id}
            id={option.id}
            onChange={handleOptionChange}
          />
          <label className="form-check-label" htmlFor={option.id}>
            {option.name}
          </label>
        </div>
      ))}
    </fieldset>
  );
};
