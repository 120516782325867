import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import { useMediaQuery } from "usehooks-ts";

import { Selector } from "../../components/forms/legacy/selector";
import { ListHeader, ListHeaderProps } from "../../components/list-header/list-header";
import { CenterStaffFilters, DefaultCenterStaffFilters } from "../../hooks/use-center";
import * as Permissions from "../../data/permissions";
import { useAccessControl } from "../../app/access-control-context";
import { PositionSelector } from "../../components/forms/legacy/position-selector";

export interface StaffAdministrationListHeaderProps extends ListHeaderProps {
  onChangeStaffFilters: (newvalue: CenterStaffFilters) => void;
  onStartOnboardingWizard: () => void;
}

export const requiredPermissionForEditing = Permissions.STAFF_EDIT;

export const StaffAdministrationListHeader: React.FC<StaffAdministrationListHeaderProps> = ({
  filter,
  onFilterChange,
  onPrintRequested,
  onChangeStaffFilters,
  onStartOnboardingWizard
}) => {
  const { hasPermission, hasFeatureAccess } = useAccessControl();
  const isSmallestScreen = useMediaQuery("(max-width: 576px)", { defaultValue: false });

  const canAddUser = hasPermission && hasPermission(requiredPermissionForEditing);
  const hasRehireAccess = !!hasFeatureAccess?.("eligible_for_rehire_edit");

  const { status = "active" } = useParams<{ status?: string }>();
  const activeStaff = useMemo(() => status.toLowerCase() !== "inactive", [status]);
  const [positionFilter, setPositionFilter] = useState<string>("");
  const [isRehireableFilter, setIsRehireableFilter] = useState<string>("");

  useEffect(() => {
    const newvalue: CenterStaffFilters = {
      ...DefaultCenterStaffFilters,
      isActive: activeStaff,
      position: !!positionFilter ? positionFilter : undefined,
      isRehireable: !!isRehireableFilter ? JSON.parse(isRehireableFilter) : undefined
    };
    onChangeStaffFilters(newvalue);
  }, [activeStaff, positionFilter, isRehireableFilter]);

  useEffect(() => {
    setPositionFilter("");
    setIsRehireableFilter("");
  }, [status]);

  const rehireLabel = isSmallestScreen ? "Elig for Reactivation" : "Eligible for Reactivation";

  return (
    <>
      <>
        <ListHeader filter={filter} onFilterChange={onFilterChange} onPrintRequested={onPrintRequested}>
          <div className={classnames("icon add-staff-member-container", { show: canAddUser })}>
            <button
              className="btn btn-primary btn-sm"
              data-testid="staff-administration-header-add-staff"
              onClick={onStartOnboardingWizard}
            >
              <span>Add Staff</span>
              <span className="sr-only">Add Staff Member</span>
            </button>
          </div>
        </ListHeader>
      </>
      <div className="d-flex align-items-center justify-content-between w-100" style={{ columnGap: "10px" }}>
        <PositionSelector
          mode="edit"
          onChangeValue={setPositionFilter}
          value={positionFilter}
          label="Position"
          includeCenterOwner
        />

        {hasRehireAccess && !activeStaff && (
          <>
            {/* <div>&nbsp;</div> */}
            <Selector
              mode="edit"
              options={[
                { id: "true", name: "Yes" },
                { id: "false", name: "No" }
              ]}
              emptyOption={{ id: "", name: "Select a Value" }}
              onChangeValue={setIsRehireableFilter}
              value={isRehireableFilter}
              label={rehireLabel}
              className="flex-grow-2"
            />
          </>
        )}
      </div>

      <div className="extended-search-row">
        <div className="role-filter-container"></div>
      </div>
    </>
  );
};
