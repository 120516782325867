import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { callLcosService } from "../../data/lcosServices";
import { useAccessToken } from "../use-access-token";
import { CenterWebsite } from "./types";

export const useCenterWebsite = (centerId: string | undefined, enabled = true) => {
  const queryClient = useQueryClient();
  const { getToken } = useAccessToken();

  const getCenterWebsiteData = async () => {
    return getCenterWebsite(await getToken(), centerId);
  };

  const queryKey = ["centerWebsite", centerId];

  const centerWebsiteQuery = useQuery({
    queryKey,
    queryFn: getCenterWebsiteData,
    enabled: !!centerId && enabled
  });

  const websiteMutator = useMutation({
    mutationFn: async (payload: CenterWebsite) => editCenterWebsite(await getToken(), centerId, payload),

    onSuccess: updated => {
      queryClient.setQueryData(queryKey, () => updated);
    }
  });

  return {
    website: centerWebsiteQuery.data,
    isLoading: centerWebsiteQuery.isLoading,
    websiteMutator
  };
};

export const getCenterWebsite = (accessToken: string, centerId: string | undefined) => {
  return callLcosService<CenterWebsite>(accessToken, `/api/centers/${centerId}/website`);
};

export const editCenterWebsite = async (accessToken: string, centerId: string | undefined, payload: CenterWebsite) => {
  return callLcosService<CenterWebsite>(accessToken, `/api/centers/${centerId}/website`, "PUT", payload);
};
