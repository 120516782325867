export const CODES = {
  SERVICES: {
    NOT_APPLICABLE: "NOT_APPLICABLE"
  },
  LOCATIONS: {
    IN_CENTER: "IN_CENTER",
    ONLINE_VIRTUAL: "ONLINE_VIRTUAL"
  },
  APPOINTMENT_STATUSES: {
    RESCHEDULED: "RESCHEDULED",
    AS_SCHEDULED: "AS_SCHEDULED",
    CANCELLED: "CANCELLED",
    NO_SHOW: "NO_SHOW",
    SCHEDULED: "SCHEDULED"
  },
  APPOINTMENT_TYPES: {
    ACADEMIC_EVAL: "ACADEMIC_EVAL",
    CONSULTATION: "CONSULTATION",
    INITIAL_CONFERENCE: "INITIAL_CONFERENCE"
  },
  DISCOUNT_REASONS: {
    OTHER: "OTHER"
  }
};
