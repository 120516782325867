import { useMemo } from "react";

import { useReferenceData } from "../../../hooks/use-reference-data";
import { ExtendableSelectorProps, Selector } from "./selector";

export interface RoleSelectorProps extends ExtendableSelectorProps {
  includeImmutable?: boolean;
  removeEmptyOption?: boolean;
  isEditingSelf?: boolean;
}

export const RoleSelector: React.FC<RoleSelectorProps> = ({
  includeImmutable,
  removeEmptyOption = false,
  isEditingSelf = false,
  ...selectorProps
}) => {
  const { mutableRoles, immutableRoles, isLoading } = useReferenceData();

  const roleSelectorOptions = useMemo(() => {
    const mutableOptions = mutableRoles.map(role => ({ id: role.value, name: role.name, readOnly: isEditingSelf }));
    if (includeImmutable) {
      return [...immutableRoles.map(role => ({ id: role.value, name: role.name, readOnly: true })), ...mutableOptions];
    } else {
      return mutableOptions;
    }
  }, [mutableRoles, immutableRoles, includeImmutable, isEditingSelf]);

  if (isLoading) {
    return null;
  }

  return (
    <Selector
      options={roleSelectorOptions}
      emptyOption={(!removeEmptyOption && { id: "", name: "Select a System Access Level" }) || undefined}
      {...selectorProps}
    />
  );
};
