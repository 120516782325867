import { z } from "zod";

import type { OptOutOption } from "../../components/inquiry-form/opt-out-multiselect";
import type { ReferenceRecord } from "../use-reference-data";
import { zipcodeSchema } from "../../components/forms/form-helpers";

export type WithId<T> = T & {
  id: number;
};

export type WithNullableUuid<T> = T & {
  id: string | null;
};

export type Family = WithNullableUuid<BaseFamily>;
export type FamilyContactSearchResult = WithId<FamilyContact>;

/**
 * Family Constants schema as returned by the API.
 */
export interface FamilyConstants {
  roles: ReferenceRecord[];
  relationships: ReferenceRecord[];
  titles: ReferenceRecord[];
  genders: ReferenceRecord[];
  grades: ReferenceRecord[];
  preferredContactMethods: ReferenceRecord[];
}

/**
 * A role assigned to a `Family` member. Accepted role types are `student`,
 * `payor`, and `primary_contact`. The `id` object is transformed to the
 * expected backend parameter, `contactId` in the use-family-roles hook.
 */
export interface FamilyRole {
  id: number;
  role: "student" | "payor" | "primary_contact";
}

export interface BaseFamily {
  name?: string;
  roles?: {
    contactId: number;
    displayName?: string;
    role: string;
  }[];
  relationships?: {
    subjectContactId: number;

    // Should this be subjectContactDisplayName? If so, we can use the
    // FamilyRelationshipDetails type here.
    subjectDisplayName?: string;

    objectContactId: number;

    // This may need to be changed to objectContactDisplayName per the API spec.
    objectDisplayName?: string;

    relationship: string;
  }[];
}

export interface FamilyMembers {
  id: string;
  name: string;
  members: FamilyInfo[];
}

export interface FamilyInfo {
  id: number;
  displayName: string;
  preferredPhone: PreferredPhone;
  email: string;
  isPrimaryContact: boolean;
  roles: string[];
  relationships: FamilyRelationshipDetails[];
}

export interface FamilyRelationship {
  subjectContactId: number;
  objectContactId: number;
  relationshipId: string;
}

export interface FamilyRelationshipDetails extends FamilyRelationship {
  id: number;
  subjectContactDisplayName: string;
  objectDisplayName: string;
  relationship: ReferenceRecord & { value: string };
}

export interface FamilyMemberInfo {
  id: number;
  displayName: string;
  preferredPhone: PreferredPhone;
  email: string;
  isPrimaryContact: boolean;
  roles: FamilyRole[];
  relationships: FamilyRelationshipDetails[];
  objectRelationshipNames: string[];
  objectRelationships: FamilyRelationshipDetails[];
  subjectRelationshipNames: string[];
  subjectRelationships: FamilyRelationshipDetails[];
}

export interface FamilySelectionOption {
  id: string | null;
  name: string;
  members: FamilyMemberInfo[];
}

/**
 * A `Family` member or contact's preferred phone number.
 */
export interface PreferredPhone {
  phoneNumber: string;
  type: "mobile" | "home" | "business";
}

export const familyContactSchema = z.object({
  id: z.number().min(1, { message: "Contact Must Be Selected" }),
  displayName: z.string()
});

export type FamilyContact = z.infer<typeof familyContactSchema>;

export interface FamilyContactSummary {
  contactId: number;
  displayName?: string;
  roles?: string[];
  relationships?: {
    subjectContactId: number;
    subjectDisplayName?: string;
    relationship: string;
  }[];
}

export const familyContactDetailResponsePayloadSchema = z.object({
  address_line1: z.string(),
  address_line2: z.string(),
  age: z.number().nullish(),
  business_phone: z.string(),
  city: z.string(),
  display_name: z.string(),
  email: z.string(),
  first_name: z.string(),
  gender: z.string(),
  grade: z.object({ value: z.string(), name: z.string() }).nullish(),
  home_phone: z.string(),
  id: z.number(),
  last_name: z.string(),
  opt_out: z.array(z.enum(["phone", "email", "sms"])),
  personal_phone: z.string(),
  preferred_contact_method: z.string(),
  school: z.string().optional(),
  state: z.string(),
  suffix: z.string(),
  title: z.string(),
  zipcode: zipcodeSchema.or(z.literal("")),
  relationshipId: z.string().optional()
});

export type FamilyContactDetailResponsePayload = z.infer<typeof familyContactDetailResponsePayloadSchema>;

export interface FamilyContactDetail {
  title: string;
  firstName: string;
  lastName: string;
  suffix: string;
  age?: number | "";
  grade?: { value: string; name: string };
  gradeId?: string;
  school?: string;
  gender?: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipcode: string;
  personalPhone: string;
  homePhone: string;
  businessPhone?: string;
  email: string;
  preferredContactMethod?: string;
  optOut?: OptOutOption[];
  relationshipId?: string;
  contactType?: "inquirer" | "student";
}

export interface FamilyContactDetailWithId extends WithId<FamilyContactDetail> {
  displayName: string;
}

export interface FamilySearchResult extends Family {
  contacts: FamilyContactDetailWithId[];
}
