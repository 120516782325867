import React, { type ReactNode, useImperativeHandle, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useModal } from "../modal/modal";

export interface ConfirmProps {
  title?: string;
  message?: ReactNode;
  okLabel?: string;
  cancelLabel?: string;
  size?: "sm" | "lg" | "xl" | "md";
  superZ?: boolean;
  disableConfirm?: boolean;
}

export interface ConfirmHandle {
  confirm: (onConfirm: () => void, onCancel?: () => void) => void;
}

export const Confirm = React.forwardRef<ConfirmHandle, ConfirmProps>(
  (
    {
      title = "Confirm Action",
      message = "message text not specified",
      okLabel = "Yes",
      cancelLabel = "No",
      size = "sm",
      superZ = false,
      disableConfirm = false
    },
    ref
  ) => {
    const { renderModal, show, hide } = useModal({ disableToast: true });

    const handleConfirm = useRef<() => void>();
    const handleCancel = useRef<() => void>();

    useHotkeys("Escape", e => {
      e.preventDefault();
      handleCancel.current && handleCancel.current();
    });

    const confirm = (onConfirm: () => void, onCancel?: () => void) => {
      handleConfirm.current = () => {
        onConfirm && onConfirm();
        hide();
      };
      handleCancel.current = () => {
        onCancel && onCancel();
        hide();
      };
      show();
    };

    useImperativeHandle(ref, () => ({
      confirm
    }));

    return (
      <>
        {renderModal({
          title: title,
          body: message,
          superZ,
          footer: (
            <>
              <button className="btn btn-secondary" onClick={handleCancel.current}>
                {cancelLabel}
              </button>
              <button
                autoFocus={true}
                className="btn btn-primary"
                onClick={handleConfirm.current}
                disabled={disableConfirm}
              >
                {okLabel}
              </button>
            </>
          ),
          centered: true,
          size: size === "md" ? undefined : size,
          backdrop: "static"
        })}
      </>
    );
  }
);

Confirm.displayName = "Confirm";
