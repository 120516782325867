/* istanbul ignore file */

export const familyQueryKeys = {
  base: ["families"],
  constants: () => [...familyQueryKeys.base, "constants"] as const,
  contactDetails: (contactId?: number) => [...familyQueryKeys.base, "contactDetails", { contactId }] as const,
  contactDetail: (contactId?: number) => [...familyQueryKeys.base, "contactDetail", { contactId }] as const,
  members: (familyId?: number) => [...familyQueryKeys.base, "members", { familyId }] as const,
  selection: (centerId: string, inquirerId?: number, studentId?: number) =>
    [...familyQueryKeys.base, "selection", { centerId, inquirerId, studentId }] as const,
  detail: (options: Partial<{ centerId: string; inquiryId: string }>) => [...familyQueryKeys.base, options] as const
} as const;
